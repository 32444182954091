import React, {useEffect, useState} from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import {motion} from "framer-motion";
import medalImg from "../img/Auth/medal.png";
import Button from "../components/CommonComponents/FormButton/FormButton";
import {useTranslation} from "react-i18next";

export default function FormSuccess(props) {
  const { t } = useTranslation();

  const [fire, setFire] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setFire(true);
    }, 100);
  }, []);

  let animationInstance = null;

  const getInstance = instance => {
    animationInstance = instance;
  };

  const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 999
  };

  return(
    <div className="form_success">
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} fire={fire}/>
      <motion.img
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{
          x: { type: "spring", stiffness: 100 },
          default: { delay: 0.4, duration: 0.3 },
        }}
        src={medalImg}
        alt="medal"
      />
      <motion.h1
        animate={{ opacity: 1, translateY: 0 }}
        initial={{ opacity: 0, translateY: "50px" }}
        transition={{
          x: { type: "spring", stiffness: 100 },
          default: { delay: 0.6, duration: 0.3 },
        }}
      >
        {t("questions.final_step.success.title", {year: props.year})}
      </motion.h1>
      <motion.div
        style={{width: "100%", marginTop: "20px"}}
        animate={{ opacity: 1, translateX: 0 }}
        initial={{ opacity: 0, translateX: "-150px" }}
        transition={{
          x: { type: "spring", stiffness: 100 },
          default: { delay: 1, duration: 0.3 },
        }}
      >
        <Button
          style={{
            width: "100%",
            maxWidth: "none"
          }}
          text={t("questions.final_step.success.btn")}
          handleClick={() => props.toFirstStep()}
        />
      </motion.div>
    </div>
  );
}
