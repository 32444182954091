import React, {useEffect, useState} from "react";
import { Table, Tag, Space } from "antd";
import {cookieService} from "../../../services/cookie.service";
import axios from "axios";
import environment from "../../../environment";
import moment from "moment";
import StatusTab from "../../../components/Admin/StatusTab";
import TableAction from "../../../components/Admin/TableAction";
import { Input } from "antd";
import TaxNumber from "../../../components/Admin/TaxNumberTable";

const { Column } = Table;
const { Search } = Input;

export default function FormsTab() {

  const [loading, setLoading] = useState(true);
  const [formsData, setFormsData] = useState([]);

  const getForms = async (search) => {
    const cookieToken = await new cookieService().getAdminTokenData();
    const queryParams = {};
    if (search) {
      Object.assign(queryParams, {"search": search});
    }
    const response = await axios.get(`${environment.baseUrl}admin/form`, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      if (response.data.length) {
        const newArray = [];
        response.data.map((formFromDb, idx) => {
          formFromDb.createdAt = moment(new Date(formFromDb.createdAt)).format("DD.MM.YYYY");
          newArray.push({
            ...formFromDb,
            key: idx.toString()
          });
        });
        setFormsData(newArray);
      } else {
        setFormsData([]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getForms();
  }, []);

  const onSearch = (value) => {
    getForms(value);
  };

  return(
    <div className="admin_tab">
      <Search placeholder="Search" onSearch={onSearch} className="admin_search" />
      {loading
        ? <p>Loading</p>
        : formsData.length
          ? <>
            <Table dataSource={formsData}>
              <Column title="Id" dataIndex="formId" key="formId" sorter={(a, b) => a.formId > b.formId} />
              <Column title="Name" dataIndex="name" key="name" />
              <Column title="Email" dataIndex="email" key="email" />
              <Column title="Finance Status" dataIndex="financeStatus" key="financeStatus" render={(status) => {
                return(
                  <StatusTab messageType={status === "7" ? "success" : "fail"} status={status} type={"fo"} />
                );
              }} />
              <Column title="Device" dataIndex="device" key="device" />
              <Column title="Tax number" render={(data) => {
                return(
                  <TaxNumber data={data} updateTable={() => getForms()} />
                );
              }} />
              <Column title="SVN" dataIndex="svn" key="svn" />
              <Column title="Created" dataIndex="createdAt" key="createdAt" />
              <Column title="Action" render={(data) => {
                return(
                  <TableAction
                    id={data.formId}
                    formStatus={data.status}
                    data={data}
                    updateTable={() => getForms()}
                  />
                );
              }} />
            </Table>
          </>
          : <p>No data</p>
      }
    </div>
  );
}
