import React, {useState} from "react";
import "./QuestionComponentsStyles.css";
import {formatDate, getText} from "../../utils/tools";
import {DatePicker} from "antd";
import moment from "moment";
import Button from "../CommonComponents/FormButton/FormButton";
import {motion} from "framer-motion";
import Autocomplete from "../CommonComponents/Autocomplete";
import {useTranslation} from "react-i18next";
import NavigationButtons from "./NavigationButtons/NavigationButtons";

export default function AddressesComponent(props) {
  const { t } = useTranslation();

  const [buttonBlocked, setButtonBlocked] = useState(false);
  const [answered, setAnswered] = useState(false);

  const endDateFromAnswer = moment(`${props.answr[0].to[0]}.${props.answr[0].to[1]}.${props.ANS.year}`,
    "MM.DD.YYYY").format("MM.DD.YYYY");

  const setDate = (value, index) => {
    if (value) {
      const month = moment(value).format("MM");
      const day = moment(value).format("DD");
      const daysArray = [+month, +day];
      props.answr[0].to = daysArray;
    }
  };

  function disabledDate(current) {
    return current && (current < moment(`01.01.${props.ANS.year}`) || current > moment(`12.31.${props.ANS.year}`));
  }

  return (
    <motion.div
      className="single_choice"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{
        delay: 0.3,
        x: { type: "spring", stiffness: 100 },
        default: { duration: 1 },
      }}
    >

      {props.showButtonBack
        ? <NavigationButtons back={props.backward} forward={() => {
          if (answered) {
            props.forward(props.condition);
          }
        }} />
        : null
      }

      <div className="question_header_box">
        <h6>{getText(props.ANS, props.QS, props.qs.title, props.lang)}</h6>
        {props.qs.dsc && props.qs.dsc.length
          ? <div className="drawer_btn" onClick={() => {
            props.showDrawer(props.qs.dsc);
          }}>
            <i className="fas fa-question"></i>
          </div>
          : null
        }
      </div>
      {
        props.answr.map((item, index) =>
          <div key={`address-${item.from[0]}_${item.from[1]}`} className="addresses_container">
            <div>
              <div>
                <p className="addresses_from">{t("questions.from")}: <span>
                  {formatDate(props.ANS.year, item.from[0], item.from[1], props.lang)}
                </span></p>
              </div>
              <Autocomplete
                qs={props.qs}
                answr={props.answr}
                index={index}
                saveAddress={(answr) => props.setValue(answr)}
              />
            </div>
            <div>
              <p className="addresses_to">{t("questions.to")}: </p>
              <DatePicker
                format="DD.MM.YYYY"
                defaultValue={moment(endDateFromAnswer)}
                onChange={(e) => setDate(e, "from")}
                disabledDate={disabledDate}
                style={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "10px",
                  marginTop: "3px",
                  boxSizing: "border-box",
                  borderRadius: "5px",
                  border: "1px solid rgba(0, 190, 170, 0.5)"
                }}
              />
            </div>
          </div>
        )
      }
      <Button
        text={t("questions.continue")}
        disabled={buttonBlocked || props.blocked}
        handleClick={() => {
          if (!buttonBlocked) {
            setButtonBlocked(true);
            if (!props.blocked) {
              setAnswered(true);
              props.forward(props.condition);
            } else {
              props.showError(props.condition.abort_code);
            }
            setTimeout(() => {
              setButtonBlocked(false);
            }, 500);
          }
        }}
      />
    </motion.div>
  );
}
