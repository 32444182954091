import React, {useEffect, useState} from "react";
import "./CreateTaxNumberView.css";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormikInput from "../../components/CommonComponents/FormikInput/FormikInput";
import {motion} from "framer-motion";
import {DatePicker, Select} from "antd";
import moment from "moment";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import axios from "axios";
import environment from "../../environment";
import {cookieService} from "../../services/cookie.service";
import {useHistory} from "react-router-dom";
import i18next from "../../i18next";

const { Option } = Select;

export default function CreateTaxNumberView() {
  const { t } = useTranslation();
  const history = useHistory();

  // Validate if user comes from form
  useEffect(() => {
    getUserData();
    if (!history || !history.location || !history.location.state || !history.location.state.svn) {
      history.push("/");
    } else {
      formik.setFieldValue("svn", history.location.state.svn.toString(), true);
    }
  }, []);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [showErrorBox, setShowErrorBox] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      family: "",
      sex: "",
      svn: "",
      zip: "",
      place: "",
      street: "",
      homeNumber: "",
      homeFloor: "",
      flatNumber: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("auth.warnings.required")),
      lastName: Yup.string().required(t("auth.warnings.required")),
      family: Yup.string().required(t("auth.warnings.required")),
      sex: Yup.string().required(t("auth.warnings.required")),
      svn: Yup.string().required(t("auth.warnings.required")),
      zip: Yup.string().required(t("auth.warnings.required")),
      place: Yup.string().required(t("auth.warnings.required")),
      street: Yup.string().required(t("auth.warnings.required")),
      homeNumber: Yup.string().required(t("auth.warnings.required")),
      homeFloor: Yup.string().required(t("auth.warnings.required")),
      flatNumber: Yup.string().required(t("auth.warnings.required"))
    }),
    // handle form submitting
    onSubmit: async () => {
      if (formik.isValid && formik.dirty && !buttonLoading) {
        setShowErrorBox(false);
        setButtonLoading(true);
        const cookieToken = await new cookieService().getTokenData();
        const changedValue = {...formik.values, svn: formik.values.svn.replace(/\s/g, "")};
        try {
          const response = await axios.post(
            `${environment.baseUrl}form/createTaxNumber`,
            changedValue,
            {
              headers: {
                Authorization: `Bearer ${cookieToken.refreshToken}`,
                "app-version": "1.0.1"
              }
            });
          if (response && response.data && response.data.taxNumber) {
            setButtonLoading(false);
            history.push("/", {taxNumber: response.data.taxNumber});
          }
        } catch (e) {
          if (e.response && e.response.data && e.response.data.message && e.response.data.message === "wrong_data") {
            setButtonLoading(false);
            setShowErrorBox(true);
          } else {
            history.push("/404");
          }
        }
      }
    },
  });

  const getUserData = async () => {
    const cookieToken = await new cookieService().getTokenData();
    const response = await axios.get(`${environment.baseUrl}user`, {
      headers: {
        Authorization: `Bearer ${cookieToken.refreshToken}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      formik.setFieldValue("firstName", response.data.firstName, true);
      formik.setFieldValue("lastName", response.data.lastName, true);
    }
  };



  const handleSelect = (value, field) => {
    formik.setFieldValue(field, value.toString(), true);
  };

  return(
    <div className="create_tax_page">
      <h1>
        {t("createTax.title")}
      </h1>

      <div className="create_tax_form">
        <form onSubmit={(e) => formik.handleSubmit(e)}>
          <div className="grid_2">
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="firstName"
                name="firstName"
                value={formik.values.firstName}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("createTax.labels.firstName")}
                placeholder="Max"
              />
              {formik.errors.firstName && formik.touched.firstName && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.firstName}
                </motion.p>
              )}
            </div>
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="lastName"
                name="lastName"
                value={formik.values.lastName}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("createTax.labels.lastName")}
                placeholder="Mustermann"
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.lastName}
                </motion.p>
              )}
            </div>
          </div>

          <div style={{height: "15px"}} />

          <div className="grid_2">
            <div>
              <p className="select_label">
                {t("createTax.labels.family")}
              </p>
              <Select onChange={(val) => handleSelect(val, "family")} onBlur={formik.handleBlur("family")}>
                {
                  Array.from(Array(9).keys()).map(num => (
                    <Option
                      key={num.toString()}
                      value={num.toString()}>
                      {t(`createTax.family_options.${num.toString()}`)}
                    </Option>
                  ))
                }
              </Select>
              {formik.errors.family && formik.touched.family && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.family}
                </motion.p>
              )}
              {formik.errors.flatNumber && formik.touched.flatNumber && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.flatNumber}
                </motion.p>
              )}
            </div>
            <div>
              <p className="select_label">
                {t("createTax.labels.sex")}
              </p>
              <Select onChange={(val) => handleSelect(val, "sex")} onBlur={formik.handleBlur("sex")}>
                {
                  Array.from(Array(3).keys()).map(num => (
                    <Option
                      key={num.toString()}
                      value={num.toString()}>
                      {t(`createTax.sex_options.${num.toString()}`)}
                    </Option>
                  ))
                }
              </Select>
              {formik.errors.sex && formik.touched.sex && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.sex}
                </motion.p>
              )}
            </div>
          </div>

          <div style={{height: "15px"}} />

          <div className="grid_2">
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="svn"
                name="svn"
                value={formik.values.svn}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("createTax.labels.svn")}
                placeholder="0000 161096"
              />
              {formik.errors.svn && formik.touched.svn && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.svn}
                </motion.p>
              )}
            </div>
          </div>

          <div style={{height: "15px"}} />

          <div className="grid_2">
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="place"
                name="place"
                value={formik.values.place}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("createTax.labels.place")}
                placeholder="Wien"
              />
              {formik.errors.place && formik.touched.place && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.place}
                </motion.p>
              )}
            </div>
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="street"
                name="street"
                value={formik.values.street}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("createTax.labels.street")}
                placeholder="Ringstraße"
              />
              {formik.errors.street && formik.touched.street && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.street}
                </motion.p>
              )}
            </div>
          </div>

          <div style={{height: "15px"}} />

          <div className="grid_4">
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="zip"
                name="zip"
                value={formik.values.zip}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("createTax.labels.zip")}
                placeholder="1010"
              />
              {formik.errors.zip && formik.touched.zip && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.zip}
                </motion.p>
              )}
            </div>
            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="homeNumber"
                name="homeNumber"
                value={formik.values.homeNumber}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("createTax.labels.homeNumber")}
                placeholder="5"
              />
              {formik.errors.homeNumber && formik.touched.homeNumber && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.homeNumber}
                </motion.p>
              )}
            </div>

            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="homeFloor"
                name="homeFloor"
                value={formik.values.homeFloor}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("createTax.labels.homeFloor")}
                placeholder="1"
              />
              {formik.errors.homeFloor && formik.touched.homeFloor && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.homeFloor}
                </motion.p>
              )}
            </div>

            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="flatNumber"
                name="flatNumber"
                value={formik.values.flatNumber}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("createTax.labels.flatNumber")}
                placeholder="23"
              />
              {formik.errors.flatNumber && formik.touched.flatNumber && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.flatNumber}
                </motion.p>
              )}
            </div>
          </div>

          <Button
            text={t("createTax.submit_btn")}
            style={{
              marginTop: "20px"
            }}
            buttonType="submit"
            disabled={!(formik.isValid && formik.dirty) || buttonLoading}
            loading={buttonLoading}
          />

          {
            showErrorBox
              ? <div className="create_tax_error">
                <i className="fas fa-exclamation-triangle"></i>
                <p>{t("createTax.error_message_1")}<a href="mailto:support@taxefy.at">support@taxefy.at</a></p>
              </div>
              : null
          }

        </form>
        <div />
      </div>
    </div>
  );
}
