import React from "react";
import "./Modals.css";
import {useTranslation} from "react-i18next";
import securityIcon from "../../assets/images/icons/security.jpeg";
import calculationIcon from "../../assets/images/icons/calculation.jpg";
import feeIcon from "../../assets/images/icons/fee.jpg";
import Button from "../CommonComponents/FormButton/FormButton";

export default function NewUserInfoModal(props) {
  const { t } = useTranslation();

  return(
    <div>
      <h3 style={{
        textAlign: "center",
        color: "rgba(0, 190, 170, 1)",
        marginBottom: "15px"
      }}>
        {t("welcome_popup.title")}
      </h3>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
        <img style={{width: "40px"}} src={securityIcon} alt="security"/>
        <p style={{
          marginTop: "5px",
          marginBottom: "3px"
        }}>{t("welcome_popup.first_title")}</p>
        <p style={{textAlign: "center"}}>{t("welcome_popup.first_text")}</p>
      </div>

      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
        <img style={{width: "40px"}} src={calculationIcon} alt="calculation"/>
        <p style={{
          marginTop: "5px",
          marginBottom: "3px"
        }}>{t("welcome_popup.second_title")}</p>
        <p style={{textAlign: "center"}}>{t("welcome_popup.second_text")}</p>
      </div>

      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
        <img style={{width: "40px"}} src={feeIcon} alt="fee"/>
        <p style={{
          marginTop: "5px",
          marginBottom: "3px"
        }}>{t("welcome_popup.third_title")}</p>
        <p style={{textAlign: "center"}}>{t("welcome_popup.third_text")}</p>
      </div>
      <div style={{
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Button
          handleClick={() => {
            window.localStorage.setItem("termsAreAccepted", "true");
            props.close();
          }}
          text={t("welcome_popup.btn")}
        />
      </div>
    </div>
  );
}
