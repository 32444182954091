import { strings } from "../localization";
import { MONTHS, regex } from "./constants";
import axios from "axios";
import environment from "../environment";
import {cookieService} from "../services/cookie.service";

export function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export const generateAnsObj = (Qs, lang) => {
  const Ans = {
    country: Qs.country,
    year: Qs.year,
    version: Qs.version,
    date: Qs.date,
    language: Qs.languages.indexOf(lang) !== -1 ? lang : Qs.default_language,
    current_group: 1,
    groups: [],
    loops: [],
    distance: null,
    calculations: null
  };
  for (const group of Qs.groups) {
    Ans.groups.push({
      id: group.id,
      current_question: 1,
      completed: false,
      questions: group.questions.map(item => ({
        id: item.id,
        previous_question: -1,
        preloop_question: -1,
        ui_type: item.answer.ui_type,
        answer: item.answer.default_value,
        loop: item.loop
      }))
    });
  }
  for (const loop of Qs.loops) {
    Ans.loops.push({
      id: loop.id,
      i: 0,
      count: 1
    });
  }
  return Ans;
};

export const getLang = (QS, lang) => {
  if (QS.languages.indexOf(lang) !== -1) return lang;
  return QS.default_language;
};

export const getText = (ANS, QS, texts, lang) => {
  let text = texts[lang];
  let idx;
  while ((idx = text.indexOf("${")) >= 0) {
    const part1 = text.substr(0, idx);
    text = text.substr(idx + 2);
    idx = text.indexOf("}");
    const expId = parseInt(text.substr(0, idx));
    text =
      part1 + solveExpressionById(ANS, QS, expId, lang) + text.substr(idx + 1);
  }
  return text;
};

export const findGroupIndexById = (QS, id) =>
  QS.groups.map(item => item.id).indexOf(id);

export const findEquivalentGroupAns = (groupQs, ANS) => {
  const idx = findGroupIndexById(ANS, groupQs.id);
  return ANS.groups[idx];
};

export const findQuestionIndexById = (group, id) =>
  group.questions.map(item => item.id).indexOf(id);

export const findQuestionById = (group, id) => {
  const idx = findQuestionIndexById(group, id);
  return group.questions[idx];
};

export const findAnswer = (ANS, QS, groupId, questionId, loop_i = null) => {
  const groupIdx = findGroupIndexById(ANS, groupId);
  const questionIdx = findQuestionIndexById(ANS.groups[groupIdx], questionId);
  if (QS.groups[groupIdx].questions[questionIdx].loop === undefined) {
    return ANS.groups[groupIdx].questions[questionIdx].answer;
  } else {
    if (loop_i === null) {
      const loop = ANS.loops.filter(
        item => item.id === QS.groups[groupIdx].questions[questionIdx].loop
      );
      if (loop.length === 1) {
        const i = loop[0].i - 1;
        if (
          i >= 0 &&
          i < loop[0].count &&
          ANS.groups[groupIdx].questions[questionIdx].answer.length ===
            loop[0].count
        ) {
          return ANS.groups[groupIdx].questions[questionIdx].answer[i];
        }
      }
    } else if (
      loop_i >= 0 &&
      loop_i < ANS.groups[groupIdx].questions[questionIdx].answer.length
    ) {
      return ANS.groups[groupIdx].questions[questionIdx].answer[loop_i];
    }
  }
  return QS.groups[groupIdx].questions[questionIdx].answer.default_value;
};

export const findAnswerNumber = (
  ANS,
  QS,
  groupId,
  questionId,
  loop_i = null
) => {
  const ans = findAnswer(ANS, QS, groupId, questionId, loop_i);
  return ans.replace(",", ".");
};

export const setAnswer = (ANS, QS, groupId, questionId, ans) => {
  const groupIdx = findGroupIndexById(ANS, groupId);
  const questionIdx = findQuestionIndexById(ANS.groups[groupIdx], questionId);
  if (QS.groups[groupIdx].questions[questionIdx].loop === undefined) {
    ANS.groups[groupIdx].questions[questionIdx].answer = ans;
  } else {
    const loop = ANS.loops.filter(
      item => item.id === QS.groups[groupIdx].questions[questionIdx].loop
    );
    if (loop.length === 1) {
      const i = loop[0].i - 1;
      if (
        i >= 0 &&
        i < loop[0].count &&
        ANS.groups[groupIdx].questions[questionIdx].answer.length ===
          loop[0].count
      ) {
        ANS.groups[groupIdx].questions[questionIdx].answer[i] = ans;
      }
    }
  }
  return ANS;
};

export const solveExpressionById = (ANS, QS, expId, lang) => {
  const exp = QS.expressions.filter(item => item.id === expId);
  if (exp.length === 1)
    return solveExpression(
      ANS,
      QS,
      checkExpectations(ANS, exp[0]) ? exp[0].passed : exp[0].otherwise,
      lang
    );
  else return "";
};

export const solveExpression = (ANS, QS, expression, lang) => {
  switch (expression.type) {
  case "answer": {
    const ans = findAnswer(ANS, QS, expression.group, expression.question);
    const ansObj = findAnswerObj(ANS, expression.group, expression.question);
    switch (ansObj.ui_type) {
    case "form":
      return finalize(ans[expression.index], expression);
    default:
      return finalize(ans, expression);
    }
  }
  case "loopindex":
    {
      const loop = ANS.loops.filter(item => item.id === expression.loop);
      if (loop.length === 1) {
        return finalize(
          expression.ordinal ? ordinal(loop[0].i, lang) : loop[0].i,
          expression
        );
      }
    }
    break;
  case "loopcount":
    {
      const loop = ANS.loops.filter(item => item.id === expression.loop);
      if (loop.length === 1) {
        return finalize(loop[0].count, expression);
      }
    }
    break;
  case "constant":
    return finalize(expression.value, expression);
  }
  return "";
};

const finalize = (val, exp) =>
  exp.data_type === "string"
    ? `${exp.prefix || ""}${val}${exp.suffix || ""}`
    : val;

const findAnswerObj = (ANS, groupId, questionId) => {
  const groupIdx = findGroupIndexById(ANS, groupId);
  const questionIdx = findQuestionIndexById(ANS.groups[groupIdx], questionId);
  return ANS.groups[groupIdx].questions[questionIdx];
};

export const findConditionByAnswer = (
  question,
  answer,
  ANS = null,
  QS = null
) => {
  let allMatched = true;
  for (let i = 1; i < question.conditions.length; i++) {
    switch (question.answer.ui_type) {
    case "options":
    case "slider":
      if (
        answer >= question.conditions[i].min &&
        answer <= question.conditions[i].max &&
        checkExpectations(ANS, question.conditions[i], QS)
      )
        return question.conditions[i];
      break;
    case "multiple":
      allMatched = true;
      for (const pat of question.conditions[i].pattern) {
        const index = answer.indexOf(Math.abs(pat));
        if ((index < 0 && pat > 0) || (index >= 0 && pat < 0)) {
          allMatched = false;
          break;
        }
      }
      if (allMatched && checkExpectations(ANS, question.conditions[i], QS))
        return question.conditions[i];
      break;
    case "addresses":
      allMatched = true;
      for (const addr of answer) {
        if (
          !addr.address ||
          addr.address.length < question.answer.min_length ||
          addr.address.length > question.answer.max_length
        ) {
          allMatched = false;
          break;
        }
      }
      if (allMatched && checkExpectations(ANS, question.conditions[i], QS))
        return question.conditions[i];
      break;
    case "input":
    case "job":
      if (
        answer &&
        answer.length >= question.answer.min_length &&
        answer.length <= question.answer.max_length
      )
        if (checkExpectations(ANS, question.conditions[i], QS))
          return question.conditions[i];
      break;
    case "workperiod":
      if (checkExpectations(ANS, question.conditions[i], QS))
        return question.conditions[i];
      break;
    case "workaddress":
      if (
        answer &&
        answer.length >= question.answer.min_length &&
        answer.length <= question.answer.max_length
      )
        if (checkExpectations(ANS, question.conditions[i], QS))
          return question.conditions[i];
      break;
    case "form":
      allMatched = true;
      for (let j = 0; j < answer.length; j++) {
        if (
          !answer[j] ||
          answer[j].length < question.answer.min_length[j] ||
          answer[j].length > question.answer.max_length[j] ||
          !validateText(answer[j], question.answer.data_type[j])
        ) {
          allMatched = false;
          break;
        }
      }
      if (allMatched && checkExpectations(ANS, question.conditions[i], QS))
        return question.conditions[i];
      break;
    }
  }
  // 0 => default condition
  return question.conditions[0];
};

export const validateText = (text, type) => {
  switch (type) {
  case "jobtitle":
    return regex.JOBTITLE.test(text);
  case "address":
    return regex.ADDRESS.test(text);
  case "salary":
    return regex.SALARY.test(text);
  case "svn":
    return regex.SVN.test(text);
  case "digits":
    return regex.DIGITS.test(text);
  case "name":
    return regex.NAMES.test(text);
  case "text":
    return regex.GENERAL.test(text);
  case "date":
    return regex.DATE.test(text);
  case "iban":
    return !text || text.length !== 20 || regex.IBAN.test(text);
  case "bic":
    return !text || text.length !== 11 || regex.BIC.test(text);
  default:
    return false;
  }
};

export const validateTextLength = (text, min, max) => {
  return text && text.length >= min && text.length <= max;
};

export const checkExpectations = (ANS, condition, QS = null, loop_i = null) => {
  if (!ANS || !condition.expectations || condition.expectations.length === 0)
    return true;
  let result = "true";
  for (const expectation of condition.expectations) {
    const groupIdx = findGroupIndexById(ANS, expectation.group);
    const questionIdx = findQuestionIndexById(
      ANS.groups[groupIdx],
      expectation.question
    );
    const question = ANS.groups[groupIdx].questions[questionIdx];
    //
    if (question.loop !== undefined && loop_i === null && QS !== null) {
      // looking for one occurance in loop -> check the whole array
      for (let i = 0; i < question.answer.length; i++)
        if (checkExpectations(ANS, condition, QS, i)) return true;
      return false;
    }
    //
    const answer =
      question.loop === undefined
        ? question.answer
        : findAnswer(ANS, QS, expectation.group, expectation.question, loop_i);
    let allPassed = true;
    switch (question.ui_type) {
    case "options":
    case "slider":
      result = checkOperator(
        result,
        answer <= expectation.max && answer >= expectation.min,
        expectation
      );
      break;
    case "multiple":
      for (const pat of expectation.pattern) {
        const index = answer.indexOf(Math.abs(pat));
        if ((index < 0 && pat > 0) || (index >= 0 && pat < 0)) {
          allPassed = false;
          break;
        }
      }
      result = checkOperator(result, allPassed, expectation);
      break;
    }
  }
  return eval(result);
};

const checkOperator = (inp, val, expectation) => {
  let operator = "";
  switch (expectation.operator) {
  case "OR":
    operator = "||";
    break;
  case "AND":
  default:
    operator = "&&";
  }
  return `${inp} ${operator} ${val}`;
};

export const checkVisibility = (item, ANS) => {
  return !item.visibility || checkExpectations(ANS, item.visibility);
};

export const formatDate = (year, month, day, lang) => {
  const dd = ordinal(day, lang);
  switch (lang) {
  case "en":
    return `${year}, ${strings.months[month - 1].longName} ${dd}`;
  case "fa":
    return `${dd} ${strings.months[month - 1].longName} ${year}`;
  case "de":
  default:
    return `${year}, ${strings.months[month - 1].longName} ${dd}`;
  }
};

const ordinal = (num, lang) => {
  switch (lang) {
  case "en":
    return `${num}${
      num % 20 === 1
        ? "st"
        : num % 20 === 2
          ? "nd"
          : num % 20 === 3
            ? "rd"
            : "th"
    }`;
  case "fa":
    return `${num}`;
  case "de":
  default:
    return `${num}.`;
  }
};

export const getLocale = (lang, country) =>
  `${lang.toLowerCase()}-${country.toUpperCase()}`;

export const getMonthLength = (year, month) =>
  MONTHS[month - 1].daysCount[year % 4 == 0 ? 0 : 1];

export const normalizePeriods = (ans, index, year) => {
  const result = [];
  const nextDay = [ans[index].to[0], ans[index].to[1] + 1];
  if (nextDay[1] > getMonthLength(year, nextDay[0])) {
    nextDay[0] = nextDay[0] + 1;
    nextDay[1] = 1;
  }
  for (let i = 0; i < ans.length; i++) {
    if (i <= index) result.push({...ans[i]});
    else {
      if (
        ans[i].to[0] > nextDay[0] ||
        (ans[i].to[0] == nextDay[0] && ans[i].to[1] > nextDay[1])
      ) {
        if (
          i === index + 1 ||
          ans[i].from[0] < nextDay[0] ||
          (ans[i].from[0] == nextDay[0] && ans[i].from[1] < nextDay[1])
        ) {
          result.push({
            ...ans[i],
            from: nextDay
          });
        } else result.push({...ans[i]});
      }
    }
  }
  if (nextDay[0] <= 12) {
    if (
      result[result.length - 1].to[0] < nextDay[0] ||
      (result[result.length - 1].to[0] === nextDay[0] &&
        result[result.length - 1].to[1] < nextDay[1])
    ) {
      result.push({
        from: nextDay,
        to: [12, 31],
        address: ""
      });
    } else {
      if (result[result.length - 1].to[0] !== 12)
        result[result.length - 1].to[0] = 12;
      if (result[result.length - 1].to[1] !== 31)
        result[result.length - 1].to[1] = 31;
    }
  }
  return result;
};

export const normalize1Period = (ans, year) => {
  const result = {...ans};
  const fromMonthLength = getMonthLength(year, ans.from[0]);
  if (result.from[1] > fromMonthLength) result.from[1] = fromMonthLength;

  if (result.from[0] > result.to[0]) {
    result.to[0] = result.from[0];
    result.to[1] = result.from[1];
  } else if (result.from[0] === result.to[0]) {
    if (result.to[1] < result.from[1]) {
      result.to[1] = result.from[1];
    }
  }

  const toMonthLength = getMonthLength(year, ans.to[0]);
  if (result.to[1] > toMonthLength) result.to[1] = toMonthLength;
  return result;
};

export const calculateTax = (QS, ANS, lang, pendler = true) => {
  const result = {
    AVG12: 0,
    PAID_TAX: {
      SV_1_12: 0,
      SV_13_14: 0,
      LST_1_12: 0,
      LST_13: 0,
      LST_14: 0,
      TOTAL: 0
    },
    NEW_TAX: {
      SV_1_12: 0,
      SV_13_14: 0,
      LST_1_12: 0,
      LST_13: 0,
      LST_14: 0,
      TOTAL: 0
    },
    AP1: 0,
    AP2: 0,
    AW: 0,
    DEV: 0,
    NET: 0,
    F1: 0,
    F2: 0,
    F3: 0,
    F4: 0,
    G1: 0,
    G2: 0,
    K1: 0,
    K2: 0,
    AG: 0,
    negative: 0
  };
  const jobCount = solveExpressionById(
    ANS,
    QS,
    QS.calculation.general.job_count,
    lang
  );
  const childCount = solveExpressionById(
    ANS,
    QS,
    QS.calculation.general.child_count,
    lang
  );
  // ------------------------------------------------------------------------------------------------------------------------------------
  result.AVG12 = calculateAvg12(QS, ANS, jobCount);
  //
  result.PAID_TAX.SV_1_12 = calculateSv_1_12(QS, result.AVG12);
  result.PAID_TAX.SV_13_14 = calculateSv_13_14(QS, result.AVG12);
  result.PAID_TAX.LST_1_12 = calculateLst_1_12(
    QS,
    result.AVG12 - result.PAID_TAX.SV_1_12
  );
  result.PAID_TAX.LST_13 = calculateLst_13_14(
    QS,
    result.AVG12 -
      result.PAID_TAX.SV_13_14 -
      QS.calculation.general.LST.TAXFREE_13
  );
  result.PAID_TAX.LST_14 = calculateLst_13_14(
    QS,
    result.AVG12 - result.PAID_TAX.SV_13_14
  );
  // result.PAID_TAX.TOTAL = result.PAID_TAX.LST_1_12 * 12 + result.PAID_TAX.LST_13 + result.PAID_TAX.LST_14;
  result.PAID_TAX.TOTAL = calculatePaidTax(QS, ANS, jobCount);
  //
  const AP = pendler
    ? calculateAp1And2(QS, ANS, jobCount, lang)
    : {AP1: 0, AP2: 0};
  result.AP1 = AP.AP1;
  result.AP2 = AP.AP2;
  const AW = calculateAw(QS, ANS);
  result.AW = AW.total;
  result.DEV = AW.dev;
  result.NET = AW.net;
  //
  result.F1 = calculateF1(QS, ANS, childCount); // !!! NEEDED
  result.F2 = calculateF2(QS, ANS, childCount);
  result.F3 = calculateF3(QS, ANS, childCount);
  result.F4 = calculateF4(QS, ANS, childCount);
  //
  result.G1 = calculateG1(QS, ANS);
  result.G2 = calculateG2(QS, ANS, result.AVG12);
  //
  result.K1 = calculateK1(QS, ANS, childCount);
  result.K2 = calculateK2(QS, ANS, childCount);
  //
  result.AG = calculateAg(QS, ANS); // !!! NEEDED

  //console.log("HH:", calculateDeductibles(QS, result.AVG12 - result.PAID_TAX.SV_1_12));
  //
  const deduction =
    (result.AW + result.AP2 + result.G1 + result.G2 + result.K1 + result.AG) /
    12;
  const newAvg = result.AVG12 - deduction;
  result.NEW_TAX.SV_1_12 = result.PAID_TAX.SV_1_12; //calculateSv_1_12(QS, newAvg);
  result.NEW_TAX.SV_13_14 = result.PAID_TAX.SV_13_14; //calculateSv_13_14(QS, newAvg);
  result.NEW_TAX.LST_1_12 = calculateLst_1_12(
    QS,
    newAvg - result.NEW_TAX.SV_1_12
  );
  result.NEW_TAX.LST_13 = calculateLst_13_14(
    QS,
    newAvg - result.NEW_TAX.SV_13_14 - QS.calculation.general.LST.TAXFREE_13
  );
  result.NEW_TAX.LST_14 = calculateLst_13_14(
    QS,
    newAvg - result.NEW_TAX.SV_13_14
  );
  result.NEW_TAX.TOTAL = notLessThanZero(
    result.NEW_TAX.LST_1_12 * 12 +
      result.NEW_TAX.LST_13 +
      result.NEW_TAX.LST_14 -
      (result.AP1 +
        result.F1 +
        result.F2 +
        result.F3 +
        result.F4 +
        result.K2 +
        calculateDeductibles(QS, result.AVG12 - result.PAID_TAX.SV_1_12))
  );
  //
  if (result.NEW_TAX.TOTAL === 0 && result.AVG12 > 0) {
    const limit = result.AP1 > 0 ? 500 : 400;
    result.negative = calculateNegativeTax(QS, ANS, jobCount, limit);
    result.negative += result.F2 + result.F3 + calculateNg(QS, ANS, childCount);
  }
  // ------------------------------------------------------------------------------------------------------------------------------------
  //console.log(result);
  return result;
};

const calculateDeductibles = (QS, avg12) => {
  // calculate deductibles:
  if (!QS.calculation.deduct) return 0;
  let total = 0;
  const avg = avg12 * 12;
  for (const deduct of QS.calculation.deduct) {
    if (!deduct || deduct.amount <= 0 || avg >= deduct.max) continue;
    if (avg <= deduct.min) total += deduct.amount;
    else {
      const range = deduct.max - deduct.min;
      const value = avg - deduct.min;
      total += Math.floor(deduct.amount - (deduct.amount * value) / range);
    }
  }
  return total > 0 ? total : 0;
};

const notLessThanZero = val => (val < 0 ? 0 : val);

const calculateNg = (QS, ANS, childCount) => {
  // calculate negative tax per kid:
  let result = 0;
  if (checkExpectations(ANS, QS.calculation.NG)) {
    result = QS.calculation.NG.amount * childCount;
  }
  return result;
};

const calculateNegativeTax = (QS, ANS, jobCount, limit) => {
  let paid_SV = 0;
  for (let i = 0; i < jobCount; i++) {
    const period = findAnswer(
      ANS,
      QS,
      QS.calculation.income.period.group,
      QS.calculation.income.period.question,
      i
    );
    const salary = parseFloat(
      `0${findAnswerNumber(
        ANS,
        QS,
        QS.calculation.income.salary_euro.group,
        QS.calculation.income.salary_euro.question,
        i
      )}`
    );
    const length = period.to[0] - period.from[0] + 1;
    //
    const SV_1_12 = calculateSv_1_12(QS, salary);
    paid_SV += length * SV_1_12;
    const L13_14 = (salary * length) / 12;
    const SV_13_14 = calculateSv_13_14(QS, L13_14);
    paid_SV += SV_13_14 * 2;
  }
  return notLessThanZero(paid_SV > limit ? limit : paid_SV);
};

const calculatePaidTax = (QS, ANS, jobCount) => {
  let TAX_1_12 = 0;
  let TAX_13 = 0;
  let TAX_14 = 0;
  for (let i = 0; i < jobCount; i++) {
    const period = findAnswer(
      ANS,
      QS,
      QS.calculation.income.period.group,
      QS.calculation.income.period.question,
      i
    );
    const salary = parseFloat(
      `0${findAnswerNumber(
        ANS,
        QS,
        QS.calculation.income.salary_euro.group,
        QS.calculation.income.salary_euro.question,
        i
      )}`
    );
    const length = period.to[0] - period.from[0] + 1;
    // console.log(period, salary, length);
    const SV_1_12 = calculateSv_1_12(QS, salary);
    const LST_1_12 = calculateLst_1_12(QS, salary - SV_1_12);
    TAX_1_12 += length * LST_1_12;

    const L13_14 = (salary * length) / 12;
    const SV_13_14 = calculateSv_13_14(QS, L13_14);
    TAX_13 += notLessThanZero(
      calculateLst_13_14(
        QS,
        L13_14 - SV_13_14 - QS.calculation.general.LST.TAXFREE_13
      )
    );
    TAX_14 += notLessThanZero(calculateLst_13_14(QS, L13_14 - SV_13_14));

    /*
        if(period.to[0] >= 6 && period.from[0] <= 6){
            // June -> 13th salary
            TAX_13 = calculateLst_13_14(QS, salary - SV_13_14 - QS.calculation.general.LST.TAXFREE_13);
        }
        if(period.to[0] >= 11 && period.from[0] <= 11){
            // November -> 14th salary
            TAX_14 = calculateLst_13_14(QS, salary - SV_13_14);
        }
        //*/
  }
  return TAX_1_12 + TAX_13 + TAX_14;
};

const calculateAvg12 = (QS, ANS, jobCount) => {
  // calculate average monthly gross income:
  let result = 0;
  for (let i = 0; i < jobCount; i++) {
    const period = findAnswer(
      ANS,
      QS,
      QS.calculation.income.period.group,
      QS.calculation.income.period.question,
      i
    );
    const salary = parseFloat(
      `0${findAnswerNumber(
        ANS,
        QS,
        QS.calculation.income.salary_euro.group,
        QS.calculation.income.salary_euro.question,
        i
      )}`
    );
    const length = period.to[0] - period.from[0] + 1;
    result += length * salary;
  }
  return result / 12;
};

const calculateSv_1_12 = (QS, avg12) => {
  // calculate social security for a regular month:
  for (const item of QS.calculation.general.SV.items) {
    if (avg12 <= item.max) {
      const val = item.fixed ? item.amount_1_12 : avg12 * item.amount_1_12;
      return val < 0 ? 0 : val;
    }
  }
  return 0;
};

const calculateSv_13_14 = (QS, avg12) => {
  // calculate social security for 13th and 14th months:
  for (const item of QS.calculation.general.SV.items) {
    if (avg12 <= item.max) {
      const val = item.fixed ? item.amount_13_14 : avg12 * item.amount_13_14;
      return val < 0 ? 0 : val;
    }
  }
  return 0;
};

const calculateLst_1_12 = (QS, total) => {
  // calculate tax for a regular months:
  let paid = 0;
  let result = 0;
  for (const item of QS.calculation.general.LST.LST_1_12) {
    if (total <= item.max) {
      result += (total - paid) * item.amount;
      break;
    } else {
      const portion = item.max - paid;
      result += portion * item.amount;
      paid = item.max;
    }
  }
  return result < 0 ? 0 : result;
};

const calculateLst_13_14 = (QS, total) => {
  // calculate tax for 13th months:
  for (const item of QS.calculation.general.LST.LST_13_14) {
    if (total <= item.max) {
      const val = total * item.amount;
      return val < 0 ? 0 : val;
    }
  }
  return 0;
};

const calculateAp1And2 = (QS, ANS, jobCount, lang) => {
  // calculate AP1 and AP2:
  const result = {
    AP1: 0,
    AP2: 0
  };
  if (
    !ANS.distance ||
    !ANS.distance.origIds ||
    ANS.distance.origIds.length === 0 ||
    !ANS.distance.destIds ||
    ANS.distance.destIds.length === 0 ||
    !ANS.distance.matrix
  )
    return result;
  const amounts = [];
  const ap1amounts = [];
  const livingAddresses = findAnswer(
    ANS,
    QS,
    QS.calculation.AP2.living_address.group,
    QS.calculation.AP2.living_address.question
  );
  for (let i = 0; i < jobCount; i++) {
    if (checkExpectations(ANS, QS.calculation.AP2, QS, i)) {
      // car transportation
      const amount = new Array(12);
      const ap1amount = new Array(12);
      const period = findAnswer(
        ANS,
        QS,
        QS.calculation.income.period.group,
        QS.calculation.income.period.question,
        i
      );
      const publicTransportPossible =
        ANS.distance.pt_possible === undefined
          ? findAnswer(
            ANS,
            QS,
            QS.calculation.AP2.public_transport_possible.group,
            QS.calculation.AP2.public_transport_possible.question,
            i
          )
          : ANS.distance.pt_possible;
      const commuteTimes = findAnswer(
        ANS,
        QS,
        QS.calculation.AP2.commute_times.group,
        QS.calculation.AP2.commute_times.question,
        i
      );
      for (let j = 0; j < 12; j++) {
        const month = j + 1;
        amount[j] = 0;
        ap1amount[j] = 0;
        if (month >= period.from[0] && month <= period.to[0]) {
          // find home address in this month
          let set = false;
          for (let k = 0; k < livingAddresses.length && !set; k++) {
            if (
              (livingAddresses[k].from[0] < month &&
                livingAddresses[k].to[0] > month) ||
              (livingAddresses[k].from[0] === month &&
                livingAddresses[k].from[1] <= 15) ||
              (livingAddresses[k].to[0] === month &&
                livingAddresses[k].to[1] >= 15)
            ) {
              // find origin and destination id index
              const orig = ANS.distance.origIds.indexOf(k);
              const dest = ANS.distance.destIds.indexOf(i);
              if (
                orig >= 0 &&
                dest >= 0 &&
                ANS.distance.matrix.rows.length > orig &&
                ANS.distance.matrix.rows[orig].elements.length > dest &&
                ANS.distance.matrix.rows[orig].elements[dest].status === "OK"
              ) {
                const distance =
                  ANS.distance.matrix.rows[orig].elements[dest].distance.value /
                  1000; // KM
                let relatedArray = [];
                switch (commuteTimes) {
                case QS.calculation.AP2.commute_times.times_4_7.value:
                  if (
                    publicTransportPossible ===
                    QS.calculation.AP2.public_transport_possible.yes
                  )
                    relatedArray =
                      QS.calculation.AP2.commute_times.times_4_7.small;
                  if (
                    publicTransportPossible ===
                    QS.calculation.AP2.public_transport_possible.no
                  )
                    relatedArray =
                      QS.calculation.AP2.commute_times.times_4_7.big;
                  break;
                case QS.calculation.AP2.commute_times.times_8_10.value:
                  if (
                    publicTransportPossible ===
                    QS.calculation.AP2.public_transport_possible.yes
                  )
                    relatedArray =
                      QS.calculation.AP2.commute_times.times_8_10.small;
                  if (
                    publicTransportPossible ===
                    QS.calculation.AP2.public_transport_possible.no
                  )
                    relatedArray =
                      QS.calculation.AP2.commute_times.times_8_10.big;
                  break;
                case QS.calculation.AP2.commute_times.times_11_x.value:
                  if (
                    publicTransportPossible ===
                    QS.calculation.AP2.public_transport_possible.yes
                  )
                    relatedArray =
                      QS.calculation.AP2.commute_times.times_11_x.small;
                  if (
                    publicTransportPossible ===
                    QS.calculation.AP2.public_transport_possible.no
                  )
                    relatedArray =
                      QS.calculation.AP2.commute_times.times_11_x.big;
                  break;
                }
                for (const item of relatedArray) {
                  if (distance >= item.min && distance <= item.max) {
                    amount[j] = item.amount / 12;
                    ap1amount[j] =
                      (distance * QS.calculation.AP1.multiply) / 12;
                    set = true;
                    break;
                  }
                }
              }
              break;
            }
          }
        }
      }
      amounts.push(amount);
      ap1amounts.push(ap1amount);
    }
  }
  if (amounts.length > 0)
    for (let i = 0; i < 12; i++) {
      let max = amounts[0][i];
      let ap1 = ap1amounts[0][i];
      for (let j = 1; j < amounts.length; j++) {
        if (amounts[j][i] > max) {
          max = amounts[j][i];
          ap1 = ap1amounts[j][i];
        }
      }
      result.AP2 += max;
      result.AP1 += ap1;
    }
  return result;
};

const calculateAw = (QS, ANS) => {
  // calculate AW:
  let result = 0;
  for (const item of QS.calculation.AW.items) {
    if (checkExpectations(ANS, item)) {
      const ans = parseFloat(
        `0${findAnswerNumber(ANS, QS, item.group, item.question)}`
      );
      result += eval(item.expression.replace("$", ans));
    }
  }
  const dev = calculateDev(QS, ANS);
  const net = calculateNet(QS, ANS);
  result += dev;
  result += net;
  return {
    total: result < QS.calculation.AW.min ? QS.calculation.AW.min : result,
    dev,
    net
  };
};

const calculateDev = (QS, ANS) => {
  // calculate DEV (laptop, mobile):
  let result = 0;
  for (const item of QS.calculation.DEV.items) {
    if (checkExpectations(ANS, item)) {
      const price = parseFloat(
        `0${findAnswerNumber(ANS, QS, item.price.group, item.price.question)}`
      );
      const usage =
        findAnswer(ANS, QS, item.usage.group, item.usage.question) / 100;
      result +=
        (price < item.limit ? price : item.limit / item.devideBy) * usage;
    }
  }
  return result;
};

export const calculateDev1item = (QS, ANS, ID) => {
  // calculate DEV (laptop, mobile): 1 item
  const items = QS.calculation.DEV.items.filter(i => i.ID === ID);
  if (items.length !== 1) return 0;
  const item = items[0];
  let result = 0;
  if (checkExpectations(ANS, item)) {
    const price = parseFloat(
      `0${findAnswerNumber(ANS, QS, item.price.group, item.price.question)}`
    );
    const usage =
      findAnswer(ANS, QS, item.usage.group, item.usage.question) / 100;
    result = (price < item.limit ? price : item.limit / item.devideBy) * usage;
  }
  return result;
};

const calculateNet = (QS, ANS) => {
  // calculate NET (Mobile/wifi tariff):
  let result = 0;
  for (const item of QS.calculation.NET.items) {
    if (checkExpectations(ANS, item)) {
      const price = parseFloat(
        `0${findAnswerNumber(ANS, QS, item.price.group, item.price.question)}`
      );
      const usage =
        findAnswer(ANS, QS, item.usage.group, item.usage.question) / 100;
      let period = 12;
      if (
        findAnswer(ANS, QS, item.is12.group, item.is12.question) !==
        item.is12.yes
      ) {
        const prd = findAnswer(
          ANS,
          QS,
          item.period.group,
          item.period.question
        );
        period = prd.to - prd.from + 1;
      }
      result += price * usage * period;
    }
  }
  return result;
};

export const calculateNet1item = (QS, ANS, ID) => {
  // calculate NET (Mobile/wifi tariff): 1 item
  const items = QS.calculation.NET.items.filter(i => i.ID === ID);
  if (items.length !== 1) return 0;
  const item = items[0];
  let result = 0;
  if (checkExpectations(ANS, item)) {
    const price = parseFloat(
      `0${findAnswerNumber(ANS, QS, item.price.group, item.price.question)}`
    );
    const usage =
      findAnswer(ANS, QS, item.usage.group, item.usage.question) / 100;
    let period = 12;
    if (
      findAnswer(ANS, QS, item.is12.group, item.is12.question) !== item.is12.yes
    ) {
      const prd = findAnswer(ANS, QS, item.period.group, item.period.question);
      period = prd.to - prd.from + 1;
    }
    result = price * usage * period;
  }
  return result;
};

const calculateAg = (QS, ANS) => {
  // calculate AG:
  let result = 0;
  for (const item of QS.calculation.AG.items) {
    if (checkExpectations(ANS, item)) {
      const ans = parseFloat(
        `0${findAnswerNumber(ANS, QS, item.group, item.question)}`
      );
      result += eval(item.expression.replace("$", ans));
    }
  }
  return result;
};

const calculateF1 = (QS, ANS, childCount) => {
  // calculate F1:
  let result = 0;
  for (let i = 0; i < childCount; i++) {
    if (checkExpectations(ANS, QS.calculation.F1, QS, i)) {
      const kid = findAnswer(
        ANS,
        QS,
        QS.calculation.F1.SVN.group,
        QS.calculation.F1.SVN.question,
        i
      );
      if (!kid) continue;
      let SVN = kid[QS.calculation.F1.SVN.index];
      SVN = SVN.length < 10 ? "0101010101" : SVN;
      const SVNY = parseInt(`0${SVN.substr(8, 2)}`);
      // For 2020 -> 06 = 2006, 07 = 1907 (Person can not be younger than 14 years)
      // Every next year the „break“ shifts + 1 year e.g. For 2021 -> 07 = 2007, 08 = 1908 (Person can not be younger than 14 years)
      // const pivot = QS.year - 2014;
      const pivot = QS.year - 2000;
      //const year = (SVNY < 30 ? 2000 : 1900) + SVNY;
      const year = (SVNY > pivot ? 1900 : 2000) + SVNY;
      if (QS.year - year < 18) {
        result += QS.calculation.F1.under_18;
      } else {
        result += QS.calculation.F1.over_18;
      }
    }
  }
  return result < 0 ? 0 : result;
};

const calculateF2 = (QS, ANS, childCount) => {
  // calculate F2:
  let result = 0;
  if (childCount > 0)
    if (checkExpectations(ANS, QS.calculation.F2)) {
      if (childCount === 1) {
        result = QS.calculation.F2.kids_1;
      } else {
        result = QS.calculation.F2.kids_2;
        result += (childCount - 2) * QS.calculation.F2.extra;
      }
    }
  return result < 0 ? 0 : result;
};

const calculateF3 = (QS, ANS, childCount) => {
  // calculate F3:
  let result = 0;
  if (childCount > 0)
    if (checkExpectations(ANS, QS.calculation.F3)) {
      if (childCount === 1) {
        result = QS.calculation.F3.kids_1;
      } else {
        result = QS.calculation.F3.kids_2;
        result += (childCount - 2) * QS.calculation.F3.extra;
      }
    }
  return result < 0 ? 0 : result;
};

const calculateF4 = (QS, ANS, childCount) => {
  // calculate F4:
  let result = 0;
  if (childCount > 0)
    if (checkExpectations(ANS, QS.calculation.F4)) {
      if (childCount > 2) {
        result = (childCount - 2) * QS.calculation.F4.extra;
      }
    }
  return result < 0 ? 0 : result;
};

const calculateG1 = (QS, ANS) => {
  // calculate G1:
  let result = 0;
  if (checkExpectations(ANS, QS.calculation.G1)) {
    const diseases = findAnswer(
      ANS,
      QS,
      QS.calculation.G1.diseases.group,
      QS.calculation.G1.diseases.question
    );
    if (diseases.indexOf(QS.calculation.G1.aids.id) >= 0) {
      result += QS.calculation.G1.aids.amount;
    }
    if (diseases.indexOf(QS.calculation.G1.kidney.id) >= 0) {
      result += QS.calculation.G1.kidney.amount;
    }
    if (diseases.indexOf(QS.calculation.G1.others.id) >= 0) {
      result += QS.calculation.G1.others.amount;
    }
  }
  return result < 0 ? 0 : result;
};

const calculateG2 = (QS, ANS, avg12) => {
  // calculate G2:
  let result = 0;
  let total = 0;
  for (const item of QS.calculation.G2.expenses) {
    if (checkExpectations(ANS, item)) {
      total += parseFloat(
        `0${findAnswerNumber(ANS, QS, item.group, item.question)}`
      );
    }
  }
  for (const item of QS.calculation.G2.payment) {
    if (avg12 <= item.max) {
      result = eval(item.expression.replace("S", total).replace("X", avg12));
      break;
    }
  }
  return result > 0 ? result : 0;
};

const calculateK1 = (QS, ANS, childCount) => {
  // calculate K1:
  let result = 0;
  for (let i = 0; i < childCount; i++) {
    if (checkExpectations(ANS, QS.calculation.K1, QS, i)) {
      const diseases = findAnswer(
        ANS,
        QS,
        QS.calculation.K1.diseases.group,
        QS.calculation.K1.diseases.question,
        i
      );
      if (diseases.indexOf(QS.calculation.K1.aids.id) >= 0) {
        result += QS.calculation.K1.aids.amount;
      }
      if (diseases.indexOf(QS.calculation.K1.kidney.id) >= 0) {
        result += QS.calculation.K1.kidney.amount;
      }
      if (diseases.indexOf(QS.calculation.K1.others.id) >= 0) {
        result += QS.calculation.K1.others.amount;
      }
    }
  }
  return result < 0 ? 0 : result;
};

const calculateK2 = (QS, ANS, childCount) => {
  // calculate K2:
  let kids = 0;
  let result = 0;
  for (let i = 0; i < childCount; i++) {
    if (checkExpectations(ANS, QS.calculation.K2, QS, i)) {
      kids++;
    }
  }
  if (kids >= 1) result += QS.calculation.K2.kid_1 * 12;
  if (kids >= 2) result += QS.calculation.K2.kid_2 * 12;
  if (kids > 2) result += (kids - 2) * QS.calculation.K2.extra * 12;
  return result < 0 ? 0 : result;
};

export const getFinalNumber = calculations => {
  if (!calculations) return 0;
  const diff = calculations.PAID_TAX.TOTAL - calculations.NEW_TAX.TOTAL;
  return (diff < 0 ? 0 : diff) + calculations.negative;
};

export const calculateAw1item = (QS, ANS, ID) => {
  // calculate AW single item:
  const items = QS.calculation.AW.items.filter(i => i.ID === ID);
  if (items.length !== 1) return 0;
  const item = items[0];
  if (checkExpectations(ANS, item)) {
    const ans = parseFloat(
      `0${findAnswerNumber(ANS, QS, item.group, item.question)}`
    );
    return eval(item.expression.replace("$", ans));
  }
  return 0;
};

export const calculateAg1item = (QS, ANS, ID) => {
  // calculate AG single item:
  const items = QS.calculation.AG.items.filter(i => i.ID === ID);
  if (items.length !== 1) return 0;
  const item = items[0];
  if (checkExpectations(ANS, item)) {
    const ans = parseFloat(
      `0${findAnswerNumber(ANS, QS, item.group, item.question)}`
    );
    return eval(item.expression.replace("$", ans));
  }
  return 0;
};

export const calculateG2_1item = (QS, ANS, ID) => {
  // calculate G2 single item:
  const items = QS.calculation.G2.expenses.filter(i => i.ID === ID);
  if (items.length !== 1) return 0;
  const item = items[0];
  if (checkExpectations(ANS, item)) {
    return parseFloat(
      `0${findAnswerNumber(ANS, QS, item.group, item.question)}`
    );
  }
  return 0;
};

export const calculateG1_separated = (QS, ANS) => {
  // calculate G1 separated:
  const result = {
    aids: 0,
    kidney: 0,
    others: 0
  };
  if (checkExpectations(ANS, QS.calculation.G1)) {
    const diseases = findAnswer(
      ANS,
      QS,
      QS.calculation.G1.diseases.group,
      QS.calculation.G1.diseases.question
    );
    if (diseases.indexOf(QS.calculation.G1.aids.id) >= 0) {
      result.aids = QS.calculation.G1.aids.amount;
    }
    if (diseases.indexOf(QS.calculation.G1.kidney.id) >= 0) {
      result.kidney = QS.calculation.G1.kidney.amount;
    }
    if (diseases.indexOf(QS.calculation.G1.others.id) >= 0) {
      result.others = QS.calculation.G1.others.amount;
    }
  }
  return result;
};

const mod97 = string => {
  var checksum = string.slice(0, 2),
    fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
};

export const isValidIBANNumber = input => {
  var CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20
  };
  let digits;
  let iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, "");
  let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  console.log(mod97(digits) === 1);
  return mod97(digits) === 1;
};



export function getFinalAmount(calculations) {
  if (!calculations) {
    return 0;
  } else {
    try {
      const diff = calculations.PAID_TAX.TOTAL - calculations.NEW_TAX.TOTAL;
      const negativeCalculation = calculations.negative
        ? calculations.negative
        : 0;
      return (diff < 0 ? 0 : diff) + negativeCalculation;
    } catch (err) {
      return 0;
    }
  }
}
