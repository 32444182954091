import React, {useEffect, useState} from "react";
import { Table, Tag, Space } from "antd";
import {cookieService} from "../../../services/cookie.service";
import axios from "axios";
import environment from "../../../environment";
import moment from "moment";
import StatusTab from "../../../components/Admin/StatusTab";
import TableAction from "../../../components/Admin/TableAction";
import { Input } from "antd";

const { Column } = Table;
const { Search } = Input;

export default function PaymentsTab() {

  const [loading, setLoading] = useState(true);
  const [formsData, setFormsData] = useState([]);

  const getPayments = async (search) => {
    const cookieToken = await new cookieService().getAdminTokenData();
    const queryParams = {};
    if (search) {
      Object.assign(queryParams, {"search": search});
    }
    const response = await axios.get(`${environment.baseUrl}admin/payments`, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      if (response.data.length) {
        const newArray = [];
        response.data.map((formFromDb, idx) => {
          formFromDb.created_at = moment(new Date(formFromDb.created_at)).format("DD.MM.YYYY");
          newArray.push({
            ...formFromDb,
            key: idx.toString()
          });
        });
        setFormsData(newArray);
      } else {
        setFormsData([]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getPayments();
  }, []);

  const onSearch = (value) => {
    getPayments(value);
  };

  return(
    <div className="admin_tab">
      <Search placeholder="Search" onSearch={onSearch} className="admin_search" />
      {loading
        ? <p>Loading</p>
        : formsData.length
          ? <div>
            <Table dataSource={formsData} scroll={{ x: 1500 }}>
              <Column title="User id" dataIndex="user_id" key="user_id" />
              <Column title="Name" dataIndex="user_name" key="user_name" />
              <Column title="Email" dataIndex="user_email" key="user_email" />
              <Column title="Phone" dataIndex="phone" key="phone" />
              <Column title="Payment id" dataIndex="id" key="id" />
              <Column title="IBAN" dataIndex="iban" key="iban" />
              <Column title="BIC" dataIndex="bic" key="bic" />
              <Column title="Amount" dataIndex="amount" key="amount" />
            </Table>
          </div>
          : <p>No data</p>
      }
    </div>
  );
}
