import React, {useEffect, useState} from "react";
import eCardImage from "../../../img/Forms/ecard.png";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import HashLoader from "react-spinners/HashLoader";
import {formService} from "../../../services/form.service";
import Button from "../../CommonComponents/FormButton/FormButton";
import ErrorBox from "../../ErrorBox/ErrorBox";
import {motion} from "framer-motion";
import {useHistory} from "react-router-dom";
import {
  getAnsSelector,
  getLanguageSelector,
  getQsSelector,
  getUserDataSelector
} from "../../../data/app/selectors";
import {connect} from "react-redux";

function SvnComponent(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const [disableInput, setDisableInput] = useState(false);
  const [taxNumberLayout, setTaxNumberLayout] = useState(true);
  const [showError, setShowError] = useState("");

  const [svn, setSvn] = useState("");
  const [taxNumber, setTaxNumber] = useState("");

  /*const validate = (input, type) => {
    const withoutSpaces = input.toString().replace(/ /g,"");
    if (type === "svn") {
      if (withoutSpaces.length !== 10) {
        return false;
      } else {
        return true;
      }
    } else {
      if (withoutSpaces.length !== 9) {
        return false;
      } else {
        return true;
      }
    }
  };*/

  const checkTaxNumber = async (inputNumber) => {
    setTaxNumber(inputNumber);
    if (inputNumber.trim().toString().length === 9) {
      setTaxNumber(inputNumber);
      props.setTaxNumber(inputNumber);
      setShowError("");
      /*setDisableInput(true);*/
      /*const taxNumber = await new formService().getTaxNumber(inputNumber.toString().replace(/ /g, ""));*/
      /*setDisableInput(false);*/
      /*if (taxNumber.success) {
        setTaxNumber(taxNumber.data);
        props.setSvn(inputNumber.toString().replace(/ /g, ""));
        setShowError("");
      } else {
        if (taxNumber && taxNumber.message === "not_exists") {
          setShowError("not_exists");
        } else if (taxNumber.message === "fo_error") {
          props.setSvn(inputNumber.toString().replace(/ /g, ""));
          setTaxNumber(inputNumber);
          setShowError("");
        } else {
          setShowError("not_found");
        }
      }*/
    }

    /*if (validate(inputNumber, "svn")) {

    }*/
  };

  const checkSvn = async (inputNumber) => {
    const noSpaceInput = inputNumber.toString().replace(/ /g, "");
    if (noSpaceInput.trim().toString().length === 10) {
      setSvn(inputNumber);
      props.setSvn(noSpaceInput.trim().toString());
      setShowError("");
    }
  };

  useEffect(() => {
    if (props && props.userData) {
      if (props.userData.svn || props.userData.taxNumber) {
        if (props.userData.taxNumber) {
          checkTaxNumber(props.userData.taxNumber);
        } else {
          checkSvn(props.userData.svn);
        }
        props.setCurrentComponent("signature");
      }
    }
  }, [props]);

  const handleTaxNumberInput = e => {
    checkTaxNumber(e.target.value);
  };

  const handleSvnInput = e => {
    checkSvn(e.target.value);
  };

  return(
    taxNumberLayout
      ? <>
        <h6>{t("questions.final_step.title_tax")}</h6>
        {/*<img
        src={eCardImage}
        alt="ecard"
      />*/}
        <div className="svn_input_box">
          <NumberFormat
            defaultValue={props && props.userData && props.userData.svn ? props.userData.svn : ""}
            disabled={disableInput}
            className="svn_input"
            displayType={"number"}
            format="#########"
            onChange={(e) => handleTaxNumberInput(e)}
          />
          {disableInput
            ? <div><HashLoader color="#00beaa" size={25} /></div>
            : null
          }
        </div>
        {
          showError && showError === "not_found"
            ? <motion.div
              className="error_box_container"
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                delay: 0.3,
                x: { type: "spring", stiffness: 100 },
                default: { duration: 1 },
              }}
            >
              <i className="fas fa-exclamation-triangle"></i>
              <p>{t("questions.warnings.svn_not_found")}
                <span
                  onClick={() => history.push("/createTax", {svn: svn})}
                >
                  {t("questions.warnings.svn_not_found_link")}
                </span>
              </p>
            </motion.div>
            : showError && showError === "not_exists"
              ? <motion.div
                className="error_box_container"
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                transition={{
                  delay: 0.3,
                  x: { type: "spring", stiffness: 100 },
                  default: { duration: 1 },
                }}
              >
                <i className="fas fa-exclamation-triangle"></i>
              </motion.div>
              : null
        }
        <p
          className="svn_link"
          onClick={() => setTaxNumberLayout(false)}
        >
          {t("questions.final_step.tax_number.toSvn")}
        </p>
        {taxNumber && taxNumber.toString().trim().length === 9
          ? <Button
            text={t("questions.continue")}
            style={{
              marginTop: "20px"
            }}
            handleClick={() => props.setCurrentComponent("signature")}
          />
          : null
        }
      </>
      : <>
        <h6>{t("questions.final_step.title_svn")}</h6>
        <img
          src={eCardImage}
          alt="ecard"
        />
        <div className="svn_input_box">
          <NumberFormat
            defaultValue={props && props.userData && props.userData.svn ? props.userData.svn : ""}
            disabled={disableInput}
            className="svn_input"
            displayType={"number"}
            format="#### ######"
            onChange={(e) => handleSvnInput(e)}
          />
          {disableInput
            ? <div><HashLoader color="#00beaa" size={25} /></div>
            : null
          }
        </div>
        <p
          className="svn_link"
          onClick={() => setTaxNumberLayout(true)}
        >
          {t("questions.final_step.svn.toTaxNumber")}
        </p>
        {svn && svn.toString().trim().length === 11
          ? <Button
            text={t("questions.continue")}
            style={{
              marginTop: "20px"
            }}
            handleClick={() => props.setCurrentComponent("signature")}
          />
          : null
        }
      </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    userData: getUserDataSelector(state)
  };
};

export default connect(mapStateToProps, null)(SvnComponent);
