import React, {useState, useEffect} from "react";
import {Table, Select, Input, Modal, Switch, Tooltip, notification} from "antd";
import {DatePicker} from "antd";
const {RangePicker} = DatePicker;
import moment from "moment";
import "./Dashboard.css";
import {partnerManagerService} from "../../../services/partnerManager.service";
import Button from "../../../components/CommonComponents/FormButton/FormButton";
import CreatePartnerModal from "../../../components/Modals/CreatePartnerModal/CreatePartnerModal";
import {CopyOutlined, LoadingOutlined} from "@ant-design/icons";
import DynamicSwitch from "../../../components/DynamicSwitch";
import {cookieService} from "../../../services/cookie.service";
import {ResponsiveFunnel} from "@nivo/funnel";

export default function Dashboard() {
  const [partnersOfPartnerManager, setPartnersOfPartnerManager] = useState([]);
  const [userName, setUserName] = useState("");
  const [swtichFlag, setSwtichFlag] = useState(false);
  const [minimum1Flag, setMinimum1Flag] = useState(false);
  const [minimum1FlagDisabled, setMinimum1FlagDisabled] = useState(false);
  const [partnerFilters, setPartnerFilters] = useState([]);
  const [summary, setSummary] = useState({
    users: 0,
    forms: 0,
    sumPrecalc: 0,
    sumDecision: 0
  });
  const [createPartnerModal, setCreatePartnerModal] = useState(false);
  const [newUserDialog, setNewUserDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: ""
  });

  const getPartnersOfPartnerManager = async () => {
    let result = await new partnerManagerService().getPartnersOfPartnerManager(
      selectedDate,minimum1Flag
    );

    result.data = result.data?.map((el, id) => ({
      key: "partnersPM" + id,
      ...el
    }));

    setPartnersOfPartnerManager(result.data);
    if (result.data?.length == 0) setNewUserDialog(true);
    const partnerFilters = result.data?.map(el => ({
      name: el.name,
      path: el.path,
      flag: true
    }));
    setPartnerFilters(partnerFilters);
  };

  useEffect(async () => {
    const token = await new cookieService().getTokenData();
    setUserName(token.name);
    await getPartnersOfPartnerManager();
  }, []);

  useEffect(async () => {
    setMinimum1FlagDisabled(true);
    setPartnersOfPartnerManager([]);
    await getPartnersOfPartnerManager();
    setTimeout(() => setMinimum1FlagDisabled(false), 500);
  }, [selectedDate, minimum1Flag]);

  useEffect(async () => {
    let totalUsers = 0;
    let totalActivatedUsers = 0;
    let totalForms = 0;
    let totalPrecalc = 0;
    let totalDecisions = 0;

    partnersOfPartnerManager.forEach(el => {
      const partner = partnerFilters.find(pt => pt.path == el.path);
      if (partner?.flag) {
        totalUsers += +el.count || 0;
        totalActivatedUsers += +el.activated_count || 0;
        totalForms += +el.sumForms || 0;
        totalPrecalc += +el.sumPrecalc.split(" ")[1] || 0;
        totalDecisions += +el.sumDecision.split(" ")[1] || 0;
      }
    });
    setSummary({
      users: totalUsers,
      activatedUsers: totalActivatedUsers,
      forms: totalForms,
      sumPrecalc: totalPrecalc,
      sumDecision: totalDecisions
    });
  }, [partnersOfPartnerManager, swtichFlag]);

  return (
    <div style={{overflow: "auto", filter: newUserDialog ? "blur(2px)" : ""}}>
      <Modal footer={null} closable={null} visible={newUserDialog}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <h3>
            Willkommen beim{" "}
            <span style={{color: "#00beaa"}}>Taxefy-Affiliate-Programm</span>!
          </h3>
          <p>
            Starte durch, indem du deinen{" "}
            <span style={{color: "#00beaa"}}>ersten Partner-Link</span>{" "}
            erstellst und ihn in deine Webseite einfügst. Verfolge die
            Ergebnisse direkt in deinem Dashboard, um die Leistung zu
            analysieren.
          </p>
          <Button
            text={"Link erstellen +"}
            style={{
              marginTop: "5px"
            }}
            buttonType="button"
            handleClick={() => {
              setNewUserDialog(false);
              setCreatePartnerModal(true);
            }}
          />
        </div>
      </Modal>
      <Modal
        footer={null}
        closable={null}
        visible={createPartnerModal}
        onCancel={() => {
          setCreatePartnerModal(false);
        }}
      >
        <CreatePartnerModal
          partnermanager={true}
          onSuccess={() => getPartnersOfPartnerManager()}
        />
      </Modal>
      <div className="dashboard_box_2">
        <div>
          <h2 style={{marginBottom: "0px"}} className="dashboard_header">
            Dashboard
          </h2>
          <h3 style={{color: "grey"}}>
            {new Date().toLocaleDateString("de-AT", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric"
            })}
          </h3>
        </div>
        <div style={{marginRight: "15%", marginBottom: "1%"}}>
          <RangePicker
            style={{
              width: "auto",
              height: "auto",
              fontSize: "25px",
              borderRadius: "20px",
              padding: "10px",
              border: "2px solid rgba(0, 190, 170, 1)"
            }}
            defaultValue={[selectedDate.startDate, selectedDate.endDate]}
            format={"DD.MM.YYYY"}
            onChange={val => {
              if (val) {
                setSelectedDate({
                  startDate: moment(val[0]).format().split("T")[0],
                  endDate: moment(val[1]).format().split("T")[0]
                });
              } else {
                setSelectedDate({
                  startDate: "",
                  endDate: ""
                });
              }
            }}
          />
        </div>
        <div
          style={{width: "20%", display: "flex", justifyContent: "flex-end"}}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              justifyContent: "center"
            }}
          >
            <h3
              style={{
                marginBottom: "0",
                fontWeight: "bolder",
                textAlign: "end",
                lineHeight: "20px"
              }}
              className="dashboard_header_2"
            >
              {userName}
            </h3>
            <h4 style={{color: "grey"}}>Affiliate Partner</h4>
          </div>
          <h1
            style={{
              fontSize: "40px",
              textAlign: "center",
              marginLeft: "5%",
              backgroundColor: "rgba(0, 190, 170, 1)",
              paddingRight: "20px",
              paddingLeft: "20px",
              color: "white",
              borderRadius: "200px"
            }}
          >
            {userName?.substring(0, 1)}
          </h1>
        </div>
      </div>
      <div className="dashboard_box">
        <h2 style={{width: "30%"}} className="dashboard_header">
          Deine Links ({partnersOfPartnerManager?.length})
          <Switch
            checkedChildren={"Mindestens 1 Steuererklärung"}
            unCheckedChildren={"Mindestens 1 Steuererklärung"}
            disabled={minimum1FlagDisabled}
            id="PM_minimum_switch"
            style={{
              backgroundColor: minimum1Flag ? "rgba(0, 190, 170, 1)" : "grey",
              marginLeft: "10px"
            }}
            onChange={async () => {
              setMinimum1Flag(!minimum1Flag);
            }}
          />
        </h2>
        <div
          style={{display: "flex", justifyContent: "flex-end", width: "70%"}}
        >
          <Button
            text={"Link erstellen +"}
            style={{
              marginTop: "5px",
              borderRadius: "15px",
              padding: "15px"
            }}
            buttonType="button"
            handleClick={() => {
              console.log("TEST"); // setOpenCreatePartnerModal(true);
              setCreatePartnerModal(true);
            }}
          />
        </div>
      </div>
      <div
        style={{
          borderRadius: "20px",
          backgroundColor: "white",
          padding: "5px",
          marginBottom: "5%"
        }}
      >
        <Table
          id="partners_table"
          rowKey={"key"}
          dataSource={partnersOfPartnerManager}
          loading={partnersOfPartnerManager?.length == 0}
          summary={data => {
            return (
              <>
                <Table.Summary.Row
                  style={{
                    backgroundColor: "rgba(0, 190, 170, 0.2)",
                    fontWeight: "bolder"
                  }}
                >
                  <Table.Summary.Cell index={0}>Gesamt</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    {summary.users}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    {summary.activatedUsers}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    {summary.forms}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    € {Number(summary.sumPrecalc).toFixed(2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    € {Number(summary.sumDecision).toFixed(2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>—</Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>—</Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        >
          <Table.Column
            dataIndex={"name"}
            title={
              <Tooltip title={"Name deines Affiliate Links"}>Link Name</Tooltip>
            }
          />
          <Table.Column
            dataIndex={"count"}
            title={
              <Tooltip
                title={
                  "Anzahl der Kunden die sich über den Affiliate Link registriert haben"
                }
              >
                Nutzer
              </Tooltip>
            }
          />
          <Table.Column
            dataIndex={"activated_count"}
            title={
              <Tooltip
                title={
                  "Anzahl der Kunden die sich über den Affiliate Link registriert haben und ihren Account aktiviert haben"
                }
              >
                Aktivierte Nutzer
              </Tooltip>
            }
          />
          <Table.Column
            dataIndex={"sumForms"}
            title={
              <Tooltip
                title={
                  "Anzahl an Steuererklärungen die von Kunden erstellt wurden, die sich mit dem Affiliate Link registriert haben"
                }
              >
                Steuererklärungen
              </Tooltip>
            }
          />
          <Table.Column
            key={"sumPrecalc"}
            dataIndex={"sumPrecalc"}
            title={
              <Tooltip
                title={
                  "Summe aller durch Taxefy vorberechneten Rückzahlungen von allen Steuererklärungen"
                }
              >
                Summe Vorberechnungen
              </Tooltip>
            }
          />
          <Table.Column
            key={"sumDecision"}
            dataIndex={"sumDecision"}
            title={
              <Tooltip
                title={"Summe aller vom Finanzamt entschiedenen Rückzahlungen"}
              >
                Summe Amtlicher Entscheidungen
              </Tooltip>
            }
          />
          <Table.Column
            title="Aktion"
            dataIndex={"link"}
            render={(link, row) => {
              const shortLink = `https://app.taxefy.at/redirect?partner=${row.partnerId}`;
              return (
                <Tooltip title={shortLink}>
                  <div
                    className={"row"}
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      navigator.clipboard.writeText(shortLink);
                      notification.success({
                        message: "Link copied to clipboard",
                        duration: 2
                      });
                    }}
                  >
                    <CopyOutlined style={{fontSize: "18px"}} />
                    <p style={{marginBottom: 0, marginLeft: "10px"}}>
                      Link kopieren
                    </p>
                  </div>
                </Tooltip>
              );
            }}
          />
          <Table.Column
            dataIndex={"partnerId"}
            title={
              <Tooltip title={"Taxefy-Interne Identifikations-Nummer"}>
                ID
              </Tooltip>
            }
          />
        </Table>
      </div>
      <div
        style={{
          width: "100%",
          height: "500px",
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "10px",
          margin: "5px"
        }}
      >
        <div style={{display: "flex", verticalAlign: "middle"}}>
          <h1 className="dashboard_header" style={{marginLeft: "1%"}}>
            Performance
          </h1>
          <div style={{marginTop: "15px"}}>
            <DynamicSwitch
              data={partnerFilters}
              loading={partnerFilters.length == 0}
              setSwtichFlag={setSwtichFlag}
              swtichFlag={swtichFlag}
            />
          </div>
        </div>
        <div style={{width: "100%", position: "relative"}}>
          <div className="dashboard_funnel_labels">
            <div>
              <p>Nutzer</p>
              <h4>{summary.users}</h4>
            </div>
            <div>
              <p>Steuererklärungen</p>
              <h4>{summary.forms}</h4>
            </div>
          </div>
        </div>
        <ResponsiveFunnel
          data={[
            {
              id: "users",
              value: summary.users,
              label: "Nutzer"
            },
            {
              id: "forms",
              value: summary.forms,
              label: "Steuererklärungen"
            }
          ]}
          margin={{top: 20, right: 20, bottom: 20, left: 20}}
          direction="horizontal"
          colors={["rgba(0, 190, 170, 0.5)", "rgba(0, 190, 170, 1)"]}
          colorBy="index"
          shapeBlending={0.56}
          borderWidth={20}
          borderOpacity={0.45}
          beforeSeparatorLength={100}
          beforeSeparatorOffset={20}
          afterSeparatorLength={100}
          afterSeparatorOffset={20}
          currentPartSizeExtension={3}
          currentBorderWidth={40}
          motionConfig="wobbly"
        />
      </div>
    </div>
  );
}
