import React, {useState} from "react";
import "./QuestionComponentsStyles.css";
import {DatePicker} from "antd";
import moment from "moment";
import Button from "../CommonComponents/FormButton/FormButton";
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";
import NavigationButtons from "./NavigationButtons/NavigationButtons";
import {getText} from "../../utils/tools";

export default function WorkPeriod(props) {
  const { t } = useTranslation();

  const [buttonBlocked, setButtonBlocked] = useState(false);
  const [answered, setAnswered] = useState(false);

  const endDateFromAnswer = moment(`${props.answr.to[0]}.${props.answr.to[1]}.${props.ANS.year}`,
    "MM.DD.YYYY").format("MM.DD.YYYY");
  const startDateFromAnswer = moment(`${props.answr.from[0]}.${props.answr.from[1]}.${props.ANS.year}`,
    "MM.DD.YYYY").format("MM.DD.YYYY");

  const setDate = (value, state) => {
    if (value) {
      if (state === "from" && moment(value).format("MM.DD.YYYY") >= endDateFromAnswer) {
        setButtonBlocked(true);
      } else if (state === "to" && moment(value).format("MM.DD.YYYY") <= startDateFromAnswer) {
        setButtonBlocked(true);
      } else {
        setButtonBlocked(false);
        const month = moment(value).format("MM");
        const day = moment(value).format("DD");
        const daysArray = [+month, +day];
        if (state === "from") {
          props.answr.from = daysArray;
        } else {
          props.answr.to = daysArray;
        }
      }
    } else {
      setButtonBlocked(true);
    }
  };


  const startDate = `01.01.${props.ANS.year}`;
  const endDate = `31.12.${props.ANS.year}`;
  const format = "DD.MM.YYYY";

  function disabledDate(current) {
    return current && (current < moment(startDate, format)
      || current > moment(endDate, format));
  }

  return(
    <motion.div
      className="single_choice"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{
        delay: 0.3,
        x: { type: "spring", stiffness: 100 },
        default: { duration: 1 },
      }}
    >

      {props.showButtonBack
        ? <NavigationButtons back={props.backward} forward={() => {
          if (answered) {
            props.forward(props.condition);
          }
        }} />
        : null
      }

      <div className="question_header_box">
        <h6>{getText(props.ANS, props.QS, props.qs.title, props.lang)}</h6>
        {props.qs.dsc && props.qs.dsc.length
          ? <div className="drawer_btn" onClick={() => {
            props.showDrawer(props.qs.dsc);
          }}>
            <i className="fas fa-question"></i>
          </div>
          : null
        }
      </div>
      <div className="work_addresses_calendar_container">
        {props.qs.tooltip && Object.keys(props.qs.tooltip).length
          ? <div className="drawer_btn" onClick={() => {
            props.showDrawer(props.qs.tooltip[props.lang]);
          }}>
            <i className="fas fa-question"></i>
          </div>
          : null
        }
        <div>
          <p className="addresses_from">{t("questions.from")}: </p>
          <DatePicker
            format="DD.MM.YYYY"
            defaultValue={moment(startDateFromAnswer)}
            onChange={(e) => setDate(e, "from")}
            disabledDate={disabledDate}
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "10px",
              marginTop: "3px",
              boxSizing: "border-box",
              borderRadius: "5px",
              border: "1px solid rgba(0, 190, 170, 0.5)"
            }}
          />
        </div>
        <div>
          <p className="addresses_to">{t("questions.to")}: </p>
          <DatePicker
            format="DD.MM.YYYY"
            defaultValue={moment(endDateFromAnswer)}
            onChange={(e) => setDate(e, "to")}
            disabledDate={disabledDate}
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "10px",
              marginTop: "3px",
              boxSizing: "border-box",
              borderRadius: "5px",
              border: "1px solid rgba(0, 190, 170, 0.5)"
            }}
          />
        </div>
      </div>
      <Button
        text={t("questions.continue")}
        disabled={buttonBlocked || props.blocked}
        handleClick={() => {
          if (!buttonBlocked) {
            setButtonBlocked(true);
            if (!props.blocked) {
              props.forward(props.condition);
            } else {
              props.showError(props.condition.abort_code);
            }
            setTimeout(() => {
              setButtonBlocked(false);
            }, 500);
          }
        }}
      />
    </motion.div>
  );
}
