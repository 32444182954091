import React, {useState} from "react";
import "./CountrySelection.css";
import ButtonWithCheck from "../ButtonWithCheck/ButtonWithCheck";
import Button from "../FormButton/FormButton";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {
  setCurrentQsAction,
  setLanguageAction,
  setQsAction,
  setUserData
} from "../../../data/app/actions";
import { motion } from "framer-motion";
import axios from "axios";
import environment from "../../../environment";
import i18n from "../../../i18next";
import {cookieService} from "../../../services/cookie.service";
import Loading from "../../Loading";

export default function CountrySelect(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedYear, setSelectedYear] = useState("");

  const setData = async () => {
    const form = props.countriesData[selectedYear].data;
    dispatch(setQsAction(form));
    dispatch(setCurrentQsAction(`AT_${form.year}`));
    props.setCurrentComponent("");
    const tokenData = await new cookieService().getTokenData();
    const response = await axios.get(`${environment.baseUrl}user`, {
      headers: {
        Authorization: `Bearer ${tokenData.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      dispatch(setUserData(response.data));
    }
  };

  return(
    <div className="question_box">
      {Object.keys(props.countriesData).length > 0
        ? <div className="single_choice">
          <div className="question_header_box">
            <h6>{t("questions.initialChoice.year.title")}</h6>
            <div className="choice_box">
              {Object.keys(props.countriesData).reverse().map(year => (
                <React.Fragment key={year}>
                  <ButtonWithCheck
                    withCheckbox={true}
                    checked={selectedYear === year}
                    text={year}
                    handleClick={() => setSelectedYear(year)}
                    disabled={props.countriesData[year].progress === "100"}
                    progress={props.countriesData[year].progress}
                  />
                </React.Fragment>
              ))}
            </div>
            <div className="form_next_button_container">
              <Button
                text={t("questions.continue")}
                handleClick={() => setData()}
                disabled={!selectedYear.length}
              />
            </div>
          </div>
        </div>
        : <Loading />
      }
    </div>
  );
}
