import React, {useEffect, useState} from "react";
import "../styles.css";
import { ResponsiveFunnel } from "@nivo/funnel";

export default function TunnelChart(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.data && Object.keys(props.data).length) {
      setLoading(false);
    }
  }, [props.data]);

  return(
    <div style={{width: "100%", height: "500px"}}>
      <div style={{width: "100%", position: "relative"}}>
        <div className="dashboard_funnel_labels">
          <div>
            <p>Downloads</p>
            <h4>{loading ? "Loading" : props.data.downloads}</h4>
          </div>
          <div>
            <p>Registrierungen</p>
            <h4>{loading ? "Loading" : props.data.registration}</h4>
          </div>
          <div>
            <p>1. Steuererklärung</p>
            <h4>{loading ? "Loading" : props.data.first}</h4>
          </div>
          <div>
            <p>2. Steuererklärung</p>
            <h4>{loading ? "Loading" : props.data.second}</h4>
          </div>
          <div>
            <p>3. Steuererklärung</p>
            <h4>{loading ? "Loading" : props.data.third}</h4>
          </div>
        </div>
      </div>
      <ResponsiveFunnel
        data={[
          {
            "id": "downloads",
            "value": loading ? 1 : props.data.downloads,
            "label": "Downloads"
          },
          {
            "id": "registered",
            "value": loading ? 1 : props.data.registration,
            "label": "Registrierungen"
          },
          {
            "id": "first",
            "value": loading ? 1 : props.data.first,
            "label": "1. Steuererklärung"
          },
          {
            "id": "second",
            "value": loading ? 1 : props.data.second,
            "label": "2. Steuererklärung"
          },
          {
            "id": "third",
            "value": loading ? 1 : props.data.third,
            "label": "3. Steuererklärung"
          }
        ]}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        direction="horizontal"
        shapeBlending={0.56}
        colors={{ scheme: "blues" }}
        borderWidth={20}
        borderOpacity={0.45}
        labelColor={{
          from: "color",
          modifiers: [
            [
              "darker",
              3
            ]
          ]
        }}
        beforeSeparatorLength={100}
        beforeSeparatorOffset={20}
        afterSeparatorLength={100}
        afterSeparatorOffset={20}
        currentPartSizeExtension={3}
        currentBorderWidth={40}
        motionConfig="wobbly"
      />
    </div>
  );
}
