import {customHistory} from "../App";

export function handleAsyncErrors(status) {
  if (status === 404) {
    customHistory.push("/404");
    return true;
  } else if (status === 500) {
    customHistory.push("/404");
    return true;
  } else if (status === 401) {
    /*LOGOUT*/
    return true;
  } else {
    return false;
  }
}
