import {Route, Switch} from "react-router-dom";
import React from "react";
import PartnerPage from "./PartnersPage/partnersPage";
import PartnerDetailPage from "./PartnerDetailPage/PartnerDetailPage";

export default function PartnerRouter() {
  return(
    <Switch>
      <Route path="/admin/panel/partners/:id" render={() =>
        <PartnerDetailPage />
      }/>
      <Route path="/" render={() =>
        <PartnerPage />
      }/>
    </Switch>
  );
}
