import React, {useState} from "react";
import {motion} from "framer-motion";
import {Tooltip} from "antd";
import "./FormikInput.css";
import {EyeOutlined} from "@ant-design/icons";
import {EyeInvisibleOutlined} from "@ant-design/icons";

export default function FormikInput(props) {
  const [passwordVisible, setPassowordVisible] = useState(false);

  const togglePassword = () => {
    setPassowordVisible(!passwordVisible);
  };

  if (props.htmlFor === "password") {
    return (
      <div style={props.style} className="formik_input_container">
        <label htmlFor="password">{props.label}</label>
        <div style={{display: "flex"}}>
          <input
            autoFocus={props.autoFocus}
            name={props.name}
            className="password_input"
            type={!props.inputType && !passwordVisible ? "password" : "text"}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.handleChange}
            onBlur={props.onBlur}
          />
          <div className="password_icon" onClick={togglePassword}>
            {!passwordVisible && <EyeOutlined />}
            {passwordVisible && <EyeInvisibleOutlined />}
          </div>
        </div>
        <div hidden={!props.error}>
          <Tooltip color="red" placement="bottomLeft" title={props.error}>
            <p>{props.error}</p>
          </Tooltip>
        </div>
      </div>
    );
  } else if (props.htmlFor === "checkbox") {
    return (
      <div style={props.style} className="formik_input_container">
        <label htmlFor="password">{props.label}</label>
        <input
          style={{accentColor: "rgba(0, 190, 170, 1)"}}
          autoFocus={props.autoFocus}
          name={props.name}
          type={"checkbox"}
          placeholder={props.placeholder}
          checked={props.value}
          onChange={props.handleChange}
          onBlur={props.onBlur}
        />
      </div>
    );
  } else {
    return (
      <div style={props.style} className="formik_input_container">
        <label htmlFor={props.htmlFor}>{props.label}</label>
        <motion.input
          whileFocus={{backgroundColor: "rgba(151, 151, 226, 0.281)"}}
          name={props.name}
          type="text"
          className={"simple_input"}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={props.value}
          onChange={props.handleChange}
          onBlur={props.onBlur}
        />
        <div hidden={!props.error}>
          <Tooltip color="red" placement="bottomLeft" title={props.error}>
            <p>{props.error}</p>
          </Tooltip>
        </div>
      </div>
    );
  }
}
