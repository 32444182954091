import React, {useEffect, useState} from "react";
import "./AuthenticationPage.css";
import {useTranslation} from "react-i18next";
import FormikInput from "../../components/CommonComponents/FormikInput/FormikInput";
import {useFormik} from "formik";
import * as Yup from "yup";
import {motion} from "framer-motion";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import {useHistory, useLocation} from "react-router-dom";
import {authService} from "../../services/auth.service";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import GoogleLogin from "react-google-login";
import axios from "axios";
import environment from "../../environment";
import ReactCanvasConfetti from "react-canvas-confetti";
import medalImg from "../../img/Auth/medal.png";
import {cookieService} from "../../services/cookie.service";
import googleLogo from "../../img/Auth/google_logo.png";
import queryString from "query-string";
import facebookLogo from "../../img/Auth/facebook.png";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import BankData from "../Profile/BankData";
import {Modal} from "antd";
import {SettingOutlined, TeamOutlined} from "@ant-design/icons";
import Logo from "../../img/taxefy_logo.png";
import TaxefyCheckmark from "../../img/taxefy_checkmark.png";
import TaxefyCheckmark2 from "../../img/taxefy_checkmark_2.png";
import TaxefyIcon from "../../img/taxefy_icon.png";
import "./style.css";

const {version} = require("../../../package.json");

export default function Login(props) {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeError, setActiveError] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [googleRes, setGoogleRes] = useState(null);
  const [loginDisable, setLoginDisable] = useState(null);

  const loginWithToken = async token => {
    const loginResponse = await new authService().loginWithToken(token);
    if (loginResponse && loginResponse.success) {
      history.push("/");
    }
  };

  const hideRegisterLink = () => {
    if (props.consultant || props.partnermanager) return true;
    return false;
  };

  const disableLoginFor3 = () => {
    setLoginDisable(true);
    setTimeout(() => setLoginDisable(false), 3 * 60 * 1000);
  };

  useEffect(() => {
    const searchQuery = queryString.parse(location.search);
    if (searchQuery && searchQuery.token) {
      loginWithToken(searchQuery.token);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t("auth.warnings.email")).required(t("auth.warnings.required")),
      password: Yup.string()
        .required(t("auth.warnings.required"))
        .min(6, t("auth.warnings.password_length"))
    }),
    // handle form submitting
    onSubmit: async () => {
      setButtonLoading(true);
      let login;
      // Login Admin
      if (props && props.admin) {
        login = await new authService().loginAdminUser({
          email: formik.values.email,
          password: formik.values.password
        });
      } else if (props && props.consultant) {
        login = await new authService().loginConsultantUser({
          email: formik.values.email,
          password: formik.values.password
        });
      } else if (props && props.partnermanager) {
        login = await new authService().loginPartnerManagerUser({
          email: formik.values.email,
          password: formik.values.password
        });
      } else {
        // Login User
        login = await new authService().login({
          email: formik.values.email,
          password: formik.values.password
        });
      }

      if (login && !login.success) {
        if (login.message === "wrong credentials") {
          setErrorMessage(t("auth.warnings.login_error"));
          setButtonLoading(false);
        } else if (login.status == 429) {
          setErrorMessage("Too many requests, please try again later");
          disableLoginFor3();
          setButtonLoading(false);
        } else if (login.status == 423) {
          setErrorMessage(login.message);
          setLoginDisable(true);
          setButtonLoading(false);
        } else if (login.message === "not activated") {
          setErrorMessage(t("auth.warnings.login_error_not_activated"));
          setButtonLoading(false);
        } else if (login.message === "user not exists") {
          setErrorMessage(t("auth.warnings.login_exists"));
          setButtonLoading(false);
        } else if (login.message === "not_active") {
          setActiveError(true);
          setButtonLoading(false);
        } else {
          setErrorMessage(t("auth.warnings.login_exists"));
          setButtonLoading(false);
        }
      } else {
        setButtonLoading(false);
        if (login && login.success) {
          if (props && props.admin) {
            history.push("/admin/panel");
          } else if (props && props.consultant) {
            history.push("/consultant/panel");
          } else if (props && props.partnermanager) {
            history.push("/partnermanager/panel");
          } else {
            history.push("/");
          }
        }
      }
    }
  });

  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);
  const [fire, setFire] = useState(false);

  useEffect(() => {
    if (successfullyRegistered) {
      setTimeout(() => {
        setFire(true);
      }, 300);
    }
  }, [successfullyRegistered]);

  const responseGoogle = async (res, termsNotAccepted) => {
    setGoogleRes(res);
    try {
      const response = await axios.post(`${environment.baseUrl}auth/googlelogin`, {
        tokenId: res.tokenId,
        termsNotAccepted: termsNotAccepted
      });
      if (response && response.data) {
        if (response.data.firstName) {
          await new cookieService().setTokenData(response.data);
          setButtonLoading(false);
          history.push("/");
        } else if (response.data === "created") {
          setButtonLoading(false);
          setSuccessfullyRegistered(true);
        } else {
          setErrorMessage(t("auth.warnings.login_exists"));
          setButtonLoading(false);
        }
      }
    } catch (e) {
      console.log(e);
      if (e.response.data.message === "wrong credentials") {
        setErrorMessage(t("auth.warnings.login_error"));
        setButtonLoading(false);
      } else if (e.response.data.message === "crypto_error") {
        setErrorMessage(t("auth.warnings.login_error"));
        setButtonLoading(false);
      } else if (e.response.data.message === "not activated") {
        setErrorMessage(t("auth.warnings.login_error_not_activated"));
        setButtonLoading(false);
      } else if (e.response.data.message === "user not exists") {
        setErrorMessage(t("auth.warnings.login_exists"));
        setButtonLoading(false);
      } else if (e.response.data.message === "not_active") {
        setActiveError(true);
        setButtonLoading(false);
      } else if (e.response.data.message === "terms_not_accepted") {
        setShowTermsModal(true);
        setButtonLoading(false);
      } else {
        setErrorMessage(t("auth.warnings.login_exists"));
        setButtonLoading(false);
      }
    }
  };

  const responseErrorGoogle = res => {
    setButtonLoading(false);
  };

  let animationInstance = null;

  const getInstance = instance => {
    animationInstance = instance;
  };

  const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "50%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 999
  };

  const googleId =
    process.env.REACT_APP_TEST_VAR && process.env.REACT_APP_TEST_VAR === "dev"
      ? "1018221763191-obi7thk61bpueleb7s3tipepu2scifg7.apps.googleusercontent.com"
      : "1018221763191-00jf7ej4bj015nogjd0te0o8p47uulsi.apps.googleusercontent.com";

  const facebookId =
    process.env.REACT_APP_TEST_VAR && process.env.REACT_APP_TEST_VAR === "dev"
      ? "671525823980146"
      : "323843402967553";

  /*const responseFacebook = async (facebookResponse) => {
    if (facebookResponse.name && facebookResponse.email) {
      try {
        const response = await axios.post(`${environment.baseUrl}auth/facebooklogin`,
          {
            name: facebookResponse.name,
            email: facebookResponse.email,
            language: facebookResponse.languages ? facebookResponse.languages[0] : "de"
          }
        );
        if (response && response.data) {
          if (response.data.firstName) {
            await new cookieService().setTokenData(response.data);
            setButtonLoading(false);
            history.push("/");
          } else if (response.data === "created") {
            setButtonLoading(false);
            setSuccessfullyRegistered(true);
          } else {
            setErrorMessage(t("auth.warnings.login_exists"));
            setButtonLoading(false);
          }
        }
      } catch (e) {
        if (e.response.data.message === "wrong credentials") {
          setErrorMessage(t("auth.warnings.login_error"));
          setButtonLoading(false);
        } else if (e.response.data.message === "not activated") {
          setErrorMessage(t("auth.warnings.login_error_not_activated"));
          setButtonLoading(false);
        } else if (e.response.data.message === "user not exists") {
          setErrorMessage(t("auth.warnings.login_exists"));
          setButtonLoading(false);
        } else if (e.response.data.message === "not_active") {
          setActiveError(true);
          setButtonLoading(false);
        } else {
          setErrorMessage(t("auth.warnings.login_exists"));
          setButtonLoading(false);
        }
      }
    } else {
      console.log("Data not provided");
    }
  };*/

  return (
    <div style={{marginTop: "5%"}} className="main_box">
      <img src={Logo} id="taxefy_logo" alt="" />
      <img
        style={{left: "15%", top: "70%", rotate: "0deg"}}
        src={TaxefyIcon}
        className="background_logos"
        alt=""
      />
      <img
        style={{left: "15%", top: "50%", rotate: "-30deg"}}
        src={TaxefyCheckmark2}
        className="background_logos_small"
        alt=""
      />
      <img
        style={{left: "25%", top: "60%", rotate: "-30deg"}}
        src={TaxefyCheckmark}
        className="background_logos_small"
        alt=""
      />
      <img
        style={{
          right: "15%",
          top: "5%",
          rotate: "-60deg",
          transform: "scale(-1,1)"
        }}
        src={TaxefyIcon}
        className="background_logos"
        alt=""
      />
      <img
        style={{right: "3%", top: "70%", rotate: "90deg"}}
        src={TaxefyCheckmark2}
        className="background_logos_small"
        alt=""
      />
      <img
        style={{
          right: "25%",
          top: "30%",
          rotate: "-67deg",
          transform: "scale(1,-1)"
        }}
        src={TaxefyCheckmark}
        className="background_logos_small"
        alt=""
      />
      {successfullyRegistered ? (
        <div className="register_success">
          <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} fire={fire} />
          <motion.img
            animate={{opacity: 1}}
            initial={{opacity: 0}}
            transition={{
              x: {type: "spring", stiffness: 100},
              default: {delay: 0.4, duration: 0.3}
            }}
            src={medalImg}
            alt="medal"
          />
          <motion.h1
            animate={{opacity: 1, translateY: 0}}
            initial={{opacity: 0, translateY: "50px"}}
            transition={{
              x: {type: "spring", stiffness: 100},
              default: {delay: 0.6, duration: 0.3}
            }}
          >
            {t("auth.register.success_title")}
          </motion.h1>
          <motion.h2
            animate={{opacity: 1, translateY: 0}}
            initial={{opacity: 0, translateY: "50px"}}
            transition={{
              x: {type: "spring", stiffness: 100},
              default: {delay: 0.8, duration: 0.3}
            }}
          >
            {t("auth.register.success_sub_title", {email: formik.values.email})}
          </motion.h2>
          <motion.div
            style={{width: "100%", marginTop: "20px"}}
            animate={{opacity: 1, translateX: 0}}
            initial={{opacity: 0, translateX: "-150px"}}
            transition={{
              x: {type: "spring", stiffness: 100},
              default: {delay: 1, duration: 0.3}
            }}
          >
            <Button
              style={{
                width: "100%",
                maxWidth: "none"
              }}
              text={t("auth.register.success_btn")}
              handleClick={() => setSuccessfullyRegistered(false)}
            />
          </motion.div>
        </div>
      ) : (
        <motion.div
          animate={{translateY: 0, opacity: 1}}
          initial={{translateY: "-100vh", opacity: 0}}
          transition={{
            x: {type: "spring", stiffness: 100},
            default: {duration: 0.3}
          }}
          exit={{translateY: "100vh", opacity: 0}}
          style={{
            width: "30%",
            minWidth: "280px"
          }}
        >
          <Modal
            footer={null}
            title={t("auth.register.agb_modal_title")}
            visible={showTermsModal}
            onCancel={() => setShowTermsModal(false)}
          >
            <h4 className="register_agb">
              {t("auth.register.agb_text_one")}
              <a href="https://taxefy.at/AGB" rel="noreferrer" target="_blank">
                {t("auth.register.agb_text_agb")}
              </a>
              <span>{t("auth.register.agb_text_two")}</span>
              <a href="https://taxefy.at/Datenschutz" rel="noreferrer" target="_blank">
                {t("auth.register.agb_text_data")}
              </a>
              <span>{t("auth.register.agb_text_three")}</span>
            </h4>
            <Button
              text={t("auth.register.agb_modal_btn")}
              style={{
                width: "100%"
              }}
              handleClick={() => {
                setShowTermsModal(false);
                responseGoogle(googleRes, false);
              }}
            />
          </Modal>
          <h1 className="login_title_2">
            {props.partnermanager ? (
              <p>
                Log In <br />
                <span style={{color: "rgba(0, 190, 170, 1)"}}>Affiliate-Partner</span>
              </p>
            ) : (
              t("auth.login.login")
            )}
          </h1>
          <h4 hidden={hideRegisterLink()} className="login_sub_title">
            {t("auth.login.login_desc")}
          </h4>

          {/*<FacebookLogin
            appId={facebookId}
            callback={responseFacebook}
            fields="name,email,languages"
            render={renderProps => (
              <button
                className="facebook_btn"
                onClick={renderProps.onClick}>
                <div className="row">
                  <img src={facebookLogo} alt="facebook"/>
                  <p>{t("auth.login.facebook_btn")}</p>
                </div>
              </button>
            )}
          />*/}

          <form onSubmit={e => formik.handleSubmit(e)}>
            {activeError ? (
              <motion.div
                className="error_box_container"
                animate={{opacity: 1}}
                initial={{opacity: 0}}
                transition={{
                  delay: 0.3,
                  x: {type: "spring", stiffness: 100},
                  default: {duration: 1}
                }}
              >
                <i className="fas fa-exclamation-triangle"></i>
                <p>
                  {t("auth.warnings.login_not_active")} <br />
                  <span onClick={() => history.push("/auth/restoreAccount")}>
                    {t("auth.warnings.login_restore")}
                  </span>
                </p>
              </motion.div>
            ) : null}

            {errorMessage.length ? <ErrorBox message={errorMessage} /> : null}

            <div style={{position: "relative"}}>
              <FormikInput
                htmlFor="email"
                name="email"
                value={formik.values.email}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("auth.login.email_label")}
                placeholder="max@musterman.at"
              />
              {formik.errors.email && formik.touched.email && (
                <motion.p animate={{y: 5}} className="input_error" style={{fontSize: "10px"}}>
                  {formik.errors.email}
                </motion.p>
              )}

              <div style={{height: "15px"}}></div>

              <FormikInput
                htmlFor="password"
                name="password"
                value={formik.values.password}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("auth.login.password_label")}
                placeholder="******"
              />
              {formik.errors.password && formik.touched.password && (
                <motion.p animate={{y: 5}} className="input_error" style={{fontSize: "10px"}}>
                  {formik.errors.password}
                </motion.p>
              )}
              <Button
                text={t("auth.login.btn")}
                style={{
                  marginTop: "30px",
                  marginBottom: "20px",
                  maxWidth: "none",
                  width: "100%"
                }}
                buttonType="submit"
                loading={buttonLoading}
                disabled={buttonLoading || loginDisable}
              />
            </div>
          </form>

          {/*<div className="or_container">
            <div />
            <p>{t("auth.login.login_or")}</p>
            <div />
          </div>

          <GoogleLogin
            clientId={googleId}
            render={renderProps => (
              <button
                className="google_btn"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}>
                <div className="row">
                  <img src={googleLogo} alt="google"/>
                  <p>{t("auth.login.google_btn")}</p>
                </div>
              </button>
            )}
            onSuccess={(res) => responseGoogle(res, true)}
            onFailure={responseErrorGoogle}
            cookiePolicy={"single_host_origin"}
          />*/}

          <p hidden={hideRegisterLink()} className="login_register_text">
            {t("auth.login.register_text")}
            <span
              onClick={() =>
                history.push((props.partnermanager ? "/partnermanager" : "") + "/auth/register")
              }
            >
              {t("auth.login.register_link")}
            </span>
          </p>
          {!props.partnermanager && (
            <p
              onClick={() => history.push(`${props.consultant ? "/consultant" : ""}/auth/forget`)}
              className="login_forget_text"
            >
              {t("auth.login.forget_link")}
            </p>
          )}
          {props.partnermanager && (
            <p
              onClick={() => history.push("/partnermanager/auth/register")}
              className="login_forget_text"
            >
              Noch kein Konto? Hier registrieren
            </p>
          )}
          <p
            style={{
              color: "#DDD",
              marginTop: "2em"
            }}
          >
            {`App version: ${version}${environment?.country?.slice(0,1) || ""}`}
          </p>
        </motion.div>
      )}
    </div>
  );
}
