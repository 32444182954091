import React, {useState} from "react";
import "./QuestionComponentsStyles.css";
import {getText, validateText} from "../../utils/tools";
import Button from "../CommonComponents/FormButton/FormButton";
import {motion} from "framer-motion";
import NavigationButtons from "./NavigationButtons/NavigationButtons";
import {useTranslation} from "react-i18next";

export default function FormComponent(props) {
  const { t } = useTranslation();

  const [buttonBlocked, setButtonBlocked] = useState(false);
  const [answered, setAnswered] = useState(false);

  return(
    <motion.form
      className="single_choice"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{
        delay: 0.3,
        x: { type: "spring", stiffness: 100 },
        default: { duration: 1 },
      }}
      onSubmit={(e) => {
        e.preventDefault();
        if (!buttonBlocked) {
          setButtonBlocked(true);
          if (!props.blocked) {
            setAnswered(true);
            props.forward(props.condition);
          } else {
            props.showError(props.condition.abort_code);
          }
          setTimeout(() => {
            setButtonBlocked(false);
          }, 500);
        }
      }}
    >

      {props.showButtonBack
        ? <NavigationButtons back={props.backward} forward={() => {
          if (answered) {
            props.forward(props.condition);
          }
        }} />
        : null
      }

      <div className="question_header_box">
        <h6>{getText(props.ANS, props.QS, props.qs.title, props.lang)}</h6>
        {props.qs.dsc && props.qs.dsc.length
          ? <div className="drawer_btn" onClick={() => {
            props.showDrawer(props.qs.dsc);
          }}>
            <i className="fas fa-question"></i>
          </div>
          : null
        }
      </div>
      {
        props.qs.placeholder.map((item, idx) => {
          return <input
            key={`frm_${props.qs.answer.uid[idx]}`}
            className="input_component"
            placeholder={getText(props.ANS, props.QS, item, props.lang)}
            value={props.answr[idx]}
            onChange={e => {
              const text = e.target.value;
              if (props.qs.answer.data_type === "salary" ) {
                if (e.target.value && e.target.value > 0) {
                  if(validateText(text, props.qs.answer.data_type[idx])){
                    props.answr[idx] = text;
                    props.setValue(props.answr);
                  }
                } else {
                  props.setValue("0");
                }
              } else {
                if(validateText(text, props.qs.answer.data_type[idx])){
                  props.answr[idx] = text;
                  props.setValue(props.answr);
                }
              }
            }}
            type={props.qs.answer.data_type === "salary" ? "number" : "text"}
          />;
        })
      }
      <Button
        text={t("questions.continue")}
        disabled={buttonBlocked || props.blocked}
        buttonType="submit"
      />
    </motion.form>
  );
}
