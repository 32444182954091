import { combineReducers } from "redux";

import generalReducer from "./general/reducer";
import authReducer from "./auth/reducer";
import appReducer from "./app/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  general: generalReducer,
});

export default rootReducer;
