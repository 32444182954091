import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {consultantService} from "../../../../services/consultant.service";
import FormikInput from "../../../../components/CommonComponents/FormikInput/FormikInput";
import Button from "../../../../components/CommonComponents/FormButton/FormButton";
import * as Yup from "yup";
import {Select} from "antd";
import {useFormik} from "formik";
import "./CreateConsultant.css";
import ErrorBox from "../../../../components/ErrorBox/ErrorBox";
import SuccessBox from "../../../../components/SuccessBox/SuccessBox";

export default function CreateConsultant(props) {
  const phoneRegExp = /^\+?[0-9]*$/;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const {t} = useTranslation();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      address: "",
      phone: "",
      taxNumber: "",
      priority: 0,
      maxPerDay: 0,
      status: 0,
      activated: false
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required(t("auth.warnings.required")),
      lastName: Yup.string().required(t("auth.warnings.required")),
      address: Yup.string().required(t("auth.warnings.required")),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(8)
        .required(t("auth.warnings.required")),
      taxNumber: Yup.string().required(t("auth.warnings.required")),
      email: Yup.string()
        .email(t("auth.warnings.email"))
        .required(t("auth.warnings.required")),
      maxPerDay: Yup.number().required(t("auth.warnings.required")),
      priority: Yup.number().required(t("auth.warnings.required")),
      password: Yup.string()
        .required(t("auth.warnings.required"))
        .min(8, t("auth.warnings.password_length")),
      confirmPassword: Yup.string()
        .min(8, t("auth.warnings.password_length"))
        .when("password", {
          is: val => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("auth.warnings.password_not_same")
          )
        })
        .required(t("auth.warnings.required"))
    }),

    onSubmit: async () => {
      setButtonLoading(true);
      setErrorMessage("");
      setSuccessMessage(false);
      const createRes = await new consultantService().createConsultant({
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        password: formik.values.password,
        address: formik.values.address,
        phone: formik.values.phone,
        taxNumber: formik.values.taxNumber,
        priority: formik.values.priority,
        maxPerDay: formik.values.maxPerDay,
        status: formik.values.status,
        activated: formik.values.activated
      });
      console.log(createRes);
      if (createRes && !createRes.success) {
        setErrorMessage(createRes.message);
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
        setSuccessMessage(true);
        setTimeout(() => {
          setSuccessMessage(false);
          props.createdSuccess(true);
        }, 2000);
        formik.resetForm();
      }
    }
  });
  return (
    <div>
      <div className="space-between">
        <h2>Create new Consultant User</h2>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="create_consultant_form">
          <div className="formBox">
            <FormikInput
              name="firstName"
              value={formik.values.firstName}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"First Name"}
              error={formik.errors.firstName}
            />
          </div>
          <div className="formBox">
            <FormikInput
              name="lastName"
              value={formik.values.lastName}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Last Name"}
              error={formik.errors.lastName}
            />
          </div>
          <div style={{width: "25%"}} className="formBox">
            <FormikInput
              name="email"
              value={formik.values.email}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"E-Mail"}
              error={formik.errors.email}
            />
          </div>

          <div style={{width: "25%"}} className="formBox">
            <FormikInput
              name="address"
              value={formik.values.address}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Address"}
              error={formik.errors.address}
            />
          </div>
          <div className="formBox">
            <FormikInput
              name="phone"
              value={formik.values.phone}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Phone"}
              error={formik.errors.phone}
            />
          </div>
          <div className="formBox">
            <FormikInput
              htmlFor="password"
              name="password"
              value={formik.values.password}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Password"}
              error={formik.errors.password}
            />
          </div>
          <div className="formBox">
            <FormikInput
              htmlFor="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Confirm password"}
              error={formik.errors.confirmPassword}
            />
          </div>
        </div>
        <div className="create_consultant_form">
          <div className="formBox">
            <FormikInput
              name="taxNumber"
              value={formik.values.taxNumber}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Tax-Number"}
              error={formik.errors.taxNumber}
            />
          </div>

          <div style={{marginRight: "20px"}}>
            <span style={{color: "rgba(0, 190, 170, 1", fontWeight: "bold"}}>
              Status
            </span>
            <Select
              value={formik.values.status}
              defaultValue={0}
              name="status"
              style={{width: "200px"}}
              onChange={e => {
                formik.setFieldValue("status", e);
              }}
              options={[
                {value: 0, label: "Disabled"},
                {value: 1, label: "Enabled"},
                {value: 2, label: "Half-Blocked"},
                {value: 3, label: "Blocked"}
              ]}
            />
          </div>
          <div style={{width: "10%", marginBottom: "10px"}}>
            <FormikInput
              name="maxPerDay"
              value={formik.values.maxPerDay}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Max Per Day"}
              error={formik.errors.maxPerDay}
            />
          </div>
          <div
            style={{
              width: "5%",
              marginBottom: "10px",
              marginLeft: "20px",
              marginRight: "20px"
            }}
          >
            <FormikInput
              name="priority"
              value={formik.values.priority}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Priority"}
              error={formik.errors.priority}
            />
          </div>
          <div style={{marginRight: "20px"}}>
            <FormikInput
              htmlFor="checkbox"
              name="activated"
              value={formik.values.activated}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Activated"}
            />
            {formik.errors.activated && (
              <p className="input_error">{formik.errors.activated}</p>
            )}
          </div>
        </div>
        <div style={{width: "15%"}} className="formBox">
          <Button
            text={"Create Consultant"}
            style={{
              marginTop: "22px",
              width: "100%"
            }}
            disabled={!formik.isValid || buttonLoading}
            loading={buttonLoading}
            buttonType="submit"
          />
        </div>
      </form>
      {errorMessage && <ErrorBox message={errorMessage} />}
      {successMessage && <SuccessBox message={"Consultant Created"} />}
    </div>
  );
}
