import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {consultantService} from "../../../services/consultant.service";
import * as Yup from "yup";
import "./Settings.css";
import Button from "../../../components/CommonComponents/FormButton/FormButton";
import FormikInput from "../../../components/CommonComponents/FormikInput/FormikInput";
import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import SuccessBox from "../../../components/SuccessBox/SuccessBox";
import Loading from "../../../components/Loading";
export default function ConsultantPanelLayout() {
  const phoneRegExp = /^\+?[0-9]*$/;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [successChange, setSuccessChange] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {t} = useTranslation();

  useEffect(() => {
    getConsultantData();
  }, [pageLoading]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      address: "",
      phone: "",
      lastName: "",
      taxNumber: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("auth.warnings.required")),
      lastName: Yup.string().required(t("auth.warnings.required")),
      address: Yup.string().required(t("auth.warnings.required")),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(8)
        .required(t("auth.warnings.required")),
    }),

    onSubmit: async () => {
      setButtonLoading(true);
      setErrorMessage("");
      setSuccessChange(false);
      const changeRes = await new consultantService().updateConsultantData({
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        address: formik.values.address,
        phone: formik.values.phone,
      });
      if (changeRes && !changeRes.success) {
        setErrorMessage(changeRes.message);

        setButtonLoading(false);
      } else {
        setButtonLoading(false);
        setSuccessChange(true);
      }
    }
  });

  const getConsultantData = async () => {
    const data = await new consultantService().getConsultantData();
    formik.values.firstName = data.firstName;
    formik.values.lastName = data.lastName;
    formik.values.address = data.address;
    formik.values.phone = data.phone;
    formik.values.taxNumber = data.taxNumber;
    formik.values.tid = data.tid;
    formik.values.benid = data.benid;
    formik.values.pin = data.pin;
    setPageLoading(false);
  };

  return pageLoading ? (
    <div style={{position: "fixed"}}>
      <Loading />
    </div>
  ) : (
    <div style={{width: "75%", paddingTop: "40px"}}>
      {errorMessage && (
        <div style={{height: "40px"}}>
          <ErrorBox message={errorMessage} />
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <h1 className="settings_header">Information for Interface</h1>
        {successChange && (
          <SuccessBox message="Information has been successfully updated" />
        )}
        <FormikInput
          name="taxNumber"
          style={{
            width: "40%",
            marginBottom: "10px"
          }}
          disabled={true}
          value={formik.values.taxNumber}
          handleChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label={"Tax-Number"}
        />
        <h1 className="settings_header">User Information</h1>
        <div className="userinfo_inputs_box">
          <div className="input_box_wider">
            <FormikInput
              name="firstName"
              value={formik.values.firstName}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"First Name"}
            />
            {formik.errors.firstName && (
              <p className="error_p">{formik.errors.firstName}</p>
            )}
          </div>
          <div className="input_box_wider">
            <FormikInput
              name="lastName"
              value={formik.values.lastName}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Last Name"}
            />
            {formik.errors.lastName && (
              <p className="error_p">{formik.errors.lastName}</p>
            )}
          </div>
          <div className="input_box_wider">
            <FormikInput
              name="address"
              value={formik.values.address}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Address"}
            />
            {formik.errors.address && (
              <p className="error_p">{formik.errors.address}</p>
            )}
          </div>
          <div className="input_box_wider">
            <FormikInput
              name="phone"
              value={formik.values.phone}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Phone"}
            />
            {formik.errors.phone && (
              <p className="error_p">{formik.errors.phone}</p>
            )}
          </div>
        </div>
        <Button
          text={"Submit Changes"}
          style={{
            marginTop: "30px",
            marginBottom: "20px",
            width: "100%"
          }}
          disabled={!formik.dirty || buttonLoading}
          loading={buttonLoading}
          buttonType="submit"
        />
      </form>
    </div>
  );
}
