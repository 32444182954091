import React, {useEffect} from "react";
import HashLoader from "react-spinners/HashLoader";
import {isAndroid, isIOS} from "react-device-detect";
import {useHistory, useParams} from "react-router-dom";

export default function InviteScreen() {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (isAndroid) {
      console.log("Android");
    } else if (isIOS) {
      console.log("IOS");
    } else {
      history.push({
        pathname: "/auth/register",
        state: {
          invitationId: id
        }
      });
    }

  }, []);

  return(
    <div style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <HashLoader color="#00beaa" size={45} />
    </div>
  );
}
