import React, {useState} from "react";
import "./ProfileView.css";
import Header from "../../components/Header/Header";
import { Tabs } from "antd";
import {useTranslation} from "react-i18next";
import ProfileTab from "./ProfileTab";
import StatisticTab from "./StatisticTab";
import InvitationsTab from "./InvitationsTab";
import BankTab from "./BankTab";
import MoreDataTab from "./MoreDataTab";

const { TabPane } = Tabs;


export default function ProfileView() {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(1);

  return(
    <div>
      <div className="profile_view_page">
        <Tabs
          defaultActiveKey={activeTab.toString()}
          onChange={(key) => setActiveTab(key)}
        >
          <TabPane tab={t("profile.tabs.profile")} key="1">
            <ProfileTab />
          </TabPane>
          <TabPane tab={t("profile.tabs.bank")} key="2">
            <BankTab />
          </TabPane>
          <TabPane tab={t("profile.tabs.statistic")} key="3">
            <StatisticTab />
          </TabPane>
          {/*<TabPane tab={t("profile.tabs.invitations")} key="4">
            <InvitationsTab />
          </TabPane>*/}
          <TabPane tab={t("profile.tabs.more")} key="4">
            <MoreDataTab />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
