import React, {useCallback, useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import "./ScreensStyles.css";
import { Drawer, Button } from "antd";
import { motion } from "framer-motion";
import bowlingAnimation from "../assets/animations/bowling.json";
import SwipeableViews from "react-swipeable-views";

// Components
import { COLORS, MONTHS } from "../utils/constants";
import {
  getAnsSelector,
  getQsSelector,
  getLanguageSelector, getSavedAnswersSelector
} from "../data/app/selectors";
import {setAnsAction, setSavedAnswers} from "../data/app/actions";
import {
  findEquivalentGroupAns,
  findQuestionById,
  findGroupIndexById,
  findQuestionIndexById,
  findConditionByAnswer,
  checkExpectations,
  solveExpression,
  setAnswer,
  findAnswer,
  checkVisibility,
  calculateTax, solveExpressionById
} from "../utils/tools";
import strings from "../localization/en";
import MultipleChoice from "../components/QuestionsComponents/MultipleChoice";
import SingleChoice from "../components/QuestionsComponents/SingleChoice";
import SliderQuestion from "../components/QuestionsComponents/SliderQuestion";
import AddressesComponent from "../components/QuestionsComponents/AddressesComponent";
import InputComponent from "../components/QuestionsComponents/InputComponent";
import WorkAddressesComponent from "../components/QuestionsComponents/WorkAddressesComponent";
import FormComponent from "../components/QuestionsComponents/FormComponent";
import WorkPeriod from "../components/QuestionsComponents/Workperiod";
import Lottie from "react-lottie";
import {getDistanceAsyncAction} from "../data/general/actions";
import Navigation from "../components/Navigation/Navigation";
import axios from "axios";
import environment from "../environment";
import {cookieService} from "../services/cookie.service";

const QuestionnaireScreen = ({
  QS, ANS, language, savedAnswers, setCurrentComponent
}) => {
  const dispatch = useDispatch();
  const lang = language;

  /*useEffect(() => {
    const getFormByYear = async (year) => {
      const cookieToken = await new cookieService().getTokenData();
      const getFormResponse = await axios.get(`${environment.baseUrl}form/getFormByUserAndYear?year=${year}`, {
        headers: {
          Authorization: `Bearer ${cookieToken.refreshToken}`,
          "app-version": "1.0.1"
        }
      });
      if (getFormResponse && getFormResponse.data && getFormResponse.data.formdata) {
        dispatch(setAnsAction(getFormResponse.data.formdata));
      }
    };
    if (QS && QS.year) {
      getFormByYear(QS.year);
    }
  }, []);*/

  const [formErrorMessage, setFormErrorMessage] = useState(false);

  let calculations = calculateTax(QS, ANS, lang);

  const showError = abortCode => {
    console.log(`Abort: ${abortCode}`);
  };

  const forward = (condition) => {

    if(condition.setloop){
      for(let i=0; i<ANS.loops.length; i++){
        if(ANS.loops[i].id === condition.setloop){
          const loop = QS.loops.filter(item => item.id === condition.setloop);
          if(loop.length === 1){
            const expr = QS.expressions.filter(item => item.id === loop[0].expression);
            if(expr.length === 1){
              const exp = checkExpectations(ANS, expr[0]) ? expr[0].passed : expr[0].otherwise;
              ANS.loops[i].i = 0;
              ANS.loops[i].count = solveExpression(ANS, QS, exp, lang);
              const groupIdx = findGroupIndexById(ANS, loop[0].start[0]);
              const loopQuestions = QS.groups[groupIdx].questions.filter(item => item.loop === condition.setloop);
              for(const lq of loopQuestions){
                const questionIdx = findQuestionIndexById(QS.groups[groupIdx], lq.id);
                ANS.groups[groupIdx].questions[questionIdx].answer = [];
                for(let j=0; j<ANS.loops[i].count; j++)
                  ANS.groups[groupIdx].questions[questionIdx]
                    .answer.push(QS.groups[groupIdx].questions[questionIdx].answer.default_value);
              }
            }
          }
          break;
        }
      }
    }

    let nextQuestionId = condition.next_question;
    let groupIdx = findGroupIndexById(QS, ANS.current_group);
    const currentQuestion = ANS.groups[groupIdx].current_question;
    let newQuestionIdx;
    let nxt = nextQuestionId;

    if(nextQuestionId > 0){
      if(isOutsideOfLoop(currentQuestion)){
        checkLoopStart(currentQuestion, nxt);
      } else if(condition.endloop) {
        nxt = checkLoopEnd(nxt);
      }
      newQuestionIdx = findQuestionIndexById(ANS.groups[groupIdx], nxt);
      ANS.groups[groupIdx].questions[newQuestionIdx].previous_question = currentQuestion;
      ANS.groups[groupIdx].current_question = nxt;
    } else {
      // group finished
      nxt = -1;
      if(condition.endloop){
        nxt = checkLoopEnd(nxt);
      }
      if(nxt < 0){
        // loop finished
        const groupCompleteState = ANS.groups[groupIdx].completed;
        ANS.groups[groupIdx].completed = true;
        if(groupIdx < QS.groups.filter(grp => checkVisibility(grp, ANS)).length - 1){
          ANS.current_group = QS.groups[groupIdx + 1].id;
          ANS.groups[groupIdx + 1].current_question = 1;
          checkLoopStart(-1, 1);
          if (!groupCompleteState) {
            setShowAnimation(true);
          }
        } else {
          // ANS.current_group = 0;
          calculations = calculateTax(QS, ANS, lang);
          setCurrentComponent("finalStep");
        }
      } else {
        ANS.groups[groupIdx].current_question = nxt;
        const questionIdx = findQuestionIndexById(QS.groups[groupIdx], ANS.groups[groupIdx].current_question);
        ANS.groups[groupIdx].questions[questionIdx].previous_question = currentQuestion;
      }
    }
    checkDistanceCalculation();
    dispatch(setAnsAction(ANS));
    // Scroll to next question after answer
    const element = document.getElementById(nxt.toString());
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth"
        });
      }, 50);
    }
  };

  const checkDistanceCalculation = () => {
    const groupIdx = findGroupIndexById(QS, ANS.current_group);
    const questionIdx = findQuestionIndexById(QS.groups[groupIdx], ANS.groups[groupIdx].current_question);
    if(QS.groups[groupIdx].questions[questionIdx].setdistance === true){
      // check if neede (private car used)
      let destinations = "";
      const destIds = [];
      const jobCount = solveExpressionById(ANS, QS, QS.calculation.general.job_count, lang);
      for(let i = 0; i < jobCount; i++) {
        if(checkExpectations(ANS, QS.calculation.AP2, QS, i)){
          const work_address = findAnswer(
            ANS,
            QS,
            QS.calculation.income.work_address.group,
            QS.calculation.income.work_address.question, i);
          destinations = `${destinations}${destinations.length === 0 ? "" : "|"}${work_address}`;
          destIds.push(i);
        }
      }
      if(destIds.length > 0){
        // calculate distance matrix
        let origins = "";
        const origIds = [];
        const livingAddresses = findAnswer(
          ANS, QS,
          QS.calculation.AP2.living_address.group,
          QS.calculation.AP2.living_address.question);
        for(let i = 0; i < livingAddresses.length; i++){
          origins = `${origins}${origins.length === 0 ? "" : "|"}${livingAddresses[i].address}`;
          origIds.push(i);
        }
        ANS.distance = null;
        console.log("NOW WILL BE CALL");
        dispatch(getDistanceAsyncAction(origins, destinations, body => {
          ANS.distance = {
            destIds,
            origIds,
            matrix: body
          };
          dispatch(setAnsAction(ANS));
        }));
      }
    }
  };

  const checkLoopEnd = nxt => {
    const groupIdx = findGroupIndexById(QS, ANS.current_group);
    const questionIdx = findQuestionIndexById(QS.groups[groupIdx], ANS.groups[groupIdx].current_question);
    for(let i=0; i<ANS.loops.length; i++){
      if(ANS.loops[i].id === QS.groups[groupIdx].questions[questionIdx].loop){
        if(ANS.loops[i].i < ANS.loops[i].count){
          ANS.loops[i].i++;
          return QS.loops[i].start[1];
        }
      }
    }
    return nxt;
  };

  const getCurrentLoop = () => {
    const groupIdx = findGroupIndexById(QS, ANS.current_group);
    const questionIdx = findQuestionIndexById(QS.groups[groupIdx], ANS.groups[groupIdx].current_question);
    const loops1 = QS.loops.filter(item => item.id === QS.groups[groupIdx].questions[questionIdx].loop);
    const loops2 = ANS.loops.filter(item => item.id === QS.groups[groupIdx].questions[questionIdx].loop);
    if(loops1.length === 1 && loops2.length === 1) return {...loops1[0], ...loops2[0]};
    return null;
  };

  const isOutsideOfLoop = questionId => {
    const groupIdx = findGroupIndexById(QS, ANS.current_group);
    const questionIdx = findQuestionIndexById(QS.groups[groupIdx], questionId);
    return QS.groups[groupIdx].questions[questionIdx].loop === undefined;
  };

  const checkLoopStart = (preLoopQuestion, nextQsId) => {
    const groupIdx = findGroupIndexById(QS, ANS.current_group);
    const questionIdx = findQuestionIndexById(QS.groups[groupIdx], nextQsId);
    const currentQuestionIdx = findQuestionIndexById(QS.groups[groupIdx], ANS.groups[groupIdx].current_question);
    if(QS.groups[groupIdx].questions[questionIdx].loop !== undefined){
      for(let i=0; i<ANS.loops.length; i++){
        if(ANS.loops[i].id === QS.groups[groupIdx].questions[questionIdx].loop && QS.loops[i].start[1] === nextQsId){
          // loop start
          ANS.loops[i].i = 1;
          ANS.groups[groupIdx].questions[questionIdx].preloop_question = preLoopQuestion;
          return true;
        }
      }
    }
    return false;
  };

  const setValue = (answer) => {
    const groupIdx = findGroupIndexById(QS, ANS.current_group);
    ANS = setAnswer(ANS, QS, ANS.current_group, ANS.groups[groupIdx].current_question, answer);
    dispatch(setAnsAction(ANS));
    return "resolved";
  };


  const backward = () => {
    const groupIdx = findGroupIndexById(QS, ANS.current_group);
    const questionIdx = findQuestionIndexById(QS.groups[groupIdx], ANS.groups[groupIdx].current_question);
    let prev = ANS.groups[groupIdx].questions[questionIdx].previous_question;
    if(QS.groups[groupIdx].questions[questionIdx].loop !== undefined){
      for(let i=0; i<ANS.loops.length; i++){
        if(ANS.loops[i].id === QS.groups[groupIdx].questions[questionIdx].loop
          && QS.loops[i].start[1] === ANS.groups[groupIdx].current_question){
          if(ANS.loops[i].i > 1){
            ANS.loops[i].i--;
          } else {
            prev = ANS.groups[groupIdx].questions[questionIdx].preloop_question;
          }
          break;
        }
      }
    }
    ANS.groups[groupIdx].current_question = prev;
    dispatch(setAnsAction(ANS));
  };

  const [visible, setVisible] = useState(false);
  const [drawerText, setDrawerText] = useState([]);

  const showDrawer = (text) => {
    setDrawerText(text);
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const ref = useRef();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: bowlingAnimation
  };

  // Show animation after step was completed
  const [showAnimation, setShowAnimation] = useState(false);

  return (
    <div style={{position: "relative"}}>
      {/*<Calculator />*/}
      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}>
        <div className="drawer_container">
          {drawerText && drawerText.length
            ? drawerText.map((drawerItem, idx) => (
              <React.Fragment key={idx.toString()}>
                {drawerItem.type === "item"
                  ? <li>{drawerItem[lang]}</li>
                  : <strong>{drawerItem[lang]}</strong>
                }
              </React.Fragment>
            ))
            : null
          }
        </div>
      </Drawer>
      {
        QS.groups.filter(grp => checkVisibility(grp, ANS)).map(item => {
          const ansGroup = findEquivalentGroupAns(item, ANS);
          return (
            <React.Fragment key={`group-${item.id}`}>
              {
                item.id === ANS.current_group &&
                <motion.div
                  className="questions_box"
                  ref={ref}
                  animate={{ translateX: 0 }}
                  initial={{ translateX: "100vw" }}
                  transition={{
                    x: { type: "spring", stiffness: 100 },
                    default: { duration: 0.3 },
                  }}
                >
                  <QuestionBox
                    qsGroup={item}
                    ansGroup={ansGroup}
                    lang={lang}
                    forward={forward}
                    backward={backward}
                    showError={showError}
                    setValue={setValue}
                    ANS={ANS}
                    QS={QS}
                    showDrawer={(text) => showDrawer(text)}
                    formErrorMessage={formErrorMessage}
                    savedAnswers={savedAnswers}
                  />
                </motion.div>
              }
            </React.Fragment>
          );
        })
      }
    </div>
  );
};

const QuestionBox = ({
  qsGroup, ansGroup,
  lang, forward,
  backward, setValue,
  ANS, QS, showDrawer,
  showError, formErrorMessage,
  savedAnswers
}) => {
  const qs = findQuestionById(qsGroup, ansGroup.current_question);
  const ans = findQuestionById(ansGroup, ansGroup.current_question);
  let answr = findAnswer(ANS, QS, ANS.current_group, ansGroup.current_question);
  const dispatch = useDispatch();

  const [showedQuestion, setShowedQuestion] = useState([1]);

  if (!showedQuestion.includes(ansGroup.current_question)) {
    setShowedQuestion(oldArray => [...oldArray, ansGroup.current_question]);
  }


  if (savedAnswers[ANS.current_group - 1]) {
    if (!savedAnswers[ANS.current_group - 1].includes(ansGroup.current_question)) {
      const stateCopy = {...savedAnswers};
      stateCopy[ANS.current_group - 1].push(ansGroup.current_question);
      dispatch(setSavedAnswers(stateCopy));
    }
  } else {
    const stateCopy = {...savedAnswers};
    stateCopy[ANS.current_group - 1] = [1];
    dispatch(setSavedAnswers(stateCopy));
  }

  const [condition, setCondition] = useState({});

  const findCondition = async () => {
    const con = await findConditionByAnswer(qs, answr, ANS, QS);
    setCondition(con);
  };
  findCondition();

  if (!qs || !ans || answr === undefined) return null;
  const blocked = condition.abort_code !== 0;
  const finished = condition.next_question < 0;
  const showButtonBack = qs.id !== 1 || ANS.current_group !== 1;

  const questionsIds = [1];

  let stoppedQs = 0;

  const [lastAnsweredQuestion, setLastAnsweredQuestion] = useState(0);
  const [navigationQuestion, setNavigationQuestions] = useState([1]);

  const [answeredQuestions, setAnsweredQuestion] = useState([]);


  useEffect(() => {
    let temporaryArray = [1];
    let blockedQuestion = undefined;
    setAnsweredQuestion(oldArray => [...oldArray,ansGroup.current_question]);
    if (ansGroup.current_question > lastAnsweredQuestion) {
      setLastAnsweredQuestion(ansGroup.current_question);
    }
    Promise.all(QS.groups[ANS.current_group - 1].questions.map(async question => {
      ANS.groups[ANS.current_group - 1].questions.map(async ansQuestion => {
        if (question.id === ansQuestion.id) {
          const condition = await findConditionByAnswer(
            findQuestionById(qsGroup, ansQuestion.id),
            findAnswer(ANS, QS, ANS.current_group, ansQuestion.id),
            ANS,
            QS
          );
          if (condition && condition.abort_code === 0) {
            if (
              !temporaryArray.includes(ansQuestion.id)
              && ansQuestion.id <= lastAnsweredQuestion && answeredQuestions.includes(ansQuestion.id)) {
              temporaryArray.push(ansQuestion.id);
              return true;
            }
          } else {
            blockedQuestion = ansQuestion.id;
          }
        }});
    })).then(() => {
      if (!temporaryArray.includes(ansGroup.current_question)) {
        temporaryArray.push(ansGroup.current_question);
      }
      const filteredArray = [];
      if (blockedQuestion) {
        temporaryArray.map(item => {
          if (item <= blockedQuestion) {
            filteredArray.push(item);
          }
        });
      }
      // Children counter
      if (ANS.current_group === 2 && ansGroup.current_question === 3) {
        const isAllowed = (value) => {
          return value <= 3;
        };
        const filtered = temporaryArray.filter(isAllowed);
        temporaryArray = filtered;
      }
      // Routes
      if (ANS.current_group === 3 && ansGroup.current_question === 4) {
        const isAllowed = (value) => {
          return value <= 4;
        };
        const filtered = temporaryArray.filter(isAllowed);
        temporaryArray = filtered;
      }
      setNavigationQuestions(blockedQuestion ? filteredArray : temporaryArray);
    });
  }, [ANS]);


  const generateAnswers = () => {
    const currentQS = findQuestionById(qsGroup, qs.id);
    const currentANSWR = findAnswer(ANS, QS, ANS.current_group, qs.id);
    switch (currentQS.answer.ui_type) {
    case "options":
      return <SingleChoice
        options={currentQS.answer.options}
        answr={currentANSWR}
        ANS={ANS}
        QS={QS}
        lang={lang}
        qs={currentQS}
        setValue={(selectedValue) => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          setValue(selectedValue, ansCopy);
        }}
        forward={(condition => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          dispatch(setAnsAction(ansCopy));
          setTimeout(async () => {
            forward(
              await findConditionByAnswer(
                findQuestionById(qsGroup, ansGroup.current_question),
                findAnswer(ANS, QS, ANS.current_group, ansGroup.current_question),
                ANS
              ),
              qs.id);
          }, 100);
        })}
        condition={condition}
        blocked={blocked}
        finished={finished}
        showDrawer={(text) => {
          showDrawer(text);
        }}
        showError={showError}
        formErrorMessage={formErrorMessage}
        backward={backward}
        showButtonBack={showButtonBack}
      />;
    case "multiple":
      return <MultipleChoice
        options={currentQS.answer.options}
        answr={currentANSWR}
        ANS={ANS}
        QS={QS}
        lang={lang}
        qs={currentQS}
        setValue={(selectedValue) => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          setValue(selectedValue, ansCopy);
        }}
        forward={(condition => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          dispatch(setAnsAction(ansCopy));
          setTimeout(async() => {
            forward(
              await findConditionByAnswer(
                findQuestionById(qsGroup, ansGroup.current_question),
                findAnswer(ANS, QS, ANS.current_group, ansGroup.current_question),
                ANS
              ),
              qs.id);
          }, 100);
        })}
        condition={condition}
        blocked={blocked}
        finished={finished}
        showDrawer={showDrawer}
        showError={showError}
        formErrorMessage={formErrorMessage}
        backward={backward}
        showButtonBack={showButtonBack}
      />;
    case "slider":
      return <SliderQuestion
        options={currentQS.answer.options}
        answr={currentANSWR}
        ANS={ANS}
        QS={QS}
        lang={lang}
        qs={currentQS}
        setValue={(selectedValue) => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          setValue(selectedValue, ansCopy);
        }}
        forward={(condition => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          dispatch(setAnsAction(ansCopy));
          setTimeout(async() => {
            forward(
              await findConditionByAnswer(
                findQuestionById(qsGroup, ansGroup.current_question),
                findAnswer(ANS, QS, ANS.current_group, ansGroup.current_question),
                ANS
              ),
              qs.id);
          }, 100);
        })}
        condition={condition}
        blocked={blocked}
        finished={finished}
        showDrawer={showDrawer}
        showError={showError}
        backward={backward}
        savedAnswers={savedAnswers}
        showButtonBack={showButtonBack}
      />;
    case "addresses":
      return <AddressesComponent
        options={currentQS.answer.options}
        answr={currentANSWR}
        ANS={ANS}
        QS={QS}
        lang={lang}
        qs={currentQS}
        setValue={(selectedValue) => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          setValue(selectedValue, ansCopy);
        }}
        forward={(condition => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          dispatch(setAnsAction(ansCopy));
          setTimeout(async() => {
            forward(
              await findConditionByAnswer(
                findQuestionById(qsGroup, ansGroup.current_question),
                findAnswer(ANS, QS, ANS.current_group, ansGroup.current_question),
                ANS
              ),
              qs.id);
          }, 100);
        })}
        condition={condition}
        blocked={blocked}
        finished={finished}
        strings={strings}
        MONTHS={MONTHS}
        showDrawer={showDrawer}
        showError={showError}
        backward={backward}
        showButtonBack={showButtonBack}
      />;
    case "input":
      return <InputComponent
        options={currentQS.answer.options}
        answr={currentANSWR}
        ANS={ANS}
        QS={QS}
        lang={lang}
        qs={currentQS}
        setValue={(selectedValue) => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          setValue(selectedValue, ansCopy);
        }}
        forward={(condition => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          dispatch(setAnsAction(ansCopy));
          setTimeout(async() => {
            forward(
              await findConditionByAnswer(
                findQuestionById(qsGroup, ansGroup.current_question),
                findAnswer(ANS, QS, ANS.current_group, ansGroup.current_question),
                ANS
              ),
              qs.id);
          }, 100);
        })}
        condition={condition}
        blocked={blocked}
        finished={finished}
        showDrawer={showDrawer}
        showError={showError}
        backward={backward}
        showButtonBack={showButtonBack}
      />;
    case "workaddress":
      return <WorkAddressesComponent
        options={currentQS.answer.options}
        answr={currentANSWR}
        ANS={ANS}
        QS={QS}
        lang={lang}
        qs={currentQS}
        setValue={(selectedValue) => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          setValue(selectedValue, ansCopy);
        }}
        forward={(condition => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          dispatch(setAnsAction(ansCopy));
          setTimeout(async() => {
            forward(
              await findConditionByAnswer(
                findQuestionById(qsGroup, ansGroup.current_question),
                findAnswer(ANS, QS, ANS.current_group, ansGroup.current_question),
                ANS,
              ),
              qs.id);
          }, 100);
        })}
        condition={condition}
        blocked={blocked}
        finished={finished}
        strings={strings}
        MONTHS={MONTHS}
        showDrawer={showDrawer}
        showError={showError}
        backward={backward}
        showButtonBack={showButtonBack}
      />;
    case "form":
      return <FormComponent
        options={currentQS.answer.options}
        answr={currentANSWR}
        ANS={ANS}
        QS={QS}
        lang={lang}
        qs={currentQS}
        setValue={(selectedValue) => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          setValue(selectedValue, ansCopy);
        }}
        forward={(condition => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          dispatch(setAnsAction(ansCopy));
          setTimeout(async() => {
            forward(
              await findConditionByAnswer(
                findQuestionById(qsGroup, ansGroup.current_question),
                findAnswer(ANS, QS, ANS.current_group, ansGroup.current_question),
                ANS
              ),
              qs.id);
          }, 100);
        })}
        condition={condition}
        blocked={blocked}
        finished={finished}
        strings={strings}
        MONTHS={MONTHS}
        showDrawer={showDrawer}
        showError={showError}
        backward={backward}
        showButtonBack={showButtonBack}
      />;
    case "workperiod":
      return <WorkPeriod
        options={currentQS.answer.options}
        answr={currentANSWR}
        ANS={ANS}
        QS={QS}
        lang={lang}
        qs={currentQS}
        setValue={(selectedValue) => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          setValue(selectedValue, ansCopy);
        }}
        forward={(condition => {
          const ansCopy = {...ANS};
          ansCopy.groups[ANS.current_group - 1].current_question = currentQS.id;
          dispatch(setAnsAction(ansCopy));
          setTimeout(async() => {
            forward(
              await findConditionByAnswer(
                findQuestionById(qsGroup, ansGroup.current_question),
                findAnswer(ANS, QS, ANS.current_group, ansGroup.current_question),
                ANS,
                QS
              ),
              qs.id);
          }, 100);
        })}
        condition={condition}
        blocked={blocked}
        finished={finished}
        showDrawer={(text) => {
          showDrawer(text);
        }}
        showError={showError}
        formErrorMessage={formErrorMessage}
        backward={backward}
        showButtonBack={showButtonBack}
      />;
    default:
      return null;
    }
  };

  const loopCheck = () => {
    if (qs.loop === undefined) return true;
    for (let i = 0; i < ANS.loops.length; i++) {
      if (ANS.loops[i].id === qs.loop) {
        return ANS.loops[i].i > 1 || QS.loops[i].start[1] !== qs.id;
      }
    }
    return false;
  };

  const loopFinished = loop => {
    for (let i = 0; i < ANS.loops.length; i++) {
      if (ANS.loops[i].id === loop) {
        return ANS.loops[i].i === ANS.loops[i].count;
      }
    }
    return true;
  };

  const scrollRef = useRef(null);

  return (
    <div
      className="scroll_container"
      ref={scrollRef}
    >
      {/*<div className="navigation">
        <Navigation data={navigationQuestion} ANS={ANS}/>
      </div>*/}
      {savedAnswers[ANS.current_group - 1]
        ? <div className="question_box">
          {generateAnswers()}
        </div>
        : null
      }
    </div>
  );
};


const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    QS: getQsSelector(state),
    ANS: getAnsSelector(state),
    language: getLanguageSelector(state),
    savedAnswers: getSavedAnswersSelector(state)
  };
};

export default connect(mapStateToProps, null)(QuestionnaireScreen);
