import React, {useEffect, useState} from "react";
import queryString from "query-string";
import {authService} from "../../services/auth.service";
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";
import medalImg from "../../img/Auth/medal.png";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import {useHistory} from "react-router-dom";

export default function RestoreSubmit() {
  const { t } = useTranslation();
  const history = useHistory();

  const [restored, setRestored] = useState(false);

  useEffect(() => {
    const getToken = async () => {
      const parsed = queryString.parse(location.search);
      if (Object.keys(parsed).length && parsed.token) {
        const activated = await new authService().submitRestore(parsed.token);
        if (activated && activated.success) {
          setRestored(true);
        } else {
          history.push("/404");
        }
      }
    };
    getToken();
  }, []);

  return(
    <div>
      {restored
        ? <div
          className="register_success"
        >
          <motion.img
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              x: { type: "spring", stiffness: 100 },
              default: { delay: 0.4, duration: 0.3 },
            }}
            src={medalImg}
            alt="medal"
          />
          <motion.h2
            animate={{ opacity: 1, translateY: 0 }}
            initial={{ opacity: 0, translateY: "50px" }}
            transition={{
              x: { type: "spring", stiffness: 100 },
              default: { delay: 0.6, duration: 0.3 },
            }}
          >
            {t("auth.restore_account.submit_success")}
          </motion.h2>
          <motion.div
            style={{width: "100%", marginTop: "20px"}}
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: "-150px" }}
            transition={{
              x: { type: "spring", stiffness: 100 },
              default: { delay: 1, duration: 0.3 },
            }}
          >
            <Button
              style={{
                width: "100%",
                maxWidth: "none"
              }}
              text={t("auth.forget.success_btn")}
              handleClick={() => history.push("/auth/login")}
            />
          </motion.div>
        </div>
        : null
      }
    </div>
  );
}
