import { api, errorHandler, formatPattern } from "../../utils/api";
import { REQUEST_URL, METHOD } from "../../utils/constants";
import { CONSTANTS } from "./constants";
import moment from "moment";

// ACTIONS --------------------

export const isLoadingAction = isLoading => ({
  type: CONSTANTS.ON_APP_LOADING,
  payload: {
    isLoading
  }
});

export const setCampaign = campaign => ({
  type: CONSTANTS.CAMPAIGN,
  payload: { campaign }
});

// ASYNC ACTIONS --------------

export const getDistanceAsyncAction = (origins, destinations, callBack = null) => (dispatch, getState) => {
  dispatch(isLoadingAction(true));
  const time = Math.floor(moment().endOf("day").add(4, "hours").valueOf() / 1000);
  return api(formatPattern(REQUEST_URL.DISTANCE_MATRIX, {
    time,
    dest: destinations,
    orig: origins
  }), METHOD.GET, null, true)
    .then(res => {
      if (res && res.ok && res.body.status === "OK") {
        if(callBack) callBack(res.body);
      }
    })
    .catch(error => {
      errorHandler(error);
    }).finally(() => {
      dispatch(isLoadingAction(false));
    });
};
