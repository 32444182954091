import React, {useState, useEffect} from "react";
import {Table, Select, Input, Modal, Tooltip, InputNumber, DatePicker, Alert} from "antd";
import "./Dashboard.css";
import {cookieService} from "../../../services/cookie.service";
import {consultantService} from "../../../services/consultant.service";
import Button from "../../../components/CommonComponents/FormButton/FormButton";
import UserAnvView from "../../../components/Consultant/UserAnvView";
import HashLoader from "react-spinners/HashLoader";
import TaxefyTable from "../../../components/TaxefyComponents/TaxefyTable";
import {CloseCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {formService} from "../../../services/form.service";

export default function Dashboard() {
  const {t, i18n} = useTranslation();
  const [name, setName] = useState("");
  const [consultantForms, setConsultantForms] = useState([]);
  const [showPOAmodal, setShowPOAmodal] = useState(false);
  const [tempRecord, setTempRecord] = useState(null);
  const [searchData, setSearchData] = useState("");
  const [ANVform, setANVform] = useState(null);
  const [ANVmodal, setANVmodal] = useState(false);
  const [totalCases, setTotalCases] = useState(0);
  const [sendMultipleDialog, setSendMultipleDialog] = useState(false);
  const [sendMultipleResponse, setSendMultipleResponse] = useState(null);
  const [sendMultipleError, setSendMultipleError] = useState(null);
  const [consultantComment, setConsultantComment] = useState(null);
  const [commentError, setCommentError] = useState(false);
  const [sortFields, setSortFields] = useState(null);
  const [filters, setFilters] = useState(null);
  const [errorList, setErrorList] = useState([]);
  const [actionRow, setActionRow] = useState(<div></div>);
  const [selectedRows, setSelectedRows] = useState([]);
  const [fromId, setFromId] = useState(0);
  const [toId, setToId] = useState(100000);
  const [datePickerdate, setDatePickerDate] = useState("");
  const [foMaintenaceText, setFoMaintenaceText] = useState(null);

  const getFormDataById = async () => {
    if (selectedRows.length == 0) return;
    setConsultantComment("");
    setCommentError(false);
    const result = await new consultantService().getFormDataById(selectedRows[0]);
    if (!result.success)
      return setErrorList(old => [...old, generateError({message: "Error Loading Form Data"})]);
    setANVform(() => result.data);
    setConsultantComment(result.data?.consultantComment);
  };

  useEffect(async () => {
    const cookie = await new cookieService().getTokenData();
    setName(cookie.name);
    getCases(searchData);
  }, []);

  // useEffect(() => {
  //   getCases(searchData);
  // }, [searchData]);

  useEffect(() => {
    setActionRow(() => taxefyActionRow());
  }, [selectedRows]);

  const taxefyActionRow = () => {
    if (!selectedRows.length) return <div></div>;
    return (
      <div
        style={{
          border: "solid 1px rgba(0, 190, 170, 1)",
          padding: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "15px"
        }}
        id="TAXEFY_ACTION_ROW"
      >
        {selectedRows.length >= 1 && (
          <>
            <Button
              disabled={foMaintenaceText != null}
              handleClick={() => {
                sendMultipleToFObyConsultant();
              }}
              style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)", margin: "1px"}}
              text={t("consultantPanel.dashboard.sendToFO")}
            ></Button>
          </>
        )}
        {selectedRows.length == 1 && (
          <>
            <div>
              <Button
                handleClick={() => {
                  veriffImagesByConsultant(
                    consultantForms.find(el => el.form_id == selectedRows[0])
                  );
                }}
                style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)", margin: "1px"}}
                text={t("consultantPanel.dashboard.downloadVerification")}
              ></Button>
              <Button
                handleClick={() => downloadZIP()}
                style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)", margin: "1px"}}
                text={t("consultantPanel.dashboard.downloadZIP")}
              ></Button>
            </div>
            <div>
              <Button
                handleClick={() => {
                  getFormDataById();
                  setANVmodal(() => true);
                }}
                style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)", margin: "1px"}}
                text={t("consultantPanel.dashboard.showANV")}
              ></Button>
              <Button
                handleClick={() => {
                  setShowPOAmodal(true);
                }}
                style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)", margin: "1px"}}
                text={t("consultantPanel.dashboard.terminatePOA")}
              ></Button>
            </div>
          </>
        )}
      </div>
    );
  };

  const downloadZIP = async () => {
    const result = await new consultantService().downloadZIP(selectedRows[0]);
    if (!result.success) return setErrorList(old => [...old, generateError(result.message)]);
  };

  const generateError = error => {
    try {
      console.log(error);
      console.log(error?.request);
      const status = error?.request ? error?.request.status : error.status;
      const text =
        error?.request.responseType == "blob"
          ? error?.request.statusText
          : error?.request.response.includes("message")
            ? JSON.parse(error?.request.response).message
            : error?.request.response;
      return (
        <div
          style={{
            margin: "10px",
            padding: "5px",
            borderRadius: "15px",
            color: "white",
            backgroundColor: "red",
            textAlign: "center",
            userSelect: "none"
          }}
          onClick={event => {
            console.log(event);
            if (event.target.localName == "div") event.target.className += "hidden_element";
            else {
              if (event.target.parentNode.localName == "div")
                event.target.parentNode.className += "hidden_element";
              else event.target.parentNode.parentNode.className += "hidden_element";
            }
          }}
        >
          <span style={{marginBottom: "5px"}}>
            {status} - {text}
          </span>
          <CloseCircleOutlined style={{marginLeft: "20px", fontSize: "20px"}} />
        </div>
      );
    } catch (err) {
      return (
        <div
          style={{
            margin: "10px",
            padding: "5px",
            borderRadius: "15px",
            color: "white",
            backgroundColor: "red",
            textAlign: "center",
            userSelect: "none"
          }}
          onClick={event => {
            console.log(event);
            if (event.target.localName == "div") event.target.className += "hidden_element";
            else {
              if (event.target.parentNode.localName == "div")
                event.target.parentNode.className += "hidden_element";
              else event.target.parentNode.parentNode.className += "hidden_element";
            }
          }}
        >
          <span style={{marginBottom: "5px"}}>500 - Server Problem</span>
          <CloseCircleOutlined style={{marginLeft: "20px", fontSize: "20px"}} />
        </div>
      );
    }
  };

  const getCases = async searchData => {
    setConsultantForms(null);
    setSelectedRows([]);
    const reqData = {
      minId: fromId,
      maxId: toId,
      search: searchData,
      dateRange: datePickerdate || "",
      sorter: sortFields?.filter(el => el.activated) || [],
      filters: filters?.filter(el => el.activated)
    };
    console.log(reqData);
    const result = await new consultantService().getConsultantCasesData(reqData);
    const keyResult = result.data?.map((el, index) => ({key: index, ...el}));
    setConsultantForms(keyResult);
    console.log(result.allowedFilters);
    setFilters(() => result.allowedFilters);
    if (result.foMaintenance.status == true) setFoMaintenaceText(result.foMaintenance.data);
    else setFoMaintenaceText(null);
    setSortFields(() =>
      result.allowedSort.map((el, id) => ({
        name: el,
        activated: false,
        order: id,
        direction: "asc"
      }))
    );
    setTotalCases(result.total);
  };

  const poaTerminatedByConsultant = async () => {
    setSelectedRows([]);
    const form = consultantForms.find(el => el.form_id == selectedRows[0]);
    const reqData = {
      formId: form.form_id,
      terminated: !form.poa.data,
      termination_date: form.poa.date != null ? null : "now"
    };

    await new consultantService().updatePOAbyConsultant(reqData);
    setShowPOAmodal(false);
    getCases(searchData);
  };

  const veriffImagesByConsultant = async record => {
    if (record.verified == null) return;
    const result = await new consultantService().getVeriffImagesByConsultant(record.form_id);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "files.zip");
    document.body.appendChild(link);
    link.click();
  };

  const sendMultipleToFObyConsultant = async () => {
    setSendMultipleResponse(null);
    setSendMultipleError(null);
    const ids = selectedRows;
    console.log(ids);
    setSendMultipleDialog(true);
    const result = await new consultantService().sendMultipleToFObyConsultant(ids);
    setSendMultipleResponse(result.data.message);
    setSendMultipleError(result.data.errors);
  };

  const finishANVcontrol = async () => {
    if (!ANVform) return;
    console.log(ANVform);
    const result = await new consultantService().finishANVcontrol(
      ANVform.form_id,
      consultantComment || ""
    );
    console.log(result);
    setANVmodal(false);
    getCases(searchData);
    setANVform(null);
    setCommentError(false);
  };

  const denyFormByConsultant = async data => {
    setCommentError(false);

    if (!consultantComment) {
      setCommentError(true);
      return;
    }
    const tokenData = await new cookieService().getTokenData();
    const result = await new consultantService().denyFormByConsultant(
      selectedRows[0],
      consultantComment,
      tokenData.token
    );
    if (!result.success) {
      console.log(result.message);
    }
    getCases(searchData);
    setANVmodal(false);
    setCommentError(false);
  };

  return (
    <div>
      <Modal
        footer={null}
        title={
          <p style={{textAlign: "center"}}>Send {selectedRows.length} Forms to Finanz-Online</p>
        }
        visible={sendMultipleDialog}
        closable={false}
        width={"30%"}
      >
        <div style={{textAlign: "center"}}>
          {!sendMultipleResponse ? (
            <div>
              <h3>This could take a few minutes</h3>
              <div style={{marginTop: "40px", minHeight: "30px", minWidth: "20px"}}>
                <HashLoader color="#00beaa" size={40} />
              </div>
            </div>
          ) : (
            <div>
              <h2>{sendMultipleResponse} sent</h2>
              <Table dataSource={sendMultipleError}>
                <Table.Column
                  title="Form"
                  dataIndex={"id"}
                  render={data => {
                    const form = consultantForms.find(el => el.form_id == data);
                    return `${form.year} - ${form.email}`;
                  }}
                />
                <Table.Column title="Error" dataIndex={"error"} />
              </Table>
              <Button
                style={{
                  disaply: sendMultipleResponse ? "block" : "none",
                  backgroundColor: "#00beaa"
                }}
                text={"Close"}
                handleClick={() => {
                  setSendMultipleDialog(false);
                  getCases();
                }}
              />
            </div>
          )}
        </div>
      </Modal>
      <Modal
        footer={null}
        title={
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <p style={{fontSize: "25px", marginBottom: 0, marginTop: "10px"}}>{"ANV Prüfen"}</p>
            <p style={{fontSize: "25px", marginBottom: 0, marginTop: "10px"}}>
              {"ID:" + ANVform?.form_id}
            </p>
            <p style={{fontSize: "25px", marginBottom: 0, marginTop: "10px"}}>
              Status: {ANVform?.status || "Not sent"}
            </p>
          </div>
        }
        visible={ANVmodal}
        closable={false}
        width={"60%"}
      >
        <div
          style={{
            position: "fixed",
            right: "3%",
            top: "20%",
            minHeight: "200px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around"
          }}
        >
          {commentError && (
            <p style={{color: "red", fontSize: "12px"}}>ANV-Ablehnung benötigt Kommentar</p>
          )}
          <textarea
            style={{
              margin: "5px",
              padding: "5px",
              border: "solid 1px rgba(0, 190, 170, 1)"
            }}
            value={consultantComment}
            placeholder="Kommentar"
            onChange={e => {
              setConsultantComment(e.target?.value);
            }}
          />
          <Button
            style={{
              marginBottom: "10px"
            }}
            disabled={ANVform?.sendDate}
            handleClick={() => finishANVcontrol()}
            text="Genehmigen"
          />
          <Button
            disabled={ANVform?.sendDate}
            handleClick={() => denyFormByConsultant()}
            style={{color: "black", backgroundColor: "white", border: "solid 1px black"}}
            text="Ablehnen"
          />
          <Button
            style={{backgroundColor: "red", marginTop: "10px"}}
            handleClick={() => setANVmodal(false)}
            text="Abbrechen"
          />
        </div>
        <UserAnvView data={ANVform} />
      </Modal>
      <Modal
        footer={null}
        title={"Update Power of Attorney Termination"}
        visible={showPOAmodal}
        closable={false}
        width={"20%"}
      >
        <h3>Are you sure?</h3>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px"
          }}
        >
          <Button text={"Yes"} handleClick={() => poaTerminatedByConsultant()} />
          <Button
            style={{backgroundColor: "red"}}
            text={"Cancel"}
            handleClick={() => setShowPOAmodal(false)}
          />
        </div>
      </Modal>
      {foMaintenaceText && (
        <Alert showIcon message={foMaintenaceText} type="warning" style={{textAlign: "center"}} />
      )}
      <h1 id="dashboard_header">{"Willkommen " + name + " (" + totalCases + ")"}</h1>
      <div
        style={{
          width: "45%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "baseline"
        }}
      >
        <span>From ID : </span>
        <InputNumber
          controls={false}
          min={0}
          defaultValue={0}
          formatter={value => `${value.replace(/(\d)(?=(\d{3})+$)/g, "$1.")}`}
          value={fromId}
          parser={value => value?.replace(".", "")}
          onChange={value => setFromId(value)}
        />
        <span style={{marginLeft: "20px"}}>To ID : </span>
        <InputNumber
          controls={false}
          min={1000}
          defaultValue={100000}
          formatter={value => `${value.replace(/(\d)(?=(\d{3})+$)/g, "$1.")}`}
          value={toId}
          parser={value => value?.replace(".", "")}
          onChange={value => setToId(value)}
        />
        <DatePicker.RangePicker
          style={{marginLeft: "10px"}}
          value={datePickerdate}
          onChange={value => setDatePickerDate(value)}
        />
      </div>
      <TaxefyTable
        data={consultantForms}
        sortFields={sortFields}
        filters={filters}
        onSort={values => setSortFields(() => values)}
        onSelect={values => setSelectedRows(values)}
        onFilter={values =>
          setFilters(() => {
            console.log("FILTER CHANGE", filters);
            return filters.map(el => {
              const temp = values.includes(el.id);
              if (temp) el.activated = true;
              else el.activated = false;
              return el;
            });
          })
        }
        onSearch={values => setSearchData(values)}
        onReload={values => {
          getCases(searchData);
          setSelectedRows(() => []);
        }}
        options={[
          {
            type: "columnGroups",
            data: [
              {
                name: "User Daten",
                columns: ["name", "email", "taxNumber", "svn", "verified"]
              },
              {
                name: "Formular  Daten",
                columns: [
                  "form_id",
                  "consultant_control_group",
                  "createdat",
                  "send_date",
                  "amount",
                  "year",
                  "status",
                  "anv_control_date",
                  "consultantComment",
                  "fraud",
                  "poa"
                ]
              }
            ]
          },
          {
            type: "createdat",
            renderType: "date"
          },
          {
            type: "send_date",
            renderType: "date"
          },
          {
            type: "anv_control_date",
            renderType: "date"
          },
          {
            type: "consultant_control_group",
            renderType: "list"
          },
          {
            type: "poa",
            renderType: "checkmark"
          },
          {
            type: "verified",
            renderType: "checkmark"
          },
          {
            type: "fraud",
            renderType: "checkmark"
          }
        ]}
        namespace="consultantPanel.dashboard."
      />
      {actionRow}
      <div style={{display: "flex", width: "100%"}}>{errorList}</div>
    </div>
  );
}
