import React, {useEffect, useState} from "react";
import "./styles.css";
import {cookieService} from "../../../../services/cookie.service";
import axios from "axios";
import environment from "../../../../environment";
import {Input, Table} from "antd";

const { Search } = Input;
const { Column } = Table;

export default function PaymentsPage() {

  const [paymentsData, setPaymentsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPaymentsData = async (search) => {
    setLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const queryParams = {};
    if (search) {
      Object.assign(queryParams, {"search": search});
    }
    const response = await axios.get(`${environment.baseUrl}admin/payments`, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      setLoading(false);
      setPaymentsData(response.data);
    }
  };

  useEffect(() => {
    getPaymentsData();
  }, []);

  const onSearch = (value) => {
    getPaymentsData(value);
  };

  return(
    <div>
      <div className="space-between">
        <h3>Transaktionen</h3>
        <Search
          placeholder="Search"
          onSearch={onSearch}
          className="admin_search"
        />
      </div>
      {loading
        ? <p>Loading</p>
        : paymentsData.length
          ? <Table dataSource={paymentsData} scroll={{ x: 1500 }}>
            <Column title="User id" dataIndex="user_id" key="user_id" />
            <Column title="Name" dataIndex="user_name" key="user_name" />
            <Column title="Email" dataIndex="user_email" key="user_email" />
            <Column title="Phone" dataIndex="phone" key="phone" />
            <Column title="Payment id" dataIndex="id" key="id" />
            <Column title="IBAN" dataIndex="iban" key="iban" />
            <Column title="BIC" dataIndex="bic" key="bic" />
            <Column title="Amount" dataIndex="amount" key="amount" />
          </Table>
          : <p>No data</p>
      }
    </div>
  );
}
