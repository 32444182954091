import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { consultantService } from "../../services/consultant.service";
import "./ConsultantAssignList.css";
import ErrorBox from "../ErrorBox/ErrorBox";
import { cookieService } from "../../services/cookie.service";

export default function ConsultantAssignList(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [enabledConsultants, setEnabledConsultants] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState({});

  const switchOpen = () => {
    props.setOpen(!props.Open);
  };

  useEffect(async () => {
    const data = await new consultantService().getEnabledConsultants();
    setEnabledConsultants(data);
  }, []);

  const rowSelection = {
    onChange: (selectedKey, selectedRows) => {
      setSelectedConsultant(selectedRows[0]);
    }
  };

  const assignConsultant = async sendToFo => {
    setErrorMessage("");

    if (!selectedConsultant.email) {
      setErrorMessage("No Consultant selected");
    } else {
      const token = await new cookieService().getAdminTokenData();
      const reqBody = {
        formId: props.record.formId,
        email: selectedConsultant.email,
        sentToFo: sendToFo,
      };
      const result = await new consultantService().assignConsultant(reqBody);
      if (result.success) props.success(true);
      else setErrorMessage(result.message);
    }
  };

  return (
    <Modal
      footer={null}
      title={"Assign to Consultant"}
      visible={props.Open}
      onCancel={switchOpen}
      closable={false}
      width={"60%"}
    >
      <div hidden={errorMessage == ""}>
        <ErrorBox message={errorMessage} />
      </div>
      <Table
        rowSelection={{ type: "radio", ...rowSelection }}
        className="assign_consultant_table"
        dataSource={enabledConsultants}
        pagination={false}
      >
        <Table.Column
          title="Name"
          key="assignConsultantName"
          dataIndex="name"
        />
        <Table.Column
          title="E-Mail"
          key="assignConsultantEmail"
          dataIndex="email"
        />
        <Table.Column
          title="Priority"
          key="assignConsultantPriority"
          dataIndex="priority"
        />
        <Table.Column
          title="Quota"
          key="assignConsultantMaxPerDay"
          dataIndex="quota"
        />
      </Table>

      <div className="space-between">
        <Button
          style={{
            backgroundColor: "rgba(0, 190, 170, 1)",
            color: "white",
            borderRadius: "5px"
          }}
          onClick={() => {
            assignConsultant(false);
          }}
        >
          Assign
        </Button>
        <Button
          style={{
            backgroundColor: "rgba(190, 50, 0, 1)",
            color: "white",
            borderRadius: "5px"
          }}
          onClick={() => {
            assignConsultant(true);
          }}
        >
          Assign and send to FO
        </Button>
      </div>
    </Modal>
  );
}
