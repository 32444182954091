import React, {useEffect, useState} from "react";
import "./AuthenticationPage.css";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormikInput from "../../components/CommonComponents/FormikInput/FormikInput";
import {motion} from "framer-motion";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import {authService} from "../../services/auth.service";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import medalImg from "../../img/Auth/medal.png";
import ReactCanvasConfetti from "react-canvas-confetti";
import {wordCapitalize} from "../../utils/wordCapitalize";
import {cookieService} from "../../services/cookie.service";
import axios from "axios";
import environment from "../../environment";
import {setLanguageAction} from "../../data/app/actions";
import i18n from "../../i18next";
import {useDispatch, useSelector} from "react-redux";
import { Select } from "antd";
import { Checkbox } from "antd";
import {langs} from "../../constants/langs";
import GoogleLogin from "react-google-login";
import googleLogo from "../../img/Auth/google_logo.png";
import facebookLogo from "../../img/Auth/facebook.png";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { campaignSelector } from "../../data/general/selectors";

const { Option } = Select;

export default function Register(props) {
  const campaign = useSelector(campaignSelector);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [invitation, setInvitation] = useState({});
  const [activeError, setActiveError] = useState(false);

  useEffect(() => {
    if (location.state && location.state.invitationId) {
      const getInvitation = async () => {
        const cookieToken = await new cookieService().getTokenData();
        const response = await axios.get(`${environment.baseUrl}invitations/${location.state.invitationId}`, {
          headers: {
            Authorization: `Bearer ${cookieToken.refreshToken}`,
            "app-version": "1.0.1"
          }
        });
        props.setInvitationFirstName(response.data.senderFirstName);
        setInvitation(response.data);
      };
      getInvitation();
    }
  }, [location.state]);

  const [errorMessage, setErrorMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);

  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("auth.warnings.required")),
      lastName: Yup.string().required(t("auth.warnings.required")),
      email: Yup.string().email(t("auth.warnings.email")).required(t("auth.warnings.required")),
      password: Yup.string()
        .required(t("auth.warnings.required"))
        .min(8, t("auth.warnings.password_length"))
        .matches(passwordRegex, t("auth.warnings.password_matches")),
      confirmPassword: Yup.string()
        .min(8, t("auth.warnings.password_length"))
        .when("password", {
          is: val => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("auth.warnings.password_not_same")
          ),
        })
    }),
    // handle form submitting
    onSubmit: async () => {
      setButtonLoading(true);
      const extraParams = {};

      try {
        if (campaign) {
          extraParams.campaign = JSON.stringify(campaign);
        }
      } catch(err) {
        console.log("Register:useFormik:onSubmit", err);
      }

      const register = await new authService().register({
        firstName: wordCapitalize(formik.values.firstName),
        lastName: wordCapitalize(formik.values.lastName),
        email: formik.values.email,
        password: formik.values.password,
        invitation: invitation.id,
        lang: i18n.language,
        ...extraParams
      });
      if (register && !register.success) {
        if (register.message === "not_active") {
          setActiveError(true);
        } else {
          setErrorMessage(register.message === "exists"
            ? t("auth.warnings.email_exists")
            : t("auth.warnings.register_error"));
        }
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
        if (register && register.success) {
          setSuccessfullyRegistered(true);
        }
      }
    },
  });

  const [fire, setFire] = useState(false);

  let animationInstance = null;

  const getInstance = instance => {
    animationInstance = instance;
  };

  const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "50%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 999
  };

  useEffect(() => {
    if (successfullyRegistered) {
      setTimeout(() => {
        setFire(true);
      }, 300);
    }
  }, [successfullyRegistered]);

  const handleLanguageSelect = (lang) => {
    i18n.changeLanguage(lang);
    window.localStorage.setItem("lang", lang);
    dispatch(setLanguageAction(lang));
  };

  const responseGoogle = async (res) => {
    try {
      const response = await axios.post(`${environment.baseUrl}auth/googlelogin`,
        {
          tokenId: res.tokenId
        }
      );
      if (response && response.data) {
        console.log(response.data);
        if (response.data.firstName) {
          await new cookieService().setTokenData(response.data);
          setButtonLoading(false);
          history.push("/");
        }
      }
    } catch (e) {
      if (e.response.data.message === "wrong credentials") {
        setErrorMessage(t("auth.warnings.login_error"));
        setButtonLoading(false);
      } else if (e.response.data.message === "not activated") {
        setErrorMessage(t("auth.warnings.login_error_not_activated"));
        setButtonLoading(false);
      } else if (e.response.data.message === "user not exists") {
        setErrorMessage(t("auth.warnings.login_exists"));
        setButtonLoading(false);
      } else if (e.response.data.message === "not_active") {
        setActiveError(true);
        setButtonLoading(false);
      } else if (e.response.data === "created") {
        setButtonLoading(false);
        setSuccessfullyRegistered(true);
      } else {
        setErrorMessage(t("auth.warnings.login_exists"));
        setButtonLoading(false);
      }
    }
  };

  const responseErrorGoogle = (res) => {
    console.log(res);
    setErrorMessage(t("auth.warnings.google_error"));
    setButtonLoading(false);
  };

  const googleId = process.env.REACT_APP_TEST_VAR && process.env.REACT_APP_TEST_VAR === "dev"
    ? "1018221763191-obi7thk61bpueleb7s3tipepu2scifg7.apps.googleusercontent.com"
    : "1018221763191-00jf7ej4bj015nogjd0te0o8p47uulsi.apps.googleusercontent.com";

  const facebookId = process.env.REACT_APP_TEST_VAR && process.env.REACT_APP_TEST_VAR === "dev"
    ? "671525823980146"
    : "323843402967553";

  /*const responseFacebook = async (facebookResponse) => {
    if (facebookResponse.name && facebookResponse.email) {
      try {
        const response = await axios.post(`${environment.baseUrl}auth/facebooklogin`,
          {
            name: facebookResponse.name,
            email: facebookResponse.email,
            language: facebookResponse.languages ? facebookResponse.languages[0] : "de"
          }
        );
        if (response && response.data) {
          if (response.data.firstName) {
            await new cookieService().setTokenData(response.data);
            setButtonLoading(false);
            history.push("/");
          } else if (response.data === "created") {
            setButtonLoading(false);
            setSuccessfullyRegistered(true);
          } else {
            setErrorMessage(t("auth.warnings.login_exists"));
            setButtonLoading(false);
          }
        }
      } catch (e) {
        if (e.response.data.message === "wrong credentials") {
          setErrorMessage(t("auth.warnings.login_error"));
          setButtonLoading(false);
        } else if (e.response.data.message === "not activated") {
          setErrorMessage(t("auth.warnings.login_error_not_activated"));
          setButtonLoading(false);
        } else if (e.response.data.message === "user not exists") {
          setErrorMessage(t("auth.warnings.login_exists"));
          setButtonLoading(false);
        } else if (e.response.data.message === "not_active") {
          setActiveError(true);
          setButtonLoading(false);
        } else {
          setErrorMessage(t("auth.warnings.login_exists"));
          setButtonLoading(false);
        }
      }
    } else {
      console.log("Data not provided");
    }
  };*/

  return(
    successfullyRegistered
      ? <div
        className="register_success"
      >
        <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} fire={fire}/>
        <motion.img
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            x: { type: "spring", stiffness: 100 },
            default: { delay: 0.4, duration: 0.3 },
          }}
          src={medalImg}
          alt="medal"
        />
        <motion.h1
          animate={{ opacity: 1, translateY: 0 }}
          initial={{ opacity: 0, translateY: "50px" }}
          transition={{
            x: { type: "spring", stiffness: 100 },
            default: { delay: 0.6, duration: 0.3 },
          }}
        >
          {t("auth.register.success_title")}
        </motion.h1>
        <motion.h2
          animate={{ opacity: 1, translateY: 0 }}
          initial={{ opacity: 0, translateY: "50px" }}
          transition={{
            x: { type: "spring", stiffness: 100 },
            default: { delay: 0.8, duration: 0.3 },
          }}
        >
          {t("auth.register.success_sub_title", {email: formik.values.email})}
        </motion.h2>
        <motion.div
          style={{width: "100%", marginTop: "20px"}}
          animate={{ opacity: 1, translateX: 0 }}
          initial={{ opacity: 0, translateX: "-150px" }}
          transition={{
            x: { type: "spring", stiffness: 100 },
            default: { delay: 1, duration: 0.3 },
          }}
        >
          <Button
            style={{
              width: "100%",
              maxWidth: "none"
            }}
            text={t("auth.register.success_btn")}
            handleClick={() => history.push("/auth/login")}
          />
        </motion.div>
      </div>
      : <motion.div
        animate={{ translateY: 0, opacity: 1 }}
        initial={{ translateY: "-100vh", opacity: 0 }}
        transition={{
          x: { type: "spring", stiffness: 100 },
          default: { duration: 0.3 },
        }}
        exit={{ translateY: "100vh", opacity: 0 }}
        className="auth_box"
        style={{marginBottom: "40px"}}
      >
        <h1 className="login_title">{t("auth.register.register")}</h1>
        <h4 className="login_sub_title">{t("auth.register.register_sub")}</h4>

        <h4 className="register_agb">{t("auth.register.agb_text_one")}
          <a href="https://taxefy.at/AGB" rel="noreferrer" target="_blank">{t("auth.register.agb_text_agb")}</a>
          <span>{t("auth.register.agb_text_two")}</span>
          <a
            href="https://taxefy.at/Datenschutz"
            rel="noreferrer"
            target="_blank">
            {t("auth.register.agb_text_data")}
          </a>
          <span>{t("auth.register.agb_text_three")}</span>
        </h4>

        {/*<FacebookLogin
          appId={facebookId}
          callback={responseFacebook}
          fields="name,email,languages"
          render={renderProps => (
            <button
              className="facebook_btn"
              onClick={renderProps.onClick}>
              <div className="row">
                <img src={facebookLogo} alt="facebook"/>
                <p>{t("auth.login.facebook_btn")}</p>
              </div>
            </button>
          )}
        />*/}

        <form onSubmit={formik.handleSubmit}>
          {activeError
            ? <motion.div
              className="error_box_container"
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                delay: 0.3,
                x: { type: "spring", stiffness: 100 },
                default: { duration: 1 },
              }}
            >
              <i className="fas fa-exclamation-triangle"></i>
              <p>{t("auth.warnings.login_not_active")} <br/>
                <span onClick={() => history.push("/auth/restoreAccount")}>{t("auth.warnings.login_restore")}</span></p>
            </motion.div>
            : null
          }

          {errorMessage.length ? <ErrorBox message={errorMessage} /> : null}

          <div style={{ position: "relative" }}>
            <FormikInput
              htmlFor="firstName"
              name="firstName"
              value={formik.values.firstName}
              disabled={false}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t("auth.register.name_label")}
              placeholder="Max"
            />
            {formik.errors.firstName && formik.touched.firstName && (
              <motion.p
                animate={{ y: 5 }}
                className="input_error"
                style={{ fontSize: "10px" }}
              >
                {formik.errors.firstName}
              </motion.p>
            )}
          </div>

          <div style={{height: "15px"}}></div>

          <div style={{ position: "relative" }}>
            <FormikInput
              htmlFor="lastName"
              name="lastName"
              value={formik.values.lastName}
              disabled={false}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t("auth.register.lastName_label")}
              placeholder="Mustermann"
            />
            {formik.errors.lastName && formik.touched.lastName && (
              <motion.p
                animate={{ y: 5 }}
                className="input_error"
                style={{ fontSize: "10px" }}
              >
                {formik.errors.lastName}
              </motion.p>
            )}
          </div>

          <div style={{height: "15px"}}></div>

          <div>
            <p style={{
              fontSize: "13px",
              color: "rgba(0, 190, 170, 1)",
              fontWeight: "bold",
              marginBottom: "3px"
            }}>
              {t("auth.register.language_label")}
            </p>
            <Select value={i18n.language} onChange={(lang) => handleLanguageSelect(lang)}>
              {langs.map((lang) => (
                <Option value={lang} key={lang}>{t(`languages.${lang}.full`)}</Option>
              ))}
            </Select>
          </div>

          <div style={{height: "15px"}}></div>

          <div style={{ position: "relative" }}>
            <FormikInput
              htmlFor="email"
              name="email"
              value={formik.values.email}
              disabled={false}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t("auth.register.email_label")}
              placeholder="max@musterman.at"
            />
            {formik.errors.email && formik.touched.email && (
              <motion.p
                animate={{ y: 5 }}
                className="input_error"
                style={{ fontSize: "10px" }}
              >
                {formik.errors.email}
              </motion.p>
            )}
          </div>


          <div style={{height: "15px"}}></div>

          <div style={{ position: "relative" }}>
            <FormikInput
              htmlFor="password"
              name="password"
              value={formik.values.password}
              disabled={false}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t("auth.register.password_label")}
              placeholder="******"
            />
            {formik.errors.password && formik.touched.password && (
              <motion.p
                animate={{ y: 5 }}
                className="input_error"
                style={{ fontSize: "10px" }}
              >
                {formik.errors.password}
              </motion.p>
            )}
          </div>


          <div style={{height: "15px"}}></div>

          <div style={{ position: "relative" }}>
            <FormikInput
              htmlFor="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              disabled={false}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t("auth.register.confirm_password_label")}
              placeholder="******"
            />
            {formik.errors.confirmPassword && formik.touched.confirmPassword && (
              <motion.p
                animate={{ y: 5 }}
                className="input_error"
                style={{ fontSize: "10px" }}
              >
                {formik.errors.confirmPassword}
              </motion.p>
            )}
          </div>

          <Button
            text={t("auth.register.btn")}
            style={{marginTop: "30px", marginBottom: "20px", maxWidth: "none", width: "100%"}}
            buttonType="submit"
            loading={buttonLoading}
            disabled={!(formik.isValid && formik.dirty)}
          />
        </form>

        {/*<div className="or_container">
          <div />
          <p>{t("auth.login.login_or")}</p>
          <div />
        </div>

        <GoogleLogin
          clientId={googleId}
          render={renderProps => (
            <button
              className="google_btn"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}>
              <div className="row">
                <img src={googleLogo} alt="google"/>
                <p>{t("auth.register.google_btn")}</p>
              </div>
            </button>
          )}
          onSuccess={responseGoogle}
          onFailure={responseErrorGoogle}
          cookiePolicy={"single_host_origin"}
        />*/}

        <p className="login_register_text">
          {t("auth.register.login_text")}
          <span onClick={() => history.push("/auth/login")}>{t("auth.register.login_link")}</span>
        </p>

      </motion.div>
  );
}
