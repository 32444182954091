import React, {useState} from "react";
import "./SuccessBox.css";

export default function SuccessBox(props) {
  return (
    <div className="success_box">
      <p>{props.message}</p>
    </div>
  );
}
