import axios from "axios";
import environment from "../environment";
import {cookieService} from "./cookie.service";
import {handleAsyncErrors} from "../utils/handleAsyncErrors";
import {customHistory} from "../App";

export class formService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  async getTaxNumber(svn) {
    const cookieToken = await new cookieService().getTokenData();
    try {
      const response = await axios.get(`${environment.baseUrl}form/taxNumber`, {
        headers: {
          Authorization: `Bearer ${cookieToken.refreshToken}`,
          "app-version": "1.0.1"
        },
        params: {
          svn: svn
        }
      });
      return {
        success: true,
        data: response.data.taxNumber
      };
    } catch (e) {
      return {
        success: false,
        message: JSON.parse(e.request.response).message
      };
    }
  }

  async sendForm(data) {
    const cookieToken = await new cookieService().getTokenData();
    try {
      const response = await axios.post(`${environment.baseUrl}form`, data, {
        headers: {
          Authorization: `Bearer ${cookieToken.refreshToken}`,
          "app-version": "1.0.1"
        }
      });
      if (response) {
        return {
          success: true
        };
      }
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: JSON.parse(e.request.response).message
        };
      }
    }
  }

  async getProtokollStatus(formId, consultantEmail) {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();

      const result = await axios({
        method: "Get",
        url: `${environment.baseUrl}form/applicationStatus/${formId}/${consultantEmail}`,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      });
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  }

  async getProtokollDecision(formId, consultantEmail) {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();

      const result = await axios({
        method: "Get",
        url: `${environment.baseUrl}form/applicationDecision/${formId}/${consultantEmail}`,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      });
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  }

  async getStripeStatus(formId) {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();

      const result = await axios({
        method: "Get",
        url: `${environment.baseUrl}form/stripe/${formId}`,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      });
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  }

  async createStripePayment(formId) {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();

      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}form/stripe`,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        },
        data: {
          formId: formId
        }
      });
      console.log(result);
      return result;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async updatePOAtermination(requestObj) {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();
      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}admin/form/updatePoaTermination`,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        },
        data: requestObj
      });
      console.log(result);
      return result;
    } catch (err) {
      return err;
    }
  }

  async updateContractTermination(requestObj) {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();
      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}admin/form/updateContractTermination`,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        },
        data: requestObj
      });
      console.log(result);
      return result;
    } catch (err) {
      return err;
    }
  }

  async getTaxNumberFromFO(formId) {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();
      const result = await axios({
        method: "PATCH",
        url: `${environment.baseUrl}admin/form/taxNumber/${formId}`,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        },
      });
      return result;
    } catch (err) {
      return err.response.data;
    }
  }

  async downloadZIP(formId) {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();

      const result = await axios({
        url: `${environment.baseUrl}admin/form/download/${formId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        },
        responseType: "blob" // important
      });
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "files.zip");
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      return JSON.parse(await err.response.data.text()).message;
    }
  }
}
