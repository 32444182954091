import axios from "axios";
import environment from "../environment";
import {cookieService} from "./cookie.service";
import {handleAsyncErrors} from "../utils/handleAsyncErrors";
import {customHistory} from "../App";

export class authService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  async login({email, password}) {
    try {
      const response = await axios.post(`${environment.baseUrl}auth/login`, {email, password});
      await new cookieService().setTokenData(response.data);
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          status: e.request.status,
          message:
            typeof e.request.response != "string"
              ? JSON.parse(e.request.response).message
              : e.request.response
        };
      }
    }
  }

  async logout(baseUrl = "") {
    await new cookieService().removeTokenData();
    customHistory.push(`${baseUrl}/auth/login`);
  }

  async logoutAdmin() {
    try {
      const token = await new cookieService().getAdminTokenData();
      const response = await axios({
        method: "GET",
        url: `${environment.baseUrl}admin/auth/logout`,
        data: {},
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        }
      });
      return true;
    } catch (e) {
      return true;
    }
  }

  async logoutPartner() {
    try {
      const token = await new cookieService().getTokenData();
      await axios({
        method: "GET",
        url: `${environment.baseUrl}partnermanager/auth/logout`,
        data: {},
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        }
      });
      return true;
    } catch (e) {
      return true;
    }
  }

  async logoutConsultant() {
    try {
      const token = await new cookieService().getTokenData();
      await axios({
        method: "GET",
        url: `${environment.baseUrl}consultant/auth/logout`,
        data: {},
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        }
      });
      return true;
    } catch (e) {
      return true;
    }
  }

  async register(data) {
    try {
      await axios.post(`${environment.baseUrl}auth/register`, data);
      return {
        success: true,
        email: data.email
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: e.response.data.message
        };
      }
    }
  }

  async forget(email, bonusPath = "") {
    try {
      await axios.post(`${environment.baseUrl}${bonusPath}auth/forget`, email);
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: e.response.data.message
        };
      }
    }
  }

  async resetPassword(data, bonusPath = "") {
    try {
      await axios.post(`${environment.baseUrl}${bonusPath}auth/resetPassword`, data);
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: "Error"
        };
      }
    }
  }

  async verifyToken(token, type) {
    try {
      const typeUrl = type ? `${type}/` : "";
      const response = await axios.get(`${environment.baseUrl}${typeUrl}auth/verifyToken`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "app-version": "1.0.1"
        }
      });
      if (response && response.data) {
        const data = response.data;
        if (data.expired) {
          return false;
        } else {
          return data.payload;
        }
      }
    } catch (e) {
      return false;
    }
  }


  async verifyConsultantToken(token) {
    try {
      const response = await axios.get(`${environment.baseUrl}consultant/auth/verifyToken`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response && response.data) {
        const data = response.data;
        if (data.expired) {
          return false;
        } else {
          return data.payload;
        }
      }
    } catch (e) {
      return false;
    }
  }

  async verifyAdminToken(token) {
    try {
      const response = await axios.get(`${environment.baseUrl}admin/auth/verifyToken`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response && response.data) {
        const data = response.data;
        if (data.expired) {
          return false;
        } else {
          return data.payload;
        }
      }
    } catch (e) {
      return false;
    }
  }

  async verifyPartnerManagerToken(token) {
    try {
      const response = await axios.get(`${environment.baseUrl}partnermanager/verifyToken`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response && response.data) {
        const data = response.data;
        if (data.expired) {
          return false;
        } else {
          return data.payload;
        }
      }
    } catch (e) {
      return false;
    }
  }

  async refreshToken(token) {
    try {
      const response = await axios.get(`${environment.baseUrl}auth/refreshToken`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "app-version": "1.0.1"
        }
      });
      if (response && response.data) {
        const data = response.data;
        // Refresh token in cookie
        const cookie = await new cookieService().getTokenData();
        const refreshedCookie = {
          ...cookie,
          token: data.token
        };
        await new cookieService().setTokenData(refreshedCookie);
        return refreshedCookie;
      }
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: "Error"
        };
      }
    }
  }

  async activateUser(token) {
    try {
      const response = await axios.post(`${environment.baseUrl}auth/activateUser`, {
        token: token
      });
      if (response && response.data && response.data.token) {
        return {
          success: true,
          message: "activated",
          token: response.data.token
        };
      }
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: "Error"
        };
      }
    }
  }

  // Admin
  async registerAdminUser(data) {
    try {
      await axios.post(`${environment.baseUrl}admin/auth/register`, data);
      return {
        success: true,
        email: data.email
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: e.response.data.message === "exists" ? "exists" : "validation"
        };
      }
    }
  }

  async loginAdminUser({email, password}) {
    try {
      const response = await axios.post(`${environment.baseUrl}admin/auth/login`, {
        email,
        password
      });
      console.log(response);
      await new cookieService().setAdminTokenData(response.data);
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          status: e.request.status,
          message:
            typeof e.request.response != "string"
              ? JSON.parse(e.request.response).message
              : e.request.response
        };
      }
    }
  }

  async loginConsultantUser({email, password}) {
    try {
      const response = await axios.post(`${environment.baseUrl}consultant/auth/login`, {
        email,
        password
      });
      await new cookieService().setTokenData(response.data);
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          status: e.request.status,
          message:
            typeof e.request.response != "string"
              ? JSON.parse(e.request.response).message
              : e.request.response
        };
      }
    }
  }

  async controlAccess(baseUrl) {
    const tokenData = await new cookieService().getTokenData();
    if (!tokenData) {
      customHistory.push(`${baseUrl}/auth/login`);
    } else {
      const token = tokenData.token;
      if (!token) {
        this.logout(baseUrl);
      } else {
        const auth = await this.verifyToken(token);
        if (!auth) {
          this.logout(baseUrl);
        }
      }
    }
  }

  async controlConsultantAccess(baseUrl) {
    const tokenData = await new cookieService().getTokenData();
    if (!tokenData) {
      customHistory.push(`${baseUrl}/consultant/auth/login`);
    } else {
      const token = tokenData.token;
      if (!token) {
        this.logout(baseUrl);
      } else {
        const auth = await this.verifyConsultantToken(token);
        if (!auth) {
          this.logout(baseUrl);
        }
      }
    }
  }

  async controlPartnerManagerAccess(baseUrl) {
    const tokenData = await new cookieService().getTokenData();
    if (!tokenData) {
      customHistory.push(`${baseUrl}/partnermanager/auth/login`);
    } else {
      const token = tokenData.token;
      if (!token) {
        this.logout(baseUrl);
      } else {
        const auth = await this.verifyPartnerManagerToken(token);
        if (!auth) {
          this.logout(baseUrl);
        }
      }
    }
  }

  async restore(email) {
    try {
      await axios.post(`${environment.baseUrl}user/restore`, email);
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: e.response.data.message
        };
      }
    }
  }

  async submitRestore(token) {
    try {
      await axios.post(`${environment.baseUrl}user/submitRestore`, {
        token: token
      });
      return {
        success: true,
        message: "activated"
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: "Error"
        };
      }
    }
  }

  async loginWithToken(token) {
    try {
      const response = await axios.post(`${environment.baseUrl}auth/loginWithToken`, {
        token: token
      });
      await new cookieService().setTokenData(response.data);
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: e.response.data.message
        };
      }
    }
  }

  async changePassword(data, bonusUrl = "") {
    try {
      const token = await new cookieService().getTokenData();
      await axios.post(`${environment.baseUrl}${bonusUrl}auth/changePassword`, {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
        email: token.email
      });
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: e.response.data.message
        };
      }
    }
  }

  async loginPartnerManagerUser({email, password}) {
    try {
      const response = await axios.post(`${environment.baseUrl}partnermanager/login`, {
        email,
        password
      });
      await new cookieService().setTokenData(response.data);
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          status: e.request.status,
          message:
            typeof e.request.response != "string"
              ? JSON.parse(e.request.response).message
              : e.request.response
        };
      }
    }
  }

  async activatePartnerManager(id) {
    try {
      const token = await new cookieService().getAdminTokenData();
      console.log(token);
      const response = await axios({
        method: "POST",
        url: `${environment.baseUrl}partnermanager/activateByAdmin`,
        data: {
          id: id
        },
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        }
      });
      return {success: true, data: response};
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          status: e.request.status,
          message:
            typeof e.request.response != "string"
              ? JSON.parse(e.request.response).message
              : e.request.response
        };
      }
    }
  }
}
