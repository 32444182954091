import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormikInput from "../../components/CommonComponents/FormikInput/FormikInput";
import {motion} from "framer-motion";
import {Modal, Select, Switch} from "antd";
import BankData from "./BankData";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import axios from "axios";
import environment from "../../environment";
import {cookieService} from "../../services/cookie.service";
import HashLoader from "react-spinners/HashLoader";
const { Option } = Select;
import i18next from "../../i18next";
import {notification} from "antd";
import i18n from "../../i18next";
import {setLanguageAction} from "../../data/app/actions";
import {useDispatch} from "react-redux";
import ChangePasswordModal from "../../components/Modals/ChangePasswordModal";
import RemoveAccountModal from "../../components/Modals/RemoveAccountModal";
import { Tooltip } from "antd";
import {langs} from "../../constants/langs";

export default function ProfileTab() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [bankData, setBankData] = useState([{
    iban: "",
    bic: ""
  }]);

  const [bankErrors, setBankErrors] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      language: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("auth.warnings.firstName")),
      lastName: Yup.string().required(t("auth.warnings.lastName")),
      email: Yup.string()
        .required(t("auth.warnings.email_required"))
        .email(t("auth.warnings.email")),
    }),
    // handle form submitting
    onSubmit: async () => {
      if (formik.isValid && formik.dirty && !bankErrors) {
        setButtonLoading(true);
        let postData = {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName
        };
        const cookieToken = await new cookieService().getTokenData();
        try {
          const updateResponse = await axios.put(`${environment.baseUrl}user`, postData, {
            headers: {
              Authorization: `Bearer ${cookieToken.refreshToken}`,
              "app-version": "1.0.1"
            }
          });
          if (updateResponse && updateResponse.status === 200) {
            notification.open({
              message: t("profile.profile_tab.success_toast_title"),
              duration: 2
            });
            setButtonLoading(false);
          }
        } catch (e) {
          notification.open({
            message: t("profile.profile_tab.error_toast_title"),
            duration: 2
          });
        }
      }
    },
  });

  const getUserData = async () => {
    const cookieToken = await new cookieService().getTokenData();
    const response = await axios.get(`${environment.baseUrl}user`, {
      headers: {
        Authorization: `Bearer ${cookieToken.refreshToken}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      const user = response.data;
      formik.setFieldValue("firstName", user.firstName);
      formik.setFieldValue("lastName", user.lastName);
      formik.setFieldValue("email", user.email);
      if (user.language) {
        formik.setFieldValue("language", user.language);
      } else {
        formik.setFieldValue("language", i18next.language ? i18next.language : "de");
      }
      if (user.bankData && user.bankData.length) {
        setBankData(user.bankData);
      }
      setLoading(false);
    }
  };

  useEffect(async () => {
    getUserData();
  }, []);

  const savePreferredLanguage = async (language) => {
    const cookieToken = await new cookieService().getTokenData();
    try {
      const updateResponse = await axios.put(`${environment.baseUrl}user/changeLanguage`,
        {
          language: language
        },
        {
          headers: {
            Authorization: `Bearer ${cookieToken.refreshToken}`,
            "app-version": "1.0.1"
          }
        });
      if (updateResponse && updateResponse.status === 200) {
        setButtonLoading(false);
      } else {
        notification.open({
          message: t("profile.profile_tab.error_toast_title"),
          duration: 2
        });
      }
    } catch (e) {
      notification.open({
        message: t("profile.profile_tab.error_toast_title"),
        duration: 2
      });
    }
  };

  return(
    <div>
      {loading
        ? <div style={{
          width: "100vw",
          height: "100%",
          minHeight: "calc(100vh - 180px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <HashLoader color="#00beaa" size={35} />
        </div>
        : <form
          onSubmit={(e) => formik.handleSubmit(e)}
          className="profile_form"
        >

          <h2>{t("profile.profile_tab.personal_data_title")}</h2>

          <div className="profile_inputs_grid">

            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="firstName"
                name="firstName"
                value={formik.values.firstName}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("auth.register.name_label")}
                placeholder="Max"
              />
              {formik.errors.firstName && formik.touched.firstName && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.firstName}
                </motion.p>
              )}
            </div>

            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="lastName"
                name="lastName"
                value={formik.values.lastName}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("auth.register.lastName_label")}
                placeholder="Zhukov"
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.lastName}
                </motion.p>
              )}
            </div>

          </div>

          <div className="profile_inputs_grid">

            <div style={{ position: "relative" }}>
              <FormikInput
                htmlFor="email"
                name="email"
                value={formik.values.email}
                disabled={true}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("auth.register.email_label")}
                placeholder="max@muster.at"
              />
              {formik.errors.email && formik.touched.email && (
                <motion.p
                  animate={{ y: 5 }}
                  className="input_error"
                  style={{ fontSize: "10px" }}
                >
                  {formik.errors.email}
                </motion.p>
              )}
            </div>

            <div style={{ position: "relative"}} className="formik_input_container">
              <label
                htmlFor="language"
              >
                {t("profile.profile_tab.language_label")}
              </label>
              <Select
                defaultValue={formik.values.language
                  ? formik.values.language
                  : i18next.language ? i18next.language : "de"
                }
                style={{ width: 120 }}
                onChange={(e) => {
                  i18n.changeLanguage(e);
                  window.localStorage.setItem("lang", e);
                  dispatch(setLanguageAction(e));
                  formik.setFieldValue("language", e, true);
                  savePreferredLanguage(e);
                }}
              >
                {langs.map((lang) => (
                  <Option key={lang} value={lang}>{t(`languages.${lang}.full`)}</Option>
                ))}
              </Select>

            </div>


          </div>

          <Button
            text={t("profile.profile_tab.btn")}
            style={{marginTop: "30px", marginBottom: "20px", maxWidth: "250px", width: "100%"}}
            buttonType="submit"
            loading={buttonLoading}
            disabled={!(formik.isValid && formik.dirty)}
          />

        </form>
      }
    </div>
  );
}
