import React from "react";
import "./Header.css";
import {useTranslation} from "react-i18next";
import {authService} from "../../services/auth.service";
import {useHistory} from "react-router-dom";

export default function HeaderMenu() {
  const { t } = useTranslation();
  const history = useHistory();

  return(
    <div className="header_burger_menu">
      <div className="header_burger_menu_item" onClick={() => history.push("/")}>
        <i className="far fa-check-square"></i>
        <p>{t("header.menu.form")}</p>
      </div>
      <div className="header_burger_menu_item" onClick={() => history.push("/profile")}>
        <i className="fas fa-user-alt"></i>
        <p>{t("header.menu.profile")}</p>
      </div>
      <div className="header_burger_menu_item" onClick={() => new authService().logout()}>
        <i className="fas fa-sign-out-alt"></i>
        <p>{t("header.menu.logout")}</p>
      </div>
    </div>
  );
}
