import React, {useEffect, useState} from "react";
import "./styles.css";
import {Menu, Badge} from "antd";
import {
  DashboardOutlined,
  FormOutlined,
  FileOutlined,
  UserOutlined,
  LinkOutlined,
  EuroCircleOutlined,
  SettingOutlined,
  LogoutOutlined,
  MailOutlined,
  TeamOutlined, ApartmentOutlined
} from "@ant-design/icons";
import logo from "../../../img/taxefy_logo.png";
import {useHistory} from "react-router-dom";
import socketClient from "socket.io-client";
import {cookieService} from "../../../services/cookie.service";
import {authService} from "../../../services/auth.service";
import getAppCountry from "../../../utils/getAppCountry";

export default function AdminSidebar(props) {
  const history = useHistory();
  const [formsCounter, setFormsCounter] = useState(0);

  const startFormsNotification = async () => {
    /*const SERVER = "https://api.taxefy.at"; // http://127.0.0.1:5001
    const token = await new cookieService().getAdminTokenData();*/
    /*const socket = socketClient (SERVER, {
      query: {
        token: token.token
      }
    });
    socket.on("forms", (data) => {
      setFormsCounter(data.forms);
    });*/
  };

  useEffect(() => {
    startFormsNotification();
  }, []);

  return (
    <Menu
      style={{
        backgroundColor: "#f6f9fd",
        paddingTop: "10px"
      }}
      selectedKeys={[props.selectedTab]}
      mode="inline"
    >
      <img src={logo} alt="taxefy_logo" className="admin_sider_logo" />

      <Menu.Item
        key="dashboard"
        icon={<DashboardOutlined style={{fontSize: "18px"}} />}
        onClick={() => history.push("/admin/panel/dashboard")}
      >
        Dashboard
      </Menu.Item>

      {/* <Menu.Item
        key="pendingForms"
        icon={<FormOutlined style={{fontSize: "18px"}} />}
        onClick={() => history.push("pendingForms")}
      >
        <div className="row">
          <p style={{marginBottom: 0}}>Erklärungen</p>
          <Badge
            count={formsCounter}
            style={{marginLeft: "10px"}}
            overflowCount={999}
          />
        </div>
      </Menu.Item> */}

      <div className="admin_sider_line" />

      <Menu.Item
        key="forms"
        icon={<FileOutlined style={{fontSize: "18px"}} />}
        onClick={() => history.push("/admin/panel/forms")}
      >
        All Forms
      </Menu.Item>
      {/*
      <Menu.Item
        key="sentForms"
        icon={<EuroCircleOutlined style={{fontSize: "18px"}} />}
        onClick={() => history.push("sentForms")}
      >
        Sent Forms
      </Menu.Item>

      <Menu.Item
        key="paidForms"
        icon={<EuroCircleOutlined style={{fontSize: "18px"}} />}
        onClick={() => history.push("paidForms")}
      >
        Paid Forms
      </Menu.Item>

      <Menu.Item
        key="mailed"
        icon={<MailOutlined style={{fontSize: "18px"}} />}
        onClick={() => history.push("mailed")}
      >
        Mailed Forms
      </Menu.Item> */}

      <Menu.Item
        key="users"
        icon={<UserOutlined style={{fontSize: "18px"}} />}
        onClick={() => history.push("/admin/panel/users")}
      >
        Users
      </Menu.Item>

      {getAppCountry() !== "DE" &&
        <Menu.Item
          key="invitations"
          icon={<LinkOutlined style={{fontSize: "18px"}} />}
          onClick={() => history.push("/admin/panel/invitations")}
        >
          Einladungen
        </Menu.Item>
      }

      {getAppCountry() !== "DE" &&
        <Menu.Item
          key="transfers"
          icon={<EuroCircleOutlined style={{fontSize: "18px"}} />}
          onClick={() => history.push("/admin/panel/transfers")}
        >
          Überweisungen
        </Menu.Item>
      }

      <Menu.Item
        key="bank"
        icon={<EuroCircleOutlined style={{fontSize: "18px"}} />}
        onClick={() => history.push("/admin/panel/bank")}
      >
        Bank Data
      </Menu.Item>

      {getAppCountry() !== "DE" &&
        <Menu.Item
          key="errors"
          icon={<LinkOutlined style={{fontSize: "18px"}} />}
          onClick={() => history.push("/admin/panel/errors")}
        >
          Errors
        </Menu.Item>
      }

      <Menu.Item
        key="settings"
        icon={<SettingOutlined style={{fontSize: "18px"}} />}
        onClick={() => history.push("/admin/panel/settings")}
      >
        Einstellungen
      </Menu.Item>

	    {getAppCountry() !== "DE" &&
		    <Menu.Item
			    key="numbers"
			    icon={<EuroCircleOutlined style={{fontSize: "18px"}} />}
			    onClick={() => history.push("/admin/panel/numbers")}
		    >
			    Numbers
		    </Menu.Item>
	    }

      {getAppCountry() !== "DE" &&
        <Menu.Item
          key="consultantList"
          icon={<TeamOutlined style={{fontSize: "18px"}} />}
          onClick={() => history.push("/admin/panel/consultantList")}
        >
          Consultant - List
        </Menu.Item>
      }

      {getAppCountry() !== "DE" &&
        <Menu.Item
          key="partners"
          icon={<ApartmentOutlined style={{fontSize: "18px"}} />}
          onClick={() => history.push("/admin/panel/partners")}
        >
          Partners
        </Menu.Item>
      }

      <Menu.Item
        key="logout"
        icon={<LogoutOutlined style={{fontSize: "18px"}} />}
        onClick={async () => {
          new authService().logoutAdmin();
          new cookieService().removeAdminTokenData();
          history.push("/admin/auth/login");
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
}
