import { useEffect } from "react";
import queryString from "query-string";
import { setCampaign } from "../data/general/actions";
import { useDispatch } from "react-redux";
import Adjust from "@adjustcom/adjust-web-sdk";

let timeoutRef: NodeJS.Timeout;

const useCampaignParams = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    
    try {
      const parsed = queryString.parse(location.search);

      if (Object.keys(parsed ?? {}).length === 0) {
        return;
      }

      const { taxefy_campaign, ...data } = parsed;

      if (!taxefy_campaign) {
        return;
      }

      dispatch(setCampaign(data));
      timeoutRef = setTimeout(()=> {
        Adjust.trackEvent({ eventToken: "36bp88", callbackParams: data as any, partnerParams: data as any });
      }, 1000);
    } catch (err) {
      console.log("useCampaignParams:useEffect:err", err);
    }

    return () => {
      if (typeof timeoutRef !== "undefined") {
        clearTimeout(timeoutRef);
      }
    };
  }, []);

  return;
};

export default useCampaignParams;
