import React, {useEffect, useState} from "react";
import "./AuthenticationPage.css";
import phoneImage from "../../img/Auth/login_img.png";
import {Route, Router, Switch, Redirect, useHistory} from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import ForgetPassword from "./ForgetPassword";
import logo from "../../img/taxefy_logo.png";
import ResetPassword from "./ResetPasswordPage";
import {cookieService} from "../../services/cookie.service";
import ActivateUser from "./ActivateUserPage";
import RestoreAccount from "./RestoreAccount";
import RestoreSubmit from "./RestoreSubmit";
import RegionsAndLanguagesModal from "../../components/Modals/RegionsAndLanguagesModal";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import {langs} from "../../constants/langs";
import i18n from "i18next";

export default function AuthenticationPage() {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const getToken = async () => {
      const tokenData = await new cookieService().getTokenData();
      if (tokenData) {
        history.push("/");
      }
    };
    getToken();
  }, []);

  const [invitationFirstName, setInvitationFirstName] = useState("");

  const [langsModalOpen, setLangsModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("de");

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    if (lang && lang.length && langs.includes(lang)) {
      setSelectedLanguage(lang);
    }
  }, []);

  return(
    <div>
      <Modal
        footer={null}
        title={t("header.regions_modal.title")}
        visible={langsModalOpen}
        onCancel={() => setLangsModalOpen(false)}
      >
        <RegionsAndLanguagesModal
          langs={langs}
          selectedLang={selectedLanguage}
          closeModal={() => setLangsModalOpen(false)}
          login={true}
          setSelectedLang={(value) => setSelectedLanguage(value)}
        />
      </Modal>
      <div className="login_header">
        <div className="region_row" onClick={() => setLangsModalOpen(true)}>
          <p>{t(`languages.${i18n.language}.short`).toUpperCase()}</p>
          <i className="fas fa-globe globe_icon"></i>
        </div>
      </div>
      <div className="auth_page">
        <div className="auth_component">
          <Switch>
            <Route path="/auth/login" exact render={() =>
              <Login />
            }/>
            <Route path="/auth/register" exact render={() =>
              <Register setInvitationFirstName={setInvitationFirstName} />
            }/>
            <Route path="/auth/forget" exact render={() =>
              <ForgetPassword />
            }/>
            <Route path="/auth/resetPassword" exact render={() =>
              <ResetPassword />
            }/>
            <Route path="/auth/activateUser" exact render={() =>
              <ActivateUser />
            }/>
            <Route path="/auth/restoreAccount" exact render={() =>
              <RestoreAccount />
            }/>
            <Route path="/auth/restore" exact render={() =>
              <RestoreSubmit />
            }/>
            <Route path="/auth" render={() =>
              <Redirect to="/auth/login" />
            }/>
          </Switch>
        </div>
        {invitationFirstName
          ? <p>You have been invited by {invitationFirstName}</p>
          : <img
            id="phone_image"
            style={{position: "fixed", right: "1px", bottom: "10px", zIndex: "-1", width: "30%", opacity: "80%"}}
            src={phoneImage}
            alt="phone"
          />
        }

      </div>
    </div>
  );
}
