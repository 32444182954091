import React from "react";
import "./styles.css";

export default function ConsultantStatus(props) {
  let color = "red";
  let text = "disabled";
  switch (props.status) {
  case 1:
    color = "green";
    text = "enabled";
    break;
  case 2:
    color = "coral";
    text = "half-blocked";
    break;
  case 3:
    color = "orange";
    text = "blocked";
    break;
  }
  return (
    <div>
      <p
        className="consultant_status"
        style={{
          backgroundColor: color,
          color: "white",
          borderRadius: "8px",
          paddingLeft: "5px"
        }}
      >
        {text}
      </p>
    </div>
  );
}
