import React, {useEffect} from "react";
import queryString from "query-string";
import HashLoader from "react-spinners/HashLoader";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {partnerManagerService} from "../../services/partnerManager.service";

export default function ActivatePartnerManager() {
  const history = useHistory();

  useEffect(() => {
    const getToken = async () => {
      const parsed = queryString.parse(location.search);
      if (Object.keys(parsed).length && parsed.token) {
        const activated =
          await new partnerManagerService().activatePartnerManager(
            parsed.token
          );
        if (activated && activated.token) {
          if (isMobile) {
            window.location.replace(
              `at.taxefy.app://email-verification?token=${activated.token}`
            );
            setTimeout(() => {
              history.push("/partnermanager/auth/login");
            }, 100);
          } else {
            history.push("/partnermanager/auth/login");
          }
        } else {
          history.push("/404");
        }
      }
    };
    getToken();
  }, []);

  return (
    <>
      <HashLoader color="gray" size={35} />
    </>
  );
}
