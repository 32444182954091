import React, {useState} from "react";
import "./AuthenticationPage.css";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormikInput from "../../components/CommonComponents/FormikInput/FormikInput";
import {motion} from "framer-motion";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import {authService} from "../../services/auth.service";
import medalImg from "../../img/Auth/medal.png";
import ErrorBox from "../../components/ErrorBox/ErrorBox";

export default function RestoreAccount() {
  const { t } = useTranslation();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [successfullySent, setSuccessfullySent] = useState(false);


  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t("auth.warnings.email")).required(t("auth.warnings.required"))
    }),
    // handle form submitting
    onSubmit: async () => {
      setButtonLoading(true);
      const forget = await new authService().restore({
        email: formik.values.email
      });
      if (forget && !forget.success) {
        setErrorMessage(forget.message === "not registered"
          ? t("auth.warnings.forget_not_found")
          : t("auth.warnings.register_error"));
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
        setSuccessfullySent(true);
      }
    },
  });

  return(
    successfullySent
      ? <div
        className="register_success"
      >
        <motion.img
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            x: { type: "spring", stiffness: 100 },
            default: { delay: 0.4, duration: 0.3 },
          }}
          src={medalImg}
          alt="medal"
        />
        <motion.h2
          animate={{ opacity: 1, translateY: 0 }}
          initial={{ opacity: 0, translateY: "50px" }}
          transition={{
            x: { type: "spring", stiffness: 100 },
            default: { delay: 0.6, duration: 0.3 },
          }}
        >
          {t("auth.restore_account.success_text", {email: formik.values.email})}
        </motion.h2>
        <motion.div
          style={{width: "100%", marginTop: "20px"}}
          animate={{ opacity: 1, translateX: 0 }}
          initial={{ opacity: 0, translateX: "-150px" }}
          transition={{
            x: { type: "spring", stiffness: 100 },
            default: { delay: 1, duration: 0.3 },
          }}
        >
          <Button
            style={{
              width: "100%",
              maxWidth: "none"
            }}
            text={t("auth.forget.success_btn")}
            handleClick={() => history.push("/auth/login")}
          />
        </motion.div>
      </div>
      : <motion.form
        animate={{ translateY: 0, opacity: 1 }}
        initial={{ translateY: "-100vh", opacity: 0 }}
        transition={{
          x: { type: "spring", stiffness: 100 },
          default: { duration: 0.3 },
        }}
        exit={{ translateY: "100vh", opacity: 0 }}
        className="auth_box"
        onSubmit={formik.handleSubmit}
      >
        <h1 className="login_title">{t("auth.restore_account.title")}</h1>
        <h4 className="login_sub_title">{t("auth.forget.forget_sub")}</h4>

        {errorMessage.length ? <ErrorBox message={errorMessage} /> : null}

        <div style={{ position: "relative" }}>
          <FormikInput
            htmlFor="email"
            name="email"
            value={formik.values.email}
            disabled={false}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t("auth.register.email_label")}
            placeholder="max@musterman.at"
          />
          {formik.errors.email && formik.touched.email && (
            <motion.p
              animate={{ y: 5 }}
              className="input_error"
              style={{ fontSize: "10px" }}
            >
              {formik.errors.email}
            </motion.p>
          )}

          <Button
            text={t("auth.forget.btn")}
            style={{marginTop: "30px", marginBottom: "20px", maxWidth: "none", width: "100%"}}
            buttonType="submit"
            loading={buttonLoading}
          />
          <p
            onClick={() => history.push("/auth/login")}
            className="login_forget_text"
          >
            {t("auth.forget.back_login")}
          </p>
        </div>

      </motion.form>
  );
}
