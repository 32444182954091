import React, {useEffect, useState} from "react";
import "./partnersPage.css";
import Loading from "../../../../../components/Loading";
import {Modal, notification, Select, Table, Tooltip} from "antd";
import Button from "../../../../../components/CommonComponents/FormButton/FormButton";
import {CopyOutlined} from "@ant-design/icons";
import CreatePartnerModal from "../../../../../components/Modals/CreatePartnerModal/CreatePartnerModal";
import {partnersService} from "../../../../../services/partners.service";
import moment from "moment";
import {useHistory} from "react-router-dom";
import PartnerManagers from "../PartnerManager";
import { partnerManagerService } from "../../../../../services/partnerManager.service";

export default function PartnerPage() {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [openCreatePartnerModal, setOpenCreatePartnerModal] = useState(false);
  const [openChangePMmodal, setOpenChangePMmodal] = useState(false);
  const [changePMrow, setChangePMrow] = useState(null);
  const [partnersData, setPartnersData] = useState([]);
  const [partnerManagersData, setPartnerManagersData] = useState([]);

  const handleSelect = async (e) => {
    const reqData = {
      partner_id : changePMrow.partner_id,
      partner_manager_id: e
    };
    await new partnerManagerService().changePartnerManagerOfPartner(reqData);
    await getAllPartenrs();
    setOpenChangePMmodal(false);
    setChangePMrow(null);
  };

  const getAllPartenrs = async () => {
    const partners = await new partnersService().get();
    setPartnersData(partners.data);
    setLoading(false);
  };

  useEffect(async () => {
    await getAllPartenrs();
  }, []);

  return loading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div style={{ overflow: "auto" }}>

      <Modal
        footer={null}
        closable={null}
        visible={openCreatePartnerModal}
        onCancel={() => {setOpenCreatePartnerModal(false);}}
      >
        <CreatePartnerModal
          onSuccess={() => getAllPartenrs()}
        />
      </Modal>
      <Modal
        title={"Change Partner-Manager for " + changePMrow?.name}
        footer={null}
        closable={null}
        visible={openChangePMmodal}
        onCancel={() => {setOpenChangePMmodal(false);}}
      >
        <Select
          placeholder="PartnerManagers"
          options={partnerManagersData.map(el =>( {label: el.email, value: el.partner_manager_id}))}
          showSearch={false}
          onChange={handleSelect}
        />
      </Modal>

      <div style={{ marginTop: "40px" }} className="space-between">
        <h2>Partners</h2>
        <Button
          text={"Add Partner +"}
          style={{
            margin: "15px"
          }}
          buttonType="submit"
          handleClick={() => {
            setOpenCreatePartnerModal(true);
          }}
        />
      </div>
      <Table dataSource={partnersData?.data}>
        <Table.Column
          title="Partner Name"
          dataIndex="name"
          key="name"
        />
        <Table.Column
          title="Registered users"
          dataIndex="users"
          key="users"
        />
        <Table.Column
          title="Created"
          dataIndex="created_at"
          key="created_at"
          render={(created_at) => {
            return(<p>{moment(created_at).format("DD.MM.YYYY HH:mm")}</p>);
          }}
        />
        <Table.Column
          title="Link"
          dataIndex="link"
          key="link"
          render={(link) => {
            return(
              <Tooltip title={link}>
                <div
                  className={"row"}
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    navigator.clipboard.writeText(link);
                    notification.success({
                      message: "Link copied to clipboard",
                      duration: 2
                    });
                  }}
                >
                  <CopyOutlined
                    style={{fontSize: "18px"}}
                  />
                  <p style={{marginBottom: 0, marginLeft: "10px"}}>
                    Copy link
                  </p>
                </div>
              </Tooltip>
            );
          }}
        /><Table.Column
          title="Partner-Manager"
          dataIndex="partner_manager_id"
          key="partner_manager"
        />
        <Table.Column
          title="Action"
          dataIndex="partner_id"
          key="partner_id"
          render={(partner_id,partner) => {
            return(
              <div>
                <Button style={{margin: "5px"}}
                  handleClick={() => {
                    history.push(`/admin/panel/partners/${partner_id}`);
                  }}
                  text={"Partner details"}
                />
                <Button style={{margin: "5px"}}
                  handleClick={() => {
                    setOpenChangePMmodal(true);
                    setChangePMrow(partner);
                  }}
                  text={"Change Partner-Manager"}
                />
              </div>
            );
          }}
        />
      </Table>
      <PartnerManagers setPartnerManager={setPartnerManagersData} />
    </div>
  );
}
