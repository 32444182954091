import React, {useEffect, useState} from "react";
import "./Stepper.css";
import { Steps } from "antd";
import {connect, useDispatch} from "react-redux";
// Icons
import {getAnsSelector, getLanguageSelector, getQsSelector} from "../../data/app/selectors";
import {
  calculateTax,
  checkVisibility,
  findEquivalentGroupAns,
  findGroupIndexById,
  getText
} from "../../utils/tools";
import Icon from "../CommonComponents/Icon/Icon";
import {setAnsAction} from "../../data/app/actions";
import axios from "axios";
import environment from "../../environment";
import {cookieService} from "../../services/cookie.service";
import {formService} from "../../services/form.service";

const { Step } = Steps;

function Stepper(props) {
  const dispatch = useDispatch();

  const [groupProgress, setGroupProgress] = useState(0);

  const [savedGroup, setSavedGroup] = useState(0);

  const saveGroup = async () => {
    const userToken = await new cookieService().getTokenData();
    let groupsCounter = 0;
    const QSCopy = {...props.QS};
    QSCopy.groups.filter(grp => checkVisibility(grp, props.ANS)).map((item, idx) => {
      groupsCounter += 1;
    });
    /*if (userToken) {
      const postData = {
        currentStep: (savedGroup + 1).toString(),
        percentage: (((savedGroup + 1) / groupsCounter) * 100).toString(),
        user: {
          id: userToken.id,
          firstName: userToken.firstName,
          lastName: userToken.lastName,
          email: userToken.email
        },
        form: props.ANS,
        calculations: calculateTax(props.QS, props.ANS, props.language),
        device: "Web"
      };
      await new formService().sendForm(postData);
    }*/
  };

  useEffect(() => {
    if (savedGroup !== props.ANS.current_group - 1) {
      saveGroup();
    }
    setSavedGroup(props.ANS.current_group - 1);
  }, [props.ANS.current_group]);

  // Set progress for group percentage circle
  useEffect(() => {
    if (props.QS
      && props.ANS
      && props.QS.groups.filter(grp => checkVisibility(grp, props.ANS))[props.ANS.current_group - 1]) {
      props.QS.groups.filter(grp => {
        checkVisibility(grp, props.ANS);
      });
      const result = findEquivalentGroupAns(
        props.QS.groups.filter(grp => checkVisibility(grp, props.ANS))[props.ANS.current_group - 1],
        props.ANS)
        .current_question / (
        findEquivalentGroupAns(
          props.QS.groups.filter(grp => checkVisibility(grp, props.ANS))[props.ANS.current_group - 1],
          props.ANS).questions.length + 1);
      if (result !== groupProgress) {
        setGroupProgress(result * 100);
      }
    }

  }, [props.QS, props.ANS]);

  const changeStep = (idx) => {
    props.ANS.current_group = idx + 1;
    const groupIdx = findGroupIndexById(props.QS, props.ANS.current_group);
    props.ANS.groups[groupIdx].current_question = 1;
    dispatch(setAnsAction(props.ANS));
  };

  const isAvailable = (idx) => {
    if (idx === props.ANS.current_group - 1) {
      return true;
    } else if (idx !== 0 && props.ANS.groups[idx - 1].completed) {
      return true;
    } else if (idx === 0 && props.ANS.groups[idx].completed) {
      return true;
    } else {
      return false;
    }
  };

  return(
    <div className="stepper">
      {props.ANS && props.ANS.groups
        ? <Steps
          direction="horizontal"
          responsive={false}
          current={props.ANS && props.ANS.current_group - 1}
          percent={props.ANS.groups[props.ANS.current_group - 1].completed ? 100 : groupProgress}>
          {props.QS
          && props.QS.groups.filter(grp => checkVisibility(grp, props.ANS)).map((item, idx) => (
            <Step
              style={isAvailable(idx) ? {cursor: "pointer"} : {cursor: "not-allowed"}}
              key={item.id}
              // TODO Add dynamic language
              title={getText(props.ANS, props.QS, item.title, props.lang)}
              icon={<Icon
                icon={item.icon}
                style={isAvailable(idx) ? {color: "rgba(0, 190, 170, 1)"} : {}}
              />}
              onClick={() => {isAvailable(idx) && changeStep(idx);}}
            />
          ))}
        </Steps>
        : null}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    QS: getQsSelector(state),
    ANS: getAnsSelector(state),
    language: getLanguageSelector(state)
  };
};

export default connect(mapStateToProps, null)(Stepper);
