import React, {useEffect, useState} from "react";
import {Table} from "antd";
import {cookieService} from "../../../services/cookie.service";
import axios from "axios";
import environment from "../../../environment";
import moment from "moment";

const { Column } = Table;

export default function InvitationsTab() {

  const [loading, setLoading] = useState(true);
  const [invitationsData, setInvitationsData] = useState([]);

  const getForms = async () => {
    const cookieToken = await new cookieService().getTokenData();
    const response = await axios.get(`${environment.baseUrl}admin/invitations`, {
      headers: {
        Authorization: `Bearer ${cookieToken.refreshToken}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data && response.data.length) {
      const newArray = [];
      response.data.map((invitationFromDb, idx) => {
        invitationFromDb.createdAt = moment(new Date(invitationFromDb.createdAt)).format("DD.MM.YYYY");
        newArray.push({
          ...invitationFromDb,
          key: idx.toString(),
        });
      });
      setInvitationsData(newArray);
    }
    setLoading(false);
  };

  useEffect(() => {
    getForms();
  }, []);

  return(
    <div className="admin_tab">
      {loading
        ? <p>Loading</p>
        : invitationsData.length
          ? <Table dataSource={invitationsData}>
            <Column title="Id" dataIndex="id" key="id" />
            <Column title="Sender Id" dataIndex="senderId" key="senderId" />
            <Column title="Recipient Id" dataIndex="recipientId" key="recipientId" />
            <Column title="Status" dataIndex="status" key="status" />
            <Column title="Created" dataIndex="createdAt" key="createdAt" />
          </Table>
          : <p>No data</p>
      }
    </div>
  );
}
