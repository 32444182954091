import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormikInput from "../../components/CommonComponents/FormikInput/FormikInput";
import {motion} from "framer-motion";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import {wordCapitalize} from "../../utils/wordCapitalize";
import {useDispatch, useSelector} from "react-redux";
import {campaignSelector} from "../../data/general/selectors";
import {partnerManagerService} from "../../services/partnerManager.service";
import "./Register.css";
import Logo from "../../img/taxefy_logo.png";
import TaxefyCheckmark from "../../img/taxefy_checkmark.png";
import TaxefyCheckmark2 from "../../img/taxefy_checkmark_2.png";
import TaxefyIcon from "../../img/taxefy_icon.png";

export default function PartnerManagerRegister(props) {
  const campaign = useSelector(campaignSelector);
  const history = useHistory();

  const [activeError, setActiveError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      password: "",
      confirmPassword: "",
      agbCheckbox: false
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Name benötigt"),
      email: Yup.string().email("Email ungültig").required("Email benötigt"),
      password: Yup.string()
        .required("Passwort benötigt")
        .min(8, "Passwort zu kurz")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[!?=+-@#$%^&*A-Za-zÜüÖöÄäß\d]{8,50}$/,
          "Ungültiges Passwort"
        ),
      confirmPassword: Yup.string()
        .min(8, "Passwort zu kurz")
        .when("password", {
          is: val => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Passwort nicht gleich"
          )
        }),
      agbCheckbox: Yup.boolean().required().isTrue()
    }),
    // handle form submitting
    onSubmit: async () => {
      setButtonLoading(true);
      const extraParams = {};

      try {
        if (campaign) {
          extraParams.campaign = JSON.stringify(campaign);
        }
      } catch (err) {
        console.log("Register:useFormik:onSubmit", err);
      }

      const register = await new partnerManagerService().register({
        firstName: wordCapitalize(formik.values.firstName),
        email: formik.values.email,
        password: formik.values.password,
        ...extraParams
      });
      if (register && !register.success) {
        if (register.message === "not_active") {
          setActiveError(true);
        } else {
          setErrorMessage(
            register.message === "exists"
              ? "Email existiert bereits"
              : "Ein Fehler ist unterlaufen"
          );
        }
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
        if (register && register.success) {
          setSuccessfullyRegistered(true);
        }
      }
    }
  });

  return (
    <div>
      <img src={Logo} id="taxefy_logo" alt="" />
      {successfullyRegistered ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20%"
          }}
        >
          <motion.h1
            animate={{opacity: 1, translateY: 0}}
            initial={{opacity: 0, translateY: "50px"}}
            transition={{
              x: {type: "spring", stiffness: 100},
              default: {delay: 0.6, duration: 0.3}
            }}
            style={{fontWeight: "bolder", fontSize: "35px"}}
          >
            {"Fast geschafft! Bitte bestätige nun deine E-Mail."}
          </motion.h1>
          <motion.h2
            animate={{opacity: 1, translateY: 0}}
            initial={{opacity: 0, translateY: "50px"}}
            transition={{
              x: {type: "spring", stiffness: 100},
              default: {delay: 0.8, duration: 0.3}
            }}
          >
            {"Eine Bestätigungs-Email wurde an " +
              formik.values.email +
              " geschickt"}
          </motion.h2>
          <motion.div
            style={{width: "50%", marginTop: "20px"}}
            animate={{opacity: 1, translateX: 0}}
            initial={{opacity: 0, translateX: "-150px"}}
            transition={{
              x: {type: "spring", stiffness: 100},
              default: {delay: 1, duration: 0.3}
            }}
          >
            <Button
              style={{
                width: "100%",
                maxWidth: "none"
              }}
              text={"Zur Anmeldung"}
              handleClick={() => history.push("/partnermanager/auth/login")}
            />
          </motion.div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img
            style={{left: "15%", top: "70%", rotate: "0deg"}}
            src={TaxefyIcon}
            className="background_logos"
            alt=""
          />
          <img
            style={{left: "15%", top: "50%", rotate: "-30deg"}}
            src={TaxefyCheckmark2}
            className="background_logos_small"
            alt=""
          />
          <img
            style={{left: "25%", top: "60%", rotate: "-30deg"}}
            src={TaxefyCheckmark}
            className="background_logos_small"
            alt=""
          />
          <img
            style={{
              right: "15%",
              top: "10%",
              rotate: "-60deg",
              transform: "scale(-1,1)"
            }}
            src={TaxefyIcon}
            className="background_logos"
            alt=""
          />
          <img
            style={{right: "15%", top: "60%", rotate: "90deg"}}
            src={TaxefyCheckmark2}
            className="background_logos_small"
            alt=""
          />
          <img
            style={{
              right: "15%",
              top: "40%",
              rotate: "-67deg",
              transform: "scale(1,-1)"
            }}
            src={TaxefyCheckmark}
            className="background_logos_small"
            alt=""
          />

          <div style={{width: "30%", marginTop: "5px", minWidth: "280px"}}>
            <h1 className="login_title">
              Registrierung zum{" "}
              <span style={{color: "rgba(0, 190, 170, 1)"}}>
                Affiliate-Partner
              </span>
            </h1>
            <h4 className="login_sub_title"></h4>

            <form onSubmit={formik.handleSubmit}>
              {activeError ? (
                <motion.div
                  className="error_box_container"
                  animate={{opacity: 1}}
                  initial={{opacity: 0}}
                  transition={{
                    delay: 0.3,
                    x: {type: "spring", stiffness: 100},
                    default: {duration: 1}
                  }}
                >
                  <i className="fas fa-exclamation-triangle"></i>
                  <p>
                    {"Accoutn not active"} <br />
                    <span onClick={() => history.push("/auth/restoreAccount")}>
                      {"Restore Account"}
                    </span>
                  </p>
                </motion.div>
              ) : null}

              {errorMessage.length ? <ErrorBox message={errorMessage} /> : null}

              <div style={{position: "relative"}}>
                <FormikInput
                  htmlFor="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  disabled={false}
                  handleChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={"Name | Firmenname"}
                  placeholder="Max"
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <motion.p
                    animate={{y: 5}}
                    className="input_error"
                    style={{fontSize: "10px"}}
                  >
                    {formik.errors.firstName}
                  </motion.p>
                )}
              </div>

              <div style={{position: "relative"}}>
                <FormikInput
                  htmlFor="email"
                  name="email"
                  value={formik.values.email}
                  disabled={false}
                  handleChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={"Email"}
                  placeholder="max@musterman.at"
                />
                {formik.errors.email && formik.touched.email && (
                  <motion.p
                    animate={{y: 5}}
                    className="input_error"
                    style={{fontSize: "10px"}}
                  >
                    {formik.errors.email}
                  </motion.p>
                )}
              </div>

              <div style={{position: "relative"}}>
                <FormikInput
                  htmlFor="password"
                  name="password"
                  value={formik.values.password}
                  disabled={false}
                  handleChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={"Passwort"}
                  placeholder="******"
                />
                {formik.errors.password && formik.touched.password && (
                  <motion.p
                    animate={{y: 5}}
                    className="input_error"
                    style={{fontSize: "10px"}}
                  >
                    {formik.errors.password}
                  </motion.p>
                )}
              </div>

              <div style={{position: "relative"}}>
                <FormikInput
                  htmlFor="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  disabled={false}
                  handleChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={"Passwort bestätigen"}
                  placeholder="******"
                />
                {formik.errors.confirmPassword &&
                  formik.touched.confirmPassword && (
                  <motion.p
                    animate={{y: 5}}
                    className="input_error"
                    style={{fontSize: "10px"}}
                  >
                    {formik.errors.confirmPassword}
                  </motion.p>
                )}
              </div>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <p style={{marginTop: "25px"}}>
                  Ich akzeptiere die{" "}
                  <span
                    onClick={() =>
                      window.open("https://taxefy.at/affiliate-agb/", "_blank")
                    }
                    style={{color: "rgba(0, 190, 170, 1)"}}
                  >
                    AGBs
                  </span>{" "}
                  zum Tätigwerden als Affiliate-Partner
                </p>
                <div style={{width: "15%"}}>
                  <FormikInput
                    htmlFor="checkbox"
                    name="confirmPassword"
                    value={formik.values.agbCheckbox}
                    disabled={false}
                    handleChange={() => {
                      formik.setFieldValue(
                        "agbCheckbox",
                        !formik.values.agbCheckbox
                      );
                    }}
                    onBlur={formik.handleBlur}
                    label={""}
                  />
                  {formik.errors.agbCheckbox && formik.touched.agbCheckbox && (
                    <motion.p
                      animate={{y: 5}}
                      className="input_error"
                      style={{fontSize: "10px"}}
                    >
                      {formik.errors.agbCheckbox}
                    </motion.p>
                  )}
                </div>
              </div>

              <Button
                text={"Registrier dich"}
                style={{
                  marginTop: "30px",
                  marginBottom: "20px",
                  maxWidth: "none",
                  width: "100%"
                }}
                buttonType="submit"
                loading={buttonLoading}
                disabled={!(formik.isValid && formik.dirty)}
              />
            </form>

            <p style={{textAlign: "center"}}>
              {"Bereits registriert? Hier "}
              <span
                style={{color: "rgba(0, 190, 170, 1)"}}
                onClick={() => history.push("/partnermanager/auth/login")}
              >
                zur Anmeldung.
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
