import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import FormikInput from "../CommonComponents/FormikInput/FormikInput";
import {motion} from "framer-motion";
import Button from "../CommonComponents/FormButton/FormButton";
import {cookieService} from "../../services/cookie.service";
import axios from "axios";
import environment from "../../environment";
import {notification} from "antd";

export default function ChangePasswordModal(props) {
  const { t } = useTranslation();

  const [buttonLoading, setButtonLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t("auth.warnings.required"))
        .min(8, t("auth.warnings.password_length"))
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[!?=+-@#$%^&*A-Za-zÜüÖöÄäß\d]{8,50}$/, t("auth.warnings.password_matches")),
      confirmPassword: Yup.string()
        .min(8, t("auth.warnings.password_length"))
        .when("password", {
          is: val => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("auth.warnings.password_not_same")
          ),
        })
    }),
    // handle form submitting
    onSubmit: async (e) => {
      if (formik.isValid && formik.dirty) {
        setButtonLoading(true);
        const cookieToken = await new cookieService().getTokenData();
        await axios.post(`${environment.baseUrl}auth/changePassword`,
          {
            password: formik.values.password
          },
          {
            headers: {
              Authorization: `Bearer ${cookieToken.refreshToken}`,
              "app-version": "1.0.1"
            }
          })
          .then(res => {
            if (res && res.data) {
              props.closeModal();
              notification.open({
                message: t("profile.profile_tab.password_changed"),
                duration: 3
              });
            }
          })
          .catch(e => {
            notification.open({
              message: t("profile.profile_tab.error_toast_title"),
              duration: 2
            });
          })
          .finally(setButtonLoading(false));
      }
    },
  });

  return(
    <form onSubmit={(e) => formik.handleSubmit(e)}>
      <FormikInput
        htmlFor="password"
        name="password"
        value={formik.values.password}
        disabled={false}
        handleChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label={t("auth.login.password_label")}
        placeholder="******"
      />
      {formik.errors.password && formik.touched.password && (
        <motion.p
          animate={{ y: 5 }}
          className="input_error"
          style={{ fontSize: "10px" }}
        >
          {formik.errors.password}
        </motion.p>
      )}

      <div style={{height: "15px"}}></div>

      <FormikInput
        htmlFor="password"
        name="confirmPassword"
        value={formik.values.confirmPassword}
        disabled={false}
        handleChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label={t("auth.register.confirm_password_label")}
        placeholder="******"
      />
      {formik.errors.confirmPassword && formik.touched.confirmPassword && (
        <motion.p
          animate={{ y: 5 }}
          className="input_error"
          style={{ fontSize: "10px" }}
        >
          {formik.errors.confirmPassword}
        </motion.p>
      )}

      <Button
        text={t("auth.resetPassword.btn")}
        style={{marginTop: "30px", marginBottom: "20px", maxWidth: "none", width: "100%"}}
        buttonType="submit"
        loading={buttonLoading}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
}
