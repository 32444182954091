import React, {useEffect} from "react";
import HashLoader from "react-spinners/HashLoader";
import {useHistory, useLocation} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {partnersService} from "../../services/partners.service";

export default function PartnerRedirect() {
  const location = useLocation();
  const history = useHistory();

  const getToken = async () => {
    if (location.search == "" || !location.search.includes("partner=")) history.push("/auth/login");
    const partner_id = location.search.split("=")[1];
    const response = await new partnersService().getPartnerLink(partner_id);
    if (response.data != []) {
      const deeplink = response.data[0].link;
      console.log(deeplink);
      window.open(deeplink, "_self");
    }
  };
  useEffect(() => {
    getToken();
  }, []);

  return (
    <div style={{textAlign: "center", marginTop: "20vh"}}>
      <HashLoader color="gray" size={55} />
    </div>
  );
}
