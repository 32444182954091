import {React, useState, useEffect} from "react";
// import logo from "https://taxefy.at/wp-content/uploads/2022/03/cropped-logo_taxefy_Appicon2.jpg-180x180.png";
import "./styles.css";
import {useHistory, useLocation} from "react-router-dom";
import {cookieService} from "../../services/cookie.service";
import Button from "../CommonComponents/FormButton/FormButton";
import {Menu} from "antd";
import {
  LogoutOutlined,
  StarFilled,
  SettingFilled,
  BulbFilled,
  PieChartFilled,
  FileFilled,
  RocketFilled,
  UserOutlined
} from "@ant-design/icons";
import {Modal} from "antd";
import {authService} from "../../services/auth.service";

export default function PartnerManagerSidebar() {
  const history = useHistory();
  const selectedTab = useLocation().pathname.split("/")[3];
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(async () => {
    const token = await new cookieService().getTokenData();
    setUsername(token.name);
  }, []);

  function toggleLogoutDialog() {
    setIsOpen(!isOpen);
  }
  return (
    <div>
      <Menu id="partnermanager_sidebar_menu" selectedKeys={[selectedTab]}>
        <div style={{display: "flex"}}>
          <img
            id="partnermanager_sidebar_logo"
            src={
              "https://taxefy.at/wp-content/uploads/2022/03/cropped-logo_taxefy_Appicon2.jpg-180x180.png"
            }
            alt="taxefy_logo"
          />
          <h1 style={{marginTop: "12%", marginLeft: "5%", fontSize: "190%"}}>
            Station
          </h1>
        </div>
        <Menu.Item
          style={{
            backgroundColor: "#00beaa",
            color: "white",
            padding: "13px",
            border: "solid 3px #d4f7f4",
            borderRadius: "15px",
            whiteSpace: "normal",
            lineHeight: "15px",
            height: "auto",
            textAlign: "center",
            margin: "5px",
            marginBottom: "10%"
          }}
          icon={<RocketFilled />}
          onClick={() => history.push("dashboard")}
          key="dashboard"
        >
          Link - Performance
        </Menu.Item>
        <Menu.Item
          key="forms"
          icon={<FileFilled />}
          onClick={() => history.push("forms")}
        >
          Steuererklärungen
        </Menu.Item>
        <Menu.Item
          key="users"
          icon={<UserOutlined />}
          onClick={() => history.push("users")}
        >
          Kunden
        </Menu.Item>
        <Menu.Item
          key="reports"
          icon={<PieChartFilled />}
          onClick={() => history.push("reports")}
        >
          Reports
        </Menu.Item>
        <Menu.Item
          key="assets"
          icon={<StarFilled />}
          onClick={() => history.push("assets")}
        >
          Assets
        </Menu.Item>
        <div
          style={{marginTop: "20%", marginBottom: "20%"}}
          className="admin_sider_line"
        />
        <Menu.Item
          key="settings"
          icon={<SettingFilled />}
          onClick={() => history.push("settings")}
        >
          Einstellungen
        </Menu.Item>
        <Menu.Item
          key="help"
          icon={<BulbFilled />}
          onClick={() => history.push("help")}
        >
          Hilfe
        </Menu.Item>

        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={() => {
            toggleLogoutDialog();
          }}
        >
          Log out
        </Menu.Item>
      </Menu>
      <Modal
        footer={null}
        closable={null}
        visible={isOpen}
        onCancel={toggleLogoutDialog}
      >
        <h4 className="logout_dialog_h4">Bist du sicher?</h4>
        <div
          style={{
            display: "flex"
          }}
        >
          <Button
            text={"Ja"}
            style={{
              width: "50%",
              margin: "5px"
            }}
            handleClick={() => {
              toggleLogoutDialog();
              new authService().logoutPartner();
              new cookieService().removeTokenData();
            }}
          />
          <Button
            text={"Nein"}
            style={{
              width: "50%",
              margin: "5px"
            }}
            handleClick={() => {
              toggleLogoutDialog();
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
