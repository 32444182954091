import { createSelector } from "reselect";

const getIsLoading = state => {
  return state.general.get("isLoading");
};

const getCampaign = state => {
  return state.general.get("campaign");
};

// SELECTORS

export const isLoadingSelector = createSelector([getIsLoading], isLoading => isLoading);
export const campaignSelector = createSelector([getCampaign], campaign => campaign);