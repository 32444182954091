import React from "react";
import "./AdminStyles.css";
import { Route, Switch} from "react-router-dom";
import AdminLogin from "./AdminLogin";
import AdminPanelLayout from "./AdminPanelLayout";

export default function AdminLayout() {

  return(
    <Switch>
      <Route path="/admin/auth" render={() =>
        <AdminLogin />
      }/>
      <Route path="/admin/panel" exact render={() =>
        <AdminPanelLayout />
      }/>
      <Route path="/admin/panel/*" exact render={() =>
        <AdminPanelLayout />
      }/>
    </Switch>
  );
}
