import React, {useState} from "react";
import axios from "axios";
import environment from "../../../environment";
import {cookieService} from "../../../services/cookie.service";

export default function NumbersTab() {

  const [numberMoneyDecided, setNumberMoneyDecided] = useState(0);
  const [sumMoneyDecided, setSumMoneyDecided] = useState(0);
  const [moneyDecidedButtonLoading, setMoneyDecidedButtonLoading] = useState(false);
  const [numberDelivered, setNumberDelivered] = useState(0);
  const [sumDelivered, setSumDelivered] = useState(0);
  const [deliveredButtonLoading, setDeliveredButtonLoading] = useState(false);
  const [numberDidBefore, setNumberDidBefore] = useState(0);
  const [sumDidBefore, setSumDidBefore] = useState(0);
  const [didBeforeButtonLoading, setDidBeforeButtonLoading] = useState(false);
  const [numberInvoicesRequired, setNumberInvoicesRequired] = useState(0);
  const [sumInvoicesRequired, setSumInvoicesRequired] = useState(0);
  const [invoicesRequiredButtonLoading, setInvoicesRequiredButtonLoading] = useState(false);
  const [numberRejection, setNumberRejection] = useState(0);
  const [sumRejection, setSumRejection] = useState(0);
  const [rejectionButtonLoading, setRejectionButtonLoading] = useState(false);
  const [numberEmployeePending, setNumberEmployeePending] = useState(0);
  const [sumEmployeePending, setSumEmployeePending] = useState(0);
  const [employeePendingButtonLoading, setEmployeePendingButtonLoading] = useState(false);

  const getMoneyDecidedData = async () => {
    setMoneyDecidedButtonLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    await axios.get(`${environment.baseUrl}admin/numbers/moneyDecided`, {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then((res) => {
        setNumberMoneyDecided(res.data.total);
        setSumMoneyDecided(res.data.totalAmount);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setMoneyDecidedButtonLoading(false);
      });
  };

  const getDeliveredButton = async () => {
    setDeliveredButtonLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    await axios.get(`${environment.baseUrl}admin/numbers/delivered`, {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then((res) => {
        setNumberDelivered(res.data.total);
        setSumDelivered(res.data.totalAmount);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setDeliveredButtonLoading(false);
      });
  };

  const getDidBeforeData = async () => {
    setDidBeforeButtonLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    await axios.get(`${environment.baseUrl}admin/numbers/didBefore`, {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then((res) => {
        setNumberDidBefore(res.data.total);
        setSumDidBefore(res.data.totalAmount);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setDidBeforeButtonLoading(false);
      });
  };

  const getInvoicesRequiredData = async () => {
    setInvoicesRequiredButtonLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    await axios.get(`${environment.baseUrl}admin/numbers/invoicesRequired`, {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then((res) => {
        setNumberInvoicesRequired(res.data.total);
        setSumInvoicesRequired(res.data.totalAmount);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setInvoicesRequiredButtonLoading(false);
      });
  };

  const getRejectionData = async () => {
    setRejectionButtonLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    await axios.get(`${environment.baseUrl}admin/numbers/rejection`, {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then((res) => {
        setNumberRejection(res.data.total);
        setSumRejection(res.data.totalAmount);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setRejectionButtonLoading(false);
      });
  };

  const getEmployeePendingData = async () => {
    setEmployeePendingButtonLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    await axios.get(`${environment.baseUrl}admin/numbers/employeePending`, {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then((res) => {
        setNumberEmployeePending(res.data.total);
        setSumEmployeePending(res.data.totalAmount);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setEmployeePendingButtonLoading(false);
      });
  };


  return(
    <div className="admin_tab">
      <h1>Numbers</h1>

      {/*Money Decided*/}
      <div>
        <h3>Number & Official sum of all „money decided“ cases</h3>
        <button
          disabled={moneyDecidedButtonLoading}
          onClick={getMoneyDecidedData}
        >
          {moneyDecidedButtonLoading
            ? "Loading..."
            : "Request Data"
          }
        </button>

        <p>{`number: ${numberMoneyDecided}`}</p>
        <p>{`amount: ${sumMoneyDecided}`}</p>

      </div>

      {/*Delivered*/}
      <div>
        <h3>Number & Precalculated sum of all „delivered“ cases</h3>
        <button
          disabled={deliveredButtonLoading}
          onClick={getDeliveredButton}
        >
          {moneyDecidedButtonLoading
            ? "Loading..."
            : "Request Data"
          }
        </button>

        <p>{`number: ${numberDelivered}`}</p>
        <p>{`amount: ${sumDelivered}`}</p>

      </div>

      {/*Did before*/}
      <div>
        <h3>Number of cases with status „did it before“</h3>
        <button
          disabled={didBeforeButtonLoading}
          onClick={getDidBeforeData}
        >
          {moneyDecidedButtonLoading
            ? "Loading..."
            : "Request Data"
          }
        </button>

        <p>{`number: ${numberDidBefore}`}</p>
        <p>{`amount: ${sumDidBefore}`}</p>

      </div>

      {/*Invoices required*/}
      <div>
        <h3>Number of cases with status „invoices required“</h3>
        <button
          disabled={invoicesRequiredButtonLoading}
          onClick={getInvoicesRequiredData}
        >
          {invoicesRequiredButtonLoading
            ? "Loading..."
            : "Request Data"
          }
        </button>

        <p>{`number: ${numberInvoicesRequired}`}</p>
        <p>{`amount: ${sumInvoicesRequired}`}</p>

      </div>

      {/*Rejection*/}
      <div>
        <h3>Number of cases with status „rejection“</h3>
        <button
          disabled={rejectionButtonLoading}
          onClick={getRejectionData}
        >
          {rejectionButtonLoading
            ? "Loading..."
            : "Request Data"
          }
        </button>

        <p>{`number: ${numberRejection}`}</p>
        <p>{`amount: ${sumRejection}`}</p>

      </div>

      {/*Employee pending*/}
      <div>
        <h3>Number of cases with status „employee pending“</h3>
        <button
          disabled={employeePendingButtonLoading}
          onClick={getEmployeePendingData}
        >
          {employeePendingButtonLoading
            ? "Loading..."
            : "Request Data"
          }
        </button>

        <p>{`number: ${numberEmployeePending}`}</p>
        <p>{`amount: ${sumEmployeePending}`}</p>

      </div>

    </div>
  );
}
