import React, {useEffect, useState} from "react";
import "./QuestionComponentsStyles.css";
import ButtonWithCheck from "../CommonComponents/ButtonWithCheck/ButtonWithCheck";
import Button from "../CommonComponents/FormButton/FormButton";
import {checkVisibility, getText} from "../../utils/tools";
import {motion} from "framer-motion";
import NavigationButtons from "./NavigationButtons/NavigationButtons";
import {useTranslation} from "react-i18next";



export default function MultipleChoice(props) {
  const { t } = useTranslation();

  const [buttonBlocked, setButtonBlocked] = useState(false);
  const [answered, setAnswered] = useState(false);

  const handleSubmitClick = () => {
    if (!buttonBlocked && !props.blocked) {
      setButtonBlocked(true);
      setAnswered(true);
      props.forward(props.condition);
      setTimeout(() => {
        setButtonBlocked(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (!answered && props.answr.length) {
      setAnswered(true);
    }
  }, [props.answr]);

  return(
    <motion.div
      className="single_choice"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{
        delay: 0.3,
        x: { type: "spring", stiffness: 100 },
        default: { duration: 1 },
      }}
    >

      {props.showButtonBack
        ? <NavigationButtons back={props.backward} forward={() => {
          if (answered) {
            props.forward(props.condition);
          }
        }} />
        : null
      }

      <div className="question_header_box">
        <h6>{getText(props.ANS, props.QS, props.qs.title, props.lang)}</h6>
        {props.qs.dsc && props.qs.dsc.length
          ? <div className="drawer_btn" onClick={() => {
            props.showDrawer(props.qs.dsc);
          }}>
            <i className="fas fa-question"></i>
          </div>
          : null
        }
      </div>
      <div className="choice_box">
        {props.qs.answer.options.filter(opt => checkVisibility(opt, props.ANS)).map(item => (
          <React.Fragment key={`option_${item.value}`}>
            <ButtonWithCheck
              withCheckbox={true}
              checked={props.answr.includes(item.value)}
              text={getText(props.ANS, props.QS, item.title, props.lang)}
              handleClick={() => {
                const idx = props.answr.indexOf(item.value);
                if(idx < 0){
                  props.answr.push(item.value);
                } else {
                  props.answr.splice(idx, 1);
                }
                // check effect
                if(item.effect){
                  for(const ef of item.effect){
                    const index = props.answr.indexOf(Math.abs(ef));
                    if (index < 0) {
                      if(ef > 0) props.answr.push(ef);
                    } else if(ef < 0) {
                      props.answr.splice(index, 1);
                    }
                  }
                }
                props.setValue(props.answr);
              }}
            />
          </React.Fragment>
        ))}
      </div>
      <div className="form_next_button_container">
        <Button
          text={t("questions.continue")}
          handleClick={() => handleSubmitClick()}
          disabled={buttonBlocked || props.blocked}
        />
      </div>
    </motion.div>
  );
}
