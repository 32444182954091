import React, {useEffect, useState} from "react";
import "./styles.css";
import {cookieService} from "../../../../services/cookie.service";
import axios from "axios";
import environment from "../../../../environment";
import {Input, notification, Table} from "antd";
import StatusTab from "../../../../components/Admin/StatusTab";
import TaxNumber from "../../../../components/Admin/TaxNumberTable";
import moment from "moment";
import TableAction from "../../../../components/Admin/TableAction";

const { Search } = Input;
const { Column } = Table;


export default function MailedForms() {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalForms, setTotalForms] = useState(0);
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const getFormsData = async (search, limit, skip) => {
    setLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const queryParams = {
      skip: skip || 0,
      limit: limit || 10
    };
    if (search) {
      Object.assign(queryParams, {"search": search});
    }
    const response = await axios.get(`${environment.baseUrl}admin/form/mailedForms`, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      const transformedData = response.data.data.map(dataItem => {
        return {...dataItem, key: dataItem.formId};
      });
      setForms(transformedData);
      setTotalForms(Number(response.data.total));
      setLoading(false);
    }
  };

  useEffect(() => {
    getFormsData();
  }, []);

  const onSearch = (value) => {
    setSearchData(value);
    getFormsData(value, pageLimit, 0);
  };

  const paginate = (val, limit) => {
    if (pageLimit !== limit) {
      setPageLimit(limit);
    }
    const skip = (val - 1) * limit;
    getFormsData(searchData, limit, skip);
    setCurrentPage(val);
  };

  const [selectedIds, setSelectedIds] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedIds(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: onSelectChange,
  };

  const changeIbans = async () => {
    const cookieToken = await new cookieService().getAdminTokenData();
    axios({
      url: `${environment.baseUrl}admin/form/changeIbanBack`,
      method: "POST",
      data: {forms: selectedIds},
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    }).then((response) => {
      getFormsData();
      console.log(response.status);
    }).catch((e) => {
      notification.error(e.response.data.message);
    }).finally(() => setSelectedIds([]));
  };

  return(
    <div>
      <div className="space-between">
        <h3>Paid Forms</h3>
        <Search
          placeholder="Search"
          onSearch={onSearch}
          className="admin_search"
        />
      </div>
      {selectedIds && selectedIds.length > 0
        ? <p
          style={{marginTop: "10px", marginBottom: "10px", cursor: "pointer"}}
          onClick={changeIbans}
        >
          Change Iban
        </p>
        : null
      }
      {loading
        ? <p>Loading</p>
        : forms && forms.length
          ? <>
            <Table
              dataSource={forms}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={{
                current: currentPage,
                pageSize: pageLimit,
                total: totalForms,
                onChange: (val, limit) => paginate(val, limit)
              }}
            >
              <Column title="Id" dataIndex="formId" key="formId" sorter={(a, b) => a.formId > b.formId} />
              <Column title="Name" dataIndex="name" key="name" />
              <Column title="Email" dataIndex="email" key="email" />
              <Column title="Finance Status" render={(data) => {
                return(
                  <StatusTab data={data} />
                );
              }} />
              <Column title="Service Status" render={(data) => {
                return(
                  <StatusTab data={data} type={"service"} />
                );
              }} />
              <Column title="Tax number" render={(data) => {
                return(
                  <TaxNumber data={data} updateTable={() => getFormsData()} />
                );
              }} />
              <Column title="SVN" dataIndex="svn" key="svn" />
              <Column title="Year" dataIndex="year" key="year" />
              <Column title="Amount" render={(data) => {
                return(
                  <p style={{marginBottom: 0}}>{`${data.amount} €`}</p>
                );
              }} />
              <Column title="Created" render={(data) => {
                return(
                  <p style={{marginBottom: 0}}>{moment(data.createdAt).format("DD.MM.YYYY")}</p>
                );
              }} />
              <Column title="Action" render={(data) => {
                return(
                  <TableAction
                    id={data.formId}
                    formStatus={data.status}
                    data={data}
                    updateTable={() => getFormsData()}
                  />
                );
              }} />
            </Table>
          </>
          : <p>No data</p>
      }
    </div>
  );
}
