import React, {useEffect, useState} from "react";
import "./styles.css";
import {cookieService} from "../../../../services/cookie.service";
import axios from "axios";
import environment from "../../../../environment";
import {Input, Table} from "antd";

const { Search } = Input;
const { Column } = Table;

export default function InvitationsPage() {

  const [invitationsData, setInvitationsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getFormsData = async () => {
    setLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const response = await axios.get(`${environment.baseUrl}admin/invitations`, {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    console.log(response);
    if (response && response.data) {
      setLoading(false);
      setInvitationsData(response.data);
    }
  };

  useEffect(() => {
    getFormsData();
  }, []);

  const onSearch = () => {
    console.log("Search");
  };

  return(
    <div>
      <div className="space-between">
        <h3>Einladungen</h3>
        <Search
          placeholder="Search"
          onSearch={onSearch}
          className="admin_search"
        />
      </div>
      {loading
        ? <p>Loading</p>
        : invitationsData.length
          ? <Table dataSource={invitationsData}>
            <Column title="Id" dataIndex="id" key="id" />
            <Column title="Sender Id" dataIndex="senderId" key="senderId" />
            <Column title="Recipient Id" dataIndex="recipientId" key="recipientId" />
            <Column title="Status" dataIndex="status" key="status" />
            <Column title="Created" dataIndex="createdAt" key="createdAt" />
          </Table>
          : <p>No data</p>
      }
    </div>
  );
}
