// Texts for English language
const strings = {
  // General
  login: "Login",
  loginFooter: "Einfach, Schnell, Steuer zurück.",
  months: [
    {
      longName: "January",
      shortName: "Jan"
    },{
      longName: "February",
      shortName: "Feb"
    },{
      longName: "March",
      shortName: "Mar"
    },{
      longName: "April",
      shortName: "Apr"
    },{
      longName: "May",
      shortName: "May"
    },{
      longName: "June",
      shortName: "Jun"
    },{
      longName: "July",
      shortName: "Jul"
    },{
      longName: "August",
      shortName: "Aug"
    },{
      longName: "September",
      shortName: "Sep"
    },{
      longName: "October",
      shortName: "Oct"
    },{
      longName: "November",
      shortName: "Nov"
    },{
      longName: "December",
      shortName: "Dec"
    }
  ],
  from: "From",
  to: "To",
  address: "Address",
  // WelcomeScreen
  taxReturn: "Tax return ?",
  fewClicks: "Only a few clicks needed!",
  haveAccount: "Have an account already ?",
  ofCourse: "Of Course !",
  iAmNew: "I'm new here",
  // LoginScreen
  emailPlaceHolder: "Email",
  emailRequired: "Email address is required",
  passwordPlaceHolder: "Password",
  passwordRequired: "Password must be at least 8 characters including letters",
  passwordForgotten: "Forgot your password ?",
  loginButton: "Login",
  dontHaveAccount: "Don't have an account?",
  // RegisterScreen
  firstNamePlaceHolder: "First Name",
  firstNameRequired: "Required",
  lastNamePlaceHolder: "Last Name",
  lastNameRequired: "Required",
  mobilePlaceHolder: "Mobile Number",
  mobileRequired: "Required",
  signUpButton: "Sign-Up",
  haveAnAccount: "Already have an account ?",

};

export default strings;
