import axios from "axios";
import environment from "../environment";
import {cookieService} from "./cookie.service";
import {handleAsyncErrors} from "../utils/handleAsyncErrors";

export class partnerManagerService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  async getPartnersOfPartnerManager(date, minimum1 = false) {
    try {
      const token = await new cookieService().getTokenData();
      const result = await axios({
        method: "GET",
        url: `${environment.baseUrl}partnermanager/partnersofpartnermanager/${
          token.email
        }/${date ? JSON.stringify(date) : null}/${minimum1}`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        }
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.response.data.message
      };
    }
  }

  async getAllPartnerManagers() {
    try {
      const token = await new cookieService().getAdminTokenData();
      const result = await axios({
        method: "GET",
        url: `${environment.baseUrl}partnermanager/all`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        }
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.response.data.message
      };
    }
  }

  async createNewPartnerManager(data) {
    try {
      const token = await new cookieService().getAdminTokenData();
      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}partnermanager`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        },
        data: data
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.response.data.message
      };
    }
  }

  async getUsersOfPartnerManager(reqData) {
    try {
      const token = await new cookieService().getTokenData();
      const result = await axios({
        method: "GET",
        url: `${environment.baseUrl}partnermanager/usersofpartnermanager/${
          token.email
        }/${JSON.stringify(reqData.dateRange)}/${reqData.skip}/${
          reqData.limit
        }/${JSON.stringify(reqData.filter)}/${JSON.stringify(
          reqData.minimum1
        )}`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        }
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.response.data.message
      };
    }
  }

  async getFormsOfPartnerManager(reqData) {
    try {
      const token = await new cookieService().getTokenData();
      const result = await axios({
        method: "GET",
        url: `${environment.baseUrl}partnermanager/formsofpartnermanager/${
          token.email
        }/${JSON.stringify(reqData.dateRange)}`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        }
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.response.data.message
      };
    }
  }

  async register(reqData) {
    try {
      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}partnermanager/register`,
        data: reqData
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.response.data.message
      };
    }
  }

  async activatePartnerManager(token) {
    try {
      const response = await axios.post(
        `${environment.baseUrl}partnermanager/auth/activatePartnerManager`,
        {
          token: token
        }
      );
      if (response && response.data && response.data.token) {
        return {
          success: true,
          message: "activated",
          token: response.data.token
        };
      }
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: "Error"
        };
      }
    }
  }

  async changePartnerManagerOfPartner(reqData) {
    try {
      const token = await new cookieService().getAdminTokenData();
      const response = await axios({
        method: "post",
        url: `${environment.baseUrl}partnermanager/changePartnermanagerOfPartner`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        },
        data: reqData
      });
      console.log(response);
      if (response && response.data && response.data.token) {
        return {
          success: true,
          message: "updated"
        };
      }
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: "Error"
        };
      }
    }
  }
}
