import React, {useEffect, useState} from "react";
import * as qrcode from "qrcode";
import {isMobile} from "react-device-detect";

export default function ReviewRedirect() {
  const [deviceName, setDeviceName] = useState(null);
  const [code, setCode] = useState(null);

  const getToken = async () => {
    const code = await qrcode.toDataURL("https://app.taxefy.at/review");
    setCode(code);
    if (navigator.userAgent.includes("Windows")) setDeviceName("windows");
    else if (navigator.userAgent.includes("Android")) setDeviceName("android");
    else setDeviceName("apple");
  };

  const handleRedirect = () => {
    console.log(deviceName);
    if (deviceName == null) return;
    if (isMobile == false || deviceName == "windows")
      return (
        <div>
          <h1 style={{color: "rgba(0, 190, 170, 1)"}}>Mit dem Handy weiter - Scanne den Code</h1>
          <img style={{width: "20%"}} src={code} alt="" />
        </div>
      );
    else if (deviceName == "android") {
      window.open("market://details?id=at.taxefy.app&reviewId=0", "_self");
      return <p>Weiter zu PlayStore</p>;
    } else {
      window.open("itms-apps://itunes.apple.com/app/id1567697425?action=write-review", "_self");
      return <p>Weiter zu AppStore</p>;
    }
  };
  useEffect(() => {
    getToken();
  }, []);

  return (
    <div style={{textAlign: "center", paddingTop: "10vh"}}>
      {/* <HashLoader color="gray" size={55} /> */}
      {handleRedirect()}
    </div>
  );
}
