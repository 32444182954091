const CONSTANTS = {
  ON_APP_SET_LANGUAGE: "auth/ON_APP_SET_LANGUAGE",
  ON_APP_SET_ENVIRONMENT: "auth/ON_APP_SET_ENVIRONMENT",
  ON_APP_SET_EMAIL: "auth/ON_APP_SET_EMAIL",
  ON_APP_SET_PASSWORD: "auth/ON_APP_SET_PASSWORD",
  ON_APP_SET_USER: "auth/ON_APP_SET_USER",
  ON_APP_SET_QS: "auth/ON_APP_SET_QS",
  ON_APP_SET_CURRENT_QS: "auth/ON_APP_SET_CURRENT_QS",
  ON_APP_SET_ANS: "auth/ON_APP_SET_ANS",
  SAVED_ANSWERS: "SAVED_ANSWERS",
  STORE_USER_DATA: "STORE_USER_DATA"
};

export { CONSTANTS };
