import { fromJS } from "immutable";

// Constants
import { CONSTANTS } from "./constants";

const initialState = fromJS({
  isLoading: false,
  campaign: null,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case CONSTANTS.ON_APP_LOADING:
    return state.set("isLoading", action.payload.isLoading);
  case CONSTANTS.CAMPAIGN:
    return state.set("campaign", action.payload.campaign);

  default:
    return state;
  }
};

export default reducer;
