import React from "react";
import "./FinalStep.css";
import checkIcon from "../../../assets/images/icons/check_info.png";
import {useTranslation} from "react-i18next";
import Button from "../../CommonComponents/FormButton/FormButton";

export default function InfoContainer(props) {
  const { t } = useTranslation();

  return(
    <div>
      <div className="final_info_row">
        <img src={checkIcon} alt="check"/>
        <p>{t("questions.final_step.info.check_one")}</p>
      </div>
      <div className="final_info_row">
        <img src={checkIcon} alt="check"/>
        <p>{t("questions.final_step.info.check_two")}</p>
      </div>
      <div className="final_info_row">
        <img src={checkIcon} alt="check"/>
        <p>{t("questions.final_step.info.check_three")}</p>
      </div>
      <div className="final_info_row">
        <img src={checkIcon} alt="check"/>
        <p>{t("questions.final_step.info.check_four")}</p>
      </div>
      <Button
        style={{
          marginTop: "20px"
        }}
        text={t("questions.final_step.info.btn")}
        handleClick={() => props.sendForm()}
      />
    </div>
  );
}
