import React, {useEffect, useState} from "react";
import {Modal, notification, Switch, Tooltip} from "antd";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import {useTranslation} from "react-i18next";
import {cookieService} from "../../services/cookie.service";
import axios from "axios";
import environment from "../../environment";
import i18next from "../../i18next";
import ChangePasswordModal from "../../components/Modals/ChangePasswordModal";
import RemoveAccountModal from "../../components/Modals/RemoveAccountModal";
import HashLoader from "react-spinners/HashLoader";

export default function MoreDataTab() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [disableAutomatedTax, setDisabledAutomatedTax] = useState(false);
  const [automatedTax, setAutomatedTax] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showRemoveAccountModal, setShowRemoveAccountModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserData();
  }, []);

  const switchAutomatedTax = async () => {
    setDisabledAutomatedTax(true);
    const cookieToken = await new cookieService().getTokenData();
    try {
      const updateResponse = await axios.put(`${environment.baseUrl}user/switchAutomatedTax`,
        {
          automatedTax: !automatedTax
        },
        {
          headers: {
            Authorization: `Bearer ${cookieToken.refreshToken}`,
            "app-version": "1.0.1"
          }
        });
      if (updateResponse && updateResponse.status === 200) {
        setAutomatedTax(!automatedTax);
        setDisabledAutomatedTax(false);
      } else {
        notification.open({
          message: t("profile.profile_tab.error_toast_title"),
          duration: 2
        });
        setDisabledAutomatedTax(false);
      }
    } catch (e) {
      notification.open({
        message: t("profile.profile_tab.error_toast_title"),
        duration: 2
      });
      setDisabledAutomatedTax(false);
    }
  };

  const getUserData = async () => {
    const cookieToken = await new cookieService().getTokenData();
    const response = await axios.get(`${environment.baseUrl}user`, {
      headers: {
        Authorization: `Bearer ${cookieToken.refreshToken}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      setAutomatedTax(response.data.automatedTax);
      setLoading(false);
    }
  };

  return(
    <div>
      {loading
        ? <div style={{
          width: "100vw",
          height: "100%",
          minHeight: "calc(100vh - 180px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <HashLoader color="#00beaa" size={35} />
        </div>
        :
        <div style={{maxWidth: "600px"}}>
          <Modal
            footer={null}
            title={t("profile.profile_tab.change_password")}
            visible={showChangePasswordModal}
            onCancel={() => setShowChangePasswordModal(false)}
          >
            <ChangePasswordModal
              closeModal={() => setShowChangePasswordModal(false)}
            />
          </Modal>
          <Modal
            footer={null}
            title={t("profile.profile_tab.remove_account")}
            visible={showRemoveAccountModal}
            onCancel={() => {
              setShowRemoveAccountModal(false);
            }}
          >
            <RemoveAccountModal
              closeModal={() => setShowRemoveAccountModal(false)}
            />
          </Modal>
          <div className="switch_row">
            <Switch
              disabled={disableAutomatedTax}
              checked={automatedTax}
              onChange={() => switchAutomatedTax()}
            />
            <p>{t("profile.profile_tab.automated_tax")}</p>
            <Tooltip title={t("profile.profile_tab.automated_tax_tooltip")}>
              <i className="far fa-question-circle"></i>
            </Tooltip>
          </div>

          <div className="profile_buttons_grid">
            <Button
              className="secondary_button"
              text={t("profile.profile_tab.change_password")}
              handleClick={() => setShowChangePasswordModal(true)}
            />

            <Button
              className="secondary_button"
              text={t("profile.profile_tab.remove_account")}
              handleClick={() => setShowRemoveAccountModal(true)}
            />
          </div>
        </div>
      }
    </div>
  );
}
