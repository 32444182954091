import axios from "axios";
import environment from "../environment";
import {cookieService} from "./cookie.service";
import {handleAsyncErrors} from "../utils/handleAsyncErrors";

export class consultantService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  async getConsultantData() {
    try {
      const token = await new cookieService().getTokenData();
      const result = await axios.get(`${environment.baseUrl}consultant/settings/consultantData`, {
        headers: {
          Authorization: "Bearer " + token.token
        }
      });
      return {
        ...result.data
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: JSON.parse(e.request.response).message
        };
      }
    }
  }

  async createConsultant(data) {
    try {
      const token = await new cookieService().getAdminTokenData();
      await axios.post(
        `${environment.baseUrl}consultant/settings/consultantData`,
        {
          ...data
        },
        {
          headers: {
            Authorization: "Bearer " + token.token
          }
        }
      );
      console.log("success");

      return {
        success: true
      };
    } catch (e) {
      console.log(e);
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: JSON.parse(e.request.response).message
        };
      }
    }
  }

  async getAllConsultantData() {
    try {
      const token = await new cookieService().getAdminTokenData();
      const result = await axios.get(
        `${environment.baseUrl}consultant/settings/allConsultantData`,
        {
          headers: {
            Authorization: "Bearer " + token.token
          }
        }
      );
      return result.data.returnObj;
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: JSON.parse(e.request.response).message
        };
      }
    }
  }

  async adminUpdateConsultantData(data) {
    try {
      const token = await new cookieService().getAdminTokenData();
      await axios.patch(`${environment.baseUrl}consultant/settings/adminConsultantData`, {
        ...data,
        token: token.token
      });
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: JSON.parse(e.request.response).message
        };
      }
    }
  }

  async updateConsultantData(data) {
    try {
      const token = await new cookieService().getTokenData();
      await axios.patch(
        `${environment.baseUrl}consultant/settings/consultantData`,
        {
          ...data
        },
        {
          headers: {
            Authorization: "Bearer " + token.token
          }
        }
      );
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: JSON.parse(e.request.response).message
        };
      }
    }
  }

  async getConsultantCases() {
    try {
      const token = await new cookieService().getAdminTokenData();
      const result = await axios.get(`${environment.baseUrl}consultant/settings/consultantCases`, {
        headers: {
          Authorization: "Bearer " + token.token
        }
      });
      return result.data;
    } catch (e) {
      return [];
    }
  }

  async getConsultantCasesData(reqData) {
    try {
      const token = await new cookieService().getTokenData();
      const result = await axios({
        url: `${environment.baseUrl}consultant/settings/consultantForms`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.token
        },
        params: reqData
      });
      console.log(result);
      return result.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async getEnabledConsultants() {
    try {
      const token = await new cookieService().getAdminTokenData();
      if (!token) return [];
      const result = await axios.get(`${environment.baseUrl}consultant/settings/consultantCases`, {
        headers: {
          Authorization: "Bearer " + token.token
        }
      });

      const filteredData = result.data
        .filter(el => el.status == 1)
        .map(el => {
          return {
            key: result.data.indexOf(el),
            name: el.name,
            email: el.email,
            priority: el.priority,
            quota: `${el.count}/${el.max_per_day}`
          };
        });
      return filteredData;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async assignConsultant(req) {
    try {
      const token = await new cookieService().getAdminTokenData();
      const result = await axios.patch(`${environment.baseUrl}consultant/forms/assignConsultant`,req,{
        headers: {
          Authorization: "Bearer " + token.token
        }
      });
      console.log(result);
      return {
        success: true
      };
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        return {
          success: false,
          message: JSON.parse(e.request.response).message
        };
      }
    }
  }

  async sendToFObyConsultant(formId, email, token) {
    try {
      // const result = {
      //   status: 200,
      //   message: "ok"
      // };
      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}consultant/forms/sendToFObyConsultant`,
        headers: {
          Authorization: "Bearer " + token,
          "app-version": "1.0.1"
        },
        data: {
          formId: formId,
          email: email,
          token: token
        }
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.response.data.message
      };
    }
  }
  async updatePOAbyConsultant(req) {
    try {
      const token = await new cookieService().getTokenData();
      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}consultant/forms/updatePOAbyConsultant`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        },
        data: req
      });
      console.log(result);
      return {
        success: true
      };
    } catch (e) {
      return {
        status: false,
        message: e.response.data.message
      };
    }
  }
  async getVeriffImagesByConsultant(data) {
    try {
      const token = await new cookieService().getTokenData();

      const result = await axios({
        method: "GET",
        url: `${environment.baseUrl}consultant/forms/veriffImagesByConsultant/${data}`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        },
        responseType: "blob"
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.toString()
      };
    }
  }

  async denyFormByConsultant(id, comment, token) {
    try {
      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}consultant/forms/denyByConsultant`,
        headers: {
          Authorization: "Bearer " + token,
          "app-version": "1.0.1"
        },
        data: {
          formId: id,
          comment: comment
        }
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.toString()
      };
    }
  }

  async assignMultipleToConsultant(req) {
    try {
      const token = await new cookieService().getAdminTokenData();
      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}consultant/forms/assignMultipleToConsultant`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        },
        data: req
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      console.log(e);
      return {
        status: false,
        ...e
      };
    }
  }

  async getFormDataById(req, admin = false) {
    try {
      const token = admin
        ? await new cookieService().getAdminTokenData()
        : await new cookieService().getTokenData();
      const result = await axios({
        method: "GET",
        url: `${environment.baseUrl}consultant/forms/getFormDataByID/${req}`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        },
        data: req
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.toString()
      };
    }
  }

  async finishANVcontrol(req, comment) {
    try {
      const token = await new cookieService().getTokenData();
      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}consultant/forms/finishANVcontrol`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        },
        data: {formId: req, comment: comment}
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.toString()
      };
    }
  }

  async sendMultipleToFObyConsultant(ids) {
    try {
      const token = await new cookieService().getTokenData();
      // const result = {
      //   data: {message: "mocked ok", errors: []}
      // };
      const result = await axios({
        method: "POST",
        url: `${environment.baseUrl}consultant/forms/sendMultipleToFinanzOnline`,
        headers: {
          Authorization: "Bearer " + token.token,
          "app-version": "1.0.1"
        },
        data: {formIds: ids}
      });
      console.log(result);
      return {
        success: true,
        data: result.data
      };
    } catch (e) {
      return {
        status: false,
        message: e.toString()
      };
    }
  }

  async downloadZIP(formId) {
    try {
      const cookieToken = await new cookieService().getTokenData();

      const result = await axios({
        url: `${environment.baseUrl}admin/form/download/${formId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        },
        responseType: "blob" // important
      });
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "files.zip");
      document.body.appendChild(link);
      link.click();
      return {success: true};
    } catch (err) {
      return {
        success: false,
        message: err
      };
    }
  }
}
