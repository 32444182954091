import React, {useEffect, useState} from "react";
import "./styles.css";
import {cookieService} from "../../../../services/cookie.service";
import axios from "axios";
import environment from "../../../../environment";
import {DatePicker, Input, InputNumber, Table, Button} from "antd";
import StatusTab from "../../../../components/Admin/StatusTab";
import TaxNumber from "../../../../components/Admin/TaxNumberTable";
import TableAction from "../../../../components/Admin/TableAction";
import moment from "moment";
import ConsultantCases from "./ConsultantCases";
import {formService} from "../../../../services/form.service";
import {Modal, Select} from "antd";
import {consultantService} from "../../../../services/consultant.service";
import TaxefyTable from "../../../../components/TaxefyComponents/TaxefyTable";
import {useTranslation} from "react-i18next";
import UserAnvView from "../../../../components/Consultant/UserAnvView";
import {CloseCircleOutlined} from "@ant-design/icons";
import getAppCountry from "../../../../utils/getAppCountry";

const {Search} = Input;
const {Column} = Table;

export default function AllForms() {
  const {t, i18n} = useTranslation();
  const [forms, setForms] = useState([]);
  const [totalForms, setTotalForms] = useState(0);
  const [assignSuccess, setAssignSuccess] = useState(false);
  const [poaORcontract, setPoaORcontract] = useState("Power of Attorney");
  const [showTermination, setShowTermination] = useState(false);
  const [terminationId, setTerminationId] = useState("");
  const [terminationValue, setTerminationValue] = useState(false);
  const [terminationDate, setTerminationDate] = useState(null);
  const [currentTerminationData, setCurrentTerminationData] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [availableConsultants, setAvailableConsultants] = useState([]);
  const [showMultiAssign, setShowMultiAssign] = useState(false);

  const [sortFields, setSortFields] = useState(null);
  const [filters, setFilters] = useState(null);
  const [fromId, setFromId] = useState(0);
  const [toId, setToId] = useState(1000);
  const [datePickerdate, setDatePickerDate] = useState("");
  const [searchData, setSearchData] = useState("");
  const [actionRow, setActionRow] = useState(<div></div>);
  const [ANVform, setANVform] = useState(null);
  const [ANVmodal, setANVmodal] = useState(false);
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    getAvailableConsultants();
    getFormsData();
  }, []);

  // useEffect(() => {
  //   getFormsData();
  // }, [searchData]);

  useEffect(() => {
    setActionRow(() => taxefyActionRow());
  }, [selectedRows]);

  useEffect(() => {
    setAssignSuccess(false);
  }, [assignSuccess]);

  const getAvailableConsultants = async () => {
    const result = await new consultantService().getAllConsultantData();
    console.log(result);
    if (result.success === false) return setErrorList(old => [...old, generateError(result)]);
    setAvailableConsultants(() =>
      result?.map((el, ind) => ({
        key: "cons-" + ind,
        consultantId: el.consultant_user_id,
        name: el.firstName + " " + el.lastName,
        email: el.email
      }))
    );
  };

  const generateError = error => {
    console.log(error?.request);
    const status = error?.request ? error?.request.status : error.status;
    const text = error?.request.response.includes("message")
      ? JSON.parse(error?.request.response).message
      : error?.request.response;
    return (
      <div
        style={{
          margin: "10px",
          padding: "5px",
          borderRadius: "15px",
          color: "white",
          backgroundColor: "red",
          textAlign: "center",
          userSelect: "none"
        }}
        onClick={event => {
          console.log(event);
          if (event.target.localName == "div") event.target.className += "hidden_element";
          else {
            if (event.target.parentNode.localName == "div")
              event.target.parentNode.className += "hidden_element";
            else event.target.parentNode.parentNode.className += "hidden_element";
          }
        }}
      >
        <span style={{marginBottom: "5px"}}>
          {status} - {text}
        </span>
        <CloseCircleOutlined style={{marginLeft: "20px", fontSize: "20px"}} />
      </div>
    );
  };

  // get forms
  const getFormsData = async (search, limit, skip) => {
    try {
      console.log(filters);
      setActionRow(null);
      setForms(null);
      const cookieToken = await new cookieService().getAdminTokenData();
      const queryParams = {
        minId: fromId,
        maxId: toId,
        search: searchData,
        dateRange: datePickerdate || "",
        sorter: sortFields?.filter(el => el.activated) || [],
        filters: filters?.filter(el => el.activated)
      };

      console.log(queryParams);
      if (search) {
        Object.assign(queryParams, {search: search});
      }
      const response = await axios.get(`${environment.baseUrl}admin/form`, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      });
      if (response && response.data) {
        setForms(
          response.data.data.map((el, index) => ({key: index + "-" + new Date().getTime(), ...el}))
        );
        setFilters(() => response.data.allowedFilters);
        setSortFields(() =>
          response.data.allowedSort.map((el, id) => ({
            name: el,
            activated: false,
            order: id,
            direction: "asc"
          }))
        );
        setTotalForms(Number(response.data.all));
      }
    } catch (err) {
      return setErrorList(old => [...old, generateError(err)]);
    }
  };

  const downloadZIP = async () => {
    const result = await new formService().downloadZIP(selectedRows[0]);
    if (result && !result.success) return setErrorList(old => [...old, generateError(result)]);

    console.log(result);
  };

  const taxefyActionRow = () => {
    if (!selectedRows.length) return <div></div>;
    return (
      <div
        style={{
          border: "solid 1px rgba(0, 190, 170, 1)",
          padding: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "15px"
        }}
        id="TAXEFY_ACTION_ROW"
      >
        {selectedRows.length >= 1 && (
          <>
            <Button
              onClick={() => {
                console.log(availableConsultants);
                setShowMultiAssign(true);
              }}
              style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)"}}
            >
              {t("adminPanel.allForms.assignToConsultant")}
            </Button>
          </>
        )}
        {selectedRows.length == 1 && (
          <div>
            <Button
              onClick={() => downloadZIP()}
              style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)"}}
            >
              {t("adminPanel.allForms.downloadZIP")}
            </Button>
            <Button
              onClick={() => {
                getFormDataById();
                setANVmodal(() => true);
              }}
              style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)"}}
            >
              {t("adminPanel.allForms.showANV")}
            </Button>
            <Button
              onClick={() => {
                const temp = forms.find(el => el.form_id == selectedRows[0]);
                showTerminationModal(temp, "Power of Attorney");
              }}
              style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)"}}
            >
              {t("adminPanel.allForms.terminationPoA")}
            </Button>
            <Button
              onClick={() => {
                const temp = forms.find(el => el.form_id == selectedRows[0]);
                showTerminationModal(temp, "Contract");
              }}
              style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)"}}
            >
              {t("adminPanel.allForms.terminationContract")}
            </Button>
            <Button disabled style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)"}}>
              {t("adminPanel.allForms.sendEmail")}
            </Button>
            <Button disabled style={{color: "white", marginLeft: "30px", backgroundColor: "red"}}>
              {t("adminPanel.allForms.deleteForm")}
            </Button>
          </div>
        )}
      </div>
    );
  };

  const showTerminationModal = (record = null, type = null) => {
    if (record && type) {
      console.log(record);
      setTerminationId(record.form_id);
      setPoaORcontract(type);
      setCurrentTerminationData(record);
      type == "Power of Attorney"
        ? setTerminationValue(record.poa_terminated.data)
        : setTerminationValue(record.contract_terminated.data);
      type == "Power of Attorney"
        ? setTerminationDate(record.poa_terminated.date)
        : setTerminationDate(record.contract_terminated.date);
    }
    setShowTermination(!showTermination);
  };

  const getFormDataById = async () => {
    if (ANVform && selectedRows[0] == ANVform.form_id) return;
    const result = await new consultantService().getFormDataById(selectedRows[0], true);
    if (!result.success) return setErrorList(old => [...old, generateError(result)]);
    setANVform(() => result.data);
  };

  const updateTerminations = async () => {
    const requestObj = {
      formId: terminationId,
      terminated: terminationValue,
      termination_date: terminationDate == "null" ? null : terminationValue ? terminationDate : null
    };
    let result = null;
    console.log(requestObj);
    if (poaORcontract == "Power of Attorney") {
      console.log("here");
      result = await new formService().updatePOAtermination(requestObj);
    } else {
      result = await new formService().updateContractTermination(requestObj);
    }
    console.log(result);
    if (result.status != 200) return setErrorList(old => [...old, generateError(result)]);
    else {
      setShowTermination(false);
      getFormsData(searchData);
    }
  };

  const radioSelect = {
    type: "radio",
    onChange: (selectedKeys, selectedRows) => {
      console.log(selectedRows[0]);
      setSelectedConsultant(selectedRows[0]);
    }
  };

  const assignMultipleToConsultant = async () => {
    if (selectedConsultant == null)
      return setErrorList(old => [
        ...old,
        generateError({
          request: {
            status: 500,
            response: "No Consultant Selected"
          }
        })
      ]);
    console.log(selectedConsultant);
    const reqBody = {
      consultantId: selectedConsultant.consultantId,
      forms: [...selectedRows]
    };
    console.log(reqBody);
    const result = await new consultantService().assignMultipleToConsultant(reqBody);
    console.log("RESULT:", result);
    if (!result.success) return setErrorList(old => [...old, generateError(result)]);
    setShowMultiAssign(false);
    setActionRow(null);
    getFormsData(selectedRows[0]);
    setSelectedRows([]);
  };

  return (
    <div>
      <p>All Forms: {totalForms}</p>
      <Modal
        footer={null}
        title={`Update ${poaORcontract} Termination`}
        visible={showTermination}
        closable={false}
        width={"20%"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            marginBottom: "30px",
            marginLeft: "10%"
          }}
        >
          <div>
            <label>Form-ID</label>
            <input value={terminationId} className="terminationInputs" type="text" disabled />
          </div>
          <div>
            <label>Terminated</label>
            <input
              checked={terminationValue}
              className="terminationInputs"
              onChange={e => {
                setTerminationValue(e.target.checked);
              }}
              type="checkbox"
            />
          </div>
          <div>
            <label>Termination-Date</label>
            <input
              value={terminationDate}
              className="terminationInputs"
              onChange={e => {
                setTerminationDate(e.target.value);
              }}
              type="date"
            />
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "space-around"}}>
          <Button onClick={() => updateTerminations()}>Yes</Button>
          <Button
            style={{backgroundColor: "red", color: "white"}}
            onClick={() => setShowTermination(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        footer={null}
        title={t("adminPanel.allForms.anvModalTitle")}
        visible={ANVmodal}
        onCancel={() => setANVmodal(() => false)}
        closable={false}
        width={"40%"}
      >
        <UserAnvView data={ANVform} />
      </Modal>

      <Modal
        footer={null}
        title={`Assign ${selectedRows.length} forms to consultant`}
        visible={showMultiAssign}
        closable={false}
        width={"40%"}
      >
        <Table
          dataSource={availableConsultants}
          rowSelection={radioSelect}
          pagination={{position: ["none", "none"]}}
        >
          <Column title="Name" dataIndex="name" key="cons-name" />
          <Column title="Email" dataIndex="email" key="cons-email" />
        </Table>
        <div className="space-between" style={{marginTop: "30px"}}>
          <Button
            style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)"}}
            onClick={() => assignMultipleToConsultant()}
          >
            {t("adminPanel.allForms.assignToConsultant")}
          </Button>
          <Button
            style={{color: "white", backgroundColor: "red"}}
            onClick={() => setShowMultiAssign(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <div
        style={{
          width: "45%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "baseline"
        }}
      >
        <span>From ID : </span>
        <InputNumber
          controls={false}
          min={0}
          defaultValue={0}
          formatter={value => `${value.replace(/(\d)(?=(\d{3})+$)/g, "$1.")}`}
          value={fromId}
          parser={value => value?.replace(".", "")}
          onChange={value => setFromId(value)}
        />
        <span style={{marginLeft: "20px"}}>To ID : </span>
        <InputNumber
          controls={false}
          min={1000}
          defaultValue={1000}
          formatter={value => `${value.replace(/(\d)(?=(\d{3})+$)/g, "$1.")}`}
          value={toId}
          parser={value => value?.replace(".", "")}
          onChange={value => setToId(value)}
        />
        <DatePicker.RangePicker
          style={{marginLeft: "10px"}}
          value={datePickerdate}
          onChange={value => setDatePickerDate(value)}
        />
      </div>
      <TaxefyTable
        data={forms}
        sortFields={sortFields}
        filters={filters}
        onSort={values => setSortFields(() => values)}
        onSelect={values => setSelectedRows(values)}
        onFilter={values =>
          setFilters(() => {
            console.log("FILTER CHANGE", filters);
            return filters.map(el => {
              const temp = values.includes(el.id);
              if (temp) el.activated = true;
              else el.activated = false;
              return el;
            });
          })
        }
        onSearch={value => {
          setSearchData(value);
        }}
        onReload={() => getFormsData()}
        options={[
          {
            type: "columnGroups",
            data: [
              {
                name: "User Daten",
                columns: ["name", "email", "taxNumber", "svn", "device", "verified"]
              },
              {
                name: "Formular  Daten",
                columns: [
                  "form_id",
                  "consultant_control_group",
                  "createdat",
                  "send_date",
                  "amount",
                  "year",
                  "consultant_email",
                  "status"
                ]
              },
              {
                name: "Vertrags Daten",
                columns: [
                  "poa_terminated",
                  "poa_terminated_timestamp",
                  "contract_terminated",
                  "contract_terminated_timestamp",
                  "decision"
                ]
              }
            ]
          },
          {
            type: "createdat",
            renderType: "date"
          },
          {
            type: "consultant_control_group",
            renderType: "list"
          },
          {
            type: "send_date",
            renderType: "date"
          },
          {
            type: "verified",
            renderType: "checkmark"
          },
          {
            type: "decision",
            renderType: "status"
          },
          {
            type: "status",
            renderType: "status"
          },
          {
            type: "poa_terminated_timestamp",
            renderType: "date"
          },
          {
            type: "contract_terminated_timestamp",
            renderType: "date"
          },
          {
            type: "poa_terminated",
            renderType: "checkmark"
          },
          {
            type: "contract_terminated",
            renderType: "checkmark"
          }
        ]}
        namespace="adminPanel.allForms."
      />
      {actionRow}
      <div style={{display: "flex", width: "100%"}}>{errorList}</div>
    </div>
  );
}
