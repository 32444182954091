import request from "superagent";
import { config, ENVIRONMENTS } from "../utils/constants";
import { store } from "../configureStore";

export const adminUrls = {
  form: {
    download: process.env.REACT_APP_FORM_DOWNLOAD_URL
  }
};

const req = (url, method, headers, data) =>
  new Promise((resolve, reject) => {
    request(method.toLowerCase(), url)
      .set(headers)
      .set("Accept", "application/json")
      .send(data)
      .then(res => resolve(res)).catch(error => reject(error.message));
  });

const api = (route, method, data = {}, absolutePath = false, headers = {}) => {

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {

    const env = store.getState().app.get("env") || ENVIRONMENTS.PROD;
    const url = `${absolutePath ? "" : config[env].API_URL}${route}`;
    // console.log(env, url);

    const res = await req(url, method, headers, data).catch(error => reject(error));
    if (!res) {
      reject("API Error");
      return;
    }
    if (res.status >= 300) {
      const errorMessage = res.body.message
        ? res.body.message
        : res.body.errorMessage
          ? res.body.errorMessage
          : "";

      reject(errorMessage || "Server error");
      return;
    }
    resolve(res);
  });
};

const errorHandler = error => {
  console.log(error);
};

const formatPattern = (url, params) => {
  let replacedUrl = url;
  Object.keys(params).map(key => {
    replacedUrl = replacedUrl.replace(`:${key}`, encodeURIComponent(params[key]));
    return null;
  });
  return replacedUrl;
};

export { api, formatPattern, errorHandler };
