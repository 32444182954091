import React, {useEffect, useState} from "react";
import HashLoader from "react-spinners/HashLoader";
import {useLocation} from "react-router-dom";
import {isMobile} from "react-device-detect";
import * as qrcode from "qrcode";

export default function DeeplinkRedirectPage() {
  const location = useLocation();
  const [code, setCode] = useState(null);

  const getToken = async () => {
    const deeplink = location.search.split("url=")[1];
    if (!isMobile) {
      const temp = await qrcode.toDataURL(deeplink);
      setCode(temp);
    } else {
      window.open(deeplink, "_self");
    }
  };
  useEffect(() => {
    getToken();
  }, []);

  return (
    <div style={{textAlign: "center", paddingTop: "20vh"}}>
      {isMobile && <HashLoader color="gray" size={55} />}
      {!isMobile && (
        <div>
          <h1 style={{color: "rgba(0, 190, 170, 1)"}}>Weiter mit dem Handy - Scanne den QR Code</h1>
          <img src={code} style={{margin: "20px", width: "20%"}} alt="" />
        </div>
      )}
    </div>
  );
}
