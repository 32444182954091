import React, {useState} from "react";
import {Menu, Dropdown, notification} from "antd";
import { Select } from "antd";
import HashLoader from "react-spinners/HashLoader";
import {cookieService} from "../../services/cookie.service";
import axios from "axios";
import environment from "../../environment";

const { Option } = Select;

export default function UsersTableAction(props) {

  const [loading, setLoading] = useState(false);

  const activateUser = async () => {
    setLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const response = await axios.put(`${environment.baseUrl}admin/users/activate`,
      {
        userId: props.userId
      },
      {
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      });
    if (response?.data?.message === "activated") {
      setLoading(false);
      notification.open({
        message: "User has been activated",
        duration: 2
      });
      props.refreshData();
    }
  };
  //

  const sendActivationEmail = async () => {
    setLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const response = await axios.post(`${environment.baseUrl}admin/users/sendActivationEmail`,
      {
        userId: props.userId
      },
      {
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      });
    if (response?.data?.message === "sent") {
      setLoading(false);
      notification.open({
        message: "Activation email was sent",
        duration: 2
      });
    }
  };

  const menu = (
    <>
      <Menu>
        <Menu.Item key="activate" onClick={() => activateUser()}>
          <p>Activate user</p>
        </Menu.Item>
        <Menu.Item key="sendActivationEmail" onClick={() => sendActivationEmail()}>
          <p>Send activation email</p>
        </Menu.Item>
      </Menu>
    </>
  );

  return(
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      {loading
        ? <HashLoader size={20} />
        : <div className="action_dots">
          <div className="action_dot" />
          <div className="action_dot" />
          <div className="action_dot" />
        </div>
      }
    </Dropdown>
  );
}
