import Cookies from "universal-cookie";

export class cookieService {
  cookies;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {
    this.cookies = new Cookies();
  }

  // Save token to cookie
  async setTokenData(tokenData) {
    this.cookies.set("tokenData", JSON.stringify(tokenData), {sameSite: true, path: "/"});
  }

  // Save Admin token to cookie
  async setAdminTokenData(tokenData) {
    this.cookies.set("adminTokenData", JSON.stringify(tokenData), {sameSite: true, path: "/"});
  }

  // Get token to cookie
  async getTokenData() {
    const tokenData = this.cookies.get("tokenData");
    if (tokenData) {
      return tokenData;
    } else {
      return false;
    }
  }

  // Get Admin token to cookie
  async getAdminTokenData() {
    const tokenData = this.cookies.get("adminTokenData");
    if (tokenData) {
      return tokenData;
    } else {
      return false;
    }
  }

  // Delete token from cookie
  async removeTokenData() {
    window.location.reload();
    await this.cookies.remove("tokenData", {path: "/"});
  }

  // Delete admin token from cookie
  async removeAdminTokenData() {
    await this.cookies.remove("adminTokenData", {path: "/"});
  }
}
