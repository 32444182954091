import React, {useState} from "react";
import "./QuestionComponentsStyles.css";
import {getText, validateText} from "../../utils/tools";
import Button from "../CommonComponents/FormButton/FormButton";
import {motion} from "framer-motion";
import NavigationButtons from "./NavigationButtons/NavigationButtons";
import { Input } from "antd";
import { InputNumber } from "antd";
import {useTranslation} from "react-i18next";

export default function InputComponent(props) {
  const { t } = useTranslation();

  const [buttonBlocked, setButtonBlocked] = useState(false);
  const [answered, setAnswered] = useState(false);

  const handleSubmit = () => {
    if (!buttonBlocked) {
      setButtonBlocked(true);
      if (!props.blocked) {
        setAnswered(true);
        props.forward(props.condition);
      } else {
        props.showError(props.condition.abort_code);
      }
      setTimeout(() => {
        setButtonBlocked(false);
      }, 500);
    }
  };

  return(
    <motion.form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      className="single_choice"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{
        delay: 0.3,
        x: { type: "spring", stiffness: 100 },
        default: { duration: 1 },
      }}
    >

      {props.showButtonBack
        ? <NavigationButtons back={props.backward} forward={() => {
          if (answered) {
            props.forward(props.condition);
          }
        }} />
        : null
      }

      <div className="question_header_box">
        <h6>{getText(props.ANS, props.QS, props.qs.title, props.lang)}</h6>
        {props.qs.dsc && props.qs.dsc.length
          ? <div className="drawer_btn" onClick={() => {
            props.showDrawer(props.qs.dsc);
          }}>
            <i className="fas fa-question"></i>
          </div>
          : null
        }
      </div>
      {props.qs.answer.data_type === "salary"
        ? <InputNumber
          className="input_component number"
          value={props.answr}
          onChange={value => {
            if (props.qs.answer.data_type === "salary" ) {
              if (value > 0) {
                if(validateText(value.toString(), props.qs.answer.data_type)){
                  props.setValue(value.toString());
                }
              } else {
                props.setValue("0");
              }
            } else {
              if(validateText(value, props.qs.answer.data_type)){
                props.setValue(value);
              }
            }
          }}
        />
        : <Input
          placeholder={getText(props.ANS, props.QS, props.qs.placeholder, props.lang)}
          className="input_component"
          value={props.answr}
          onChange={e => {
            if (props.qs.answer.data_type === "salary" ) {
              if (e.target.value && e.target.value > 0) {
                if(validateText(e.target.value.toString(), props.qs.answer.data_type)){
                  props.setValue(e.target.value.toString());
                }
              } else {
                props.setValue("0");
              }
            } else {
              if(validateText(e.target.value, props.qs.answer.data_type)){
                props.setValue(e.target.value);
              }
            }
          }}
        />
      }
      <Button
        text={t("questions.continue")}
        disabled={buttonBlocked || props.blocked}
        style={{
          marginTop: "30px"
        }}
        handleClick={() => handleSubmit()}
      />
    </motion.form>
  );
}
