import React from "react";
import "../styles.css";

export default function AgeChart(props) {

  return(
    <div className="age_chart">
      <h3 style={{marginBottom: "50px"}}>Kundenalter</h3>
      <div className="age_chart_box">
        <h5>18-24</h5>
        <div className="age_chart_line_box">
          <div className="age_chart_line" style={{width: `${props.data["18-24"]}%`}} />
        </div>
        <p>{props.data["18-24"].toFixed(2)}%</p>
      </div>

      <div className="age_chart_box">
        <h5>25-34</h5>
        <div className="age_chart_line_box">
          <div className="age_chart_line" style={{width: `${props.data["25-34"]}%`}} />
        </div>
        <p>{props.data["25-34"].toFixed(2)}%</p>
      </div>

      <div className="age_chart_box">
        <h5>35-44</h5>
        <div className="age_chart_line_box">
          <div className="age_chart_line" style={{width: `${props.data["35-44"]}%`}} />
        </div>
        <p>{props.data["35-44"].toFixed(2)}%</p>
      </div>

      <div className="age_chart_box">
        <h5>45-64</h5>
        <div className="age_chart_line_box">
          <div className="age_chart_line" style={{width: `${props.data["45-64"]}%`}} />
        </div>
        <p>{props.data["45-64"].toFixed(2)}%</p>
      </div>

      <div className="age_chart_box">
        <h5>65+</h5>
        <div className="age_chart_line_box">
          <div className="age_chart_line" style={{width: `${props.data["65+"]}%`}} />
        </div>
        <p>{props.data["65+"].toFixed(2)}%</p>
      </div>

      <div className="age_description_row">
        <div />
        <p>Kunden mit mindestens einer Steuererklärung</p>
      </div>

    </div>
  );
}
