import React, {useEffect, useState} from "react";
import "./Header.css";
import logoImage from "../../img/taxefy_light_logo.png";
import { Modal, Button } from "antd";
import RegionsAndLanguagesModal from "../Modals/RegionsAndLanguagesModal";
import {useTranslation} from "react-i18next";
import {getAnsSelector, getLanguageSelector, getQsSelector} from "../../data/app/selectors";
import {connect} from "react-redux";
import { Popover } from "antd";
import HeaderMenu from "./HeaderMenu";
import {calculateTax, getFinalNumber} from "../../utils/tools";
import CountUp from "react-countup";
import {useHistory} from "react-router-dom";

function Header({QS, language, ANS, showCalculations, currentComponent}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [showMenu, setShowMenu] = useState(false);

  const [previousValue, setPreviousValue] = useState(0);

  useEffect(() => {
    if (showCalculations) {
      setTimeout(() => {
        setPreviousValue(getFinalNumber(calculateTax(QS, ANS, language)));
      }, 500);
    }
  }, [ANS]);

  return(
    <header className="header">
      <Modal
        footer={null}
        title={t("header.regions_modal.title")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <RegionsAndLanguagesModal
          langs={QS && QS.languages && QS.languages}
          selectedLang={language}
          closeModal={handleCancel}
        />
      </Modal>
      <img onClick={() => history.push("/")} src={logoImage} alt="taxefy"/>
      {showCalculations && currentComponent !== "countries"
        ? <p className="header_calculation">
          <CountUp
            start={previousValue}
            end={getFinalNumber(calculateTax(QS, ANS, language))}
            duration={1.5}
            formattingFn={(value) => {
              return `${value.toFixed(2)} €`;
            }}
          />
        </p>
        : null
      }
      <div className="header_menu">
        <div className="region_row" onClick={() => showModal()}>
          <p>{t(`languages.${language}.short`).toUpperCase()}</p>
          <i className="fas fa-globe globe_icon"></i>
        </div>

        <Popover
          style={{borderRadius: "10px"}}
          content={<HeaderMenu />}
          trigger="click"
          visible={showMenu}
          onVisibleChange={() => setShowMenu(!showMenu)}
        >
          <i className="fas fa-bars header_burger"></i>
        </Popover>
      </div>
    </header>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    QS: getQsSelector(state),
    ANS: getAnsSelector(state),
    language: getLanguageSelector(state)
  };
};

export default connect(mapStateToProps, null)(Header);
