import {cookieService} from "./cookie.service";
import axios from "axios";
import environment from "../environment";

export class partnersService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  async create(data) {
    const token = await new cookieService().getTokenData();
    return axios
      .post(`${environment.baseUrl}partners`, data, {
        headers: {
          Authorization: `Bearer ${token.token}`,
          "app-version": "1.0.1"
        }
      })
      .then(response => {
        if (response && response.status === 200) {
          return {
            status: true,
            data: response.data
          };
        } else {
          return {
            status: false
          };
        }
      })
      .catch(e => {
        console.error(e);
        return {
          status: false
        };
      });
  }

  async get(data) {
	  const token = await new cookieService().getAdminTokenData();
    return axios
      .get(`${environment.baseUrl}partners`, {
        headers: {
          Authorization: `Bearer ${token.token}`,
          "app-version": "1.0.1"
        },
        params: data
      })
      .then(response => {
        return {
          status: true,
          data: response.data
        };
      })
      .catch(e => {
        console.error(e);
        return {
          status: false
        };
      });
  }

  async getPartnerUsers(data) {
    const token = await new cookieService().getTokenData();
    return axios
      .get(`${environment.baseUrl}partners/users`, {
        headers: {
          Authorization: `Bearer ${token.refreshToken}`,
          "app-version": "1.0.1"
        },
        params: data
      })
      .then(response => {
        return {
          status: true,
          data: response.data
        };
      })
      .catch(e => {
        console.error(e);
        return {
          status: false
        };
      });
  }

  async getPartnerInfo(id) {
    const token = await new cookieService().getTokenData();
    return axios
      .get(`${environment.baseUrl}partners/${id}`, {
        headers: {
          Authorization: `Bearer ${token.refreshToken}`,
          "app-version": "1.0.1"
        }
      })
      .then(response => {
        return {
          status: true,
          data: response.data
        };
      })
      .catch(e => {
        console.error(e);
        return {
          status: false
        };
      });
  }

  async getPartnerLink(id) {
    try {
      const response = await axios.get(`${environment.baseUrl}partners/partnerlink/${id}`, {
        headers: {
          "app-version": "1.0.1"
        }
      });
      return response;
    } catch (err) {
      console.log("PARTNER-REDIRECT-ERROR:", err);
    }
  }
}
