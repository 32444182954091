import React, {useEffect} from "react";
import "./PartnerManagerPanelLayout.css";
import {useLocation} from "react-router-dom";
import {authService} from "../../services/auth.service";
import {Layout} from "antd";
import {Switch, Route, Redirect} from "react-router-dom";
import PartnerManagerSidebar from "../../components/PartnerManager/PartnerManagerSidebar";
import Forms from "./Tabs/Forms";
import Dashboard from "./Tabs/Dashboard";
import Users from "./Tabs/Users";

export default function PartnerManagerPanelLayout() {
  const location = useLocation();
  useEffect(() => {
    new authService().controlPartnerManagerAccess(`/${location.pathname.split("/")[1]}`);
  }, []);
  return (
    <Layout>
      <Layout.Sider id="partnermanager_panel_sidebar">
        <PartnerManagerSidebar />
      </Layout.Sider>
      <Layout>
        <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "scroll",
            padding: "30px"
          }}
        >
          <Switch>
            <Route
              exact
              path="/partnermanager/panel"
              render={() => <Redirect to="/partnermanager/panel/dashboard" />}
            />
            <Route
              path="/partnermanager/panel/dashboard"
              render={() => <Dashboard />}
            />
            <Route
              path="/partnermanager/panel/forms"
              render={() => <Forms />}
            />
            <Route
              path="/partnermanager/panel/users"
              render={() => <Users />}
            />
          </Switch>
        </div>
      </Layout>
    </Layout>
  );
}
