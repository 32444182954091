import React from "react";
import "./NavigationButtons.css";
import {useTranslation} from "react-i18next";

export default function NavigationButtons(props) {
  const { t } = useTranslation();

  return(
    <div className="navigation_buttons">
      <p onClick={() => props.back()}>
        <i className="fas fa-angle-up"></i>
        <span>{t("questions.previous_question")}</span>
      </p>
    </div>
  );
}
