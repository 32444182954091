import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {authService} from "../../../services/auth.service";
import * as Yup from "yup";

import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import SuccessBox from "../../../components/SuccessBox/SuccessBox";
import Button from "../../../components/CommonComponents/FormButton/FormButton";
import FormikInput from "../../../components/CommonComponents/FormikInput/FormikInput";

export default function ChangePassword() {
  const {t} = useTranslation();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .required(t("auth.warnings.required"))
        .min(8, t("auth.warnings.password_length")),
      newPassword: Yup.string()
        .required(t("auth.warnings.required"))
        .min(8, t("auth.warnings.password_length"))
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[!?=+-@#$%^&*A-Za-zÜüÖöÄäß\d]{8,50}$/,
          t("auth.warnings.password_matches")
        ),
      confirmPassword: Yup.string()
        .min(8, t("auth.warnings.password_length"))
        .when("newPassword", {
          is: val => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            t("auth.warnings.password_not_same")
          )
        })
    }),
    onSubmit: async () => {
      setButtonLoading(true);
      setErrorMessage("");
      setSuccessMessage(false);
      const changeRes = await new authService().changePassword(
        {
          currentPassword: formik.values.currentPassword,
          newPassword: formik.values.newPassword
        },
        "consultant/"
      );
      console.log(changeRes);
      if (changeRes && !changeRes.success) {
        if (changeRes.message == "wrong credentials") {
          setErrorMessage("Current Password is wrong");
        }
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
        setSuccessMessage(true);
      }
    }
  });

  return (
    <div style={{width: "50%"}}>
      {errorMessage && (
        <div style={{height: "60px", marginBottom: "10px"}}>
          <ErrorBox message={errorMessage} />
        </div>
      )}
      {successMessage && (
        <SuccessBox message="Your password has been successfully changed" />
      )}
      <form onSubmit={formik.handleSubmit}>
        <div style={{marginBottom: "20px"}}>
          <FormikInput
            htmlFor="password"
            name="currentPassword"
            value={formik.values.currentPassword}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={"Current Password"}
          />
          {formik.errors.currentPassword && (
            <p className="input_error">{formik.errors.currentPassword}</p>
          )}
        </div>
        <div style={{marginBottom: "20px"}}>
          <FormikInput
            htmlFor="password"
            name="newPassword"
            value={formik.values.newPassword}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={"New Password"}
          />
          {formik.errors.newPassword && (
            <p className="input_error">{formik.errors.newPassword}</p>
          )}
        </div>
        <div style={{marginBottom: "20px"}}>
          <FormikInput
            htmlFor="password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={"Confirm Password"}
          />
          {formik.errors.confirmPassword && (
            <p className="input_error">{formik.errors.confirmPassword}</p>
          )}
        </div>
        <Button
          text={"Change Password"}
          style={{
            marginTop: "30px",
            marginBottom: "20px",
            width: "100%"
          }}
          disabled={!formik.isValid || buttonLoading}
          loading={buttonLoading}
          buttonType="submit"
        />
      </form>
    </div>
  );
}
