import React, {useState, useEffect} from "react";
import {Table, Select, Input, Modal, Tooltip} from "antd";
import {DatePicker} from "antd";
const {RangePicker} = DatePicker;
import moment from "moment";
import {partnerManagerService} from "../../../services/partnerManager.service";
import DynamicSwitch from "../../../components/DynamicSwitch";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons";

export default function Forms() {
  const [partnerFilters, setPartnerFilters] = useState([]);
  const [swtichFlag, setSwtichFlag] = useState(false);
  const [formsOfPartnerManager, setFormsOfPartnerManager] = useState(null);
  const [filteredForms, setFilteredForms] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: ""
  });

  const getPartnersOfPartnerManager = async () => {
    let result = await new partnerManagerService().getPartnersOfPartnerManager(
      null
    );
    result.data = result.data?.map((el, id) => ({
      key: "partnersPM" + id,
      ...el
    }));

    const partnerFilters = result.data.map(el => ({
      name: el.name,
      path: el.path,
      flag: true
    }));
    setPartnerFilters(partnerFilters);
  };

  const getFormsOfPartnerManager = async () => {
    const reqData = {
      dateRange: selectedDate
    };
    const result = await new partnerManagerService().getFormsOfPartnerManager(
      reqData
    );
    result.data = result.data?.map((el, id) => ({key: "usersPM" + id, ...el}));

    setFormsOfPartnerManager(result.data);
    setFilteredForms(result.data.length == 0 ? [] : result.data);
  };

  useEffect(async () => {
    await getPartnersOfPartnerManager();
    await getFormsOfPartnerManager();
  }, []);

  useEffect(async () => {
    setFilteredForms(null);
    await getFormsOfPartnerManager();
  }, [selectedDate]);

  useEffect(async () => {
    const temp = formsOfPartnerManager?.filter(el => {
      const partner = partnerFilters.find(pt => pt.path == el.partner);
      return partner?.flag;
    });
    setFilteredForms(temp);
  }, [swtichFlag]);

  return (
    <div style={{overflow: "auto"}}>
      <div className="dashboard_box">
        <div className="dashboard_box">
          <h2 className="dashboard_header">
            Steuererklärungen ({filteredForms?.length})
          </h2>
          {partnerFilters.length == 0 && (
            <LoadingOutlined style={{marginTop: "20px", marginLeft: "5%"}} />
          )}
          <div style={{marginTop: "15px"}}>
            <DynamicSwitch
              data={partnerFilters}
              setSwtichFlag={setSwtichFlag}
              swtichFlag={swtichFlag}
            />
          </div>
        </div>
        <div style={{marginTop: "15px"}}>
          <RangePicker
            style={{
              width: "auto",
              height: "auto",
              fontSize: "25px",
              borderRadius: "20px",
              padding: "10px",
              border: "2px solid rgba(0, 190, 170, 1)"
            }}
            defaultValue={[selectedDate.startDate, selectedDate.endDate]}
            format={"DD.MM.YYYY"}
            onChange={val => {
              if (val) {
                setSelectedDate({
                  startDate: moment(val[0]).format().split("T")[0],
                  endDate: moment(val[1]).format().split("T")[0]
                });
              } else {
                setSelectedDate({
                  startDate: "",
                  endDate: ""
                });
              }
            }}
          />
        </div>
      </div>
      <div>
        <Table
          id="partners_table"
          rowKey={"key"}
          dataSource={filteredForms}
          loading={filteredForms == null}
        >
          <Table.Column
            dataIndex={"partnerName"}
            title={
              <Tooltip title={"Name deines Affiliate Links"}>Link Name</Tooltip>
            }
          />
          <Table.Column
            dataIndex={"preCalculatedAmount"}
            title={
              <Tooltip title={"Durch Taxefy vorberechnete Rückzahlung"}>
                Vorberechnung
              </Tooltip>
            }
          />
          <Table.Column
            dataIndex="decisionAmount"
            title={
              <Tooltip title={"Vom Finanzamt entschiedene Rückzahlung"}>
                Amtliche Entscheidung
              </Tooltip>
            }
          />
          <Table.Column
            dataIndex={"formDecision"}
            title={
              <Tooltip
                title={
                  <div>
                    <div>Status der Steuererklärung im Finanzamt-Prozess</div>
                    <div style={{backgroundColor: "rgba(0, 190, 170, 1)"}}>
                      Grün: Rückzahlung bestätigt
                    </div>
                    <div style={{backgroundColor: "#aa4455"}}>
                      Rot: abgelehnt
                    </div>
                    <div style={{backgroundColor: "grey"}}>
                      Grau: Prozess nicht fertig
                    </div>
                  </div>
                }
              >
                Steuererklärungs Status
              </Tooltip>
            }
            render={data => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    paddingLeft: "15px"
                  }}
                >
                  {data == "money_decided" ? (
                    <CheckCircleOutlined
                      style={{
                        fontSize: "25px",
                        color: "rgba(0, 190, 170, 1)"
                      }}
                    />
                  ) : data == "rejection" ? (
                    <CloseCircleOutlined
                      style={{fontSize: "25px", color: "#aa4455"}}
                    />
                  ) : (
                    <ClockCircleOutlined
                      style={{fontSize: "25px", color: "grey"}}
                    />
                  )}
                </div>
              );
            }}
          />
          <Table.Column
            dataIndex={"paymentStatus"}
            title={
              <Tooltip
                title={
                  <div>
                    <div>Status der Zahlung an Taxefy</div>
                    <div style={{backgroundColor: "rgba(0, 190, 170, 1)"}}>
                      Grün: Taxefy wurde bezahlt
                    </div>
                    <div style={{backgroundColor: "#aa4455"}}>
                      Rot: Taxefy wurde nocht nicht bezahlt
                    </div>
                  </div>
                }
              >
                Amtlicher Zahlungs Status
              </Tooltip>
            }
            render={data => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    paddingLeft: "15px"
                  }}
                >
                  {data == "succeeded" ? (
                    <CheckCircleOutlined
                      style={{
                        fontSize: "25px",
                        color: "rgba(0, 190, 170, 1)"
                      }}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{fontSize: "25px", color: "#aa4455"}}
                    />
                  )}
                </div>
              );
            }}
          />
          <Table.Column
            dataIndex={"partnerPaymentStatus"}
            title={
              <Tooltip
                title={
                  <div>
                    <div>Status der Zahlung an dich</div>
                    <div style={{backgroundColor: "rgba(0, 190, 170, 1)"}}>
                      Grün: Du wurdest von Taxefy bezahlt
                    </div>
                    <div style={{backgroundColor: "#aa4455"}}>
                      Rot: Du wurdest noch nicht von Taxefy bezahlt
                    </div>
                  </div>
                }
              >
                Taxefy Zahlungs Status
              </Tooltip>
            }
            render={data => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    paddingLeft: "15px"
                  }}
                >
                  {data == "success" ? (
                    <CheckCircleOutlined
                      style={{
                        fontSize: "25px",
                        color: "rgba(0, 190, 170, 1)"
                      }}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{fontSize: "25px", color: "#aa4455"}}
                    />
                  )}
                </div>
              );
            }}
          />
          <Table.Column
            dataIndex={"created_at"}
            title={
              <Tooltip title={"Erstellungs-Datum der Steuererklärung"}>
                Erstellungs-Datum
              </Tooltip>
            }
            render={data => {
              return (
                <span>{new Date(data).toLocaleString().split(", ")[0]}</span>
              );
            }}
          />
          <Table.Column
            dataIndex={"formId"}
            title={
              <Tooltip title={"Taxefy-Interne Identifikations-Nummer"}>
                ID
              </Tooltip>
            }
          />
        </Table>
      </div>
    </div>
  );
}
