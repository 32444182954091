import React, {useEffect, useState} from "react";
import {cookieService} from "../../services/cookie.service";
import {authService} from "../../services/auth.service";
import ProfileTab from "../Profile/ProfileTab";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import FormsTab from "./Tabs/FormsTab";
import UsersTab from "./Tabs/UsersTab";
import InvitationsTab from "./Tabs/InvitationsTab";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import SettingsTab from "./Tabs/SettingsTab";
import PaymentsTab from "./Tabs/PaymentsTab";
const { TabPane } = Tabs;
import { Layout } from "antd";
import AdminSidebar from "../../components/Admin/Sidebar/AdminSidebar";
import AdminLogin from "./AdminLogin";
import DashboardPage from "./Pages/Dashboard/Dashboard";
import PendingForms from "./Pages/PendingForms/PendingForms";
import AllForms from "./Pages/AllForms/AllForms";
import UsersPage from "./Pages/Users/UsersPage";
import InvitationsPage from "./Pages/Invitations/Invitaions";
import PaymentsPage from "./Pages/Payments/Payments";
import PaidForms from "./Pages/PaidForms/PaidForms";
import SentForms from "./Pages/SentForms/SentForms";
import MailedForms from "./Pages/MailedForms/MailedForms";
import BankData from "./Pages/BankData/BankData";
import Errors from "./Pages/Errors/Errors";
import NumbersTab from "./Tabs/NumbersTab";
import ConsultantList from "./Pages/ConsultantList/ConsultantList";
import PartnerRouter from "./Pages/Partners/router";

const { Sider } = Layout;


export default function AdminPanelLayout () {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [collapsed, setCollapsed] = useState(false);
  const [selectedTab, setSelctedTab] = useState("dashboard");

  useEffect(() => {
    const logout = async () => {
      await new cookieService().removeAdminTokenData();
      history.push("auth/login");
    };
    const getToken = async () => {
      const tokenData = await new cookieService().getAdminTokenData();
      if (!tokenData) {
        history.push("auth/login");
      } else {
        const token = tokenData.token;
        if (!token) {
          logout();
        }
        const auth = await new authService().verifyToken(token, "admin");
        if (!auth) {
          logout();
        }
        setLoading(false);
      }
    };
    getToken();
  }, []);

  // Define a route
  useEffect(() => {
    const routes = [
      "dashboard",
      // "pendingForms",
      "forms",
      // "sentForms",
      // "paidForms",
      // "mailed",
      "users",
      "invitations",
      "transfers",
      "bank",
      "errors",
      "settings",
      "consultantList",
      "partners",
    ];
    routes.map((routeKey) => {
      if (location.pathname.includes(routeKey)) {
        setSelctedTab(routeKey);
      }
    });
  }, [location]);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  if (loading) {
    return(<p>Loading..</p>);
  } else {
    return(
      <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          style={{
            height: "100%",
            backgroundColor: "#f6f9fd"
          }}
        >
          <div className="logo" />
          <AdminSidebar selectedTab={selectedTab} />
        </Sider>
        <Layout>
          <div style={{width: "100%", height: "100%", backgroundColor: "#ffffff", padding: "20px"}}>
            <Switch>
              <Route exact path="/admin/panel" render={() =>
                <Redirect to="/admin/panel/dashboard" />
              }/>
              <Route path="/admin/panel/dashboard" render={() =>
                <DashboardPage />
              }/>
              {/* <Route path="/admin/panel/pendingForms" render={() =>
                <PendingForms />
              }/>
              <Route path="/admin/panel/sentForms" render={() =>
                <SentForms />
              }/>
              <Route path="/admin/panel/paidForms" render={() =>
                <PaidForms />
              }/>
              <Route path="/admin/panel/mailed" render={() =>
                <MailedForms />
              }/> */}
              <Route path="/admin/panel/forms" render={() =>
                <AllForms />
              }/>
              <Route path="/admin/panel/users" render={() =>
                <UsersPage />
              }/>
              <Route path="/admin/panel/invitations" render={() =>
                <InvitationsPage />
              }/>
              <Route path="/admin/panel/transfers" render={() =>
                <PaymentsPage />
              }/>
              <Route path="/admin/panel/bank" render={() =>
                <BankData />
              }/>
              <Route path="/admin/panel/errors" render={() =>
                <Errors />
              }/>
              <Route path="/admin/panel/settings" render={() =>
                <SettingsTab />
              }/>
              <Route path="/admin/panel/numbers" render={() =>
                <NumbersTab />
              }/>
              <Route path="/admin/panel/consultantList" render={() =>
                <ConsultantList />
              }/>
              <Route path="/admin/panel/partners" render={() =>
                <PartnerRouter />
              }/>
            </Switch>
          </div>
        </Layout>
      </Layout>
      /*<div className="panel_page">
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(key) => setActiveTab(key)}
        >
          <TabPane tab="Forms" key="1">
            <FormsTab />
          </TabPane>
          <TabPane tab="Payments" key="2">
            <PaymentsTab />
          </TabPane>
          <TabPane tab="Users" key="3">
            <UsersTab />
          </TabPane>
          <TabPane tab="Invitations" key="4">
            <InvitationsTab />
          </TabPane>
          <TabPane tab="Settings" key="5">
            <SettingsTab />
          </TabPane>
        </Tabs>
      </div>*/
    );
  }
}
