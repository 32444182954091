import React from "react";
import "./FormButton.css";
import HashLoader from "react-spinners/HashLoader";

export default function Button(props) {

  return(
    <button
      onClick={() => props.handleClick && props.handleClick()}
      className={props.className ? props.className : "primary_button"}
      style={props.style}
      type={props.buttonType === "submit" ? "submit" : "button"}
      disabled={props.disabled}
    >
      {props.loading
        ? <HashLoader color="#ffffff" size={25} />
        : props.text
      }
    </button>
  );
}
