import React from "react";
import {Route} from "react-router-dom";
import ResetPassword from "../Authentication/ResetPasswordPage";

export default function ConsultantResetPassword() {
  return (
    <div className="login_page">
      <Route
        path="/consultant/auth/resetPassword"
        render={() => <ResetPassword consultant />}
      />
    </div>
  );
}
