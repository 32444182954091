import React from "react";
import {Route, Switch} from "react-router-dom";
import PartnerManagerLogin from "./PartnerManagerLogin";
import PartnerManagerPanelLayout from "./PartnerManagerPanelLayout";
import PartnerManagerRegister from "./Register";
import ActivatePartnerManager from "./ActivatePartnerManager";
// import ConsultantForgetPassword from "./ConsultantForgetPassword";
// import ConsultantResetPassword from "./ConsultantResetPassword";

export default function PartnerManagerLayout() {
  return (
    <Switch>
      <Route
        path="/partnermanager/auth/login"
        render={() => <PartnerManagerLogin />}
      />
      <Route
        path="/partnermanager/auth/register"
        render={() => <PartnerManagerRegister />}
      />
      <Route
        path="/partnermanager/auth/activateUser"
        render={() => <ActivatePartnerManager />}
      />
      <Route
        path="/partnermanager/panel"
        exact
        render={() => <PartnerManagerPanelLayout />}
      />
      <Route path="/partnermanager/panel/*" exact render={() => <PartnerManagerPanelLayout />} />
      {/* <Route path="/partnermanager/auth/forget" exact render={()  => <ConsultantForgetPassword />} />
      <Route path="/partnermanager/auth/resetPassword" exact render={()  => <ConsultantResetPassword consultant />} /> */}
    </Switch>
  );
}
