import React, {useEffect} from "react";
import "./ConsultantPanelLayout.css";
import ConsultantSidebar from "../../components/Consultant/ConsultantSidebar";
import {useLocation} from "react-router-dom";
import {authService} from "../../services/auth.service";
import {Layout} from "antd";
import {Switch, Route, Redirect} from "react-router-dom";
import Dashboard from "./Tabs/Dashboard";
import Settings from "./Tabs/Settings";
import ChangePassword from "./Tabs/ChangePassword";
import {SearchOutlined} from "@ant-design/icons";

export default function ConsultantPanelLayout() {
  const location = useLocation();
  useEffect(() => {
    new authService().controlConsultantAccess(`/${location.pathname.split("/")[1]}`);
  }, []);
  return (
    <Layout>
      <Layout.Sider id="consultant_panel_sidebar">
        <ConsultantSidebar />
      </Layout.Sider>
      <Layout>
        <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "scroll",
            padding: "30px"
          }}
        >
          <Switch>
            <Route
              exact
              path="/consultant/panel"
              render={() => <Redirect to="/consultant/panel/dashboard" />}
            />
            <Route path="/consultant/panel/dashboard" render={() => <Dashboard />} />
            <Route path="/consultant/panel/settings" render={() => <Settings />} />
            <Route path="/consultant/panel/changepassword" render={() => <ChangePassword />} />
            <Route
              path="/consultant/panel/help"
              render={() => (
                <div>
                  <h1 style={{fontSize: "25px"}}>Gruppen-Erklärung</h1>
                  <div style={{display: "flex", fontSize: "12px"}}>
                    <table>
                      <thead>
                        <tr>
                          <td className="help_td">ID</td>
                          <td className="help_td">Erklärung</td>
                          <td className="help_td">Beispiel</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="help_td">X</td>
                          <td className="help_td">Haupt-Gruppe nach Definition</td>
                          <td className="help_td">1, 2, 3, 4, 5, 6</td>
                        </tr>
                        <tr>
                          <td className="help_td">X.1</td>
                          <td className="help_td">Berufliche Anteile %-Frage fehlt</td>
                          <td className="help_td">
                            2.1 <br />
                            3.1 <br />
                            4.1 <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="help_td">X.2</td>
                          <td className="help_td">
                            Durch Pendlerpauschale/euro wird auf nächst-höhere Gruppe aufgestuft
                          </td>
                          <td className="help_td">
                            2.2 = Rechnung + Pendler = 3 <br />
                            3.2 = Rechnung + Pendler = 4 <br />
                            4.2 = Rechnung + Pendler = 5 <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="help_td">X.3</td>
                          <td className="help_td">Pendler-Zumutbarkeit ohne Nachprüfung</td>
                          <td className="help_td">
                            2.3 <br />
                            3.3 <br />
                            4.3 <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="help_td">X.4</td>
                          <td className="help_td">
                            {" "}
                            Pendler-Zumutbarkeit mit zusätzlicher Nachprüfung
                          </td>
                          <td className="help_td">
                            2.4 <br />
                            3.4 <br />
                            4.4 <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="help_td">X.5</td>
                          <td className="help_td">Unterhaltabsetzbetrag</td>
                          <td className="help_td">
                            2.5 <br />
                            3.5 <br />
                            4.5 <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="help_td">X.6</td>
                          <td className="help_td">
                            Arbeitsmittel - Anschaffungskosten{" "}
                            <span style={{fontWeight: "bolder"}}>kleiner</span> als: <br />
                            2019: 400€ <br />
                            2020: 800€ <br />
                            2021: 800€ <br />
                            2022: 800€ <br />
                            2023+: 1000€ <br />
                          </td>
                          <td className="help_td">
                            2.6 <br />
                            3.6 <br />
                            4.6 <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="help_td">X.7</td>
                          <td className="help_td">
                            Arbeitsmittel - Anschaffungskosten{" "}
                            <span style={{fontWeight: "bolder"}}>größer</span> als: <br />
                            2019: 400€ <br />
                            2020: 800€ <br />
                            2021: 800€ <br />
                            2022: 800€ <br />
                            2023+: 1000€ <br />
                          </td>
                          <td className="help_td">
                            2.7 <br />
                            3.7 <br />
                            4.7 <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="help_td">X.8</td>
                          <td className="help_td">
                            Fehlende Hausnummer bei Wohn- und/oder Arbeitsadressen, bei Jobs zu
                            denen mit dem eigenen Auto gefahren wurde
                          </td>
                          <td className="help_td">
                            2.8 <br />
                            3.8 <br />
                            4.8 <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="help_td">X.9</td>
                          <td className="help_td">Alleinerzieher</td>
                          <td className="help_td">
                            2.9 <br />
                            3.9 <br />
                            4.9 <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="help_td">X.10</td>
                          <td className="help_td">Alleinverdiener</td>
                          <td className="help_td">
                            2.10 <br />
                            3.10 <br />
                            4.10 <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="help_td">X.11</td>
                          <td className="help_td">Kindermehrbetrag</td>
                          <td className="help_td">
                            2.11 <br />
                            3.11 <br />
                            4.11 <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h1 style={{fontSize: "25px", marginTop: "20px"}}>Tabellen-Erklärung</h1>
                  <div style={{display: "flex", fontSize: "15px"}}>
                    <ul>
                      <li>
                        Der <span style={{color: "rgba(0, 190, 170, 1)"}}>grüne</span> Such-Button{" "}
                        <SearchOutlined
                          style={{
                            userSelect: "none",
                            fontSize: 20,
                            backgroundColor: "rgba(0, 190, 170, 1)",
                            color: "white"
                          }}
                        />{" "}
                        schickt eine Anfrage an Taxefy mit den ausgewählten Filtern, Sortierung,
                        Such-Text, ID-Limits und Datum-Limits
                      </li>
                      <li>
                        Tabellen-Filter, Sortierung und ausgewählte Datensätze werden nach jeder
                        Anfrage zurückgesetzt
                      </li>
                      <li>
                        ID-Limits und Datum-Limits werden NICHT nach jeder Anfrage zurückgesetzt
                      </li>
                      <li>
                        Die blauen Checkboxen über der Tabelle verstecken gruppierte Spalten, dies
                        ändern nicht die Daten, dient der Übersichtlichkeit
                      </li>
                      <li>
                        Bei Tabellen-Blatt-Wechsel werden ausgewählte Datensätze NICHT
                        zurückgesetzt, man kann somit auf Seite 1 drei Werte auswählen, auf Seite 2
                        fünf Werte und hat ingesamt 8 Werte ausgewählt
                      </li>
                      <li>
                        Filter wirken sich mit UND-Logik auf die angezeigten Daten aus, wenn man 2
                        voneinander exklusive Filter anwendet (Gruppe 2.3 UND 2.4) dann ist das
                        Tabellen-Ergebnis leer
                      </li>
                      <li>
                        Um Aktionen wie{" "}
                        <span style={{fontWeight: "bold"}}>An FinanzOnline senden</span> oder{" "}
                        <span style={{fontWeight: "bold"}}>ANV-Fragen anzeigen</span> durchzuführen,
                        muss die jeweilige Zeile mit der Checkbox ganz links ausgewählt werden. Je
                        nach Anzahl der ausgewählten Zeilen werden die passenden Aktionen angezeigt.
                      </li>
                      <li>
                        Bei der Sortierung ist die vertikale Reihenfolge WICHTIG, die erste aktive
                        Spalte in der Liste wird auch als erstes angewendet{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            />
          </Switch>
        </div>
      </Layout>
    </Layout>
  );
}
