import React, {useEffect, useState} from "react";
import Loading from "../../../../components/Loading";
import {ConfigProvider, Modal, Switch, Table} from "antd";
import Button from "../../../../components/CommonComponents/FormButton/FormButton";
import {partnerManagerService} from "../../../../services/partnerManager.service";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import "./PartnerManager.css";
import FormikInput from "../../../../components/CommonComponents/FormikInput/FormikInput";
import TableAction from "../../../../components/Admin/TableAction";

export default function PartnerManagers(props) {
  const [loading, setLoading] = useState(true);
  const [createPMactivated, setCreatePMactivated] = useState(true);
  const [createPMerror, setCreatePMerror] = useState(null);
  const [createPMName, setCreatePMName] = useState("");
  const [createPMemail, setCreatePMemail] = useState("");
  const [createPMpassword, setCreatePMpassword] = useState("");
  const [partnerManagerData, setPartnerManager] = useState([]);
  const [openCreatePartnerManagerModal, setOpenCreatePartnerManagerModal] =
    useState(false);

  const getAllPartenrManagers = async () => {
    setLoading(true);
    const partners = await new partnerManagerService().getAllPartnerManagers();
    if (!partners.status) {
      setPartnerManager(partners.data);
      props.setPartnerManager(partners.data);
    }
    setLoading(false);
  };

  const switchCreatePM = () => {
    setCreatePMactivated(!createPMactivated);
    console.log(createPMactivated);
  };

  const createPartnerManager = async () => {
    const reqObject = {
      name: createPMName,
      email: createPMemail,
      password: createPMpassword,
      activated: createPMactivated
    };

    const result = await new partnerManagerService().createNewPartnerManager(
      reqObject
    );

    if (result.status) {
      setOpenCreatePartnerManagerModal(false);
    } else setCreatePMerror(result.message);
    getAllPartenrManagers();
  };

  useEffect(async () => {
    await getAllPartenrManagers();
  }, []);

  useEffect(async () => {
    setTimeout(() => setCreatePMerror(null), 5000);
  }, [createPMerror]);

  return loading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div style={{overflow: "auto"}}>
      <Modal
        title="Create new Partner-Manager"
        footer={null}
        closable={null}
        visible={openCreatePartnerManagerModal}
        onCancel={() => {
          setOpenCreatePartnerManagerModal(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexFlow: "column"
          }}
        >
          {createPMerror && (
            <p
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "10px",
                borderRadius: "10px"
              }}
            >
              {createPMerror}
            </p>
          )}
          <FormikInput
            htmlFor="text"
            value={createPMName}
            handleChange={e => setCreatePMName(e.target.value)}
            label={"Name"}
          />
          <FormikInput
            htmlFor="text"
            value={createPMemail}
            handleChange={e => setCreatePMemail(e.target.value)}
            label={"Email"}
          />
          <FormikInput
            htmlFor="password"
            value={createPMpassword}
            handleChange={e => setCreatePMpassword(e.target.value)}
            label={"Password"}
          />

          <div id="switchbox">
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Disabled"
              id="PMswitch"
              style={{
                backgroundColor: createPMactivated
                  ? "rgba(0, 190, 170, 1)"
                  : "grey"
              }}
              defaultChecked
              onChange={() => switchCreatePM()}
            />
          </div>

          <Button
            text={"Create Partner-Manager +"}
            buttonType="button"
            handleClick={() => {
              createPartnerManager();
            }}
          />
        </div>
      </Modal>
      <div style={{marginTop: "40px"}} className="space-between">
        <h2>Partner-Managers</h2>
        <Button
          text={"Add Partner-Manager +"}
          style={{
            margin: "15px"
          }}
          buttonType="submit"
          handleClick={() => {
            setOpenCreatePartnerManagerModal(true);
          }}
        />
      </div>
      <Table dataSource={partnerManagerData} style={{overflow: "auto"}}>
        <Table.Column title="Id" dataIndex={"partner_manager_id"} />
        <Table.Column title="Name" dataIndex={"name"} />
        <Table.Column title="Email" dataIndex={"email"} />
        <Table.Column
          title="Created at"
          dataIndex={"created_at"}
          render={data => {
            return <p>{data.split("T")[0]}</p>;
          }}
        />
        <Table.Column
          title="Activated"
          dataIndex={"activated"}
          render={data => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingLeft: "15px"
                }}
              >
                {data ? (
                  <CheckCircleOutlined
                    style={{fontSize: "25px", color: "rgba(0, 190, 170, 1)"}}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{fontSize: "25px", color: "#aa4455"}}
                  />
                )}
              </div>
            );
          }}
        />
        <Table.Column
          title="Action"
          render={data => {
            return (
              <TableAction
                id={data.formId}
                type="admin-partnermanager"
                data={data}
                updateTable={() => getAllPartenrManagers()}
              />
            );
          }}
        />
      </Table>
    </div>
  );
}
