import React from "react";
import bowlingAnimation from "./../assets/animations/loading.json";
import HashLoader from "react-spinners/HashLoader";

export default function Loading(props) {

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: bowlingAnimation
  };

  return(
    <div className="loading_screen">
      <HashLoader color="#00beaa" size={65} />
    </div>
  );
}
