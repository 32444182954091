import React, {useEffect, useState} from "react";
import {Table} from "antd";
import {cookieService} from "../../../services/cookie.service";
import axios from "axios";
import environment from "../../../environment";
import moment from "moment";
import TableAction from "../../../components/Admin/TableAction";

const { Column, ColumnGroup } = Table;

export default function UsersTab() {

  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);

  const getForms = async () => {
    const cookieToken = await new cookieService().getAdminTokenData();
    const response = await axios.get(`${environment.baseUrl}admin/users`, {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data && response.data.length) {
      const newArray = [];
      response.data.map((userFromDb, idx) => {
        userFromDb.createdAt = moment(new Date(userFromDb.createdAt)).format("DD.MM.YYYY");
        console.log(userFromDb);
        newArray.push({
          ...userFromDb,
          key: idx.toString(),
        });
      });
      setUsersData(newArray);
    }
    setLoading(false);
  };

  useEffect(() => {
    getForms();
  }, []);

  return(
    <div className="admin_tab">
      {loading
        ? <p>Loading</p>
        : usersData.length
          ? <Table dataSource={usersData}>
            <Column title="Id" dataIndex="id" key="id" />
            <Column title="Name" dataIndex="name" key="name" />
            <Column title="Email" dataIndex="email" key="email" />
            <Column title="Language" dataIndex="language" key="language" />
            <Column
              title="Activated"
              dataIndex="activated"
              key="activated"
              render={activated => (
                <p key={activated.toString()}>{activated.toString()}</p>
              )}
            />
            <Column
              title="Form Filled"
              dataIndex="formFilled"
              key="formFilled"
              render={formFilled => (
                <p key={formFilled.toString()}>{formFilled.toString()}</p>
              )}
            />
            <Column
              title="Created"
              dataIndex="createdAt"
              key="createdAt"
              sorter={(a, b) => moment(a.createdAt, "DD.MM.YYYY").unix() - moment(b.createdAt, "DD.MM.YYYY").unix()}
            />
          </Table>
          : <p>No data</p>
      }
    </div>
  );
}
