import React from "react";
import {Route} from "react-router-dom";
import Login from "../Authentication/Login";

export default function ConsultantLogin() {
  return (
    <div className="login_page">
      <Route
        path="/consultant/auth/login"
        render={() => <Login consultant />}
      />
    </div>
  );
}
