import React, { useEffect, useState } from "react";
import "./App.css";
import { connect } from "react-redux";
import Adjust from "@adjustcom/adjust-web-sdk";
import { Route, Router, Switch, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import AuthenticationPage from "./screens/Authentication/AuthenticationPage";
import ProtectedRoutes from "./screens/ProtectedRoutes";
import ErrorScreen from "./screens/ErrorScreen";
import InviteScreen from "./screens/InviteScreen";
import ProfileView from "./screens/Profile/ProfileView";
import AdminLayout from "./screens/Admin/AdminLayout";
import ConsultantLayout from "./screens/Consultant/ConsultantLayout";
import queryString from "query-string";
import { langs } from "./constants/langs";
import i18n from "./i18next";
import "antd/dist/antd.css";
import { setLanguageAction } from "./data/app/actions";
import useCampaignParams from "./hooks/useCampaignParams";
import PartnerManagerLayout from "./screens/PartnerManager/PartnerManagerLayout";
import StoresScreen from "./screens/SctoresScreen";
import PartnerRedirect from "./screens/Authentication/PartnerRedirect";
import ReviewRedirect from "./screens/ReviewRedirect";
import DeeplinkRedirectPage from "./screens/DeeplinkRedirect";

export const customHistory = createBrowserHistory();

Adjust.initSdk({
  appToken: "pgucnnm94em8",
  environment: process?.env?.NODE_ENV ?? "production"
});

function App() {
  useCampaignParams();
  const location = useLocation();

  const list = [];

  for (let i = 0; i < 30; i += 1) {
    list.push(<div key={i}>{`item n°${i + 1}`}</div>);
  }

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const savedLanguage = window.localStorage.getItem("lang");
    if (parsed && parsed.lang && langs.includes(parsed.lang)) {
      i18n.changeLanguage(parsed.lang);
      window.localStorage.setItem("lang", parsed.lang);
    } else if (
      savedLanguage &&
      savedLanguage.length &&
      langs.includes(savedLanguage)
    ) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  const reload = () => window.location.reload();

  return (
    <div className="App">
      <Router history={customHistory}>
        <Switch>
          <Route path="/admin" render={() => <AdminLayout />} />
          <Route path="/consultant" render={() => <ConsultantLayout />} />
          <Route
            path="/partnermanager"
            render={() => <PartnerManagerLayout />}
          />
          <Route path="/review" render={() => <ReviewRedirect />} />
          <Route path="/deeplink" render={() => <DeeplinkRedirectPage />} />
          <Route path="/redirect" render={() => <PartnerRedirect />} />
          <Route path="/auth" render={() => <AuthenticationPage />} />
          <Route path="/404" exact render={() => <ErrorScreen />} />
          <Route path="/invite/:id" exact render={() => <InviteScreen />} />
          <Route path="/stores" exact render={() => <StoresScreen />} />
          <Route path="/" exact render={() => <ProtectedRoutes />} />
          {/*onEnter={reload}*/}
          {/*<Route path="/apple-app-site-association" />
          <Route path="/.well-known/apple-app-site-association" />*/}
          <Route path="/:page" render={() => <ProtectedRoutes />} />
        </Switch>
      </Router>
    </div>
  );
}

export default connect(null, null)(App);
