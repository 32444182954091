import { applyMiddleware, compose, createStore } from "redux";
import { fromJS } from "immutable";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

// Reducers
import rootReducer from "./data/reducer";
import { createTransform, persistReducer, persistStore } from "redux-persist";

const composeEnhancers =
    (process.env.NODE_ENV === "development"
        && window
        && (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
|| compose;

const persistStoreTransform = createTransform(null, (outboundState, key) => {
  const outboundStateModified = Object.assign({}, outboundState);
  //
  if (key === "app") {
    return fromJS(outboundStateModified);
  }
  return outboundStateModified;
});

const persistConfig = {
  transforms: [persistStoreTransform],
  blacklist: [],
  whitelist: ["app"],
  key: "tx_20210601",
  storage: storage
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, compose(
  applyMiddleware(thunk),
  composeEnhancers && composeEnhancers()
));

const configureStore = callback =>
  new Promise(() => {
    try {
      persistStore(store, {}, () => {
        if (callback) callback(store);
      });
      if (callback) callback(null);
    } catch (e) {
      console.log(e);
    }
  });

export default configureStore;
