import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../CommonComponents/FormButton/FormButton";

export default function SignComponent(props) {
  const { t } = useTranslation();

  const [signWasUsed, setSignWasUsed] = useState(false);

  const ref = useRef(null);

  const clear = () => {
    ref.current.clear();
  };

  return(
    <div className="signature_container">
      <h4>{t("questions.final_step.signature.title")}</h4>
      <SignatureCanvas
        penColor="#00beaa"
        ref={ref}
        canvasProps={{className: "sigCanvas"}}
        onEnd={() => {
          setSignWasUsed(true);
          ref.current.getCanvas().toBlob((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
              const base64data = reader.result;
              props.saveSignature(base64data);
            };
          });
        }}
      />
      <div className="sign_btns_grid">
        <Button
          text={t("questions.clear")}
          style={{
            marginTop: "20px"
          }}
          className={"secondary_button"}
          disabled={!signWasUsed}
          handleClick={() => clear()}
        />
        <Button
          text={t("questions.continue")}
          style={{
            marginTop: "20px"
          }}
          disabled={!signWasUsed}
          handleClick={() => props.setCurrentComponent("info")}
        />
      </div>
    </div>
  );
}
