import React from "react";
import Login from "../Authentication/Login";
import {Redirect, Route, Switch} from "react-router-dom";
import Register from "../Authentication/Register";
import AdminRegister from "./AdminRegistration";

export default function AdminLogin() {

  return (
    <div className="login_page">
      <Switch>
        <Route path="/admin/auth/login" render={() =>
          <Login admin />
        }/>
        <Route path="/admin/auth/register" render={() =>
          <AdminRegister />
        }/>
        <Route path="/admin/auth" render={() =>
          <Redirect to="/auth/login" />
        }/>
      </Switch>
    </div>
  );
}
