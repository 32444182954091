import {React, useState} from "react";
import logo from "../../img/taxefy_logo.png";
import "./styles.css";
import {useHistory, useLocation} from "react-router-dom";
import {cookieService} from "../../services/cookie.service";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import {Menu} from "antd";
import {
  DashboardOutlined,
  SettingOutlined,
  LogoutOutlined,
  EditOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {Modal} from "antd";
import {authService} from "../../services/auth.service";

export default function ConsultantSidebar() {
  const history = useHistory();
  const selectedTab = useLocation().pathname.split("/")[3];
  const [isOpen, setIsOpen] = useState(false);

  function toggleLogoutDialog() {
    setIsOpen(!isOpen);
  }
  return (
    <div>
      <Menu id="consultant_sidebar_menu" selectedKeys={[selectedTab]} mode="inline">
        <img id="consultant_sidebar_logo" src={logo} alt="taxefy_logo" />
        <Menu.Item
          key="dashboard"
          icon={<DashboardOutlined />}
          onClick={() => history.push("dashboard")}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="settings"
          icon={<SettingOutlined />}
          onClick={() => history.push("settings")}
        >
          Settings
        </Menu.Item>
        <Menu.Item
          key="changepassword"
          icon={<EditOutlined />}
          onClick={() => history.push("changepassword")}
        >
          Change Password
        </Menu.Item>
        <Menu.Item key="help" icon={<InfoCircleOutlined />} onClick={() => history.push("help")}>
          Hilfe
        </Menu.Item>
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={() => {
            toggleLogoutDialog();
          }}
        >
          Log out
        </Menu.Item>
      </Menu>
      <Modal footer={null} closable={null} visible={isOpen} onCancel={toggleLogoutDialog}>
        <h4 className="logout_dialog_h4">Are you sure you want to log out?</h4>
        <div
          style={{
            display: "flex"
          }}
        >
          <Button
            text={"Yes"}
            style={{
              width: "50%",
              margin: "5px"
            }}
            handleClick={() => {
              toggleLogoutDialog();
              new authService().logoutConsultant();
              new cookieService().removeTokenData();
            }}
          />
          <Button
            text={"No"}
            style={{
              width: "50%",
              margin: "5px"
            }}
            handleClick={() => {
              toggleLogoutDialog();
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
