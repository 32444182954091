import React from "react";
import "../styles.css";
import { ResponsivePie } from "@nivo/pie";

export default function DevicesChart(props) {
  return(
    <div style={{borderRight: "2px solid rgb(238, 238, 238)"}}>
      <h3>Geräte</h3>
      <div style={{height: "300px"}}>
        <ResponsivePie
          data={[
            {
              "id": "mobile",
              "label": "Mobile",
              "value": props.data.mobile,
              "color": "hsl(329, 70%, 50%)"
            },
            {
              "id": "desktop",
              "label": "Desktop",
              "value": props.data.web,
              "color": "hsl(82, 70%, 50%)"
            }
          ]}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.7}
          activeOuterRadiusOffset={8}
          colors={["#1618d7", "#b9c8e7"]}
          borderWidth={1}
          padAngle={3}
          borderColor={{
            from: "color",
            modifiers: [
              [
                "darker",
                0.2
              ]
            ]
          }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          enableArcLabels={false}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [
              [
                "darker",
                2
              ]
            ]
          }}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000"
                  }
                }
              ]
            }
          ]}
        />
      </div>
    </div>
  );
}
