import React, {useEffect, useState} from "react";
import "./AdminComponents.css";
import {Menu, Dropdown, Button, notification, Modal} from "antd";
import axios from "axios";
import {cookieService} from "../../services/cookie.service";
import environment from "../../environment";
import {Select} from "antd";
import {formStatuses} from "../../constants/statuses";
import HashLoader from "react-spinners/HashLoader";
import {CloseCircleOutlined, DeleteFilled, HighlightOutlined} from "@ant-design/icons";
import taxefyIcon from "../../assets/images/taxefy_icon.png";
import ConsultantAssignList from "./ConsultantAssignList";
import {consultantService} from "../../services/consultant.service";
import {authService} from "../../services/auth.service";
import {adminUrls} from "../../utils/api";

const {Option} = Select;

const emailTypesArray = [
  {
    title: "SVN",
    value: "svn"
  },
  {
    title: "Tax Number",
    value: "taxNumber"
  },
  {
    title: "Tax Number not exists",
    value: "not_exists"
  }
];

export default function TableAction(props) {
  const [selectedFormStatus, setSelectedFormStatus] = useState("Select");
  const [loading, setLoading] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [emailType, setEmailType] = useState("svn");
  const [showAssignConsultant, setShowAssignConsultant] = useState(false);

  useEffect(() => {
    formStatuses.map(formStatusItem => {
      if (formStatusItem.value === +props.formStatus) {
        setSelectedFormStatus(formStatusItem.title);
      }
    });
  }, [props.formStatus]);

  const downloadFiles = async () => {
    try {
      const cookieToken =
        props.type == "admin"
          ? await new cookieService().getAdminTokenData()
          : await new cookieService().getTokenData();

      console.log("Called");
      console.log(process.env);
      console.log(`${environment.baseUrl}${adminUrls.form.download}${props.id}`);

      const result = await axios({
        url: `${environment.baseUrl}${adminUrls.form.download}${props.id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        },
        responseType: "blob" // important
      });
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "files.zip");
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      props.setErrorMessage(JSON.parse(await err.response.data.text()).message);
    }
  };

  const handleChangeFormStatus = async status => {
    let selectedItem = 0;
    formStatuses.map(formStatusItem => {
      if (formStatusItem.title === status) {
        selectedItem = formStatusItem.value;
      }
    });
    const cookieToken = await new cookieService().getAdminTokenData();
    axios({
      url: `${environment.baseUrl}admin/form/changeFormStatus/${props.id}`,
      method: "PUT",
      data: {formStatus: selectedItem},
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    }).then(response => {
      props.updateTable();
    });
  };

  const handleSendToFO = async () => {
    setLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    await axios({
      url: `${environment.baseUrl}admin/form/sendToFO/${props.id}`,
      method: "POST",
      data: {},
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then(response => {
        props.updateTable();
        notification.open({
          message: "Sent to FinanzOnline",
          duration: 2
        });
      })
      .catch(e => {
        notification.open({
          message: e.response.data.message,
          duration: 2
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendEmail = async () => {
    const cookieToken = await new cookieService().getAdminTokenData();
    await axios({
      url: `${environment.baseUrl}admin/form/sendEmail`,
      method: "POST",
      data: {
        ...props.data,
        emailType: emailType
      },
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then(response => {
        props.updateTable();
        notification.open({
          message: "Email was sent",
          duration: 2
        });
      })
      .catch(e => {
        notification.open({
          message: e.response.data.message,
          duration: 2
        });
      });
  };

  const changeIbanBack = async () => {
    const cookieToken = await new cookieService().getAdminTokenData();
    axios({
      url: `${environment.baseUrl}admin/form/changeIbanBack`,
      method: "POST",
      data: {forms: [props.id]},
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then(response => {
        props.updateTable();
        notification.open({
          message: "IBAN was changed!!!",
          duration: 2
        });
      })
      .catch(e => {
        notification.error(e.response.data.message);
      });
  };

  const handleDeleteForm = async () => {
    setLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    await axios({
      url: `${environment.baseUrl}admin/form/delete/${props.id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then(response => {
        props.updateTable();
        notification.open({
          message: "Deleted",
          duration: 2
        });
      })
      .catch(e => {
        notification.open({
          message: e.response.data.message,
          duration: 2
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendToFObyConsultant = async data => {
    console.log(props.data);
    props.setErrorMessage(false);
    const tokenData = await new cookieService().getTokenData();
    const result = await new consultantService().sendToFObyConsultant(
      data.formId,
      tokenData.email,
      tokenData.token
    );

    alert(
      JSON.stringify(result.data.errors)
        .replaceAll("},{", "\n")
        .replaceAll("[{", "")
        .replaceAll("}]", "")
    );

    if (!result.success) {
      console.log(result.message);
      props.setErrorMessage(result.message);
    }

    setTimeout(() => props.updateTable(), 1000);
  };

  const activatePartnerManager = async data => {
    console.log(props.data);
    if (data?.activated == true) return;
    const result = await new authService().activatePartnerManager(data.partner_manager_id);

    props.updateTable();
    if (result && !result.success) {
      console.log(result.data);
    }
  };

  const menu = (
    <>
      <Modal
        footer={null}
        title={"Send email to customer"}
        visible={showEmailPopup}
        onCancel={() => setShowEmailPopup(false)}
      >
        <Select value={emailType} onChange={type => setEmailType(type)}>
          {emailTypesArray.map(type => (
            <Option value={type.value} key={type.value}>
              {type.title}
            </Option>
          ))}
        </Select>
        <button onClick={() => sendEmail()}>Send</button>
      </Modal>
      <ConsultantAssignList
        Open={showAssignConsultant}
        setOpen={setShowAssignConsultant}
        record={props.data}
        success={props.success}
      />
      <Menu>
        {props.type == "admin" && (
          <>
            <Menu.Item
              key="assignConsultant"
              disabled={props.selected}
              onClick={() => {
                setShowAssignConsultant(true);
              }}
            >
              <p>
                <img src={taxefyIcon} style={{width: "20px"}} /> Assign to Consultant
              </p>
            </Menu.Item>
            {/* <Menu.Item key="send" onClick={() => handleSendToFO()}>
              <p>Send to FO</p>
            </Menu.Item> */}
            {/* <Menu.Item key="changeIban" onClick={() => changeIbanBack()}>
              <p>Change IBAN Back</p>
            </Menu.Item> */}
            <Menu.Item key="email" onClick={() => setShowEmailPopup(true)}>
              <p>Send email</p>
            </Menu.Item>
            <Menu.Item key="download" onClick={() => downloadFiles()}>
              <p>Download ZIP files</p>
            </Menu.Item>
            <Menu.SubMenu key="formStatus" title="Change form status">
              <Select
                defaultValue={selectedFormStatus}
                style={{minWidth: 180}}
                onChange={status => handleChangeFormStatus(status)}
              >
                {formStatuses.map(formStatus => (
                  <Option value={formStatus.title} key={formStatus.value}>
                    {formStatus.title}
                  </Option>
                ))}
              </Select>
            </Menu.SubMenu>
            <Menu.Item
              key="delete"
              onClick={() => {
                Modal.confirm({
                  icon: <DeleteFilled />,
                  content: "Delete this form?",
                  okText: "YES",
                  cancelText: "NO",
                  onOk() {
                    handleDeleteForm();
                  }
                });
              }}
            >
              <p>
                <DeleteFilled />
                &nbsp;Delete
              </p>
            </Menu.Item>
          </>
        )}
        {props.type == "consultant" && (
          <>
            <Menu.Item key="download" onClick={() => downloadFiles()}>
              <p>Download ZIP-Datein</p>
            </Menu.Item>
            <Menu.Item
              disabled={props.selected}
              key="sendToFo"
              onClick={() => sendToFObyConsultant(props.data)}
            >
              <p>An FinanzOnline senden</p>
            </Menu.Item>
            <Menu.Item
              key="control"
              onClick={() => {
                props.ANVid(props.data.formId);
                props.openANVmodal(true);
              }}
            >
              <p>
                <HighlightOutlined
                  style={{
                    fontSize: "20px",
                    color: "#aa4455",
                    marginRight: "10px"
                  }}
                />
                ANV Prüfen
              </p>
            </Menu.Item>
          </>
        )}
        {props.type == "admin-partnermanager" && (
          <>
            <Menu.Item
              key="admin-pm-activate"
              onClick={() => {
                console.log(props.data);
                activatePartnerManager(props.data);
              }}
            >
              <p>Partner-Manager aktivieren</p>
            </Menu.Item>
          </>
        )}
      </Menu>
    </>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      {loading ? (
        <HashLoader size={20} />
      ) : (
        <div className="action_dots">
          <div className="action_dot" />
          <div className="action_dot" />
          <div className="action_dot" />
        </div>
      )}
    </Dropdown>
  );
}
