import React, {useEffect, useRef, useState} from "react";
import {Input, List} from "antd";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {findGroupIndexById, setAnswer, validateText} from "../../utils/tools";
import {setAnsAction} from "../../data/app/actions";

export default function Autocomplete(props) {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: "AIzaSyCksiK5cT4KTCKaDLYOMXdPFVy7dwkZCWM",
    options: {
      types: ["address"],
      componentRestrictions: { country: "at" }
    }});

  // Detect click outside for close menu
  const menuRef = useRef(null);

  useEffect(() => {
    const handler = (event) => {
      if (!menuRef.current?.contains(event.target)) {
        setShowLocationsMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [value, setValue] = useState("");

  const [showLocationsMenu, setShowLocationsMenu] = useState(false);

  const onPlaceSelected = (place, index) => {
    setShowLocationsMenu(false);
    setValue(place.description);
    if (place.description) {
      const text = place.description;
      placesService?.getDetails(
        {
          placeId: place.place_id,
        },
        () => {
          if(validateText(text, props.qs.answer.data_type)){
            if (props.work) {
              props.saveAddress(text);
              setValue(text);
            } else {
              props.answr[index].address = text;
              setValue(text);
              props.saveAddress(props.answr);
            }
          }
        }
      );
    }
  };

  return(
    <div>
      <Input.Search
        value={value}
        onChange={(evt) => {
          setShowLocationsMenu(true);
          getPlacePredictions({ input: evt.target.value });
          setValue(evt.target.value);
        }}
        loading={isPlacePredictionsLoading}
      />
      <div ref={menuRef}>
        {placePredictions.length && showLocationsMenu
          ? !isPlacePredictionsLoading && (
            <List
              style={{fontSize: "14px"}}
              dataSource={placePredictions}
              renderItem={(item) => (
                <List.Item
                  style={{cursor: "pointer", paddingTop: "7px", paddingBottom: "7px"}}
                  onClick={() => onPlaceSelected(item, props.index)}>
                  <List.Item.Meta title={item.description} />
                </List.Item>
              )}
            />
          )
          : null
        }

      </div>

    </div>
  );
}
