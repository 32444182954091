import React, {useEffect, useState} from "react";
import "./FinalStep.css";
import SvnComponent from "./SvnComponent";
import SignComponent from "./SignComponent";
import {cookieService} from "../../../services/cookie.service";
import {getAnsSelector, getLanguageSelector, getQsSelector} from "../../../data/app/selectors";
import {connect} from "react-redux";
import {formService} from "../../../services/form.service";
import {calculateTax} from "../../../utils/tools";
import FormSuccess from "../../../screens/FormSuccess";
import InfoContainer from "./InfoComponent";

function FinalStep(props) {

  const [currentComponent, setCurrentComponent] = useState("svn");
  const [svn, setSvn] = useState("");
  const [signature, setSignature] = useState("");
  const [taxNumber, setTaxNumber] = useState("");

  // Check if tax number provided from root component => switch to signature
  useEffect(() => {
    if (props && props.svn) {
      setSvn(props.svn);
      setCurrentComponent("svn");
    }
  }, [props]);

  const sendForm = async () => {
    const userToken = await new cookieService().getTokenData();
    if (userToken && setSvn && signature) {
      setCurrentComponent("finalLoading");
      const postData = {
        signature: signature.split(",")[1],
        user: {
          id: userToken.id,
          firstName: userToken.firstName,
          lastName: userToken.lastName,
          email: userToken.email,
          svn: svn,
          taxNumber: taxNumber
        },
        form: props.ANS,
        calculations: calculateTax(props.QS, props.ANS, props.language),
        taxNumber: taxNumber,
        device: "Web"
      };
      await new formService().sendForm(postData);
    }
  };

  const toFirstStep = () => {
    props.toFirstStep();
    setCurrentComponent("svn");
  };

  const getCurrentComponent = () => {
    switch (currentComponent) {
    case "svn":
      return <SvnComponent
        setCurrentComponent={(value) => setCurrentComponent(value)}
        setSvn={setSvn}
        setTaxNumber={setTaxNumber}
      />;
    case "signature":
      return <SignComponent
        setCurrentComponent={(value) => setCurrentComponent(value)}
        saveSignature={setSignature}
      />;
    case "info":
      return <InfoContainer
        sendForm={() => sendForm()}
      />;
    case "finalLoading":
      return <FormSuccess toFirstStep={() => toFirstStep()} year={props.ANS.year} />;
    }
  };

  return(
    <div className="question_box">
      <div className="final_step">
        {getCurrentComponent()}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    ANS: getAnsSelector(state),
    QS: getQsSelector(state),
    language: getLanguageSelector(state)
  };
};

export default connect(mapStateToProps, null)(FinalStep);
