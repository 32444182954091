import React, {useState, useEffect} from "react";
import {Table, Select, Input, Modal, Tooltip, Switch} from "antd";
import {DatePicker} from "antd";
const {RangePicker} = DatePicker;
import moment from "moment";
import {partnerManagerService} from "../../../services/partnerManager.service";
import DynamicSwitch from "../../../components/DynamicSwitch";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons";

export default function Users() {
  const [partnerFilters, setPartnerFilters] = useState([]);
  const [swtichFlag, setSwtichFlag] = useState(false);
  const [totalUsers, setTotalUsers] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [minimum1Flag, setMinimum1Flag] = useState(false);
  const [minimum1FlagDisabled, setMinimum1FlagDisabled] = useState(false);
  const [usersOfPartnerManager, setUsersOfPartnerManager] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: ""
  });

  const getPartnersOfPartnerManager = async () => {
    let result =
      await new partnerManagerService().getPartnersOfPartnerManager();
    result.data = result.data?.map((el, id) => ({
      key: "partnersPM" + id,
      ...el
    }));

    const partnerFilters = result.data?.map(el => ({
      name: el.name,
      path: el.path,
      flag: true
    }));
    setPartnerFilters(partnerFilters);
  };

  const getUsersOfPartnerManager = async (limit, skip) => {
    const reqData = {
      filter: partnerFilters.filter(el => el.flag),
      dateRange: selectedDate,
      limit: limit || 10,
      skip: skip || 0,
      minimum1: minimum1Flag
    };
    let result = await new partnerManagerService().getUsersOfPartnerManager(
      reqData
    );
    setTotalUsers(result.data?.total);

    result.data = result.data?.data?.map((el, id) => ({
      key: "usersPM" + id,
      ...el
    }));

    setUsersOfPartnerManager(result.data);
  };

  const paginate = (val, limit) => {
    setUsersOfPartnerManager(null);
    if (pageLimit !== limit) {
      setPageLimit(limit);
    }
    const skip = (val - 1) * limit;
    getUsersOfPartnerManager(limit, skip);
    setCurrentPage(val);
  };

  useEffect(async () => {
    await getPartnersOfPartnerManager();
    await getUsersOfPartnerManager();
  }, []);

  useEffect(async () => {
    setMinimum1FlagDisabled(true);
    setUsersOfPartnerManager(null);
    setPageLimit(10);
    await getUsersOfPartnerManager();
    setTimeout(() => {
      setMinimum1FlagDisabled(false);
    }, 500);
  }, [minimum1Flag, swtichFlag, selectedDate]);

  return (
    <div style={{overflow: "auto"}}>
      <div className="dashboard_box">
        <div className="dashboard_box">
          <h2 className="dashboard_header">Kunden ({totalUsers})</h2>
          {partnerFilters.length == 0 && (
            <LoadingOutlined style={{marginTop: "20px", marginLeft: "5%"}} />
          )}
          <div style={{display: "flex", flexDirection: "column"}}>
            <DynamicSwitch
              data={partnerFilters}
              setSwtichFlag={setSwtichFlag}
              swtichFlag={swtichFlag}
            />
            <Switch
              checkedChildren={"Mindestens 1 Steuererklärung"}
              unCheckedChildren={"Mindestens 1 Steuererklärung"}
              disabled={minimum1FlagDisabled}
              id="PM_minimum_switch"
              style={{
                backgroundColor: minimum1Flag ? "rgba(0, 190, 170, 1)" : "grey",
                marginLeft: "10px",
                marginTop: "5px",
                maxWidth: "250px"
              }}
              onChange={async () => {
                setMinimum1Flag(!minimum1Flag);
              }}
            />
          </div>
        </div>
        <div style={{marginTop: "15px"}}>
          <RangePicker
            style={{
              width: "auto",
              height: "auto",
              fontSize: "25px",
              borderRadius: "20px",
              padding: "10px",
              border: "2px solid rgba(0, 190, 170, 1)"
            }}
            defaultValue={[selectedDate.startDate, selectedDate.endDate]}
            format={"DD.MM.YYYY"}
            onChange={val => {
              if (val) {
                setSelectedDate({
                  startDate: moment(val[0]).format().split("T")[0],
                  endDate: moment(val[1]).format().split("T")[0]
                });
              } else {
                setSelectedDate({
                  startDate: "",
                  endDate: ""
                });
              }
            }}
          />
        </div>
      </div>
      <div>
        <Table
          id="partners_table"
          rowKey={"key"}
          loading={usersOfPartnerManager == null}
          dataSource={usersOfPartnerManager}
          pagination={{
            current: currentPage,
            pageSize: pageLimit,
            total: totalUsers,
            onChange: (val, limit) => paginate(val, limit)
          }}
        >
          <Table.Column
            dataIndex={"partner_name"}
            title={
              <Tooltip title={"Name deines Affiliate Links"}>Link Name</Tooltip>
            }
          />
          <Table.Column
            dataIndex={"count"}
            title={
              <Tooltip
                title={
                  "Anzahl an Steuererklärungen die von diesem Kunden erstellt wurden"
                }
              >
                Steuererklärungen
              </Tooltip>
            }
          />
          <Table.Column
            dataIndex={"sumPreCalc"}
            title={
              <Tooltip
                title={
                  "Summe aller durch Taxefy vorberechneten Rückzahlungen von allen Steuererklärungen dieses Kunden"
                }
              >
                Summe Vorberechnungen
              </Tooltip>
            }
          />
          <Table.Column
            dataIndex={"sumDecision"}
            title={
              <Tooltip
                title={
                  "Summe aller vom Finanzamt entschiedenen Rückzahlungen von den Steuererklärungen dieses Kunden"
                }
              >
                Summe Amtlicher Entscheidungen
              </Tooltip>
            }
          />
          <Table.Column
            dataIndex={"created_at"}
            title={
              <Tooltip title={"Registrierungs-Datum dieses Kunden"}>
                Registrierungs-Datum
              </Tooltip>
            }
            render={data => {
              return (
                <span>{new Date(data).toLocaleString().split(", ")[0]}</span>
              );
            }}
          />
          <Table.Column
            dataIndex={"activated"}
            title={
              <Tooltip title={"Registrierungs-Datum dieses Kunden"}>
                Aktiviert
              </Tooltip>
            }
            render={data => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    paddingLeft: "15px"
                  }}
                >
                  {data ? (
                    <CheckCircleOutlined
                      style={{fontSize: "25px", color: "rgba(0, 190, 170, 1)"}}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{fontSize: "25px", color: "#aa4455"}}
                    />
                  )}
                </div>
              );
            }}
          />
          <Table.Column
            dataIndex={"id"}
            title={
              <Tooltip title={"Taxefy-Interne Identifikations-Nummer"}>
                ID
              </Tooltip>
            }
          />
        </Table>
      </div>
    </div>
  );
}
