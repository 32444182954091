import React from "react";
import "./ButtonWithCheck.css";
import Checkbox from "../Checkbox/Checkbox";
import Circle from "react-circle";
import {useTranslation} from "react-i18next";

export default function ButtonWithCheck(props) {
  const { t } = useTranslation();

  return(
    <button
      className={props.selected ? "form_button_selected" : "form_button"}
      onClick={() => {
        props.handleClick(props.value);
      }}
      type="button"
      disabled={props.disabled}
    >
      {props.progress && props.progress > 0
        ? <div className="button_progress">
          {props.progress === "100"
            ? <p className="progress_badge">
              {t("questions.initialChoice.year.complete_badge")}
            </p>
            : <div className="form_progress_circle">
              <Circle
                showPercentage={false}
                showPercentageSymbol={false}
                textColor="#ffffff"
                progress={props.progress}
                lineWidth="40"
                progressColor="#09CE8A"
                bgColor="transparent"
              />
            </div>
          }
        </div>
        : null
      }
      {props.withCheckbox && props.checked !== undefined
        ? <div className="form_button_checkbox">
          <Checkbox
            id={`${props.value}_check`}
            disabled={!props.checked}
            checked={props.checked}
          />
        </div>
        : null
      }
      {props.text}
    </button>
  );
}
