import React, { useEffect, useState } from "react";
import Loading from "../../../../components/Loading";
import { Table, Switch } from "antd";
import { Modal, Select } from "antd";
import FormikInput from "../../../../components/CommonComponents/FormikInput/FormikInput";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Button from "../../../../components/CommonComponents/FormButton/FormButton";
import { EditOutlined } from "@ant-design/icons";

import { consultantService } from "../../../../services/consultant.service";
import "./ConsultantList.css";
import SuccessBox from "../../../../components/SuccessBox/SuccessBox";
import CreateConsultant from "../CreateConsultant/CreateConsultant";
import ErrorBox from "../../../../components/ErrorBox/ErrorBox";
import ConsultantStatus from "../../../../components/Consultant/ConsultantStatus";

export default function ConsultantList() {
  const phoneRegExp = /^\+?[0-9]*$/;
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [createdSuccess, setCreatedSuccess] = useState(false);
  const [consultantData, setConsultantData] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [initialEmail, setInitialEmail] = useState("");

  const { t } = useTranslation();

  const setEditData = record => {
    formik.values.firstName = record.firstName;
    formik.values.lastName = record.lastName;
    formik.values.email = record.email;
    formik.values.address = record.address;
    formik.values.phone = record.phone;
    formik.values.taxNumber = record.taxNumber;
    formik.values.maxPerDay = record.maxPerDay;
    formik.values.priority = record.priority;
    formik.values.status = record.status;
    formik.values.activated = record.activated;
    formik.values.password = "";
    formik.values.confirmPassword = "";
    setInitialEmail(record.email);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      address: "",
      phone: "",
      taxNumber: "",
      priority: 0,
      maxPerDay: 0,
      status: 0,
      activated: false
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required(t("auth.warnings.required")),
      lastName: Yup.string().required(t("auth.warnings.required")),
      address: Yup.string().required(t("auth.warnings.required")),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(8)
        .required(t("auth.warnings.required")),
      taxNumber: Yup.string().required(t("auth.warnings.required")),
      email: Yup.string()
        .email(t("auth.warnings.email"))
        .required(t("auth.warnings.required")),
      password: Yup.string().min(8, t("auth.warnings.password_length")),
      maxPerDay: Yup.number().required(t("auth.warnings.required")),
      priority: Yup.number().required(t("auth.warnings.required")),
      confirmPassword: Yup.string()
        .min(8, t("auth.warnings.password_length"))
        .when("password", {
          is: val => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("auth.warnings.password_not_same")
          )
        })
    }),

    onSubmit: async () => {
      setButtonLoading(true);
      setErrorMessage("");
      setSuccessMessage(false);
      const updateRes = await new consultantService().adminUpdateConsultantData(
        {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          initialEmail: initialEmail,
          email: formik.values.email,
          password: formik.values.password,
          address: formik.values.address,
          phone: formik.values.phone,
          taxNumber: formik.values.taxNumber,
          priority: formik.values.priority,
          maxPerDay: formik.values.maxPerDay,
          status: formik.values.status,
          activated: formik.values.activated
        }
      );
      if (updateRes && !updateRes.success) {
        setErrorMessage(updateRes.message);
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
        setSuccessMessage(true);
        getAllConsultantData();
        setTimeout(() => {
          setSuccessMessage(false);
        }, 3000);
      }
    }
  });

  const getAllConsultantData = async () => {
    setLoading(true);
    const result = await new consultantService().getAllConsultantData();

    if (result) {
      setConsultantData(result);
      setLoading(false);
    }
  };
  useEffect(async () => {
    setCreateIsOpen(false);
    await getAllConsultantData();
  }, [createdSuccess]);

  const toggleCreateIsOpen = () => {
    setCreateIsOpen(!createIsOpen);
  };

  return loading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div style={{ overflow: "auto" }}>
      <Modal
        width="70%"
        footer={null}
        closable={true}
        onCancel={toggleCreateIsOpen}
        visible={createIsOpen}
      >
        <CreateConsultant createdSuccess={setCreatedSuccess} />
      </Modal>
      <div style={{ marginTop: "40px" }} className="space-between">
        <h2>Consultant Users</h2>
        <Button
          text={"Add +"}
          style={{
            margin: "15px"
          }}
          buttonType="submit"
          handleClick={() => {
            setCreateIsOpen(true);
          }}
        />
      </div>
      <Table dataSource={consultantData}>
        <Table.Column
          title="First Name"
          dataIndex="firstName"
          key="firstName"
        />
        <Table.Column title="Last Name" dataIndex="lastName" key="lastName" />
        <Table.Column title="E-Mail" dataIndex="email" key="email" />
        <Table.Column title="Address" dataIndex="address" key="address" />
        <Table.Column title="Phone" dataIndex="phone" key="phone" />
        <Table.Column
          className="consultant_fo_col"
          title="Tax-Number"
          key="taxNumber"
          dataIndex="taxNumber"
        />
        <Table.Column title="Priority" dataIndex="priority" key="priority" />
        <Table.Column
          title="Max per Day"
          dataIndex="maxPerDay"
          key="maxPerDay"
        />
        <Table.Column
          title="Status"
          dataIndex="status"
          key="status"
          render={status => {
            return <ConsultantStatus status={status} />;
          }}
        />
        <Table.Column
          title="Activated"
          dataIndex="activated"
          key="activated"
          render={activated => {
            return <Switch disabled checked={activated} />;
          }}
        />
        <Table.Column
          title="Edit"
          key="edit"
          render={record => {
            return (
              <a
                style={{ fontSize: "20px" }}
                onClick={() => {
                  setIsOpen(true);
                  setEditData(record);
                }}
              >
                <EditOutlined />
              </a>
            );
          }}
        />
      </Table>
      {successMessage && (
        <SuccessBox message={"Consultant User Data successfully updated"} />
      )}
      {errorMessage && <ErrorBox message={errorMessage} />}
      <Modal width="70%" footer={null} closable={null} visible={isOpen}>
        <div style={{ width: "100%" }}>
          <h2>Edit Consultant Data</h2>
          <form onSubmit={formik.handleSubmit}>
            <div id="editForm">
              <div className="form_column">
                <div style={{ width: "90%", marginBottom: "10px" }}>
                  <FormikInput
                    name="firstName"
                    value={formik.values.firstName}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={"First Name"}
                    error={formik.errors.firstName}
                  />
                </div>
                <div style={{ width: "90%", marginBottom: "10px" }}>
                  <FormikInput
                    name="lastName"
                    value={formik.values.lastName}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={"Last Name"}
                    error={formik.errors.lastName}
                  />
                </div>
                <div style={{ width: "90%", marginBottom: "10px" }}>
                  <FormikInput
                    name="email"
                    value={formik.values.email}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={"E-Mail"}
                    error={formik.errors.email}
                  />
                </div>

                <div style={{ width: "90%", marginBottom: "10px" }}>
                  <FormikInput
                    name="address"
                    value={formik.values.address}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={"Address"}
                    error={formik.errors.address}
                  />
                </div>
                <div style={{ width: "90%", marginBottom: "10px" }}>
                  <FormikInput
                    name="phone"
                    value={formik.values.phone}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={"Phone"}
                    error={formik.errors.phone}
                  />
                </div>
                <div
                  style={{
                    width: "90%",
                    marginBottom: "10px",
                    marginTop: "10px"
                  }}
                >
                  <FormikInput
                    htmlFor="password"
                    name="password"
                    value={formik.values.password}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={"Password"}
                    error={formik.errors.password}
                  />
                </div>
              </div>
              <div className="form_column">
                <div style={{ width: "90%", marginBottom: "10px" }}>
                  <FormikInput
                    name="taxNumber"
                    value={formik.values.taxNumber}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={"Tax-Number"}
                    error={formik.errors.taxNumber}
                  />
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div
                    style={{
                      width: "30%",
                      marginBottom: "10px",
                      marginRight: "20px",
                      minWidth: "100px"
                    }}
                  >
                    <span
                      style={{
                        color: "rgba(0, 190, 170, 1",
                        fontWeight: "bold"
                      }}
                    >
                      Status
                    </span>
                    <Select
                      value={formik.values.status}
                      defaultValue={0}
                      name="status"
                      style={{ width: "200px" }}
                      onChange={e => {
                        formik.setFieldValue("status", e);
                      }}
                      options={[
                        { value: 0, label: "Disabled" },
                        { value: 1, label: "Enabled" },
                        { value: 2, label: "Half-Blocked" },
                        { value: 3, label: "Blocked" }
                      ]}
                    />
                  </div>
                  <div
                    style={{
                      width: "10%",
                      marginBottom: "10px",
                      minWidth: "100px"
                    }}
                  >
                    <FormikInput
                      htmlFor="checkbox"
                      name="activated"
                      value={formik.values.activated}
                      handleChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={"Activated"}
                    />
                  </div>
                  <div style={{ width: "15%", marginBottom: "10px" }}>
                    <FormikInput
                      name="maxPerDay"
                      value={formik.values.maxPerDay}
                      handleChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={"Max Per Day"}
                      error={formik.errors.maxPerDay}
                    />
                  </div>
                  <div
                    style={{
                      width: "15%",
                      marginBottom: "10px",
                      marginLeft: "20px"
                    }}
                  >
                    <FormikInput
                      name="priority"
                      value={formik.values.priority}
                      handleChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={"Priority"}
                      error={formik.errors.priority}
                    />
                  </div>
                </div>
                <div style={{ width: "90%", marginBottom: "10px" }}>
                  <FormikInput
                    htmlFor="password"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={"Confrim Password"}
                    error={formik.errors.confirmPassword}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <Button
                disabled={!formik.dirty || !formik.isValid}
                text={"Update"}
                style={{
                  width: "50%",
                  margin: "5px"
                }}
                buttonType="submit"
                handleClick={() => {
                  setIsOpen(false);
                }}
              />
              <Button
                text={"Cancel"}
                style={{
                  width: "50%",
                  margin: "5px"
                }}
                buttonType="button"
                handleClick={() => {
                  setIsOpen(false);
                }}
              />
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
