import React from "react";
import "./ScreensStyles.css";
import errorImage from "../img/404.png";
import Button from "../components/CommonComponents/FormButton/FormButton";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

export default function ErrorScreen() {
  const { t } = useTranslation();
  const history = useHistory();

  return(
    <div className="error_page">
      <img src={errorImage} alt="404"/>
      <h1>{t("error_page.title")}</h1>
      <Button
        text={t("error_page.btn")}
        handleClick={() => history.push("/")}
      />
    </div>
  );
}

