import React, {useEffect, useState} from "react";
import {Col, InputNumber, Row, Slider} from "antd";
import "./QuestionComponentsStyles.css";
import Button from "../CommonComponents/FormButton/FormButton";
import {getText} from "../../utils/tools";
import {motion} from "framer-motion";
import NavigationButtons from "./NavigationButtons/NavigationButtons";
import {useTranslation} from "react-i18next";

export default function SliderQuestion(props) {
  const { t } = useTranslation();

  const [buttonBlocked, setButtonBlocked] = useState(false);
  const [answered, setAnswered] = useState(false);

  useEffect(() => {
    if (props.savedAnswers[props.ANS.current_group] || props.ANS.groups.length === props.ANS.current_group - 1) {
      setAnswered(true);
    }
  }, [props.savedAnswers]);

  return(
    <motion.div
      className="single_choice"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{
        delay: 0.3,
        x: { type: "spring", stiffness: 100 },
        default: { duration: 1 },
      }}
    >

      {props.showButtonBack
        ? <NavigationButtons back={props.backward} forward={() => {
          if (answered) {
            props.forward(props.condition);
          }
        }} />
        : null
      }

      <div className="question_header_box">
        <h6>{getText(props.ANS, props.QS, props.qs.title, props.lang)}</h6>
        {props.qs.dsc && props.qs.dsc.length
          ? <div className="drawer_btn" onClick={() => {
            props.showDrawer(props.qs.dsc);
          }}>
            <i className="fas fa-question"></i>
          </div>
          : null
        }
      </div>
      <Row className="slider_container">
        <Col span={18}>
          <Slider
            min={props.qs.answer.min}
            max={props.qs.answer.max}
            onChange={val => props.setValue(val)}
            value={props.answr}
            trackStyle={{backgroundColor: "#00beaa"}}
            handleStyle={{backgroundColor: "#ffffff", borderColor: "#00beaa"}}
          />
        </Col>
        <InputNumber
          min={props.qs.answer.min}
          max={props.qs.answer.max}
          style={{ margin: "0 16px" }}
          value={props.answr}
          onChange={val => props.setValue(val)}
        />
      </Row>
      <div className="form_next_button_container">
        <Button
          text={t("questions.continue")}
          disabled={buttonBlocked || props.blocked}
          handleClick={() => {
            if (!buttonBlocked) {
              setButtonBlocked(true);
              if (!props.blocked) {
                setAnswered(true);
                props.forward(props.condition);
              } else {
                props.showError(props.condition.abort_code);
              }
              setTimeout(() => {
                setButtonBlocked(false);
              }, 500);
            }
          }}
        />
      </div>
    </motion.div>
  );
}
