import React, {useEffect, useState} from "react";
import FormikInput from "../../components/CommonComponents/FormikInput/FormikInput";
import {motion} from "framer-motion";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {notification, Switch, Tooltip} from "antd";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import {cookieService} from "../../services/cookie.service";
import axios from "axios";
import environment from "../../environment";

export default function BankData(props) {
  const { t } = useTranslation();
  const [buttonLoading, setButtonLoading] = useState(false);

  function mod97(string) {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }

  /*
   * Returns 1 if the IBAN is valid
   * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
   * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
   */
  function isValidIBANNumber(input) {
    var CODE_LENGTHS = {
      AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
      CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
      FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
      HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
      LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
      MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
      RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
      AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
      SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
    };
    let digits;
    let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, "");
    let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
  }

  const formik = useFormik({
    initialValues: {
      iban: props.data.iban,
      selected: props.data.selected
    },
    validationSchema: Yup.object({
      iban: Yup.string().required(t("profile.profile_tab.bank_information.iban_required"))
        .test("email-include-domain", t("profile.profile_tab.bank_information.iban_valid"), (val) => {
          const resp =  isValidIBANNumber(val);
          return resp === 1;
        }),
      selected: Yup.boolean(),
    }),
    // handle form submitting
    onSubmit: (e) => {
      console.log("NOW", formik.values.iban);
    },
  });

  useEffect(() => {
    formik.setFieldValue("iban", props.data.iban);
    formik.setFieldValue("selected", props.data.selected);
  }, [props.data]);

  const handleSwitch = async () => {
    const cookieToken = await new cookieService().getTokenData();
    await axios.put(`${environment.baseUrl}user/bankData/select`,
      {
        id: props.data.id
      },
      {
        headers: {
          Authorization: `Bearer ${cookieToken.refreshToken}`,
          "app-version": "1.0.1"
        }
      })
      .then((res) => {
        notification.open({
          message: t("profile.bank_tab.data_saved"),
          duration: 2
        });
      })
      .catch((e) => {
        if (
          e.response
          && e.response.data
          && e.response.data.message
          && e.response.data.message === "blocked_change_selected_item") {
          notification.open({
            message: t("profile.bank_tab.one_required"),
            duration: 2
          });
        } else {
          notification.open({
            message: t("profile.profile_tab.error_toast_title"),
            duration: 2
          });
        }
      }).finally(() => {
        props.getBankData();
      });
  };

  const updateData = async () => {
    if (formik.isValid && formik.dirty && !props.create) {
      const cookieToken = await new cookieService().getTokenData();
      await axios.put(`${environment.baseUrl}user/bankData`,
        {
          id: props.data.id,
          iban: formik.values.iban,
          selected: formik.values.selected
        },
        {
          headers: {
            Authorization: `Bearer ${cookieToken.refreshToken}`,
            "app-version": "1.0.1"
          }
        })
        .then((res) => {
          notification.open({
            message: t("profile.bank_tab.data_saved"),
            duration: 2
          });
        })
        .catch((e) => {
          notification.open({
            message: t("profile.profile_tab.error_toast_title"),
            duration: 2
          });
        }).finally(() => {
          props.getBankData();
        });
    }
  };

  const deleteBankItem = async () => {
    const cookieToken = await new cookieService().getTokenData();
    await axios.delete(`${environment.baseUrl}user/bankData/${props.data.id}`,
      {
        headers: {
          Authorization: `Bearer ${cookieToken.refreshToken}`,
          "app-version": "1.0.1"
        }
      })
      .catch((e) => {
        if (
          e.response
          && e.response.data
          && e.response.data.message
          && e.response.data.message === "delete_selected_item") {
          notification.open({
            message: t("profile.bank_tab.remove_required"),
            duration: 2
          });
        }
      }).finally(() => {
        props.getBankData();
      });
  };

  const createAccount = async () => {
    const cookieToken = await new cookieService().getTokenData();
    await axios.post(`${environment.baseUrl}user/bankData`,
      {
        iban: formik.values.iban
      },
      {
        headers: {
          Authorization: `Bearer ${cookieToken.refreshToken}`,
          "app-version": "1.0.1"
        }
      })
      .then(res => {
        if (res && res.data && res.data.id) {
          props.getBankData();
        }
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.id) {
          notification.open({
            message: t("profile.bank_tab.account_exists"),
            duration: 2
          });
        } else {
          notification.open({
            message: t("profile.profile_tab.error_toast_title"),
            duration: 2
          });
        }
      });
  };

  return(
    <form>
      <div className="bank_inputs_grid" style={props.create ? {gridTemplateColumns: "1fr 1fr"} : {}}>
        <div style={{ position: "relative" }}>
          <FormikInput
            htmlFor="iban"
            name="iban"
            value={formik.values.iban.replace(/[^\dA-Z]/g, "").replace(/(.{4})/g, "$1 ").trim()}
            disabled={false}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t("profile.profile_tab.bank_information.iban_label")}
            placeholder="AT33"
          />
          {formik.errors.iban && formik.touched.iban && (
            <motion.p
              animate={{ y: 5 }}
              className="input_error"
              style={{ fontSize: "10px" }}
            >
              {formik.errors.iban}
            </motion.p>
          )}
        </div>

        {props.create
          ? null
          : <div className="bank_check">
            <p>{t("profile.bank_tab.main_account")}</p>
            <Switch
              checked={formik.values.selected}
              onChange={() => handleSwitch()}
            />
            <div className="delete_bank_button" onClick={() => deleteBankItem()}>
              <i className="fas fa-trash"></i>
            </div>
            <div
              className="delete_bank_button"
              style={
                !(formik.isValid && formik.dirty && !props.create)
                  ? {
                    backgroundColor: "rgba(0, 190, 170, 0.15)",
                    borderColor: "rgba(0, 190, 170, 0.3)",
                    cursor: "not-allowed"
                  }
                  : {
                    backgroundColor: "rgba(0, 190, 170, 0.6)",
                    borderColor: "rgba(0, 190, 170, 1)"
                  }}
              onClick={() => updateData()}
            >
              <i className="fas fa-save"></i>
            </div>
          </div>
        }

      </div>

      {
        props.create
          ? <Button
            text={t("profile.bank_tab.add_account")}
            style={{marginTop: "30px", marginBottom: "20px", maxWidth: "250px", width: "100%"}}
            handleClick={() => createAccount()}
            loading={buttonLoading}
            disabled={!(formik.isValid && formik.dirty)}
          />
          : null
      }

    </form>
  );
}
