import React, {useEffect, useState} from "react";
import "../styles.css";
import { ResponsiveLine, linearGradientDef } from "nivo";
import BankData from "../../../../Profile/BankData";
import {Modal} from "antd";

export default function HeaderChart(props) {
  const [percentageValue, setPercentageValue] = useState(0);
  const [showModal, setShowModal] = useState(false);

  /*useEffect(() => {
    if (props.data && Object.keys(props.data).length > 0) {
      let startValue = props.data.data[0].y;
      let endValue = props.data.data[props.data.data.length - 1].y;
      if (startValue === 0 && endValue === 0) {
        setPercentageValue(0);
      } else {
        if (startValue < endValue) {
          if (startValue === 0 && endValue > 0) {
            setPercentageValue(100);
          } else if (endValue === 0) {
            setPercentageValue(-100);
          } else {
            setPercentageValue((startValue / endValue) * 100);
          }
        } else {
          if (endValue === 0 && startValue > 0) {
            setPercentageValue(-100);
          } else {
            setPercentageValue((endValue / startValue) * -100);
          }
        }
      }
    }
  }, [props.data]);*/

  return(
    <div className="header_chart" style={props.withBorder ? {borderRight: "2px solid #eeeeee"} : {}}>
      <Modal
        footer={null}
        title={"Chart"}
        visible={showModal}
        onCancel={() => setShowModal(false)}
      >
        <div style={{width: "470px", height: "300px"}}>
          <ResponsiveLine
            colorBy={d => d.color}
            data={{}}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false
            }}
            curve="cardinal"
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridX={false}
            enableGridY={false}
            lineWidth={1}
            enablePoints={false}
            pointSize={10}
            pointColor={{ from: "color" }}
            pointBorderWidth={0}
            pointBorderColor={{ from: "color" }}
            pointLabelYOffset={-12}
            enableArea={true}
            areaOpacity={0.1}
            useMesh={true}
            legends={[]}
            animate={true}
          />
        </div>
      </Modal>

      <div className="header_chart_content">
        <h4>{props.title}</h4>
        <h3>{
          props.data
          && Object.keys(props.data).length > 0
            ? props.id !== "Forms"
              ? props.data.count.toFixed(2)
              : props.data.count
            : "loading"}
        </h3>
        {/* <div
          className="header_chart_badge"
          style={percentageValue < 0 ? {backgroundColor: "rgba(240, 52, 52, 0.4)"} : {}}
        >
          {percentageValue.toFixed(2)}%
        </div> */}
      </div>
      {/*<div
        style={{width: "110px", height: "80px"}}
      >
        {props.data && Object.keys(props.data).length > 0
          ? <ResponsiveLine
            colorBy={d => d.color}
            data={[
              {
                "id": props.id,
                "color": "#0027bc",
                "data": props.data.data
              }
            ]}
            margin={{ bottom: 5, left: 5, right: 5, top: 5 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false
            }}
            curve="cardinal"
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridX={false}
            enableGridY={false}
            lineWidth={1}
            enablePoints={false}
            pointSize={10}
            pointColor={{ from: "color" }}
            pointBorderWidth={0}
            pointBorderColor={{ from: "color" }}
            pointLabelYOffset={-12}
            enableArea={true}
            areaOpacity={0.1}
            useMesh={true}
            legends={[]}
            animate={true}
          />
          : <p>Loading</p>
        }
      </div>*/}
    </div>
  );
}
