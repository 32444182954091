import React, {useEffect, useState} from "react";
import QuestionnaireScreen from "../screens/QuestionnaireScreen";
import {
  getAnsSelector,
  getLanguageSelector,
  getQsSelector,
  getUserDataSelector
} from "../data/app/selectors";
import {connect, useDispatch} from "react-redux";
import Header from "../components/Header/Header";
import Stepper from "../components/Stepper/Stepper";
import {setCurrentQsAction, setQsAction} from "../data/app/actions";
import {AT2020} from "../forms/newForm.json";
import CountrySelect from "../components/CommonComponents/CountrySelection/CountrySelection";
import FinalStep from "../components/Containers/FinalStep/FinalStep";
import {useHistory} from "react-router-dom";
import Loading from "../components/Loading";

function QuestionsPage(props) {
  const history = useHistory();

  const [taxNumber, setTaxNumber] = useState("");

  // Validate if user comes from create tax number
  useEffect(() => {
    if (history && history.location && history.location.state && history.location.state.taxNumber) {
      setTaxNumber(history.location.state.taxNumber);
      setCurrentComponent("finalStep");
    }
  }, []);

  const [currentComponent, setCurrentComponent] = useState("countries");

  const toFirstStep = () => {
    setCurrentComponent("countries");
    props.refreshCountriesData();
  };

  const getCurrentComponent = () => {
    switch (currentComponent) {
    case "countries":
      return <CountrySelect
        countriesData={props.countriesData}
        setCurrentComponent={setCurrentComponent}
      />;
    case "finalStep":
      return <FinalStep taxNumber={taxNumber} toFirstStep={() => toFirstStep()} />;
    }
  };

  useEffect(() => {
    props.setCurrentComponent(currentComponent);
  }, [currentComponent]);

  return(
    <div className="page">
      {currentComponent.length
        ? getCurrentComponent()
        : <>
          <Stepper />
          {props.QS && Object.keys(props.QS).length
          && props.ANS && Object.keys(props.ANS).length
          && props.language
            ? <QuestionnaireScreen setCurrentComponent={setCurrentComponent} />
            : <Loading />
          }
        </>
      }
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    QS: getQsSelector(state),
    ANS: getAnsSelector(state),
    language: getLanguageSelector(state),
    userData: getUserDataSelector(state)
  };
};

export default connect(mapStateToProps, null)(QuestionsPage);
