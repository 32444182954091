import { fromJS } from "immutable";

// Constants
import { CONSTANTS } from "./constants";

const initialState = fromJS({
  isLoading: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case CONSTANTS.ON_AUTH_LOADING:
    return state.set("isLoading", action.payload.isLoading);

  default:
    return state;
  }
};

export default reducer;
