import React, {useEffect, useState} from "react";
import "./styles.css";
import {cookieService} from "../../../../services/cookie.service";
import axios from "axios";
import environment from "../../../../environment";
import {Input, Table} from "antd";

const { Search } = Input;
const { Column } = Table;

export default function Errors() {

  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalForms, setTotalForms] = useState(0);
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const getFormsData = async (search, limit, skip) => {
    setLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const queryParams = {
      skip: skip || 0,
      limit: limit || 10
    };
    if (search) {
      Object.assign(queryParams, {"search": search});
    }
    const response = await axios.get(`${environment.baseUrl}admin/form/foErrors`, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      const transformedData = response.data.data.map(dataItem => {
        return {...dataItem, key: dataItem.id};
      });
      setForms(transformedData);
      setTotalForms(Number(response.data.total));
      setLoading(false);
    }
  };

  useEffect(() => {
    getFormsData();
  }, []);

  const onSearch = (value) => {
    setSearchData(value);
    getFormsData(value, 10, 0);
  };

  const paginate = (val) => {
    const skip = val * 10 - 1;
    getFormsData(searchData, 10, skip);
    setCurrentPage(val);
  };

  return(
    loading
      ? <p>Loding</p>
      : <div>
        <div className="space-between">
          <h3>Errors from Finanz Online</h3>
          <Search
            placeholder="Search"
            onSearch={onSearch}
            className="admin_search"
          />
        </div>
        {loading
          ? <p>Loading</p>
          : forms && forms.length
            ? <>
              <Table
                dataSource={forms}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalForms,
                  onChange: (val) => paginate(val)
                }}
              >
                <Column title="Id" dataIndex="id" key="id" />
                <Column title="Form Id" dataIndex="form_id" key="form_id" />
                <Column title="Error Text" dataIndex="error_text" key="error_text" />
              </Table>
            </>
            : <p>No data</p>
        }
      </div>
  );
}
