import React, {useEffect, useState, useRef} from "react";
import {notification, Switch} from "antd";
import {cookieService} from "../../../services/cookie.service";
import axios from "axios";
import environment from "../../../environment";
import Loading from "../../../components/Loading";
import { DatePicker, Table, Card, Input } from "antd";
import moment from "moment";
import Button from "../../../components/CommonComponents/FormButton/FormButton";
import { CSVLink } from "react-csv";
import { useStateWithCallbackLazy } from "use-state-with-callback";

const { RangePicker } = DatePicker;

export default function SettingsTab() {
  const [loading, setLoading] = useState(true);
  const [sendMail, setSendMail] = useState(true);
  const [sendMailDisabled, setSendMailDisabled] = useState(false);
  const [paymentsTableData, setPaymentsTableData] = useState([]);
  const [generatedCSV, setGeneratedCSV] = useState([]);
  const [changeIbanLimit, setChangeIbanLimit] = useState(0);
  const [changeIbanOffset, setChangeIbanOffset] = useState(0);
  const [ibanButtonLoading, setIbanButtonLoading] = useState(false);
  const [changeApplicationsLimit, setChangeApplicationsLimit] = useState(0);
  const [changeApplicationsOffset, setChangeApplicationsOffset] = useState(0);
  const [applicationsButtonLoading, setApplicationsButtonLoading] = useState(false);
  const [getAllUsersButtonLoading, setGetAllUsersButtonLoading] = useState(false);
  const [getUsersWithApplicationsButtonLoading, setGetUsersWithApplicationsButtonLoading] = useState(false);
  const [getUsersWithoutApplicationsButtonLoading, setGetUsersWithoutApplicationsButtonLoading] = useState(false);
  // eslint-disable-next-line max-len
  const [finishedForms, setFinishedForms] = useState([]);
  const [numberOfUsersToSetBack, setNumberOfUsersToSetBack] = useState(0);

  const getUsersNumberToSetBack = async () => {
    const cookieToken = await new cookieService().getAdminTokenData();
    const response = await axios.get(`${environment.baseUrl}admin/form/getAllUsersToSetBack`, {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      setNumberOfUsersToSetBack(response.data.users);
    }
  };

  const getSettings = async () => {
    const cookieToken = await new cookieService().getAdminTokenData();
    const response = await axios.get(`${environment.baseUrl}admin/settings`, {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      setSendMail(response.data.sendMail);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSettings();
    getUsersNumberToSetBack();
  }, []);

  const handleMailsSwitch = async () => {
    setSendMailDisabled(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const response = await axios.put(`${environment.baseUrl}admin/settings/switchMail`,
      {
        sendMail: !sendMail
      },
      {
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      });
    if (response && response.data) {
      setSendMailDisabled(false);
      getSettings();
    } else {
      setSendMailDisabled(false);
    }
  };

  const [selectedFile, setSelectedFile] = useState();

  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: ""
  });

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handlePaymentFileSubmit = async () => {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();
      const formData = new FormData();
      formData.append("myfile", selectedFile);
      formData.append("startDate", selectedDate.startDate);
      formData.append("endDate", selectedDate.endDate);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      };
      axios.post(`${environment.baseUrl}admin/settings/createPaymentsFile`,formData,config)
        .then((response) => {
          /*setPaymentsTableData(response.data);
          transformToCSVTables(response.data);*/
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "payments.csv");
          document.body.appendChild(link);
          link.click();
        }).catch((error) => {
          if (
            error.response
            && error.response.data
            && error.response.data.message
            && error.response.data.message === "no_data"
          ) {
            notification.open({
              message: "No data was found",
              duration: 2
            });
          }
        });

    } catch (e) {
      console.log(e);
    }
  };

  const transformToCSVTables = (data) => {
    const resultData = [];
    resultData.push(Object.keys(data[0]));
    data.map((dataItem, idx) => {
      resultData.push(Object.values(data[idx]));
    });
    setGeneratedCSV(resultData);
  };

  const handleTableChange = (idx, field, changeValue) => {
    const tableStateCopy = [...paymentsTableData];
    tableStateCopy[idx][field] = changeValue;
    setPaymentsTableData(tableStateCopy);
    transformToCSVTables(tableStateCopy);
  };

  const ibansFileHandler = async (event) => {
    setLoading(true);
    try {
      const cookieToken = await new cookieService().getAdminTokenData();
      const formData = new FormData();
      formData.append("myfile", event.target.files[0]);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      };
      axios.post(`${environment.baseUrl}admin/settings/createIbansTable`, formData, config)
        .then((response) => {
          notification.open({
            message: "Data received and started processing",
            duration: 2
          });
          setLoading(false);
        }).catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const responseTextFileHandler = async (event) => {
    console.log(event.target.files[0]);
    try {
      const cookieToken = await new cookieService().getAdminTokenData();
      const formData = new FormData();
      formData.append("myfile", event.target.files[0]);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      };
      axios.post(`${environment.baseUrl}admin/settings/responseText`, formData, config)
        .then((response) => {
          notification.open({
            message: "Data received and started processing",
            duration: 2
          });
        }).catch((error) => {
          console.error(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const setIbansBack = async () => {
    setIbanButtonLoading(true);
    window.localStorage.setItem("changeIbanOffset", Number(changeIbanLimit) + Number(changeIbanOffset));
    console.log("Called");
    const cookieToken = await new cookieService().getAdminTokenData();
    const config = {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    };
    axios.post(`${environment.baseUrl}admin/form/setCompanyIban`, {
      limit: changeIbanLimit,
      offset: changeIbanOffset
    }, config)
      .then((response) => {
        notification.open({
          message: "Changing is finished",
          duration: 2
        });
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        setIbanButtonLoading(false);
      });
  };

  const setApplications = async () => {
    setApplicationsButtonLoading(true);
    window.localStorage.setItem(
      "changeApplicationsOffset",
      Number(changeApplicationsLimit) + Number(changeApplicationsOffset));
    const cookieToken = await new cookieService().getAdminTokenData();
    const config = {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    };
    axios.post(`${environment.baseUrl}admin/form/createTable`, {
      limit: changeApplicationsLimit,
      offset: changeApplicationsOffset
    }, config)
      .then((response) => {
        setFinishedForms(response.data.taxNumbers);
        getUsersNumberToSetBack();
        notification.open({
          message: "Changing is finished",
          duration: 2
        });
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        setApplicationsButtonLoading(false);
      });
  };

  const getAllUsers = async () => {
    setGetAllUsersButtonLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const config = {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      },
      responseType: "blob"
    };
    axios.get(`${environment.baseUrl}admin/form/getAllUsersFile`, config)
      .then((res) => {
        if (res) {
          const fileName = "all_users.xlsx";
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

          const xhr = new XMLHttpRequest();
          const a = document.createElement("a");
          let file;
          const url = window.URL.createObjectURL(
            new Blob([res.data],
              {type: fileType})
          );
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.onload = function () {
            file = new Blob([xhr.response], { type : fileType });
            a.href = window.URL.createObjectURL(file);
            a.download = fileName;
            a.click();
          };
          xhr.send();
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setGetAllUsersButtonLoading(false);
      });
  };

  const getUsersWithNewPOA  = async () => {
    setGetAllUsersButtonLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const config = {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      },
      responseType: "blob"
    };
    axios.get(`${environment.baseUrl}admin/form/getUsersWithNewPOAFile`, config)
      .then((res) => {
        if (res) {
          const fileName = "users_with_new_poa.xlsx";
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

          const xhr = new XMLHttpRequest();
          const a = document.createElement("a");
          let file;
          const url = window.URL.createObjectURL(
            new Blob([res.data],
              {type: fileType})
          );
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.onload = function () {
            file = new Blob([xhr.response], { type : fileType });
            a.href = window.URL.createObjectURL(file);
            a.download = fileName;
            a.click();
          };
          xhr.send();
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setGetAllUsersButtonLoading(false);
      });
  };

  const getUsersWithApplications = async () => {
    setGetUsersWithApplicationsButtonLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const config = {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      },
      responseType: "blob"
    };
    axios.get(`${environment.baseUrl}admin/form/getUsersWithApplicationsFile`, config)
      .then((res) => {
        if (res) {
          const fileName = "users_with_applications.xlsx";
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

          const xhr = new XMLHttpRequest();
          const a = document.createElement("a");
          let file;
          const url = window.URL.createObjectURL(
            new Blob([res.data],
              {type: fileType})
          );
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.onload = function () {
            file = new Blob([xhr.response], { type : fileType });
            a.href = window.URL.createObjectURL(file);
            a.download = fileName;
            a.click();
          };
          xhr.send();
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setGetUsersWithApplicationsButtonLoading(false);
      });
  };

  const getUsersWithoutApplications = async () => {
    setGetUsersWithoutApplicationsButtonLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const config = {
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      },
      responseType: "blob"
    };
    axios.get(`${environment.baseUrl}admin/form/getUsersWithoutApplicationsFile`, config)
      .then((res) => {
        if (res) {
          const fileName = "users_without_applications.xlsx";
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

          const xhr = new XMLHttpRequest();
          const a = document.createElement("a");
          let file;
          const url = window.URL.createObjectURL(
            new Blob([res.data],
              {type: fileType})
          );
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.onload = function () {
            file = new Blob([xhr.response], { type : fileType });
            a.href = window.URL.createObjectURL(file);
            a.download = fileName;
            a.click();
          };
          xhr.send();
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setGetUsersWithoutApplicationsButtonLoading(false);
      });
  };

  const [usersTableOffset, setUsersTableOffset] = useState(0);

  const createTable = async () => {
    console.log(usersTableOffset);

    const queryParams = {
      skip: usersTableOffset
    };

    const cookieToken = await new cookieService().getAdminTokenData();
    await axios.get(`${environment.baseUrl}admin/form/createTable`, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    })
      .then((res) => {
        setFinishedForms(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return(
    <div className="admin_tab">

      <div style={{overflow: "auto"}}>
        <p>
          Forms:
          <br />
          {JSON.stringify(finishedForms)}
        </p>
      </div>

      {loading
        ? <Loading />
        : <>
          <h3>General</h3>
          <div style={{
            display: "flex",
            alignItems: "center"
          }}>
            <Switch
              disabled={sendMailDisabled}
              checked={sendMail}
              onChange={() => handleMailsSwitch()}
            />
            <p style={{
              marginTop: 0,
              marginBottom: 0,
              marginLeft: "10px"
            }}>Send warning emails</p>
          </div>
          <PaymentFile />
          { false &&
            <>
              <h3 style={{
                marginTop: "30px",
                marginBottom: "10px"
              }}>Generate payment file</h3>
              <p style={{
                margin: 0,
                fontSize: "12px"
              }}>Selected days will be also included</p>
              <div>
                <RangePicker
                  defaultValue={[moment(new Date()), moment(new Date())]}
                  format={"DD.MM.YYYY"}
                  onChange={(val) => {
                    setSelectedDate({
                      startDate: moment(val[0]).format("DD.MM.YYYY"),
                      endDate: moment(val[1]).format("DD.MM.YYYY")
                    });
                  }}
                />
                <input type="file" name="file" onChange={changeHandler} />
                <Button
                  text={"Submit"}
                  handleClick={() => handlePaymentFileSubmit()}
                />
              </div>
              <div style={{
                overflow: "auto"
              }}>
                {paymentsTableData && paymentsTableData.length
                  ? <>
                    <div style={{
                      marginTop: "50px"
                    }}>
                      <CSVLink data={generatedCSV} separator=";">Download CSV</CSVLink>
                    </div>
                    <table style={{
                      minWidth: "1700px"
                    }}>
                      <thead>
                        <tr>
                          {Object.keys(paymentsTableData[0]).map((headerKey) => (
                            <th
                              style={{
                                marginLeft: 0,
                                textAlign: "left"
                              }}
                              key={headerKey.toString().trim()}
                            >
                              {headerKey}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {paymentsTableData.map((tableItem, idx) => (
                          <tr key={`${tableItem["Empfaenger-IBAN"]}_${tableItem["Betrag"]}`}>
                            {Object.keys(tableItem).map((item) => (
                              <td key={item}>
                                <input
                                  style={{
                                    width: "100%"
                                  }}
                                  type="text"
                                  value={tableItem[item]}
                                  onChange={(e) => handleTableChange(idx, item, e.target.value)}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                  : null
                }
              </div>
            </>
          }

          <h3>Write ibans to table</h3>
          <input type="file" name="ibanFile" onChange={ibansFileHandler} />

          <div style={{height: "30px"}}>
            <h3>Response text reading</h3>
            <input type="file" name="responseText" onChange={responseTextFileHandler} />
          </div>

          <div style={{height: "50px"}} />

          <p>{ibanButtonLoading ? "Iban change in proccess" : null}</p>

          <h4>Set iban and permissions back</h4>

          <p>Left users to set back: {numberOfUsersToSetBack}</p>

          {/*<div>
            <label htmlFor="">
              Limit
            </label>
            <input
              type="text"
              value={changeApplicationsLimit}
              onChange={(event) => {
                setChangeApplicationsLimit(event.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="">
              Offset
            </label>
            <input
              type="text"
              value={changeApplicationsOffset}
              onChange={(event) => {
                setChangeApplicationsOffset(event.target.value);
              }}
            />
          </div>*/}

          <button
            onClick={() => setApplications()}
            disabled={ibanButtonLoading}
          >
            Change iban and permissions back
          </button>

          <div style={{height: "60px"}} />

          <button
            onClick={() => getUsersWithNewPOA()}
            disabled={getAllUsersButtonLoading}
          >
                  Get Users with new POA XLSX
          </button>

          <button
            onClick={() => getAllUsers()}
            disabled={getAllUsersButtonLoading}
          >
            Get All Users XLSX
          </button>

          <button
            onClick={() => getUsersWithApplications()}
            disabled={getUsersWithApplicationsButtonLoading}
          >
            Get Users With Applications XLSX
          </button>

          <button
            onClick={() => getUsersWithoutApplications()}
            disabled={getUsersWithoutApplicationsButtonLoading}
          >
            Get Users WITHOUT any aplication done XLSX
          </button>

        </>
      }
    </div>
  );
}

const unaccent = text => text.toLowerCase().replace(/ö/g, "oe").replace(/ä/g, "ae")
  .replace(/ü/g, "ue").replace(/ß/g, "ss").replace(/-/g, " ").normalize("NFD").replace(/[\u0300-\u036f]/g, "");


const PaymentFile = () => {

  const [result, setResult] = useState(false);
  const [csvData, setCsvData] = useStateWithCallbackLazy([]);
  const csvLink = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [data, setData] = useState([]);
  const headers = [
    { label: "Auftragsart", key: "C1" },
    { label: "Auftraggeber-IBAN", key: "C2" },
    { label: "Auftraggeber-BIC", key: "C3" },
    { label: "Auftraggeber-Name", key: "C4" },
    { label: "Betrag", key: "C5" },
    { label: "Auftragswaehrung", key: "C6" },
    { label: "Empfaenger-IBAN", key: "C7" },
    { label: "Empfaenger-BIC", key: "C8" },
    { label: "Empfaenger-Name", key: "C9" },
    { label: "Durchfuehrungsdatum (optional)", key: "C10" },
    { label: "Zahlungsreferenz (optional)", key: "C11" },
    { label: "Verwendungszweck (optional)", key: "C12" },
    { label: "Sammelauftrag (optional)", key: "C13" },
    { label: "Bezeichnung des Sammelauftrags (optional)", key: "C14" },
    { label: "Auftraggeber-Anschrift (optional)", key: "C15" },
    { label: "Empfaenger-Anschrift (optional)", key: "C16" },
    { label: "Auftraggeberreferenz (optional)", key: "C17" },
    { label: "Art der Zahlung (optional)", key: "C18" },
    { label: "Geschaeftsvorfallcode (optional)", key: "C19" },
    { label: "Abweichender Auftraggeber (optional)", key: "C20" },
    { label: "Abweichender Empfaenger (optional)", key: "C21" },
    { label: "Einzelbuchung gewuenscht (optional)", key: "C22" },
    { label: "Spesenoption (optional)", key: "C23" }
  ];

  const exportCSV = () => {
    const DATA = [];
    for(const rec of data){
      if(rec.error) continue;
      const finalAmountCustomer = (rec.amount * 0.9).toFixed(2).toString().replace(/\./g,",");
      const finalAmountTaxefy = (rec.amount * 0.1).toFixed(2).toString().replace(/\./g,",");
      const cleanName = unaccent(rec.name);
      DATA.push({
        C1: "CT",
        C2: "AT792011184477051101",
        C3: "GIBAATWWXXX",
        C4: "Taxefy GmbH",
        C5: finalAmountCustomer, //
        C6: "EUR",
        C7: rec.iban, // user iban
        C8: rec.bic, // user bic
        C9: cleanName, // user name
        C10: "",
        C11: "",
        C12: "Taxefy sagt Danke! Viel Spass mit dem Geld",
        C13: "",
        C14: "",
        C15: "",
        C16: "",
        C17: "",
        C18: "",
        C19: "",
        C20: "",
        C21: "",
        C22: "",
        C23: ""
      });
      DATA.push({
        C1: "CT",
        C2: "AT792011184477051101",
        C3: "GIBAATWWXXX",
        C4: "Taxefy GmbH",
        C5: finalAmountTaxefy, //
        C6: "EUR",
        C7: "AT092011184477051100",
        C8: "GIBAATWWXXX",
        C9: "Taxefy GmbH",
        C10: "",
        C11: "",
        C12: `${cleanName} app payment`,
        C13: "",
        C14: "",
        C15: "",
        C16: "",
        C17: "",
        C18: "",
        C19: "",
        C20: "",
        C21: "",
        C22: "",
        C23: ""
      });
    }
    setCsvData(DATA, dt => {
      setTimeout(() => {
        csvLink.current.link.click();
      });
    });
    return true;
  };

  const exportFamilyBonusCSV = () => {
    const DATA = [];
    for(const rec of data){
      if(rec.error) continue;
      const finalAmountCustomer = rec.amount.toFixed(2).toString().replace(/\./g,",");
      const cleanName = unaccent(rec.name);
      DATA.push({
        C1: "CT",
        C2: "AT792011184477051101",
        C3: "GIBAATWWXXX",
        C4: "Taxefy GmbH",
        C5: finalAmountCustomer, //
        C6: "EUR",
        C7: rec.iban, // user iban
        C8: rec.bic, // user bic
        C9: cleanName, // user name
        C10: "",
        C11: "",
        C12: "Familienbeihilfe",
        C13: "",
        C14: "",
        C15: "",
        C16: "",
        C17: "",
        C18: "",
        C19: "",
        C20: "",
        C21: "",
        C22: "",
        C23: ""
      });
    }
    setCsvData(DATA, dt => {
      setTimeout(() => {
        csvLink.current.link.click();
      });
    });
    return true;
  };

  const changeHandler = event => {
    setSelectedFile(event.target.files[0]);
  };

  const handlePaymentFileSubmit = async () => {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();
      const formData = new FormData();
      formData.append("myfile", selectedFile);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      };
      axios.post(`${environment.baseUrl}admin/settings/preparePaymentsFile`,formData,config)
        .then((response) => {
          setData(response.data.data);
          setResult(true);
        }).catch((error) => {
          if (
            error.response
            && error.response.data
            && error.response.data.message
            && error.response.data.message === "no_data"
          ) {
            notification.open({
              message: "No data was found",
              duration: 2
            });
          }
        });

    } catch (e) {
      console.log(e);
    }
  };

  const handlePaymentRetry = async () => {
    try {
      const cookieToken = await new cookieService().getAdminTokenData();
      //const formData = new FormData();
      //formData.append("data", JSON.stringify(data));
      const config = {
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      };
      const formData = {
        data: JSON.stringify(data)
      };
      axios.post(`${environment.baseUrl}admin/settings/preparePaymentsRetry`,formData,config)
        .then((response) => {
          setData(response.data.data);
          //console.log(response.data.data);
        }).catch((error) => {
          if (
            error.response
            && error.response.data
            && error.response.data.message
            && error.response.data.message === "no_data"
          ) {
            notification.open({
              message: "No data was found",
              duration: 2
            });
          }
        });

    } catch (e) {
      console.log(e);
    }
  };

  const setName = (idx, name) => {
    const data1 = [...data];
    data1[idx-1].name = name;
    setData(data1);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },{
      title: "Ref",
      dataIndex: "ref",
      key: "ref",
      render: (txt, rec) => rec.error ? <span style={{color: "red"}}>{txt}</span> : txt
    },{
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (txt, rec) => rec.error ? <Input value={txt} onChange={e => setName(rec.key, e.target.value)} /> : txt
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: txt => `${txt} €`
    },
    {
      title: "Iban",
      dataIndex: "iban",
      key: "iban",
    },
    {
      title: "Bic",
      dataIndex: "bic",
      key: "bic",
    },
  ];

  return (
    <div style={{
      margin: "4em 0"
    }}>
      <Card size="small" title="Generate payment file" >
        {
          !result ?
            <>
              <input style={{margin: "0 1em"}} type="file" name="file" onChange={changeHandler} />
              <Button
                text={"Next >"}
                handleClick={() => handlePaymentFileSubmit()}
              />
              <br />
            </>
            :
            <>
              <Table dataSource={data} columns={columns} size="small" pagination={false} />
              <br />
              <Button
                text={"Back"}
                handleClick={() => setResult(false)}
              />
              <Button
                style={{margin: "0 1em"}}
                text={"Retry"}
                handleClick={() => handlePaymentRetry()}
              />
              <CSVLink separator=";" ref={csvLink} data={csvData} headers={headers}
                enclosingCharacter=""
                filename={`${moment().format("DD-MM-YYYY.HHmmss")}.csv`}
              />
              <Button
                style={{margin: "0 1em 0 0"}}
                text={"Download Taxefy CSV"}
                handleClick={() => exportCSV()}
              />
              <Button
                text={"Download Family Bonus CSV"}
                handleClick={() => exportFamilyBonusCSV()}
              />
            </>

        }
      </Card>
    </div>
  );
};
