export const formStatuses = [
  {
    title: "Neue",
    value: 0
  },
  {
    title: "Sent to FO",
    value: 1
  },
  {
    title: "Money sent",
    value: 3
  },
  {
    title: "Iban Changed",
    value: 4
  },
  {
    title: "Declined",
    value: 6
  },
];

export const financeOnlineStatuses = [
  {
    title: "Created",
    value: 0
  },
  {
    title: "Scheduled",
    value: 1
  },
  {
    title: "Req. change permission",
    value: 2
  },
  {
    title: "Change permission",
    value: 3
  },
  {
    title: "Req. tax number",
    value: 4
  },
  {
    title: "Change iban",
    value: 5
  },
  {
    title: "xml generated",
    value: 6
  },
  {
    title: "xml sent to FO",
    value: 7
  },
  {
    title: "Form completed",
    value: 8
  },
];


