import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {partnersService} from "../../../../../services/partners.service";
import {Table, Card, Col, Row, notification, Breadcrumb} from "antd";
import moment from "moment";
import Loading from "../../../../../components/Loading";
import {CopyOutlined} from "@ant-design/icons";

const { Column } = Table;

export default function PartnerDetailPage(props) {
  const { id } = useParams();

  const [usersData, setUsersData] = useState(null);
  const [usersLoading, setUsersLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [infoLoading, setInfoLoading] = useState(true);
  const [infoData, setInfoData] = useState({
    name: "",
    link: "",
    users: 0,
    forms: 0,
    precalculatedTotalAmount: 0,
    decisionTotalAmount: 0
  });

  const getPartnerUsers = async (limit, skip) => {
    const response = await new partnersService().getPartnerUsers({
      id: id,
      limit: limit,
      skip: skip
    });
    if (response.status) {
      setUsersData(response.data);
    }
    setUsersLoading(false);
  };

  const getPartnersInfo = async () => {
    const response = await new partnersService().getPartnerInfo(id);
    if (response.status) {
      setInfoData(response.data);
      setInfoLoading(false);
    }
  };

  const paginate = (val, limit) => {
    if (pageLimit !== limit) {
      setPageLimit(limit);
    }
    const skip = (val - 1) * limit;
    setUsersLoading(true);
    getPartnerUsers(limit, skip);
    setCurrentPage(val);
  };

  useEffect(() => {
    if (id) {
      getPartnerUsers(pageLimit, 0);
      getPartnersInfo();
    }
  }, [id]);

  return(
    <div>

      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/admin/panel/dashboard">Admin</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/admin/panel/partners">Partners</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{infoData.name}</Breadcrumb.Item>
      </Breadcrumb>

      <div style={{height: "20px"}} />

      {!infoLoading
        &&
        <>
          <p><strong>Partner name:</strong> {infoData.name}</p>
          <p><strong>Partner Link: <CopyOutlined
            style={{fontSize: "18px", cursor: "pointer"}}
            onClick={() => {
              navigator.clipboard.writeText(infoData.link);
              notification.success({
                message: "Link copied to clipboard",
                duration: 2
              });
            }}
          />
          </strong> {infoData.link}
          </p>
        </>
      }

      <Row>
        <Col span={6}>
          <Card title="Users" bordered={true}>
            {infoLoading
              ? <p>Loading...</p>
              : <p>{infoData.users}</p>
            }
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Forms" bordered={true}>
            {infoLoading
              ? <p>Loading...</p>
              : <p>{infoData.forms}</p>
            }
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Precalculated amount" bordered={true}>
            {infoLoading
              ? <p>Loading...</p>
              : <p>€ {infoData.precalculatedTotalAmount}</p>
            }
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Decision amount" bordered={true}>
            {infoLoading
              ? <p>Loading...</p>
              : <p>€ {infoData.decisionTotalAmount}</p>
            }
          </Card>
        </Col>
      </Row>

      <div style={{height: "40px"}} />

      {usersLoading
        ? <Loading />
        : <Table
          className="allforms_table"
          dataSource={usersData.data}
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: usersData.total,
            onChange: (val, limit) => paginate(val, limit)
          }}
        >
          <Column title="Email" dataIndex="email" key="email" />
          <Column title="First Name" dataIndex="first_name" key="first_name" />
          <Column title="Last Name" dataIndex="last_name" key="last_name" />
          <Column
            title="Created"
            dataIndex="created_at"
            key="created_at"
            render={(created_at) => {
              return(
                <p>{moment(created_at).format("DD.MM.YYYY HH:mm")}</p>
              );
            }}
          />
          <Column
            title="Precalculated Amount"
            dataIndex="precalculatedTotalAmount"
            key="precalculatedTotalAmount"
            render={(precalculatedTotalAmount) => {
              return(
                <p>{`€ ${(precalculatedTotalAmount || 0).toFixed(2)}`}</p>
              );
            }}
          />
          <Column
            title="Decision Amount"
            dataIndex="decisionTotalAmount"
            key="decisionTotalAmount"
            render={(decisionTotalAmount) => {
              return(
                <p>{`€ ${(decisionTotalAmount || 0).toFixed(2)}`}</p>
              );
            }}
          />
          <Column
            title="Forms years"
            dataIndex="forms"
            key="forms"
            render={(forms) => {
              return(
                <p>{forms.map((item) => item.formdata.year).toString()}</p>
              );
            }}
          />
        </Table>
      }
    </div>
  );
}
