import React, {useState, useEffect} from "react";
import {Switch, Tooltip} from "antd";

export default function DynamicSwitch(props) {
  const createFilterSwitches = () => {
    const temp = [];
    props.data.forEach((el, id) => {
      const switchTemp = (
        <div key={"switch-" + id}>
          <Tooltip title={"Tabellen-Filter"}>
            <Switch
              checkedChildren={el.name}
              unCheckedChildren={"No " + el.name}
              id="PMswitch"
              style={{
                backgroundColor: props.data[id].flag
                  ? "rgba(0, 190, 170, 1)"
                  : "grey",
                marginTop: "5px",
                marginLeft: "10px"
              }}
              defaultChecked
              onChange={() => {
                props.data[id].flag = !props.data[id].flag;
                props.setSwtichFlag(!props.swtichFlag);
              }}
            />
          </Tooltip>
        </div>
      );
      temp.push(switchTemp);
    });

    return temp;
  };

  return <div style={{display: "flex"}}>{createFilterSwitches()}</div>;
}
