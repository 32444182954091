import React, {useEffect, useState} from "react";
import "./ScreensStyles.css";
import Header from "../components/Header/Header";
import Lottie from "react-lottie";
import coinAnimation from "../assets/animations/coin.json";
import Button from "../components/CommonComponents/FormButton/FormButton";
import {useHistory} from "react-router-dom";
import ReactCanvasConfetti from "react-canvas-confetti";

export default function SuccessScreen() {
  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: coinAnimation
  };

  let animationInstance = null;

  useEffect(() => {
    setTimeout(() => {
      setFire(true);
    }, 50);
  }, []);

  const getInstance = instance => {
    animationInstance = instance;
  };

  const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 999
  };

  const [fire, setFire] = useState(false);

  return(
    <div>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} fire={fire}/>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} fire={fire}/>
      <Header />
      <div className="success_center">
        <div className="success_column">
          <div className="coin">
            <Lottie
              options={defaultOptions}
            />
          </div>
          <h2>Vielen Dank!</h2>
          <p>Sie haben das Formular erfolgreich abgeschickt! Wir haben bereits eine Bestätigung an
            <strong> max@mustermann.at</strong> gesendet.
          </p>
          <Button
            text={"Zurück zur Hauptseite"}
            handleClick={() => history.push("/")}
          />
        </div>
      </div>
    </div>
  );
}
