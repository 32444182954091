import React, {useEffect, useRef, useState} from "react";
import "./QuestionComponentsStyles.css";
import {formatDate, getMonthLength, getText, normalize1Period, validateText} from "../../utils/tools";
import {DatePicker, Input, List, Select} from "antd";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import moment from "moment";
import Button from "../CommonComponents/FormButton/FormButton";
import {motion} from "framer-motion";
import Autocomplete from "../CommonComponents/Autocomplete";
import {MyMapComponent} from "./maps";
import {useTranslation} from "react-i18next";
import NavigationButtons from "./NavigationButtons/NavigationButtons";


export default function WorkAddressesComponent(props) {
  const { t } = useTranslation();

  const [buttonBlocked, setButtonBlocked] = useState(false);
  const [answered, setAnswered] = useState(false);

  return(
    <motion.div
      className="single_choice"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{
        delay: 0.3,
        x: { type: "spring", stiffness: 100 },
        default: { duration: 1 },
      }}
    >

      {props.showButtonBack
        ? <NavigationButtons back={props.backward} forward={() => {
          if (answered) {
            props.forward(props.condition);
          }
        }} />
        : null
      }

      <div className="question_header_box">
        <h6>{getText(props.ANS, props.QS, props.qs.title, props.lang)}</h6>
        {props.qs.dsc && props.qs.dsc.length
          ? <div className="drawer_btn" onClick={() => {
            props.showDrawer(props.qs.dsc);
          }}>
            <i className="fas fa-question"></i>
          </div>
          : null
        }
      </div>
      <div className="work_addresses_container">
        <div>
          <div>
            <Autocomplete
              qs={props.qs}
              answr={props.answr}
              saveAddress={(answr) => props.setValue(answr)}
              work={true}
            />
          </div>
        </div>
      </div>
      <Button
        text={t("questions.continue")}
        disabled={buttonBlocked || props.blocked}
        handleClick={() => {
          if (!buttonBlocked) {
            setButtonBlocked(true);
            if (!props.blocked) {
              setAnswered(true);
              props.forward(props.condition);
            } else {
              props.showError(props.condition.abort_code);
            }
            setTimeout(() => {
              setButtonBlocked(false);
            }, 500);
          }
        }}
      />
    </motion.div>
  );
}
