import React from "react";
import {Route} from "react-router-dom";
import Login from "../Authentication/Login";

export default function PartnerManagerLogin() {
  return (
    <div className="login_page">
      <Route
        path="/partnermanager/auth/login"
        render={() => <Login partnermanager />}
      />
    </div>
  );
}
