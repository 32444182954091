import React, {useEffect} from "react";
import queryString from "query-string";
import HashLoader from "react-spinners/HashLoader";
import {authService} from "../../services/auth.service";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import environment from "../../environment";

export default function ActivateUser() {
  const history = useHistory();

  useEffect(() => {
    const getToken = async () => {
      const parsed = queryString.parse(location.search);
      if (Object.keys(parsed).length && parsed.token) {
        const activated = await new authService().activateUser(parsed.token);
        if (activated && activated.token) {
          if (isMobile) {
            if (environment?.country === "DE") {
              window.location.replace(`de.taxefy.app://email-verification?token=${activated.token}`);
            } else {
              window.location.replace(`at.taxefy.app://email-verification?token=${activated.token}`);
            }
            setTimeout(() => {
              history.push("/auth/login");
            }, 100);
          } else {
            history.push(`/auth/login?token=${activated.token}`);
          }
        } else {
          history.push("/404");
        }
      }
    };
    getToken();
  }, []);

  return(
    <>
      <HashLoader color="gray" size={35} />
    </>
  );
}
