import React, {useEffect, useState} from "react";
import axios from "axios";
import environment from "../../environment";
import {cookieService} from "../../services/cookie.service";
import BankData from "./BankData";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import {useTranslation} from "react-i18next";
import {Modal} from "antd";

export default function BankTab() {
  const { t } = useTranslation();

  const [bankData, setBankData] = useState([]);

  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

  const getBankData = async () => {
    if (showCreateAccountModal) {
      setShowCreateAccountModal(false);
    }
    const cookieToken = await new cookieService().getTokenData();
    const response = await axios.get(`${environment.baseUrl}user/bankData`, {
      headers: {
        Authorization: `Bearer ${cookieToken.refreshToken}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      setBankData(response.data);
    }
  };

  useEffect(() => {
    getBankData();
  }, []);

  return(
    <div>
      <Modal
        footer={null}
        title={t("profile.bank_tab.add_account")}
        visible={showCreateAccountModal}
        onCancel={() => setShowCreateAccountModal(false)}
      >
        <BankData
          data={{
            iban: "",
            bic: "",
            selected: false
          }}
          idx={0}
          create={true}
          getBankData={getBankData}
        />
      </Modal>
      {bankData && bankData.length
        ? <>
          {bankData.map((data, idx) => (
            <React.Fragment key={data.id}>
              <BankData
                data={data}
                idx={idx}
                getBankData={getBankData}
              />
            </React.Fragment>
          ))}
        </>
        : null
      }
      <Button
        text={t("profile.bank_tab.add_account")}
        style={{marginTop: "30px", marginBottom: "20px", maxWidth: "250px", width: "100%"}}
        handleClick={() => setShowCreateAccountModal(true)}
      />
    </div>
  );
}
