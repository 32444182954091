export const COLORS = {
  TAXEFY_GREEN: "#00BEAA",
  HIGHLIGHT: "#FF6600"
};

export const METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
};

export const ENVIRONMENTS = {
  DEVELOP: "develop",
  TEST: "test",
  PROD: "prod"
};

export const GOOGLE_API_KEY = "AIzaSyCksiK5cT4KTCKaDLYOMXdPFVy7dwkZCWM";

export const REQUEST_URL = {
  // Auth
  LOGIN: "/auth/login",
  // Sign-Up
  REGISTER: "/auth/register",
  // Google maps API
  // eslint-disable-next-line max-len
  DISTANCE_MATRIX: `https://maps.googleapis.com/maps/api/distancematrix/json?departure_time=:time&destinations=:dest&origins=:orig&key=${GOOGLE_API_KEY}`
};


// Changed env url

export const config = {
  [ENVIRONMENTS.DEVELOP]:{
    API_URL: "http://127.0.0.1:30003/api"
  },
  [ENVIRONMENTS.TEST]:{
    API_URL: "https://temp-api.taxefy.at/api"
  },
  [ENVIRONMENTS.PROD]:{
    API_URL: "https://api.taxefy.at/api"
  }
};

export const regex = {
  EMAIL: /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/,
  NAME: /^[A-Za-zoÜüÖöÄäß]{2,50}$/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)[!@#$%^&*A-Za-z\d]{8,50}$/,
  MOBILE: /^([+]\d{2})?\d{8,12}$/,
  JOBTITLE: /^[a-zA-Z\s,-ÜüÖöÄäß]*$/,
  ADDRESS: /^[0-9a-zA-ZÜüÖöÄäß\s/,-]*$/,
  SALARY: /^[0-9]*$/,
  NAMES: /^[a-zA-ZÜüÖöÄäß\s-]*$/,
  GENERAL: /^[0-9a-zA-ZÜüÖöÄäß\s-]*$/,
  DATE: /^[0-9.]*$/,
  SVN: /^([0-9]{4,4})(0[1-9]|1\d|2\d|3[01])(0[1-9]|1[0-2])([0-9]{2,2})$/
};

export const MONTHS = [
  {
    id: 1,
    daysCount: [31, 31]
  },{
    id: 2,
    daysCount: [29, 28]
  },{
    id: 3,
    daysCount: [31, 31]
  },{
    id: 4,
    daysCount: [30, 30]
  },{
    id: 5,
    daysCount: [31, 31]
  },{
    id: 6,
    daysCount: [30, 30]
  },{
    id: 7,
    daysCount: [31, 31]
  },{
    id: 8,
    daysCount: [31, 31]
  },{
    id: 9,
    daysCount: [30, 30]
  },{
    id: 10,
    daysCount: [31, 31]
  },{
    id: 11,
    daysCount: [30, 30]
  },{
    id: 12,
    daysCount: [31, 31]
  }
];
