import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {authService} from "../../services/auth.service";
import {useTranslation} from "react-i18next";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import FormikInput from "../../components/CommonComponents/FormikInput/FormikInput";
import {motion} from "framer-motion";
import Button from "../../components/CommonComponents/FormButton/FormButton";

export default function AdminRegister() {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t("auth.warnings.email")).required(t("auth.warnings.required")),
      password: Yup.string().required(t("auth.warnings.required")).min(6, t("auth.warnings.password_length")),
      confirmPassword: Yup.string()
        .min(6, t("auth.warnings.password_length"))
        .when("password", {
          is: val => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("auth.warnings.password_not_same")
          ),
        }),
    }),
    // handle form submitting
    onSubmit: async () => {
      setButtonLoading(true);
      const register = await new authService().registerAdminUser({
        email: formik.values.email,
        password: formik.values.password,
      });
      console.log(register);
      if (register && !register.success) {
        setErrorMessage(register.message === "exists"
          ? t("auth.warnings.email_exists")
          : t("auth.warnings.register_error"));
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
        if (register && register.success) {
          setSuccessfullyRegistered(true);
        }
      }
    },
  });

  return(
    <form
      onSubmit={formik.handleSubmit}
      className="auth_box"
    >
      <h1 className="login_title">{t("auth.register.register")}</h1>

      {errorMessage.length ? <ErrorBox message={errorMessage} /> : null}

      <div style={{ position: "relative" }}>
        <FormikInput
          htmlFor="email"
          name="email"
          value={formik.values.email}
          disabled={false}
          handleChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label={t("auth.register.email_label")}
          placeholder="max@musterman.at"
        />
        {formik.errors.email && formik.touched.email && (
          <motion.p
            animate={{ y: 5 }}
            className="input_error"
            style={{ fontSize: "10px" }}
          >
            {formik.errors.email}
          </motion.p>
        )}
      </div>


      <div style={{height: "15px"}}></div>

      <div style={{ position: "relative" }}>
        <FormikInput
          htmlFor="password"
          name="password"
          value={formik.values.password}
          disabled={false}
          handleChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label={t("auth.register.password_label")}
          placeholder="******"
        />
        {formik.errors.password && formik.touched.password && (
          <motion.p
            animate={{ y: 5 }}
            className="input_error"
            style={{ fontSize: "10px" }}
          >
            {formik.errors.password}
          </motion.p>
        )}
      </div>


      <div style={{height: "15px"}}></div>

      <div style={{ position: "relative" }}>
        <FormikInput
          htmlFor="password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          disabled={false}
          handleChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label={t("auth.register.confirm_password_label")}
          placeholder="******"
        />
        {formik.errors.confirmPassword && formik.touched.confirmPassword && (
          <motion.p
            animate={{ y: 5 }}
            className="input_error"
            style={{ fontSize: "10px" }}
          >
            {formik.errors.confirmPassword}
          </motion.p>
        )}
      </div>

      <Button
        text={t("auth.register.btn")}
        style={{marginTop: "30px", marginBottom: "20px", maxWidth: "none", width: "100%"}}
        buttonType="submit"
        loading={buttonLoading}
      />

    </form>
  );
}
