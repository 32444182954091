import React, {useState} from "react";
import "./AuthenticationPage.css";
import {useTranslation} from "react-i18next";
import FormikInput from "../../components/CommonComponents/FormikInput/FormikInput";
import {useFormik} from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import {useHistory, useLocation} from "react-router-dom";
import {authService} from "../../services/auth.service";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import queryString from "query-string";

export default function ResetPassword(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [buttonLoading, setButtonLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("auth.warnings.required")).min(6, t("auth.warnings.password_length")),
      confirmPassword: Yup.string()
        .min(6, t("auth.warnings.password_length"))
        .when("password", {
          is: val => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("auth.warnings.password_not_same")
          ),
        })
    }),
    // handle form submitting
    onSubmit: async () => {
      const parsed = queryString.parse(location.search);
      const bonusPath = props.consultant ? "consultant/" : "";
      setButtonLoading(true);
      if (parsed.email && parsed.token) {
        const resetPassword = await new authService().resetPassword({
          email: parsed.email,
          password: formik.values.password,
          token: parsed.token,
        }, bonusPath );
        if (resetPassword && !resetPassword.success) {
          setErrorMessage(t("auth.warnings.login_error"));
          setButtonLoading(false);
        } else {
          setButtonLoading(false);
          if (resetPassword && resetPassword.success) {
            history.push(`/${bonusPath}auth/login`);
          }
        }
      }
    },
  });

  return(
    <motion.form
      onSubmit={(e) => formik.handleSubmit(e)}
      animate={{ translateY: 0, opacity: 1 }}
      initial={{ translateY: "-100vh", opacity: 0 }}
      transition={{
        x: { type: "spring", stiffness: 100 },
        default: { duration: 0.3 },
      }}
      exit={{ translateY: "100vh", opacity: 0 }}
      className="auth_box"
    >
      <h1 className="login_title">{t("auth.resetPassword.title")}</h1>
      <h4 className="login_sub_title">{t("auth.resetPassword.sub_title")}</h4>

      {errorMessage.length ? <ErrorBox message={errorMessage} /> : null}

      <FormikInput
        htmlFor="password"
        name="password"
        value={formik.values.password}
        disabled={false}
        handleChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label={t("auth.login.password_label")}
        placeholder="******"
      />
      {formik.errors.password && formik.touched.password && (
        <motion.p
          animate={{ y: 5 }}
          className="input_error"
          style={{ fontSize: "10px" }}
        >
          {formik.errors.password}
        </motion.p>
      )}

      <div style={{height: "15px"}}></div>

      <FormikInput
        htmlFor="password"
        name="confirmPassword"
        value={formik.values.confirmPassword}
        disabled={false}
        handleChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label={t("auth.register.confirm_password_label")}
        placeholder="******"
      />
      {formik.errors.confirmPassword && formik.touched.confirmPassword && (
        <motion.p
          animate={{ y: 5 }}
          className="input_error"
          style={{ fontSize: "10px" }}
        >
          {formik.errors.confirmPassword}
        </motion.p>
      )}

      <Button
        text={t("auth.resetPassword.btn")}
        style={{marginTop: "30px", marginBottom: "20px", maxWidth: "none", width: "100%"}}
        buttonType="submit"
        loading={buttonLoading}
        disabled={buttonLoading}
      />
    </motion.form>
  );
}
