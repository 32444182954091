import { CONSTANTS } from "./constants";

export const setLanguageAction = lang => ({
  type: CONSTANTS.ON_APP_SET_LANGUAGE,
  payload: { lang }
});

export const setEnvironmentAction = env => ({
  type: CONSTANTS.ON_APP_SET_ENVIRONMENT,
  payload: { env }
});

export const setEmailAction = email => ({
  type: CONSTANTS.ON_APP_SET_EMAIL,
  payload: { email }
});

export const setPasswordAction = password => ({
  type: CONSTANTS.ON_APP_SET_PASSWORD,
  payload: { password }
});

export const setUserAction = user => ({
  type: CONSTANTS.ON_APP_SET_USER,
  payload: { user }
});

export const setQsAction = Qs => ({
  type: CONSTANTS.ON_APP_SET_QS,
  payload: { Qs }
});

export const setCurrentQsAction = currentQs => ({
  type: CONSTANTS.ON_APP_SET_CURRENT_QS,
  payload: { currentQs }
});

export const setAnsAction = Ans => ({
  type: CONSTANTS.ON_APP_SET_ANS,
  payload: { Ans }
});

export const setSavedAnswers = savedQuestions => ({
  type: CONSTANTS.SAVED_ANSWERS,
  payload: { savedQuestions }
});

export const setUserData = userData => ({
  type: CONSTANTS.STORE_USER_DATA,
  payload: { userData }
});

