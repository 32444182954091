import React from "react";
import {Route, Switch} from "react-router-dom";
import ConsultantLogin from "./ConsultantLogin";
import ConsultantPanelLayout from "./ConsultantPanelLayout";
import ConsultantForgetPassword from "./ConsultantForgetPassword";
import ConsultantResetPassword from "./ConsultantResetPassword";

export default function ConsultantLayout() {
  return (
    <Switch>
      <Route path="/consultant/auth/login" render={() => <ConsultantLogin />} />
      <Route path="/consultant/panel" exact render={() => <ConsultantPanelLayout />} />
      <Route path="/consultant/panel/*" exact render={() => <ConsultantPanelLayout />} />
      <Route path="/consultant/auth/forget" exact render={()  => <ConsultantForgetPassword />} />
      <Route path="/consultant/auth/resetPassword" exact render={()  => <ConsultantResetPassword consultant />} />
    </Switch>
  );
}
