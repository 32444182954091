import { createSelector } from "reselect";
import { ENVIRONMENTS } from "../../utils/constants";
import { encode } from "base-64";

const getLanguage = state => {
  return state.app.get("lang") || "de";
};
const getEnvironment = state => state.app.get("env") || ENVIRONMENTS.PROD;
const getEmail = state => state.app.get("email");
const getPassword = state => state.app.get("password");
const getUser = state => state.app.get("user");
const getQsData = state => {
  const currentQs = state.app.get("currentQs");
  return state.app.getIn(["data", currentQs]);
};
const getSavedAnswers = state => state.app.get("savedQuestions");
const getUserData = state => state.app.get("userData");

// ======== SELECTORS ============

export const getLanguageSelector = createSelector(
  [getLanguage],
  lang => {
    return lang;
  }
);

export const getEnvironmentSelector = createSelector(
  [getEnvironment],
  env => env
);

export const getEmailSelector = createSelector(
  [getEmail],
  email => email
);

export const getPasswordSelector = createSelector(
  [getPassword],
  password => password
);

export const getUserSelector = createSelector(
  [getUser],
  user => user && user.toJS ? user.toJS() : user
);

export const getAuthHeaderSelector = createSelector(
  [getEmail, getPassword],
  (email, password) => encode(`${email}:${password}`)
);

export const isLoggedInSelector = createSelector(
  [getPassword],
  password => password && password != ""
);

export const getQsSelector = createSelector(
  [getQsData],
  Qs => Qs && Qs.get("QS").toJS()
);

export const getAnsSelector = createSelector(
  [getQsData],
  Qs => Qs && Qs.get("ANS").toJS()
);

export const getSavedAnswersSelector = createSelector(
  [getSavedAnswers],
  answers => {
    return answers.toJS();
  }
);

export const getUserDataSelector = createSelector(
  [getUserData],
  userData => {
    return userData && userData.toJS();
  }
);
