import React, {useEffect, useState} from "react";
import "./styles.css";
import { DatePicker } from "antd";
import moment from "moment";
import HeaderChart from "./Charts/HeaderChart";
import TunnelChart from "./Charts/TunnelChart";
import DevicesChart from "./Charts/DevicesChart";
import AgeChart from "./Charts/AgeChart";
import axios from "axios";
import environment from "../../../../environment";
import {cookieService} from "../../../../services/cookie.service";
import getAppCountry from "../../../../utils/getAppCountry";
const { RangePicker } = DatePicker;

export default function DashboardPage() {
  const [selectedDate, setSelectedDate] = useState({
    startDate:moment().startOf("month"),
    endDate: moment(new Date())
  });

  const [topChartsData, setTopChartsData] = useState({
    forms: {},
    amount: {},
    taxAmount: {}
  });
  const [funnelData, setFunnelData] = useState(null);
  const [mobileData, setMobileData] = useState({
    web: 0,
    mobile: 0
  });
  const [agesData, setAgesData] = useState({
    "18-24": 0,
    "25-34": 0,
    "35-44": 0,
    "45-64": 0,
    "65+": 0
  });

  const getChartsData = async (startDate, endDate) => {
    const cookieToken = await new cookieService().getAdminTokenData();
    const queryParams = {};
    Object.assign(queryParams, {"startDate": startDate ? startDate : moment(selectedDate.startDate, "DD.MM.YYYY")});
    Object.assign(queryParams, {"endDate": endDate ? endDate : moment(selectedDate.endDate, "DD.MM.YYYY")});
    const response = await axios.get(`${environment.baseUrl}admin/statistics/infoCharts`, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      setTopChartsData(response.data);
    }
    if (getAppCountry() !== "DE") {
      // Funnel data
      const funnelResponse = await axios.get(`${environment.baseUrl}admin/statistics/funnelChart`, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      });
      if (funnelResponse && funnelResponse.data) {
        setFunnelData(funnelResponse.data);
      }
      // Devices data
      const devicesResponse = await axios.get(`${environment.baseUrl}admin/statistics/devicesChart`, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      });
      if (devicesResponse && devicesResponse.data) {
        setMobileData(devicesResponse.data);
      }
      // Ages data
      const agesResponse = await axios.get(`${environment.baseUrl}admin/statistics/ageChart`, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${cookieToken.token}`,
          "app-version": "1.0.1"
        }
      });
      if (agesResponse && agesResponse.data) {
        setAgesData(agesResponse.data);
      }
    }
  };

  useEffect(() => {
    getChartsData();
  }, []);

  return(
    <div>
      <div className="space-between">
        <h1>Dashboard</h1>
        <RangePicker
          defaultValue={[selectedDate.startDate, selectedDate.endDate]}
          format={"DD.MM.YYYY"}
          onChange={(val) => {
            setSelectedDate({
              startDate: moment(val[0]).format("DD.MM.YYYY"),
              endDate: moment(val[1]).format("DD.MM.YYYY")
            });
            setTimeout(() => {
              getChartsData(moment(val[0]), moment(val[1]));
            }, 100);
          }}
        />
      </div>

      <div className="dashboard_header_charts">
        <HeaderChart
          id={"Forms"}
          title={"Steurerklärungen"}
          withBorder
          data={topChartsData.forms}
        />
        <HeaderChart
          id={"Amount"}
          title={"Steueranspruch kumuliert"}
          withBorder
          data={topChartsData.amount}
        />
        <HeaderChart
          id={"tax_amount"}
          title={"Transaktionsvolumen Finanzamt"}
          data={topChartsData.taxAmount}
        />
      </div>

      {getAppCountry() !== "DE" &&
        <>
          <h2>Pipeline</h2>

          <TunnelChart data={funnelData} />

          <div style={{
            width: "100%",
            backgroundColor: "#ffffff",
            paddingTop: "10px",
            marginTop: "-50px",
            position: "relative",
            zIndex: 2
          }}>

            <div style={{width: "100%", height: "2px", backgroundColor: "#eeeeee"}} />

            <div className="bottom_charts_container">
              <DevicesChart data={mobileData} />
              <AgeChart data={agesData} />
            </div>

          </div>
        </>
      }

    </div>
  );
}
