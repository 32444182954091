import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import "./styles.css";
import {StopOutlined} from "@ant-design/icons";
import {calculateTax, getFinalAmount} from "../../utils/tools";

export default function UserAnvView(props) {
  const {t} = useTranslation();
  const [form, setForm] = useState([]);
  const [forceRender, setForceRender] = useState(true);

  const renderQuestionComp = (array, group) => {
    const result = [];
    const breakpoint = /"|\[|\]|\{|\}/;

    if (!array || array.length == 0) return;

    array[group].forEach(el => {
      if (el.loop) {
        //
        const loopAnswer = [];
        el.answer.forEach(item => {
          const answerSpan = [];
          let answerString = `${JSON.stringify(item) || item}`.split(breakpoint).join(" ");
          answerString = answerString.split("|/|");
          answerString.forEach(ell => {
            const temp = <span>{ell}</span>;
            answerSpan.push(temp);
            answerSpan.push(<br />);
          });
          const answerDiv = <div className="answer_span">{answerSpan}</div>;
          loopAnswer.push(answerDiv);
        });
        result.push(
          <div key={group + "-" + el.id} className="question_div">
            <span style={{width: "50%"}}>
              {el.id}: {el.text}
            </span>
            {loopAnswer.reverse()}
          </div>
        );
      } else {
        const answerSpan = [];
        let answerString = `${JSON.stringify(el.answer) || el.answer}`.split(breakpoint).join(" ");
        answerString = answerString.split("|/|");
        answerString.forEach(el => {
          const temp = <span>{el}</span>;
          answerSpan.push(temp);
          answerSpan.push(<br />);
        });
        const answerDiv = <div className="answer_span">{answerSpan}</div>;

        result.push(
          <div key={group + "-" + el.id} className="question_div">
            <span style={{width: "50%"}}>
              {el.id}: {el.text}
            </span>
            {answerDiv}
          </div>
        );
      }
    });
    return result;
  };

  useEffect(() => {
    setForm(props.data?.questions);
  }, [props.data]);

  useEffect(() => {
    setForceRender(!forceRender);
  }, [form]);

  return (
    <div id="user_anv_view_main_box">
      {/* <span
        style={{
          backgroundColor: "rgba(0, 190, 170, 1)",
          color: "white"
        }}
        className="answer_span"
      >
        Nutzer-Antwort
      </span>
      <span
        style={{
          backgroundColor: "white",
          color: "black"
        }}
        className="answer_span"
      >
        Standard-Antwort
      </span> */}
      <div style={{marginTop: "10px"}} className="question_group">
        <h1>Allgemein</h1>
        {renderQuestionComp(form, 1)}
      </div>
      <div className="question_group">
        <h1>Familie</h1>
        {renderQuestionComp(form, 2)}
      </div>
      <div className="question_group">
        <h1>Arbeit</h1>
        {renderQuestionComp(form, 3)}
      </div>
      <div className="question_group">
        <h1>Gesundheit</h1>
        {renderQuestionComp(form, 4)}
      </div>
      <div className="question_group">
        <h1>Kinder</h1>
        {renderQuestionComp(form, 5)}
      </div>
    </div>
  );
}
