import { fromJS } from "immutable";
import { ENVIRONMENTS } from "../../utils/constants";
import { generateAnsObj } from "../../utils/tools";
import { CONSTANTS } from "./constants";

const initialState = fromJS({
  lang: "de",
  env: ENVIRONMENTS.PROD,
  email: "",
  password: "",
  user: null,
  data: {},
  currentQs: "-",
  savedQuestions: {
    0: [1]
  },
  userData: {}
});

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case CONSTANTS.ON_APP_SET_LANGUAGE:
    return state.set("lang", payload.lang);

  case CONSTANTS.ON_APP_SET_ENVIRONMENT:
    return state.set("env", payload.env);

  case CONSTANTS.ON_APP_SET_EMAIL:
    return state.set("email", payload.email);

  case CONSTANTS.ON_APP_SET_PASSWORD:
    return state.set("password", payload.password);

  case CONSTANTS.ON_APP_SET_USER:
    return state.set("user", payload.user);

  case CONSTANTS.ON_APP_SET_QS:
    // eslint-disable-next-line no-case-declarations
    const title = `${payload.Qs.country}_${payload.Qs.year}`;
    // eslint-disable-next-line no-case-declarations
    const ANS = state.getIn(["data", title, "ANS"]);
    if(!ANS || ANS.get("version") !== payload.Qs.version){
      state = state.setIn(["data", title, "ANS"], fromJS(generateAnsObj(payload.Qs)));
    }
    return state.setIn(["data", title, "QS"], fromJS(payload.Qs));

  case CONSTANTS.ON_APP_SET_CURRENT_QS:
    return state.set("currentQs", payload.currentQs);

  case CONSTANTS.ON_APP_SET_ANS:
    return state.setIn(["data", `${payload.Ans.country}_${payload.Ans.year}`, "ANS"], fromJS(payload.Ans));

  case CONSTANTS.SAVED_ANSWERS:
    return state.set("savedQuestions", fromJS(payload.savedQuestions));

  case CONSTANTS.STORE_USER_DATA:
    return state.set("userData", fromJS(payload.userData));

  default:
    return state;
  }
};

export default reducer;
