import React from "react";
import "./Checkbox.css";

export default function Checkbox(props) {
  return(
    <div className="check_round">
      <input
        type="checkbox"
        disabled={props.disabled}
        checked={props.checked}
        id={props.id}
      />
      <label htmlFor={props.id}></label>
    </div>
  );
}
