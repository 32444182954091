import React, {useState} from "react";
import "./Modals.css";
import {useTranslation} from "react-i18next";
import Button from "../CommonComponents/FormButton/FormButton";
import {useDispatch} from "react-redux";
import {setLanguageAction} from "../../data/app/actions";
import i18n from "../../i18next";
import {cookieService} from "../../services/cookie.service";
import axios from "axios";
import environment from "../../environment";
import {notification} from "antd";
import {langs} from "../../constants/langs";

export default function RegionsAndLanguagesModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedValues, setSelectedValue] = useState({
    region: "at",
    lang: props.selectedLang
  });
  const changeLanguage = lang => {
    setSelectedValue({
      ...selectedValues,
      lang
    });
  };

  const savePreferredLanguage = async (language) => {
    const cookieToken = await new cookieService().getTokenData();
    try {
      const updateResponse = await axios.put(`${environment.baseUrl}user/changeLanguage`,
        {
          language: language
        },
        {
          headers: {
            Authorization: `Bearer ${cookieToken.refreshToken}`,
            "app-version": "1.0.1"
          }
        });
      if (updateResponse && updateResponse.status !== 200) {
        notification.open({
          message: t("profile.profile_tab.error_toast_title"),
          duration: 2
        });
      }
    } catch (e) {
      notification.open({
        message: t("profile.profile_tab.error_toast_title"),
        duration: 2
      });
    }
  };

  const handleSaveClick = () => {
    i18n.changeLanguage(selectedValues.lang);
    window.localStorage.setItem("lang", selectedValues.lang);
    dispatch(setLanguageAction(selectedValues.lang));
    props.closeModal();
    if (!props.login) {
      savePreferredLanguage(selectedValues.lang);
    } else if (props.login) {
      props.setSelectedLang(selectedValues.lang);
    }
  };

  return(
    <div className="modal">

      <h4 style={{marginTop: "20px"}}>{t("header.regions_modal.languages")}</h4>
      <div className="regions_box">
        {langs.map(lang => (
          <div
            key={lang}
            className={selectedValues.lang === lang ? "region_button selected" : "region_button"}
            onClick={() => changeLanguage(lang)}
          >
            {t(`languages.${lang}.full`)}
          </div>
        ))}
      </div>

      <div style={{
        marginTop: "30px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
      }}>
        <Button
          text={t("header.regions_modal.save_btn")}
          handleClick={() => handleSaveClick()}
        />
      </div>
    </div>
  );
}
