import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useFormik} from "formik";
import medalImg from "../../img/Auth/medal.png";
import {authService} from "../../services/auth.service";
import * as Yup from "yup";
import "./ConsultantForgetPassword.css";

import ErrorBox from "../../components/ErrorBox/ErrorBox";
import Button from "../../components/CommonComponents/FormButton/FormButton";
import FormikInput from "../../components/CommonComponents/FormikInput/FormikInput";

export default function ConsultantForgetPassword() {
  const history = useHistory();
  const {t} = useTranslation();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("auth.warnings.email"))
        .required(t("auth.warnings.required"))
    }),

    onSubmit: async () => {
      setButtonLoading(true);
      const result = await new authService().forget(
        {email: formik.values.email},
        "consultant/"
      );
      if (result && !result.success) {
        console.log(result.message);
        if (result.message === "not_active") {
          setErrorMessage("Not active user");
        } else {
          setErrorMessage(
            result.message === "not registered"
              ? t("auth.warnings.forget_not_found")
              : t("auth.warnings.register_error")
          );
        }
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
        setSuccess(true);
      }
    }
  });
  return success ? (
    <div className="consultant_forget_box">
      <img
        className="consultant_forget_success_medal"
        src={medalImg}
        alt="medal"
      />
      <h2 className="consultant_forget_h2">
        {t("auth.forget.success_text", {email: formik.values.email})}
      </h2>
      <Button
        style={{
          width: "50%",
          maxWidth: "none"
        }}
        text={t("auth.forget.success_btn")}
        handleClick={() => history.push("/consultant/auth/login")}
      />
    </div>
  ) : (
    <div className="consultant_forget_box">
      <div style={{width: "50%"}}>
        <h1 className="login_title">{t("auth.forget.forget")}</h1>
        <h4 className="login_sub_title">{t("auth.forget.forget_sub")}</h4>

        <div
          hidden={errorMessage.length > 0 ? false : true}
          className="consultant_error_box"
        >
          <ErrorBox message={errorMessage} />
        </div>

        <form onSubmit={formik.handleSubmit}>
          <FormikInput
            name="email"
            value={formik.values.email}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t("auth.register.email_label")}
            placeholder="max@musterman.at"
          />
          {formik.errors.email && (
            <p className="input_error">{formik.errors.email}</p>
          )}

          <Button
            text={t("auth.forget.btn")}
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              width: "100%"
            }}
            disabled={formik.errors.email || buttonLoading}
            loading={buttonLoading}
            buttonType="submit"
          />
          <p
            onClick={() => history.push("/consultant/auth/login")}
            className="login_forget_text"
          >
            {t("auth.forget.back_login")}
          </p>
        </form>
      </div>
    </div>
  );
}
