import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import QuestionsPage from "../layouts/QustionsPage";
import SuccessScreen from "./SuccessScreen";
import {cookieService} from "../services/cookie.service";
import {authService} from "../services/auth.service";
import {setLanguageAction, setUserData} from "../data/app/actions";
import {connect, useDispatch} from "react-redux";
import axios from "axios";
import environment from "../environment";
import {handleAsyncErrors} from "../utils/handleAsyncErrors";
import Header from "../components/Header/Header";
import {
  getAnsSelector,
  getLanguageSelector,
  getQsSelector,
  getUserDataSelector
} from "../data/app/selectors";
import CreateTaxNumberView from "./CreateTaxNumber/CreateTaxNumberView";
import Loading from "../components/Loading";
import i18n from "../i18next";
import ProfileView from "./Profile/ProfileView";
import RegionsAndLanguagesModal from "../components/Modals/RegionsAndLanguagesModal";
import {langs} from "../constants/langs";
import {Modal} from "antd";
import NewUserInfoModal from "../components/Modals/NewUserInfoModal";

function ProtectedRoutes(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [countriesData, setCountriesData] = useState({});
  const [showTermsPopup, setShowTermsPopup] = useState(false);

  const getCountriesData = async () => {
    // Get list of countries. years and data for form
    const cookieToken = await new cookieService().getTokenData();
    try {
      const response = await axios.get(`${environment.baseUrl}form/countries`, {
        headers: {
          Authorization: `Bearer ${cookieToken.refreshToken}`,
          "app-version": "1.0.1"
        }
      });
      if (response && response.data) {
        setCountriesData(response.data);
      }
    } catch (e) {
      if (!handleAsyncErrors(e.request.status)) {
        console.log("Get countries error");
      }
    }
  };

  useEffect(() => {
    const logout = async () => {
      await new cookieService().removeTokenData();
      history.push("/auth/login");
    };
    const getToken = async () => {
      const tokenData = await new cookieService().getTokenData();
      if (!tokenData) {
        history.push("/auth/login");
      } else {
        let token = tokenData.token;
        if (!token) {
          logout();
        }
        const auth = await new authService().verifyToken(token);
        if (!auth) {
          const refreshTokenVerification = await new authService().verifyToken(tokenData.refreshToken);
          if (refreshTokenVerification) {
            const refreshedToken = await new authService().refreshToken(tokenData.refreshToken);
            token = refreshedToken.token;
            if (!refreshedToken) {
              logout();
            }
          } else {
            logout();
          }
        }
        const response = await axios.get(`${environment.baseUrl}user`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "app-version": "1.0.1"
          }
        });
        if (response && response.data) {
          setLoading(false);
          i18n.changeLanguage(response.data.language);
          window.localStorage.setItem("lang", response.data.language);
          dispatch(setLanguageAction(response.data.language));
          getCountriesData();
          dispatch(setUserData(response.data));
        } else {
          setLoading(false);
        }
      }
    };
    getToken();
    setShowTermsPopup(window.localStorage.getItem("termsAreAccepted") !== "true");
  }, []);

  const [currentComponent, setCurrentComponent] = useState("countries");

  return(
    <div>
      <Modal
        footer={null}
        visible={showTermsPopup}
        onCancel={() => setShowTermsPopup(false)}
        closable={false}
        keyboard={false}
        maskClosable={false}
      >
        <NewUserInfoModal close={() => setShowTermsPopup(false)} />
      </Modal>
      {loading
        ? <Loading />
        : <div>
          <Header showCalculations={props.QS && Object.keys(props.QS).length
          && props.ANS && Object.keys(props.ANS).length
          && props.language} currentComponent={currentComponent} />
          <Switch>
            <Route path="/" exact render={() =>
              <QuestionsPage
                countriesData={countriesData}
                refreshCountriesData={() => getCountriesData()}
                setCurrentComponent={(comp) => setCurrentComponent(comp)}
              />
            }/>
            <Route path="/profile" exact render={() =>
              <ProfileView />
            }/>
            <Route path="/success" render={() =>
              <SuccessScreen />
            }/>
            <Route path="/createTax" exact render={() =>
              <CreateTaxNumberView />
            }/>
          </Switch>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    QS: getQsSelector(state),
    ANS: getAnsSelector(state),
    language: getLanguageSelector(state),
    userData: getUserDataSelector(state)
  };
};

export default connect(mapStateToProps, null)(ProtectedRoutes);
