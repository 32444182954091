import React from "react";
import {withScriptjs, withGoogleMap, GoogleMap, Marker, Circle} from "react-google-maps";

export const MyMapComponent = React.memo(withScriptjs(withGoogleMap((props) => {

  const checkMarkers = (markers) => {
    let allowed = true;
    if (markers.length) {
      markers.forEach(marker => {
        console.log("Here: ", marker.lat);
        console.log("Here: ", marker.lng);
        if (!marker.lat || !marker.lng) {
          allowed = false;
        }
      });
    } else {
      allowed = false;
    }
    console.log(allowed);
    return allowed;
  };

  return(
    <GoogleMap
      key={`${props.lat.toString()}${props.lng.toString()}`}
      defaultOptions={{
        disableDefaultUI: true,
        draggable: true,
        keyboardShortcuts: true,
        scaleControl: true,
        scrollwheel: true,
        styles: props.mapStyles
      }}
      defaultZoom={11}
      defaultCenter={{ lat: +props.lat, lng: +props.lng }}
    >
      {props.markers && checkMarkers(props.markers) && props.markers.map((mark, index) => (
        <Marker
          key={index}
          position={{
            lat: mark.lat,
            lng: mark.lng
          }}
        />
      ))}
      {props.workMarkers && checkMarkers(props.workMarkers) && props.workMarkers.map((mark, index) => (
        <Marker
          key={index}
          position={{
            lat: mark.lat,
            lng: mark.lng
          }}
        />
      ))}
    </GoogleMap>
  );
})));
