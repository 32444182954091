import React, {useState, useEffect} from "react";
import {Button, Checkbox, Col, Input, Modal, Pagination, Row, Select, Table} from "antd";
import "./TaxefyTable.css";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

/*
DOCUMENTATION:

PROPS:
  - DATA
  - NAMESPACE
  - OPTIONS
        - COL-ORDER
        - COL-GROUPING
  - [[COLUMN-NAME]]
        - RENDER TYPE



*/

export default function TaxefyTable(props) {
  const {t, i18n} = useTranslation();
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [columnGroups, setColumnGroups] = useState([]);
  const [hidden, setHidden] = useState([]);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showSorterDialog, setShowSorterDialog] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (values, rows) => {
      props.onSelect(rows.map(el => el.form_id));
      setSelectedRowKeys(values);
    }
  };

  useEffect(() => setSelectedRowKeys([]), [data]);

  const renderOptions = {
    checkmark: data => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            paddingLeft: "15px"
          }}
        >
          {(data?.data != undefined ? !data.data : data) ? (
            <CheckCircleOutlined style={{fontSize: "25px", color: "rgba(0, 190, 170, 1)"}} />
          ) : (
            <CloseCircleOutlined style={{fontSize: "25px", color: "#aa4455"}} />
          )}
          {data?.date && <p style={{marginTop: "5px"}}>{renderOptions["date"](data.date)}</p>}
        </div>
      );
    },
    plain: data => {
      return <div>{data}</div>;
    },
    date: data => {
      return data ? <span>{new Date(data).toLocaleString().split(", ")[0]}</span> : "";
    },
    status: data => {
      return (
        <div>
          <span
            style={{whiteSpace: "nowrap", padding: "2px", border: "solid 1px rgba(0, 190, 170, 1)"}}
          >
            {data.decision || "NULL"}{" "}
          </span>{" "}
          {data.decided_amount && (
            <>
              <br />
              <span>{data.decided_amount} €</span>
            </>
          )}
        </div>
      );
    },
    list: data => {
      return data?.map((el, ind) => (
        <p
          style={{
            color: "white",
            backgroundColor: "rgba(0, 190, 170, 1)",
            margin: "2px",
            fontWeight: "bold",
            padding: "1px",
            textAlign: "center",
            borderRadius: "10px"
          }}
          key={"list-" + ind}
        >
          {el}
        </p>
      ));
    }
  };

  const generateColumns = columns.map(el => {
    return {
      title: t(props.namespace + el),
      dataIndex: el,
      hidden: !columnGroups
        ?.filter(ell => hidden.includes(ell.name))
        .find(ell => ell.columns.includes(el))
        ? true
        : false,
      render: renderOptions[props.options.find(ell => ell.type == el)?.renderType || "plain"]
    };
  });

  const renderSorterRow = props.sortFields?.map((el, ind) => {
    const sortRowTemp = (
      <tr key={"sorter-row-" + ind}>
        <td>
          {el.order != 0 && (
            <ArrowUpOutlined
              style={{
                userSelect: "none"
              }}
              onClick={() => {
                let temp = JSON.parse(JSON.stringify(props.sortFields));
                temp = temp.filter(oldel => oldel.name != el.name);
                temp.splice(el.order - 1, 0, el);
                temp.forEach((el, ind) => (el.order = ind));
                props.onSort(temp);
                // setSortFields(old => temp);
              }}
            />
          )}
        </td>
        <td>
          <Checkbox
            checked={el.activated}
            onChange={() => {
              const temp = JSON.parse(JSON.stringify(props.sortFields));
              temp.find(oldel => oldel.name == el.name).activated = !el.activated;
              props.onSort(temp);
            }}
          />
        </td>
        <td>{t(props.namespace + el.name)}</td>
        <td>
          <Select
            value={el.direction}
            options={[
              {value: "asc", label: <ArrowUpOutlined />},
              {value: "desc", label: <ArrowDownOutlined />}
            ]}
            onChange={value => {
              const temp = JSON.parse(JSON.stringify(props.sortFields));
              temp.find(oldel => oldel.name == el.name).direction = value;
              props.onSort(temp);
              // setSortFields(old => temp);
            }}
            disabled={!el.activated}
          />
        </td>
      </tr>
    );
    return sortRowTemp;
  });

  const renderFilters = () => {
    const filterGroups = props.filters
      ?.map(el => el.group)
      .reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);
    const groupRows = [];
    filterGroups?.forEach((group, ind) => {
      const smallerFilters = props.filters.filter(el => el.group == group);
      const groupCols = [];
      smallerFilters.forEach((el, i) => {
        const filterTemplate = (
          <Checkbox
            style={{
              userSelect: "none"
            }}
            key={"filter-col-" + i}
            value={el.id}
          >
            {el.name}
          </Checkbox>
        );
        groupCols.push(filterTemplate);
      });
      const RowTemplate = (
        <div style={{borderBottom: "solid 1px rgba(0,0,0,0.1)"}} key={"filter-rows-" + ind}>
          <div
            style={{
              backgroundColor: "rgba(0, 190, 170, 1)",
              color: "white",
              padding: "5px",
              marginRight: "5px",
              minWidth: "20%"
            }}
          >
            <span>{t(props.namespace + group)}</span>
          </div>
          {groupCols}
        </div>
      );
      groupRows.push(RowTemplate);
    });
    const GridTemplate = (
      <Checkbox.Group
        style={{width: "100%"}}
        value={props.filters?.filter(el => el.activated).map(el => el.id)}
        onChange={checkedValue => {
          console.log("checked:", checkedValue);
          props.onFilter(checkedValue);
        }}
      >
        {groupRows}
      </Checkbox.Group>
    );
    return GridTemplate;
  };

  useEffect(async () => {
    setData(props.data);
    setColumns(props.data != null && props.data?.length != 0 ? Object.keys(props.data[0]) : []);
    setColumnGroups(props.options.find(el => el.type == "columnGroups")?.data);
    setHidden(props.options.find(el => el.type == "columnGroups")?.data.map(el => el.name));
  }, [props.data]);

  return (
    <div id="taxefy_table_main_div">
      <Modal
        width={"60%"}
        footer={
          <Button
            style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)"}}
            onClick={() => {
              setShowFilterDialog(false);
            }}
          >
            <CheckOutlined />
          </Button>
        }
        closable={false}
        onCancel={() => setShowFilterDialog(false)}
        title={<p style={{textAlign: "center"}}>Filter</p>}
        visible={showFilterDialog}
      >
        {renderFilters()}
      </Modal>
      <Modal
        footer={
          <Button
            style={{color: "white", backgroundColor: "rgba(0, 190, 170, 1)"}}
            onClick={() => {
              setShowSorterDialog(false);
            }}
          >
            <CheckOutlined />
          </Button>
        }
        onCancel={() => setShowSorterDialog(false)}
        closable={false}
        title={<p style={{textAlign: "center"}}>Sorter</p>}
        visible={showSorterDialog}
      >
        <table style={{width: "100%"}}>
          <thead>
            <tr>
              <td>Order</td>
              <td>Active</td>
              <td>Name</td>
              <td>Direction</td>
            </tr>
          </thead>
          <tbody>{renderSorterRow}</tbody>
        </table>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Checkbox.Group
          value={hidden}
          style={{
            userSelect: "none"
          }}
          options={columnGroups?.map(el => el.name)}
          onChange={value => {
            setHidden(value);
          }}
        />
        <div>
          <Button onClick={() => setShowFilterDialog(true)} style={{width: "150px"}}>
            Filter
          </Button>
          <Button onClick={() => setShowSorterDialog(true)} style={{width: "150px"}}>
            Sorter
          </Button>
        </div>
        <div>
          <Input.Search
            style={{width: "300px"}}
            size="small"
            placeholder={t(props.namespace + "search")}
            onInput={event => {
              console.log(event.target.value);
              props.onSearch(event.target.value);
            }}
          />
          <SearchOutlined
            onClick={() => {
              props.onReload();
              setSelectedRowKeys([]);
            }}
            style={{
              userSelect: "none",
              fontSize: 25,
              padding: "10px",
              margin: "3px",
              marginLeft: "30px",
              borderRadius: "100px",
              backgroundColor: "rgba(0, 190, 170, 1)",
              color: "white"
            }}
          />
        </div>
      </div>
      <Table
        scroll={{y: window.innerHeight > 1000 ? 770 : 370}}
        loading={!data}
        dataSource={data}
        rowSelection={rowSelection}
        columns={generateColumns.filter(el => !el.hidden)}
        pagination={{
          showQuickJumper: true,
          showTotal: total => `Total ${total}`,
          defaultPageSize: 10
        }}
      ></Table>
    </div>
  );
}
