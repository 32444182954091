import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "../CommonComponents/FormButton/FormButton";
import {cookieService} from "../../services/cookie.service";
import axios from "axios";
import environment from "../../environment";
import {notification} from "antd";
import {useHistory} from "react-router-dom";
import warningImage from "../../img/Profile/warning.jpg";

export default function RemoveAccountModal(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showActiveFormsError, setShowActiveFormsError] = useState(false);

  const removeAccount = async () => {
    setButtonDisabled(true);
    // Send deactivation
    const cookieToken = await new cookieService().getTokenData();
    try {
      const response = await axios.post(`${environment.baseUrl}user/deactivate` ,
        {},
        {
          headers: {
            Authorization: `Bearer ${cookieToken.refreshToken}`,
            "app-version": "1.0.1"
          }
        });
      if (response && response.status === 200) {
        // Remove cookie
        await new cookieService().removeTokenData();
        // logout user
        setTimeout(() => {
          setButtonDisabled(false);
          history.push("auth/login");
        }, 50);
      }
    } catch (e) {
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.data.message &&
        e.response.data.message === "active_forms"
      ) {
        setShowActiveFormsError(true);
      } else {
        setButtonDisabled(false);
        notification.open({
          message: t("profile.profile_tab.error_toast_title"),
          duration: 2
        });
      }
    }
  };

  return(
    <div>
      {showActiveFormsError
        ? <div className="warning_popup">
          <img src={warningImage} alt="warning"/>
          <h3>{t("profile.profile_tab.remove_account_modal.popup.title")}</h3>
          <p>{t("profile.profile_tab.remove_account_modal.popup.text")}</p>
          <Button
            text={t("profile.profile_tab.remove_account_modal.popup.btn")}
            handleClick={() => props.closeModal()}
          />
        </div>
        : <>
          <h3>{t("profile.profile_tab.remove_account_modal.title")}</h3>
          {/* eslint-disable-next-line max-len */}
          <p>{t("profile.profile_tab.remove_account_modal.text")}</p>
          <div className="remove_modal_btn_grid">
            <Button
              className="secondary_button"
              text={t("profile.profile_tab.remove_account_modal.cancel_btn")}
              handleClick={() => props.closeModal()}
              disabled={buttonDisabled}
            />
            <Button
              text={t("profile.profile_tab.remove_account_modal.delete_btn")}
              handleClick={() => removeAccount()}
              disabled={buttonDisabled}
              loading={buttonDisabled}
            />
          </div>
        </>
      }
    </div>
  );
}
