import React, { useEffect } from "react";

const StoresScreen = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any)?.opera;
    if (/android/i.test(userAgent)) {
      window.location.href = "https://play.google.com/store/apps/details?id=at.taxefy.app&hl=de_AT";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any)?.MSStream) {
      window.location.href = "https://apps.apple.com/at/app/taxefy-%C3%B6sterreichs-steuerapp/id1567697425";
    } else {
      window.location.href = "https://taxefy.at";
    }
  }, []);

  return (
    <div>
      <h1>Redirecting...</h1>
      {/* You can add a loading spinner or message here */}
    </div>
  );
};

export default StoresScreen;
