import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { Bar } from "react-chartjs-2";
import {cookieService} from "../../services/cookie.service";
import axios from "axios";
import environment from "../../environment";
import {Tooltip} from "antd";

export default function StatisticTab() {
  const { t } = useTranslation();

  const [statisticData, setStatisticData] = useState({});
  const [labels, setLabels] = useState([]);
  const [chartData, setChartData] = useState([]);

  const getStatisticData = async () => {
    const cookieToken = await new cookieService().getTokenData();
    const response = await axios.get(`${environment.baseUrl}form/userStatistic`, {
      headers: {
        Authorization: `Bearer ${cookieToken.refreshToken}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      setStatisticData(response.data);
      console.log(response.data);
      const labelsArray = [];
      Object.keys(response.data).map((label) => {
        if (+label > 0) {
          labelsArray.push(label);
        }
      });
      setLabels(labelsArray);
      const dataArray = [];
      Object.keys(response.data).map((label) => {
        if (+label > 0) {
          if (Object.keys(response.data[label]).length) {
            dataArray.push(response.data[label].amount);
          } else {
            dataArray.push(0);
          }
        }
      });
      setChartData(dataArray);
    }
  };

  useEffect(() => {
    getStatisticData();
  }, []);

  const data = {
    labels: labels,
    datasets: [
      {
        barPercentage: 0.9,
        categoryPercentage: 0.5,
        label: t("profile.statistic_tab.chart_label"),
        data: chartData,
        backgroundColor: function (tooltipItem) {
          if (
            statisticData
            && Object.keys(statisticData)
            && Object.keys(statisticData).length
            && tooltipItem.dataIndex
            && labels[tooltipItem.dataIndex]
            && statisticData[labels[tooltipItem.dataIndex]].status
            && statisticData[labels[tooltipItem.dataIndex]].status === "4"
          ) {
            return "rgba(0, 190, 170, 0.6)";
          } else {
            return "rgba(241, 130, 141, 0.7)";
          }
        },
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    cornerRadius: 30,
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          const item = data["datasets"][tooltipItem.datasetIndex];
          return `${item.label} ${+tooltipItem.value} €`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          gridLines: {
            drawOnArea: false,
            color: "rgb(100, 87, 232 ,0.1)",
            borderDash: [3, 3],
          },
          ticks: {
            suggestedMin: 0,
            stepSize: 200,
            fontColor: "#468a83", // #468a83
            callback: function (value) {
              return `€ ${value}`;
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawOnArea: false,
            color: "rgb(100, 87, 232 ,0.1)",
            borderDash: [3, 3],
          },
          ticks: {
            suggestedMin: 0,
            fontColor: "#7C828A",
            stepSize: 200,
          },
        },
      ],
    },
  };

  return(
    <div
      className="stat_container"
      style={{width: "100vw", height: "100%", minHeight: "calc(100vw - 150px)"}}
    >
      <div className="stat_header_grid">
        <Tooltip placement="bottom" title={t("profile.statistic_tab.tooltip_received")}>
          <div className="stat_card">
            <div>
              <i className="far fa-check-circle"></i>
              <p>{t("profile.statistic_tab.received_label")}</p>
            </div>
            <p>{statisticData.received} <span> €</span></p>
          </div>
        </Tooltip>
        <Tooltip placement="bottom" title={t("profile.statistic_tab.tooltip_pending")}>
          <div className="stat_card" style={{backgroundColor: "rgba(241, 130, 141, 0.7)"}}>
            <div>
              <i className="fas fa-spinner"></i>
              <p>{t("profile.statistic_tab.pending_label")}</p>
            </div>
            <p>{statisticData.pending} <span> €</span></p>
          </div>
        </Tooltip>
      </div>
      <h2>{t("profile.statistic_tab.overview_title")}</h2>
      <div className="stat_chart_container">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}
