import React, {useEffect, useRef, useState} from "react";
import "./QuestionComponentsStyles.css";
import ButtonWithCheck from "../CommonComponents/ButtonWithCheck/ButtonWithCheck";
import {findConditionByAnswer, getText} from "../../utils/tools";
import { motion, AnimatePresence } from "framer-motion";
import workErrorImage from "../../img/Forms/work_error.png";
import countryErrorImage from "../../img/Forms/country_error.png";
import {useTranslation} from "react-i18next";
import Button from "../CommonComponents/FormButton/FormButton";
import NavigationButtons from "./NavigationButtons/NavigationButtons";

export default function SingleChoice(props) {
  const { t } = useTranslation();

  const [answered, setAnswered] = useState(false);
  const [buttonBlocked, setButtonBlocked] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [componentVisible, setComponentVisible] = useState(true);

  const handleChoice = async (value) => {
    if (!buttonBlocked) {
      setButtonBlocked(true);
      setAnswered(true);
      props.setValue(value);
      const ansCopy = {...props.ANS};
      ansCopy.groups[props.ANS.current_group - 1].current_question = props.qs.id;
      const condition = await findConditionByAnswer(props.qs, value, ansCopy, props.QS);
      if (condition.abort_code === 0) {
        setComponentVisible(false);
        props.forward(condition);
      } else {
        let error = "";
        switch (props.qs.id) {
        case 2:
          error = "country";
        }
        setErrorMessage(error);
      }
      setTimeout(() => {
        setButtonBlocked(false);
      }, 500);
    }
  };

  useEffect(() => {
    setComponentVisible(true);
  }, [props.qs.id]);

  useEffect(() => {
    if (!answered && props.answr !== -1) {
      setAnswered(true);
    }
  }, [props.answr]);

  const isSelected = (value) => {
    return value === props.answr;
  };

  return(
    <AnimatePresence>
      {componentVisible
        ? <motion.div
          className="single_choice"
          initial={{ opacity: 0 }}
          animate={componentVisible && { opacity: 1 }}
          transition={{
            x: { type: "spring", stiffness: 100 },
            default: { duration: 0.5 },
          }}
        >

          {props.showButtonBack
            ? <NavigationButtons back={props.backward} forward={() => {
              if (answered) {
                props.forward(props.condition);
              }
            }} />
            : null
          }

          <div className="question_header_box">
            <h6>{getText(props.ANS, props.QS, props.qs.title, props.lang)}</h6>
            {props.qs.dsc && props.qs.dsc.length
              ? <div className="drawer_btn" onClick={() => {
                props.showDrawer(props.qs.dsc);
              }}>
                <i className="fas fa-question"></i>
              </div>
              : null
            }
          </div>
          <div
            className="choice_box"
            style={
              props.options
              && props.options.length === 2
                ? {gridTemplateColumns: "1fr 1fr"}
                : {}}
          >
            {props.options.map((option) => (
              <React.Fragment key={`${option.value}_${props.ANS.current_group - 1}`}>
                <ButtonWithCheck
                  selected={isSelected(option.value)}
                  withCheckbox={false}
                  text={getText(props.ANS, props.QS, option.title, props.lang)}
                  value={option.value}
                  handleClick={() => handleChoice(option.value)}
                />
              </React.Fragment>
            ))}
          </div>
          {errorMessage && errorMessage.length && <motion.div
            className="error_box"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              delay: 0,
              x: { type: "spring", stiffness: 100 },
              default: { duration: 0.3 },
            }}
          >
            <img src={errorMessage === "country" ? countryErrorImage : workErrorImage} alt="error"/>
            <h2>{errorMessage === "country"
              ? t("questions.warnings.country_title")
              : t("questions.warnings.country_title")}
            </h2>
            <p>{errorMessage === "country"
              ? t("questions.warnings.country_text")
              : t("questions.warnings.country_text")}
            </p>
            <Button
              style={{marginTop: "20px"}}
              text={t("questions.warnings.btn")}
              handleClick={() => setErrorMessage("")}
            />
          </motion.div>}
        </motion.div>
        : null
      }
    </AnimatePresence>
  );
}
