import React, {useState} from "react";
import "./CreatePartnerModal.css";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormikInput from "../../CommonComponents/FormikInput/FormikInput";
import Button from "../../CommonComponents/FormButton/FormButton";
import {partnersService} from "../../../services/partners.service";
import {notification} from "antd";
import {CopyOutlined} from "@ant-design/icons";

export default function CreatePartnerModal(props) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(undefined);

  const formik = useFormik({
    initialValues: {
      partnerName: ""
    },
    validationSchema: Yup.object({
      partnerName: Yup.string().required("required").max(30, "Maximum allowed are 30 characters"),
    }),
    // handle form submitting
    onSubmit: async () => {
      setButtonLoading(true);
      const response = await new partnersService().create({
        partnermanager: props.partnermanager,
        ...formik.values
      });
      if (response?.status) {
        setGeneratedLink(response.data.link);
        props.onSuccess();
      } else {
        notification.error({
          message: "Partner with this name or link path already exists",
          duration: 2
        });
      }

      setButtonLoading(false);
    },
  });

  return(
    <form onSubmit={formik.handleSubmit}>
      <h1>Neuen Link erstellen</h1>
      <FormikInput
        htmlFor="partnerName"
        name="partnerName"
        value={formik.values.partnerName}
        disabled={false}
        handleChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label={"Link Name"}
        placeholder="Wunschname"
      />
      <Button
        text={"Neuen Link erstellen"}
        style={{
          width: "100%",
          marginTop: "30px"
        }}
        buttonType="submit"
        loading={buttonLoading}
      />

      {false &&
        <>
          <CopyOutlined
            style={{fontSize: "30px", marginTop: "14px", marginBottom: "14px", cursor: "pointer"}}
            onClick={() => {
              navigator.clipboard.writeText(generatedLink);
              notification.success({
                message: "Link copied to clipboard",
                duration: 2
              });
            }}
          />
          <p>
            {generatedLink}
          </p>
        </>
      }

    </form>
  );
}
