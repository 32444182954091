import React, {useEffect, useState} from "react";
import "./styles.css";
import {cookieService} from "../../../../services/cookie.service";
import axios from "axios";
import environment from "../../../../environment";
import {Input, Table} from "antd";
import moment from "moment";
import UsersTableAction from "../../../../components/Admin/UserTableAction";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
const { Search } = Input;
const { Column } = Table;

export default function UsersPage() {

  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalForms, setTotalForms] = useState(0);
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const getUsersData = async (search, limit, skip) => {
    setLoading(true);
    const cookieToken = await new cookieService().getAdminTokenData();
    const queryParams = {
      skip: skip || 0,
      limit: limit || 10
    };
    if (search) {
      Object.assign(queryParams, {"search": search});
    }
    const response = await axios.get(`${environment.baseUrl}admin/users`, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${cookieToken.token}`,
        "app-version": "1.0.1"
      }
    });
    if (response && response.data) {
      setUsersData(response.data.data);
      setTotalForms(Number(response.data.total));
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsersData();
  }, []);

  const onSearch = (value) => {
    setSearchData(value);
    getUsersData(value, pageLimit, 0);
  };

  const paginate = (val, limit) => {
    if (pageLimit !== limit) {
      setPageLimit(limit);
    }
    const skip = (val - 1) * limit;
    getUsersData(searchData, limit, skip);
    setCurrentPage(val);
  };

  return (
    <div>
      <div className="space-between">
        <h3>Benutzer</h3>
        <Search
          placeholder="Search"
          onSearch={onSearch}
          className="admin_search"
        />
      </div>
      {loading ? (
        <p>Loading</p>
      ) : usersData && usersData.length ? (
        <Table
          dataSource={usersData}
          pagination={{
            current: currentPage,
            pageSize: pageLimit,
            total: totalForms,
            onChange: (val, limit) => paginate(val, limit)
          }}
        >
          <Column title="Id" dataIndex="id" key="id" />
          <Column
            title="Verified"
            dataIndex="verified"
            key="verified"
            render={(data) => {
              return <div style={{display: "flex", justifyContent: "start", paddingLeft: "15px"}}>
                {data ? <CheckCircleOutlined style={{fontSize: "25px",color: "rgba(0, 190, 170, 1)"}} /> : <CloseCircleOutlined style={{fontSize: "25px",color: "#aa4455"}} />}
              </div>;
            }}
          />
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Email" dataIndex="email" key="email" />
          <Column title="SVN" dataIndex="svn" key="svn" />
          <Column title="Language" dataIndex="language" key="language" />
          <Column
            title="Activated"
            dataIndex="activated"
            key="activated"
            render={(data) => {
              return <div style={{display: "flex", justifyContent: "start", paddingLeft: "15px"}}>
                {data ? <CheckCircleOutlined style={{fontSize: "25px",color: "rgba(0, 190, 170, 1)"}} /> : <CloseCircleOutlined style={{fontSize: "25px",color: "#aa4455"}} />}
              </div>;
            }}
          />
          <Column
            title="Form Filled"
            dataIndex="formFilled"
            key="formFilled"
            render={(data) => {
              return <div style={{display: "flex", justifyContent: "start", paddingLeft: "15px"}}>
                {data ? <CheckCircleOutlined style={{fontSize: "25px",color: "rgba(0, 190, 170, 1)"}} /> : <CloseCircleOutlined style={{fontSize: "25px",color: "#aa4455"}} />}
              </div>;
            }}
          />
          <Column
            title="Created"
            dataIndex="createdAt"
            key="createdAt"
            render={(data) => {return <div>{data.split("T").join(" ")}</div>;}}
          />
          <Column
            title="Action"
            render={data => {
              return (
                <UsersTableAction
                  userId={data.id}
                  refreshData={() => getUsersData()}
                />
              );
            }}
          />
        </Table>
      ) : (
        <p>No data</p>
      )}
    </div>
  );
}
