import React from "react";
import "./ErrorBox.css";
import {motion} from "framer-motion";

export default function ErrorBox(props) {
  return(
    <motion.div
      className="error_box_container"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{
        delay: 0.3,
        x: { type: "spring", stiffness: 100 },
        default: { duration: 1 },
      }}
    >
      <i className="fas fa-exclamation-triangle"></i>
      <p>{props.message}</p>
    </motion.div>
  );
}
